import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const InactivityHandler = () => {
  const navigate = useNavigate();
  let inactivityTimeout;

 
const user_role = localStorage.getItem("user_role");

  const handleSignOut = ()=>{ 
    if (user_role === "1") {
      navigate("/adminlogin");
    } else if (user_role === '5'){
      navigate('/customerportal')
    } else {
      navigate("/");
    }
    localStorage.clear();
    localStorage.setItem('signOutToken', Date.now().toString());

  }
  const resetTimer = () => {
    clearTimeout(inactivityTimeout);
    inactivityTimeout = setTimeout(() => {
        handleSignOut();
    }, 60 * 60 * 1000); // 60 minutes
  };

  useEffect(() => {
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('click', resetTimer);
    
    resetTimer();

    return () => {
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
      window.removeEventListener('click', resetTimer);
      clearTimeout(inactivityTimeout);
    };
  }, []);

  return null;
};

export default InactivityHandler;
