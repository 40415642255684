import React, { useState } from "react";
import { HiGift, HiOutlineGift, HiOutlineSquares2X2 } from "react-icons/hi2";
import PropTypes from "prop-types";
import {  Menu,  MenuHandler,  MenuList,  MenuItem,  Avatar,  Typography,} from "@material-tailwind/react";
import { FaMoneyBillAlt, FaProductHunt, FaThLarge, FaUser, FaUserSecret } from "react-icons/fa";
import { CgGift } from "react-icons/cg";
import { IoBookOutline, IoBookSharp, IoSettings, IoSettingsOutline, IoSettingsSharp } from "react-icons/io5";
import { BiSolidUser } from "react-icons/bi";
import { Link, useNavigate , useLocation, Routes, useParams} from "react-router-dom";
import { BsBell, BsFillBellFill } from "react-icons/bs";
import { useEffect } from "react";
import axios from "axios";
import { HiOutlineUserGroup, HiUserGroup } from "react-icons/hi2";
import ReduceCapacityOutlinedIcon from '@mui/icons-material/ReduceCapacityOutlined';
import GroupIcon from "@mui/icons-material/Group";

import { RiTicketLine, RiTicketFill } from "react-icons/ri";  // Ticket icons (outline and fill)
import { IoCloudUploadOutline, IoCloudUpload } from "react-icons/io5";   // Substitute release icons (outline and fill)
import { RiLinksLine, RiLinksFill } from "react-icons/ri";       // Link icons (outline and fill)
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ReceiptIcon from '@mui/icons-material/Receipt'; // Accounting icon
import { Grid, IconButton } from '@mui/material';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';

import { Tooltip } from "react-tippy";


import {

  MdPersonOutline,
} from "react-icons/md";

import config  from "../config/config"
import HeaderScrollingMessage from "./pages/ScrollingMessageHeader";
import { IceSkatingOutlined } from "@mui/icons-material";

function capitalizeFirstLetter(email) {
  return email.charAt(0).toUpperCase() + email.slice(1).toLowerCase();
}

Header.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
};
function Header({ toggleSidebar , handleSignOut }) {
  const contact_name = localStorage.getItem("userName")
  const contact_email = localStorage.getItem("userEmail")
  const navigate = useNavigate();
  const location = useLocation();
  const [isHovered, setIsHovered] = useState(false);
  const [isSetting, setIsSetting] = useState(false);
  const [isBookHovered, setIsBookHovered] = useState(false);
  const [isTicketHovered, setIsTicketHovered] = useState(false);
  const [isReleaseHovered, setIsReleaseHovered] = useState(false);
  const [isLinkHovered, setIsLinkHovered] = useState(false);
  const [isGiftHovered, setIsGiftHovered] = useState(false);
  const [isWindowsHovered, setIsWindowsHovered] = useState(false);
  const [isBellHovered , setIsBellHovered] = useState(false);
  const [isSpyHovered , setIsSpyHovered] = useState(false);
  const [isSpy , setIsSpy] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [businessType , setBusinessType] = useState("");
  const [userRole, setUserRole] = useState('');
  const [scrollingMessage ,setScrollingMessage] = useState('')
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const isCurrentRoute = (route) => location.pathname === route;
  const handleSettingIconClick = () => { setShowMenu(true);
  };
  const handleMenuItemClick = () => {
    setShowMenu(false);
  };

  useEffect(() => {
    const storedUserRole = localStorage.getItem("user_role");
    setUserRole(storedUserRole); 
  }, []); 
  const handleMouseEnter = (iconType) => {
    setIsTicketHovered(iconType === 'ticket');
    setIsReleaseHovered(iconType === 'Release');
    setIsLinkHovered(iconType === 'Link');
    setIsGiftHovered(iconType === 'Gift')
    setIsSpyHovered(iconType === 'Spy')
    setIsBellHovered(iconType === 'Bell')
    setIsHovered(iconType === 'Setting')
    // Add other icon states as needed
  };
  
  const handleMouseLeave = () => {
    setTimeout(() => {
      setIsTicketHovered(false);
      setIsReleaseHovered(false);
      setIsLinkHovered(false);
      setIsGiftHovered(false)
      setIsSpyHovered(false)

      setIsBellHovered(false)
      setIsHovered(false)

      // Reset other icon states
    }, 100); // Adjust the timeout duration as needed
  };
  
  const handleMouseEnterSetting = () => {setIsHovered(true);};
  const handleMouseLeaveSetting = () => {setIsHovered(false);};
  const handleBookMouseEnter =()=>{setIsBookHovered(true)}
  const handleBookMouseLeave =()=>{ setIsBookHovered(false)}
  const handleTicketMouseEnter =()=>{setIsTicketHovered(true)}
  const handleTicketMouseLeave =()=>{setIsTicketHovered(false) }
  const handleReleaseMouseEnter =()=>{setIsReleaseHovered(true)}
  const handleReleaseMouseLeave =()=>{ setIsReleaseHovered(false)}
  const handleLinkMouseEnter =()=>{setIsLinkHovered(true)}
  const handleLinkMouseLeave =()=>{ setIsLinkHovered(false)}
  const handleGiftMouseEnter =()=>{setIsGiftHovered(true)}
  const handleGiftMouseLeave =()=>{setIsGiftHovered(false)}
  const handleBellMouseEnter =()=>{
    setIsBellHovered(true);
    setIsLinkHovered(false)
    setIsTicketHovered(false)
    setIsReleaseHovered(false)
    setIsBookHovered(false)
    setIsGiftHovered(false)
  }
  const handleBellMouseLeave=()=>{
    setIsBellHovered(false);
    setIsLinkHovered(false)
    setIsTicketHovered(false)
    setIsReleaseHovered(false)
    setIsBookHovered(false)
    setIsGiftHovered(false)
  }
  const handlespyMouseEnter = () => setIsSpyHovered(true);
  const handlespyMouseLeave = () => setIsSpyHovered(false);
  const business_id = localStorage.getItem("business_id")
  const user_id = localStorage.getItem("user_id")
  const user_role = localStorage.getItem("user_role")
  const [businessData , setBusinessData] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
    .get(`${baseURL}${btoa("business/profile")}/${btoa(business_id)}/${btoa(user_id)}/${btoa(user_role)}`)
      .then((response) => {
        setBusinessData(response.data.business_data);
        setBusinessType(response.data.business_data[0].business_type)
      })
      .catch((error) => {
        console.error("Error fetching business data:", error);
       ;
      });
  }, []); 
  useEffect(() => {
    setIsSetting(location.pathname === "/products" || location.pathname === "/items" || location.pathname === "/business"
    || location.pathname === "/products/productform" || location.pathname === "/items/itemform" || location.pathname === "/business"
    || location.pathname === "/businessdashboard"  || location.pathname === "/viewbusinessusers" ||
    location.pathname === "/itemprice" || location.pathname === "/viewBusiness" || location.pathname === "/setup/viewitem" ||
    location.pathname.includes("/customers") || location.pathname.includes("/vendors")
    || location.pathname.includes("/salesTaxCustomers")
    || location.pathname.includes("/customerTax")
    
    || location.pathname.includes("/Pricing")
    || location.pathname.includes("/NewPrice")
    || location.pathname.includes("/UserDashBoard")
    ||  location.pathname.includes("/createvendor")
    ||  location.pathname.includes("/customerforms")
    
    ||  location.pathname.includes("/users")

     || location.pathname.includes("/viewvendor")
     ||  location.pathname.startsWith("/setup/viewitem/") 
    );
  }, [location.pathname]);
  
 useEffect(()=> {
if(location.pathname === "/AuditLog" ){ 
  
  setIsSpy(true)

} else{
  setIsSpy(false)
};
 }, [location.pathname])
  return (
    <>
      <header className="bg-[white] shadow h-14 flex  items-center justify-between  px-4 sticky top-0 z-50">
        <div>
          <HiOutlineSquares2X2
            className="w-6 h-6 cursor-pointer hover:text-[#3479E0]"
            onClick={toggleSidebar}
          />
        </div>
        <div className="flex-1 ml-3 mr-0">
          <HeaderScrollingMessage />

          </div>

        <div className="flex items-center space-x-2 mr-4">
 
<Grid item xs={12} md={8}>
  <Grid container alignItems="end" justifyContent="end" spacing={0}>

    {/* Ticket Icon */}
    <Grid item>
      <div
         onMouseEnter={() => handleMouseEnter('ticket')}
         onMouseLeave={handleMouseLeave}
        style={{
          width: '45px',
          height: '45px',
          backgroundColor:isTicketHovered ? 'rgba(173, 216, 230, 0.5)': 'transparent', // No background color initially
          color:isTicketHovered ? '#3479E0': 'grey', // No background color initially

          borderRadius: '50%',
          padding: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'background-color 0.3s ease',
          cursor: 'pointer', // Cursor change to indicate interactivity
        }}
      >
        {isTicketHovered ? (
          <RiTicketFill size={20} /> // Red filled icon on hover
        ) : (
          <RiTicketLine size={20}  /> // Outline icon with red color before hover
        )}
      </div>
    </Grid>

    {/* Release Icon */}
    <Grid item>
      <div
        onMouseEnter={() => handleMouseEnter('Release')}
        onMouseLeave={handleMouseLeave}
        style={{
          width: '45px',
          height: '45px',
          backgroundColor:isReleaseHovered ? 'rgba(176, 224, 230, 0.5)': 'transparent', // No background color initially
          color:isReleaseHovered ? '#25caed': 'grey', // No background color initially
          borderRadius: '50%',
          padding: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'background-color 0.3s ease',
          cursor: 'pointer', // Cursor change to indicate interactivity
    
        }}
      >
        {isReleaseHovered ? (
          <IoCloudUpload size={20}  /> // Skyline color on hover
        ) : (
          <IoCloudUploadOutline size={20}  /> // Outline icon before hover
        )}
      </div>
    </Grid>

    {/* Link Icon */}
    <Grid item>
      <div
       onMouseEnter={() => handleMouseEnter('Link')}
       onMouseLeave={handleMouseLeave}
        style={{
          width: '45px',
          height: '45px',
          borderRadius: '50%',
          padding: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'background-color 0.3s ease',
          cursor: 'pointer', // Cursor change to indicate interactivity
          backgroundColor:isLinkHovered ? 'rgba(173, 216, 230, 0.5)': 'transparent', // No background color initially
          color:isLinkHovered ? '#3479E0': 'grey', // No background color initially
    
        }}
      >
        {isLinkHovered ? (
          <RiLinksFill size={20}  /> // Blue filled icon on hover
        ) : (
          <RiLinksLine size={20}  /> // Outline icon before hover
        )}
      </div>
    </Grid>

  </Grid>
</Grid>

            <div
                   onMouseEnter={() => handleMouseEnter('Gift')}
                   onMouseLeave={handleMouseLeave}
                  style={{
                    backgroundColor: isGiftHovered ? "#dcd7fe" : "transparent",
                    color: isGiftHovered ? "#7e3af2" : "gray",
                    display: "inline-block",
                    padding: "10px",
                    borderRadius: "50%",
                    transition: "all 0.3s ease",
                
                  }}
                >
                  {isGiftHovered ? (
                    <HiGift  size={20} />
                  ) : (
                    <HiOutlineGift size={20} />
                  )}
            </div>
            <Menu>
              
              <MenuHandler>
                <div>
                  <div
                    onClick={handleSettingIconClick}
                    onMouseEnter={() => handleMouseEnter('Setting')}
                    onMouseLeave={handleMouseLeave}
                    style={{
                      backgroundColor: (isHovered || isSetting) ? "#C3DDFD" : "transparent",
                      color: (isHovered || isSetting) ? "#1C64F2" : "gray",
                      display: "inline-block",
                      padding: "10px",
                      borderRadius: "50%",
                      transition: "all 0.3s ease",
                      marginTop: "6px"
                    }}
                  >
                    {isHovered || isSetting ? <IoSettings size={20} /> : <IoSettingsOutline size={20} />}
                  </div>
                </div>
              </MenuHandler>
              {user_role !== '3'&& 

              <div
              onMouseEnter={() => handleMouseEnter('Spy')}
              onMouseLeave={handleMouseLeave}
                  onClick={() => navigate('/AuditLog')}

                  style={{
                    backgroundColor: isSpyHovered || isSpy ? "#dcd7fe" : "transparent",
                    color: isSpyHovered || isSpy ? "#7e3af2" : "gray",
                    display: "inline-block",
                    padding: "8px",
                    borderRadius: "50%",
                    transition: "all 0.3s ease",
                
                  }}
                >
                  {isSpyHovered || isSpy ? (
                    <FactCheckIcon  size={20} />
                  ) : (
                    <FactCheckOutlinedIcon size={20} />
                  )}
            </div>
}
              {userRole !== '1' && user_role !== '5' && user_role !== '3'&& (
                <MenuList className={`z-50 ${showMenu ? 'visible' : 'hidden'}`}>
                 
                  {userRole !== '3' && (
                    <>
                      {businessType === "1" && (
                        <>
                          <MenuItem
                            className={`flex items-center gap-2 ${isCurrentRoute('/products') ? 'bg-gray-200' : ''} hover:bg-gray-200`}
                            onClick={(e) => { e.stopPropagation(); navigate(`/products`); handleMenuItemClick(); }}
                            style={{
                              backgroundColor: isCurrentRoute('/products') ? '#E5E7EB' : 'transparent',
                              color: 'inherit'
                            }}
                          >
                            <FaProductHunt size={20} />
                            <Typography variant="small" className="font-normal">
                              Products
                            </Typography>
                          </MenuItem>

                          <MenuItem
                            className={`flex items-center gap-2 ${isCurrentRoute('/itemsdashboard') ? 'bg-gray-200' : ''} hover:bg-gray-200`}
                            onClick={() => { navigate(`/itemsdashboard`); handleMenuItemClick(); }}
                            style={{
                              backgroundColor: isCurrentRoute('/itemsdashboard') ? '#E5E7EB' : 'transparent',
                              color: 'inherit'
                            }}
                          >
                            <FaMoneyBillAlt size={20} />
                            <Typography variant="small" className="font-normal">
                              Items
                            </Typography>
                          </MenuItem>
                        </>
                      )}

                      {businessType === "2" && (
                        <MenuItem
                          className={`flex items-center gap-2 ${isCurrentRoute('/vendors') ? 'bg-gray-200' : ''} hover:bg-gray-200`}
                          onClick={() => { navigate(`/vendors`); handleMenuItemClick(); }}
                          style={{
                            backgroundColor: isCurrentRoute('/vendors') ? '#E5E7EB' : 'transparent',
                            color: 'inherit'
                          }}
                        >
                          <LocalShippingOutlinedIcon size={20} />
                          <Typography variant="small" className="font-normal">
                            Vendors
                          </Typography>
                        </MenuItem>
                      )}
                      <MenuItem
                        className={`flex items-center gap-2 ${isCurrentRoute('/customers') ? 'bg-gray-200' : ''} hover:bg-gray-200`}
                        onClick={() => { navigate(`/customers`); handleMenuItemClick(); }}
                        style={{
                          backgroundColor: isCurrentRoute('/customers') ? '#E5E7EB' : 'transparent',
                          color: 'inherit'
                        }}
                      >
                        <HiUserGroup size={20} />
                        <Typography variant="small" className="font-normal">
                          Customers
                        </Typography>
                      </MenuItem>
                      {businessType === "2" && (
                        <MenuItem
                          className={`flex items-center gap-2 ${isCurrentRoute('/UserDashBoard') ? 'bg-gray-200' : ''} hover:bg-gray-200`}
                          onClick={() => { navigate(`/UserDashBoard`); handleMenuItemClick(); }}
                          style={{
                            backgroundColor: isCurrentRoute('/UserDashBoard') ? '#E5E7EB' : 'transparent',
                            color: 'inherit'
                          }}
                        >
                          <GroupIcon size={20} />
                          <Typography variant="small" className="font-normal">
                            Users
                          </Typography>
                        </MenuItem>
                      )}
                      <MenuItem
                        className={`flex items-center gap-2 hover:bg-gray-200`}
                        onClick={() => { navigate(`/salesTaxCustomers`);  handleMenuItemClick(); }}
                        style={{
                          backgroundColor: 'transparent',
                          color: 'inherit'
                        }}
                      >
                        <MonetizationOnIcon size={20} />
                        <Typography variant="small" className="font-normal">
                          Sales Tax
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        className={`flex items-center gap-2 hover:bg-gray-200`}
                        onClick={() => { navigate(`/Pricing`); handleMenuItemClick(); }}
                        style={{
                          backgroundColor: 'transparent',
                          color: 'inherit'
                        }}
                      >
                        <ReceiptIcon size={20} />
                        <Typography variant="small" className="font-normal">
                          Price Book
                        </Typography>
                      </MenuItem>
                    </>
                  )}
                </MenuList>
              )}
            </Menu>
          
          
            {/* <div
                  onMouseEnter={handleWindowsMouseEnter}
                  onMouseLeave={handleWindowsMouseLeave}
                  style={{
                    backgroundColor: isWindowsHovered ? "#e0f7fa" : "transparent",
                    color: isWindowsHovered ? "#00acc1" : "gray",
                    display: "inline-block",
                    padding: "10px",
                    borderRadius: "50%",
                    transition: "all 0.3s ease",
                  
                  }}
                >
                  {isWindowsHovered ? (
                    <AiFillWindows  size={20} />
                  ) : (
                    <AiOutlineWindows size={20} />
                  )}
            </div> */}


      
          {/* <FaUserSecret
            className="text-gray-400 hover:text-orange-600  hover:bg-orange-200 rounded-full  p-2"
            size={37}
            onClick={()=> {navigate('/AuditLog')}}
          /> */}
          {/* <i className="fa-thin fa-user-secret" style={{ textShadow: '0 0 3px white', textStroke: '1px black'}} 
           onClick={() => navigate('/AuditLog')}></i> */}
           {/* {isSpyHovered ||isSpy  ?
          <FactCheckIcon 
  className="text-black hover:bg-gray-300 hover:text-black rounded-full p-2"
  size={37}
  onClick={() => navigate('/AuditLog')}
  // onMouseEnter={handlespyMouseEnter} 
  onMouseLeave={handlespyMouseLeave}
/> :

      <FactCheckOutlinedIcon 
      onMouseEnter={handlespyMouseEnter} 
      size={37}
      // onMouseLeave={handlespyMouseLeave}
      className="text-black hover:bg-gray-300 hover:text-black rounded-full p-2" onClick={()=> {navigate('/AuditLog')}}/>
    } */}

      {/* <img src={spyiconfill} alt="SMS Icon" style={{ width: '18px', height: '19px', marginLeft: '8px' }} onClick={()=> {navigate('/AuditLog')}}/> */}




       {user_role !== "1" && (   
          <div
          onMouseEnter={() => handleMouseEnter('Bell')}
          onMouseLeave={handleMouseLeave}
            style={{
              backgroundColor: isBellHovered ? "#f8d1e8" : "transparent",
              color: isBellHovered ? "#d61f69" : "gray",
              display: "inline-block",
              padding: "10px",
              borderRadius: "50%",
              transition: "all 0.3s ease",
            }}
          >
            {isBellHovered ? (
              <BsFillBellFill size={20} />
            ) : (
              <BsBell size={20} />
            )}
          </div> )}


          <Menu>
            <MenuHandler>
              <div className="cursor-pointer">
                <BiSolidUser
                  className="text-green-500  hover:bg-green-100 rounded-full  p-2"
                  size={39}
                />
              </div>
            </MenuHandler>
            <MenuList className="z-50 w-64">
            <MenuItem className="flex items-center gap-2 py-1 px-4 rounded-md bg-balck-100 dark:hover:bg-gray-800 transition-colors">
  {/* Icon */}
  <MdPersonOutline className="text-gray-700 dark:text-gray-300 w-6 h-6" />

  {/* Contact Info */}
  <div className="text-gray-900 dark:text-white">
    <div className="text-sm font-medium">{contact_name}</div>
    {/* Uncomment to display email */}
    {/* <div className="text-xs font-light text-gray-500 truncate">{capitalizeFirstLetter(formatEmail(contact_email))}</div> */}
  </div>
</MenuItem>


              <hr className="my-2 border-blue-gray-50" />
              {userRole !== '1' && user_role !== '5' && (
              <MenuItem className="flex items-center gap-3 py-2 px-4 rounded-md hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
                 onClick={() => {
                  if (userRole === '2') {
                    navigate("/viewBusiness");
                  } else if (businessData.length > 0 && userRole === '3') {
                    navigate("/viewbusinessusers");
                  } 
                  
                  else {
                    navigate("/business");
                  }
                  handleMenuItemClick();
                }}
                style={{
                  // backgroundColor: isCurrentRoute('/viewBusiness') || (businessData.length > 0 && userRole === '3' && isCurrentRoute('/viewbusinessusers')) ? '#E5E7EB' : '',
                  // color: 'inherit'
                }}>
  {/* User Icon */}
  <MdPersonOutline className="text-gray-700 dark:text-gray-300 w-6 h-6" />

  {/* Typography with User Profile Text */}
  <Typography variant="small" className="font-normal text-gray-900 dark:text-white">
    User Profile
    <br />
    <span style={{ fontSize: '12px', color: '#6b7280' }}>User Details and Credentials</span>
  </Typography>
</MenuItem>
              )}

            

            </MenuList>
          </Menu>
        </div>
      </header>
        
    </>
  );
}

export default Header;
