import React, { useState, useMemo, useEffect, useRef } from 'react';
import { BiSolidUser } from "react-icons/bi";
import { useNavigate , useLocation } from "react-router-dom";

const UserForm = () => {
        const [activeItem, setActiveItem] = useState("View");
        const navigate = useNavigate()
      
            const handleMenuClick = (item) => {
              setActiveItem(item);
            };
                const [isSubtotalExpanded, setSubtotalExpanded] = useState(false);
                  const [isTaxesExpanded, setTaxesExpanded] = useState(false);
                  
                  const toggleSubtotal = (e) => {
                    e.preventDefault();
                    setSubtotalExpanded(!isSubtotalExpanded); // Toggle Subtotal
                    setTaxesExpanded(false); // Ensure Taxes is closed
                  };
                  
                  const toggleTaxes = (e) => {
                    e.preventDefault();
                    setTaxesExpanded(!isTaxesExpanded); // Toggle Taxes
                    setSubtotalExpanded(false); // Ensure Subtotal is closed
                  };
  return (
    <div className=" flex flex-col items-center justify-center  mt-5"

    >
         <div className="flex mb-6 justify-center p-4 rounded-md font-bold text-md items-center w-full mx-auto py-2 bg-white mt-3 ">
      {/* View */}
      <div
        className={`flex items-center cursor-pointer px-4 py-1 ${
          activeItem === "View" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
        } border-b border-gray-300  `}
        onClick={() => handleMenuClick("View")}
      >
        {/* <FaEye className="mr-2" /> */}
        <span>View</span>
      </div>

      <div
        className={`flex items-center cursor-pointer px-4 py-1 ${
          activeItem === "Download" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
        } border-b border-gray-300 `}
        onClick={() =>{handleMenuClick("Download");}}
      >
        {/* <FaDownload className="mr-2" /> */}
        <span>Edit</span>
      </div>
      {
      <div
        className={`flex items-center cursor-pointer px-4 py-1 ${
          activeItem === "Edit" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
        } border-b border-gray-300 `}
      onClick={() => {handleMenuClick("Edit");}}

      >
        {/* <FaDownload className="mr-2" /> */}
        <span>Delete</span>
      </div>
      }
    

    </div>
    {/* <div className="flex justify-between w-full gap-4 overflow-hidden w-[880px] mb-4 "
    style={{      height:'120px'
    }}>
  <div
    className={` flex flex-col items-center justify-between w-1/5 bg-white rounded-lg p-4 relative hover:scale-105  transition-all duration-300`}
    style={{
      boxShadow: '0 0 20px 0 rgba(0, 0, 0,.3)',
      height: '90px', 
    }}
    
  >
    <div className="flex justify-between w-full">
      <span className="text-sm text-gray-500 font-bold">USERS</span>
    </div>
    <div>
      <h2 className="absolute bottom-2 left-4 text-2xl font-bold text-grey-500 mt-2">10</h2>
    </div>
    <div className="absolute bottom-0 right-0 w-[100px] h-[55px] bg-gradient-to-tl from-[#ffffff] to-transparent rounded-tl-full flex items-center justify-center">
      <BiSolidUser sx={{ fontSize: 30 }} className="text-grey-500 mt-2" />
    </div>
  </div>

  <div className="w-2/5"></div>

  <div
    className="w-2/5 bg-gray-100 rounded-md p-4 border border-gray-300"
    style={{
      boxShadow: '0 0 20px 0 rgba(0, 0, 0,.1)',
    }}
  >
    <div className="flex justify-between items-center mb-2">
      <div className="flex items-center gap-2">
        <button onClick={toggleSubtotal} className="text-blue-500">
          {isSubtotalExpanded ? '▼' : <span className="inline-block text-xl leading-none">►</span>}
        </button>
        <span className="text-gray-800 font-medium">Total User Licenses</span>
      </div>
      <span className="text-gray-800 font-medium">0</span>
    </div>

    {isSubtotalExpanded && (
      <>
        <div className="ml-6 mb-4">
          <div className="flex justify-between">
            <span className="capitalize">Owners Count:</span>
            <span className="font-normal">0</span>
          </div>

          <li className="flex justify-between font-normal pt-2">
            <span>Users Count:</span>
            <span className="border-t border-black text-right">0</span>
          </li>
        </div>
      </>
    )}
  </div>
</div> */}


      {/* Form Container */}
      <div className="flex justify-between bg-white  rounded-lg p-8 w-[880px] mx-auto"
                  style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)" }}

      >
        <div className="flex flex md:flex gap-8 " 

        >
          {/* Left Section */}
          <div className="flex flex-col items-center justify-center space-y-4 w-[300px]">
            <div className="w-16 h-16 bg-emerald-500 rounded-full flex items-center justify-center">
               <BiSolidUser
                                className="text-green-500 rounded-full  p-2"
                                size={80}
                              />
            </div>
            <h2 className="text-lg font-medium text-gray-700">New User Info</h2>
          </div>

          {/* Right Section - Form */}
          <div className="space-y-4">
            {/* User Name */}
            <div className="space-y-1">
              <label className="block text-sm font-medium text-gray-700">
                User Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                placeholder="Enter User Name"
                className="w-full p-1 border border-gray-300 rounded-md"
              />
            </div>

            {/* Contact Email */}
            <div className="space-y-1">
              <label className="block text-sm font-medium text-gray-700">
                Contact Email <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                placeholder="Enter Contact Email"
                className="w-full p-1 border border-gray-300 rounded-md  "
              />
            </div>

            {/* Contact Number */}
            <div className="space-y-1">
              <label className="block text-sm font-medium text-gray-700">
                Contact Number <span className="text-red-500">*</span>
              </label>
              <input
                type="tel"
                placeholder="Enter Contact Number"
                className="w-full p-1 border border-gray-300 rounded-md "
              />
            </div>

            {/* Buttons */}
            <div className="flex justify-end gap-4 mt-10">
          
              <button
                 style={{fontFamily:'poppins'}}
                //   type="submit"
               
                  className='w-40 px-2 px-12 py-1 border-2 
                  border-blue-500 text-blue-500 hover:font-bold rounded-md hover:bg-blue-700 
                  hover:shadow-blue-700/50 uppercase duration-200 hover:text-white 
                  hover:shadow-lg hover:border-transparent active:scale-90 "
'
                >
                  SUBMIT
                </button>
                <button 
                onClick={()=>{navigate('/UserDashBoard')}}
              className="w-40 px-2 px-12 py-1 border-2 border-red-500 text-red-500 hover:font-bold
               rounded-md hover:bg-[#FF0001] hover:shadow-red-700/50
               uppercase duration-200 hover:text-white hover:shadow-lg hover:border-transparent active:scale-90">
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
