
import React, { useEffect } from "react";
import { useState } from "react";
import { FaEnvelope, FaLock, FaSignInAlt } from "react-icons/fa";
// import img1 from "../../assets/images/Login.jpg"
// import img2 from "../../assets/images/snapedit_1701426729284.png";
import { IoPerson } from "react-icons/io5";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup"
import Header from "../Headerside";
import { RiLoginCircleFill } from "react-icons/ri";
import { MdOutlineCancel } from "react-icons/md";
import { MdAttachEmail } from "react-icons/md";  
import { FaMobileButton } from "react-icons/fa6";
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { FaCheckCircle } from "react-icons/fa";
import {  FaEye, FaEyeSlash } from 'react-icons/fa';
import { MdBusinessCenter } from "react-icons/md";
import config  from "../../config/config"
import PersonIcon from "@mui/icons-material/Person";
import InvoiceFileLogo from '../../assets/images/InvoiceFileLogo.png'
import { useDispatch } from "react-redux";
import { addToast } from "./ToastBox/ToastBoxMessageSlice";

const SignUpPage = ({ onLogin  , handleCancelClick}) => {
  const dispatch = useDispatch()

  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError , setPasswordError] = useState("")
  const [invalidUserError, setinvalidUserError] = useState(false)

    const [invalidemailError, setinvalidEmailError] = useState(false)
    const [invalidMobileError, setinvalidMobileError] = useState(false)
    const [invalidDomainError, setinvalidDomainError] = useState(false)

  const [formData, setFormData] = useState({
    email: "",
  });
  const [userNameinputStyles, setUserNameInputStyles] = useState(
    'pl-10 w-full px-3 py-2  rounded-lg focus:ring-blue-500 focus:border-blue-500 pr-10'
  );
  const [userEmailinputStyles, setUserEmailInputStyles] = useState(
    'pl-10 w-full px-3 py-2  rounded-lg focus:ring-blue-500 focus:border-blue-500 pr-10'
  );
  const [userMobileinputStyles, setUserMobileInputStyles] = useState(
    'pl-10 w-full px-3 py-2  rounded-lg focus:ring-blue-500 focus:border-blue-500 pr-10'
  );
  const [userDomaininputStyles, setUserDomainInputStyles] = useState(
    'pl-10 w-full px-3 py-2  rounded-lg focus:ring-blue-500 focus:border-blue-500 pr-10'
  );
  // const [passwordVisible, setPasswordVisible] = useState(false);
  // const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);


  const [showDomainNameTooltip, setShowDomainNameTooltip] = useState(null);
  const [showUserNameTooltip, setShowUserNameTooltip] = useState(null);
  const [showMobileNumberTooltip, setShowMobileNumberTooltip] = useState(null);

  const [showPasswordTooltip, setShowPasswordTooltip] = useState(null);
  const [showConfirmPasswordTooltip, setShowConfirmPasswordTooltip] = useState(null);
  const [invalidPhoneNumber , setInvalidPhoneNumber] = useState("")

  const CustomTooltip1 = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? 'block' : 'none',
          position: 'absolute',
          backgroundColor: '#ffffff',
          color: '#fff',
          padding: '5px',
          borderRadius: '4px',
          border:'1px solid red',
          marginTop: '-35px',
          marginLeft: `${marginLeft}px`,  // Use the provided marginLeft prop
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div
          style={{
            position: 'absolute',
            bottom: '-7px',
            left: '50%',
            marginLeft: '-10px',
            width: '0',
            height: '0',
            borderLeft: '7px solid transparent',
            borderRight: '7px solid transparent',
            borderTop: '7px solid #f98080',
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };

  const DomainNameTooltip = ({ content, styles }) => {
    return (
      <div
        style={{
          display: content ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: `1px solid ${styles.borderColor || "red"}`, // Adjust border color as needed
          // left: "309px", // Adjust this value as needed
          // marginTop: "-3px",
          // right: "20px", // Adjust this value as needed
          right : "80px", 
          marginTop: "44px",
            right: "10px",
          zIndex: 1,
          width: "225px",
          ...styles,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "-7px",
            marginTop: "-7px",
            width: "0",
            height: "0",
            borderTop: "7px solid transparent",
            borderBottom: "7px solid transparent",
            borderRight: `7px solid ${
              styles.arrowStyle?.borderTopColor || "red" // Adjust arrow color as needed
            }`,
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };
  


  const UsernameTooltip = ({ content, styles }) => {
       return (
      <div
        style={{
          display: content ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: `1px solid ${styles.borderColor || "red"}`,
          // left : "309px", 
          right : "80px", 
          // marginTop: "-3px",
          marginTop: "44px",
            right: "10px",
          // right: "20px",
          zIndex: 1,
          width : "205px",
          ...styles,
        }}
      >
        <div
          style={{
          position: "absolute",
          top: "50%",
          left: "-7px",
          marginTop: "-7px",
          width: "0",
          height: "0",
          borderTop: "7px solid transparent",
          borderBottom: "7px solid transparent",
          borderRight: `7px solid ${styles.arrowStyle?.borderTopColor || "red"}`,
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };
  
 


  const EmailTooltip = ({ content, styles }) => {
    return (
      <div
      style={{
        display: content ? "block" : "none",
        position: "absolute",
        backgroundColor: "#ffffff",
        color: "#fff",
        padding: "5px",
        borderRadius: "4px",
        border: `1px solid ${styles.borderColor || "red"}`,
        // left : "309px", 
        right : "80px",
        marginTop: "44px",
        right: "10px", // Adjust this value as needed
        zIndex: 1,
        width : "125px",
        ...styles,
      }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "-7px",
            marginTop: "-7px",
            width: "0",
            height: "0",
            borderTop: "7px solid transparent",
            borderBottom: "7px solid transparent",
            borderRight: `7px solid ${styles.arrowStyle?.borderTopColor || "red"}`,
            }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };
  

  const MobileNumberTooltip = ({ content, styles }) => {
    return (
      <div
        style={{
          display: content ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: `1px solid ${styles.borderColor || "red"}`,
          // left : "308px", 
          // marginTop: "-3px",
          // right: "18px", 
          right : "80px", 
          // marginTop: "-3px",
          marginTop: "38px",
            right: "10px",
          zIndex: 1,
          width: "125px", // Adjust this value as needed
          ...styles,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "-7px",
            marginTop: "-7px",
            width: "0",
            height: "0",
            borderTop: "7px solid transparent",
            borderBottom: "7px solid transparent",
            borderRight: `7px solid ${styles.arrowStyle?.borderTopColor || "red"}`,
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };

  const PasswordTooltip = ({ content, styles }) => {
    return (
      <div
        style={{
          display: content ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: `1px solid ${styles.borderColor || "red"}`,
          left : "309px",
          marginTop: "19px",
          right: "18px",
          width : "165px",
          zIndex: 1,
          ...styles,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "-7px",
            marginTop: "-7px",
            width: "0",
            height: "0",
            borderTop: "7px solid transparent",
            borderBottom: "7px solid transparent",
            borderRight: `7px solid ${styles.arrowStyle?.borderTopColor || "red"}`,
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };

  const ConfirmPasswordTooltip = ({ content, styles }) => {
    return (
      <div
        style={{
          display: content ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: `1px solid ${styles.borderColor || "red"}`,
          left : "309px",
          marginTop: "19px",
          right: "18px",
          width : "165px",
          zIndex: 1,
          ...styles,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "-7px",
            marginTop: "-7px",
            width: "0",
            height: "0",
            borderTop: "7px solid transparent",
            borderBottom: "7px solid transparent",
            borderRight: `7px solid ${styles.arrowStyle?.borderTopColor || "red"}`,
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };

  // const shouldDisableSubmit = () => {
  //   return (
  //     showTooltip() ||
  //     showUserNameTooltip ||
  //     showMobileNumberTooltip ||
  //     (formik.values.password &&
  //       typeof formik.values.password === 'string' &&
  //       formik.values.password.length > 0 &&
  //       formik.values.password.length < 6) ||
  //     (formik.values.confirmPassword &&
  //       typeof formik.values.confirmPassword === 'string' &&
  //       formik.values.confirmPassword.length > 0 &&
  //       formik.values.confirmPassword.length < 6)
  //   );
  // };
  
  

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Invalid email address")
        .test({
          name: 'custom-email-validation',
          test: (value) => {
            return /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/.test(value);
          },
          message: 'Invalid email address',
        }),
    }),
    initialTouched: { 
      email: true,
    },
  });

  const handleLogin = () => {


  };

  let navigate = useNavigate();

  const handleForgotPassword = () => {
    navigate("/forgotpassword");
  };

  const handleSignUpClick =(e)=>{
      e.preventDefault()

    console.log("Register Data:" , {
      domainName : formik.values.domainName,
      userName :  formik.values.userName,
      userEmail : formik.values.email,
      contactNumber : formik.values.mobileNumber
     });

   
              const response = axios.post(`${baseURL}/${btoa("login/managersignup")}` , {
                domainName : formik.values.domainName,
                userName :  formik.values.userName,
                userEmail : formik.values.email,
                contactNumber : formik.values.mobileNumber
              })
              .then((response)=>{
               
                console.log("response" , response);
               
                if (response.data.dataSavingStatus === true && response.data.otpSentStatus === true) {
                  // alert(`signUp successfull "This is your password for login ${response.data.passWord}`)
                  // toast.success(`${formik.values.userName} you have been registered successfully` , {autoClose:1500}) // Call the function to show the login success toast
                  dispatch(addToast({ type: 'success', message: `${formik.values.userName} you have been registered successfully` }));
             
                } else {
                  // Handle other conditions based on your API response
                  console.log("Signup was not successful");
                }
                handleCancelClick();
              })
             
              .catch((error)=>{
                console.log("error posting details " , error);
                if( error.response.data.userStatus === "Email already exist" && error.response.data.dataSavingStatus === false){
                  setPasswordError("Email / Mobile number already exist")
                }
                if(error.response.data.userStatus === "Domain name does not exist" &&
                   error.response.data.dataSavingStatus === false
                ){
                  // toast.error("Domain name does not exist")
                  dispatch(addToast({ type: 'error', message: `Domain name does not exist` }));
                  
                }
              })
      }
  

  const showLoginSuccessToast = (userName) => {
    toast.success(
      <div className="flex justify-between items-center text-green-500">
        <div className="rounded-lg">{`${userName} , you have been successfully registered`}</div>
        <div className="rounded-lg">
          <FaCheckCircle className="mr-4 text-green-500" size={24} />
        </div>
      </div>,
      {
        position: "top-left",
        hideProgressBar: true,
        autoClose: 4000,
        icon: false,
        className: "w-[643px] ml-[430px] mt-[70px]", // Full width

        style: {
          borderLeft: "4px solid #4CAF50", // Green border
          background: "#e8f5e9", // Light green background
          minHeight: '10px',
        },
      }
    );
  };
  


  const validateEmail =(email)=>{
    const emailRegex = /^.+@.+\..+$/
    return emailRegex.test(email)
  }

  const showTooltip = () => {
    const isInvalidEmail = !validateEmail(formik.values.email);
    return (
      formik.touched.email &&
      formik.values.email.length > 0 &&
      isInvalidEmail && {
        borderColor: "red",
        arrowStyle: {
          borderTopColor: "red",
        },
      }
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    formik.handleChange(e);
    formik.setFieldValue(name, value.trim());
    setFormData((prevData) => ({
      ...prevData,
      [name]: value.trim(), 
    }));
   
  };

  function formatPhoneNumber(value) {
    // Check if value is undefined or null
    if (value == null) {
      return '';
    }
  
    // Remove non-digit characters
    const digitsOnly = value.replace(/\D/g, '');
  
    // Limit to a maximum of 10 digits
    const limitedDigits = digitsOnly.slice(0, 10);
  
    // Apply formatting based on the length
    if (limitedDigits.length >= 4 && limitedDigits.length <= 6) {
      return `(${limitedDigits.slice(0, 3)})${limitedDigits.slice(3)}`;
    } else if (limitedDigits.length >= 7) {
      return `(${limitedDigits.slice(0, 3)}) ${limitedDigits.slice(3, 6)}-${limitedDigits.slice(6)}`;
    } else {
      return limitedDigits;
    }
  }
  



  const handlePhoneNumberChange = (fieldName, e) => {
    const inputValue = e.target?.value ?? ''; 
  
    const formattedValue = formatPhoneNumber(inputValue);
  
    formik.handleChange({
      target: {
        name: fieldName,
        value: formattedValue,
      },
    });
  };


  const isUserNameValid = (userName) => {
   
    return /^[a-zA-Z]+$/.test(userName) && userName.length >= 3;
  };

  const isDomainNameValid = (domainName) => {
   
    return /^[a-zA-Z]+$/.test(domainName) && domainName.length >= 3;
  };
  



  return (
    <div className="flex h-screen w-screen">
    {/* Left Section (3/4 width) */}
    <div className="w-3/5 bg-gradient-to-br from-[#235DB6] to-[#0044AB] flex flex-col justify-center items-center">
      <div className="text-center text-white max-w-[500px] px-8">
      <h1 className="text-5xl font-bold m-auto ml-[50px]">
        <img
           style={{ width: '300px',
            height: '80px'}}
            src={ InvoiceFileLogo}
            alt="InvoiceFile"
            className={`${ "w-max"}`}
          />

        </h1>
        <p className="text-xl font-normal mb-14 text-white-300">Smart Invoice Processing</p>
        <h2 className="text-[28px] font-bold mb-4">
          Create Professional Invoices to Send to Your Customers
        </h2>
        <p className="text-base leading-relaxed ">
          Streamline your billing process with 
        </p>
        <p className="text-md leading-relaxed ">
        professional, easy-to-use
        templates.
        </p>
      </div>
    </div>

    {/* Right Section (1/4 width) */}
    <div className="w-2/5 flex justify-center items-center">
      <div className="w-full max-w-sm px-8 py-12">
        <div className="text-center mb-4">
          <div className="w-24 h-24 bg-gray-200 rounded-full m-auto flex items-center justify-center">
      <PersonIcon style={{ fontSize: 50, color: "gray" }} />

          </div>
          <h2 className="text-2xl text-gray-600 font-bold mt-2">Sign Up</h2>
        </div>

        <form className="space-y-3" >
        { invalidUserError && (
                  <UsernameTooltip content={showUserNameTooltip} styles={{}} />
                )}
          <div className="relative">
            <label htmlFor="userName" className="block text-sm font-medium text-gray-700">
              User Name
            </label>
            <div className="relative">
                    <IoPerson
                      className="text-gray-500 absolute top-1/2 transform -translate-y-1/2 left-3"
                      style={{  fontSize: "20px" }}
                    />
            <input
              type="text"
              name="userName"
              id="userName"
              value={formik.values.userName}
              onFocus={()=> {setinvalidUserError(false)}}
              onChange={(e) => {
                const inputUserName = e.target.value;
            
                // Restrict input to alphabets only
                const sanitizedUserName = inputUserName.replace(/[^a-zA-Z\s]/g, '');
            
                formik.setFieldValue('userName', sanitizedUserName);
            
                if (sanitizedUserName.trim().length < 3) {
                  const tooltipMessage = "Username should be at least 3 char";
                  setShowUserNameTooltip(tooltipMessage);
                } else {
                  setShowUserNameTooltip(null);
                }
              }}
              onBlur={() => {
                setinvalidUserError(true)
                // formik.handleBlur()
                if (formik.values.userName) {
                  setUserNameInputStyles(
                    'pl-10 w-full px-3 py-2 border-2 rounded-lg bg-green-100 text-green-600 border-green-600 pr-10 '
                  );
                } else {
                  setUserNameInputStyles(
                    'pl-10 w-full px-3 py-2 border-2 rounded-lg pr-10 '
                  ); 
                }
              }}
              placeholder="Enter your user name"
              autoComplete="off"
              className={`${userNameinputStyles} pl-10 w-full px-3 py-2 border rounded-lg focus:ring-blue-500 focus:border-blue-500 ${
                ( showUserNameTooltip  )  ? "border-red-500" : "border-gray-300"
              } `}
            />
            </div>
          </div>
          {invalidemailError && showTooltip() && (
                      <EmailTooltip content="Invalid email address" styles={showTooltip()} />
                    )}
          <div className="relative">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <div className="relative">
                    <MdAttachEmail
                      className="text-gray-500 absolute top-1/2 transform -translate-y-1/2 left-3"
                      style={{ fontSize: "18px" }}
                    />
            <input
              type="text"
              name="email"
              id="email"
              autoComplete="off"
              value={formik.values.email}
              onChange={(e) => {
                handleInputChange(e);
                formik.setFieldValue('email', e.target.value.trim());
                
              }}
              // onBlur={formik.handleBlur}
              onFocus={()=> setinvalidEmailError(false)}
              onBlur={() => {
                setinvalidEmailError(true)
                // formik.handleBlur()
                if (formik.values.email) {
                  setUserEmailInputStyles(
                    'pl-10 w-full px-3 py-2 border-2 rounded-lg bg-green-100 text-green-600 border-green-600 pr-10 '
                  );
                } else {
                  setUserEmailInputStyles(
                    'pl-10 w-full px-3 py-2 border-2 rounded-lg  pr-10 '
                  );
                }
              }}
              placeholder="Enter your email address"
              className={`${userEmailinputStyles} pl-10 w-full px-3 py-2 border rounded-lg focus:ring-blue-500 focus:border-blue-500 ${
                showTooltip() && invalidemailError  ? "border-red-500" : "border-gray-300"
              } `}
            />
            </div>
          </div>
          {invalidMobileError && (
  <>
    <MobileNumberTooltip content={showMobileNumberTooltip} styles={{}} />
    {invalidPhoneNumber === "Invalid number" && !showMobileNumberTooltip && (
      <MobileNumberTooltip content="Invalid number" styles={{}} />
    )}
  </>
)}

          <div className="relative">
            <label htmlFor="mobileNumber" className="block text-sm font-medium text-gray-700">
              Mobile Number
           
            </label>
            <div className="relative">
                    <FaMobileButton 
                      className="text-gray-500 absolute top-1/2 transform -translate-y-1/2 left-3"
                      style={{ fontSize: "18px" }}
                    />
                   <input
                      id="mobileNumber"
                      name="mobileNumber"
                      autoComplete="off"
                      value={formatPhoneNumber(formik.values.mobileNumber)}
                      onChange={(e) => {
                        handleInputChange(e);
                        formik.setFieldValue('mobileNumber', e.target.value.trim());
                        const containsNonZero = /[1-9]/.test(e.target.value);
                        if(containsNonZero){
                          setInvalidPhoneNumber("valid")
                        }else{
                          setInvalidPhoneNumber("Invalid number")
                        }
                        if(e.target.value === ""){
                          setInvalidPhoneNumber("")
                        }
                        
                        if (e.target.value.trim().length < 13) {
                          const tooltipMessage = "Invalid Mobile no format";
                          setShowMobileNumberTooltip(tooltipMessage);
                        } else {
                          setShowMobileNumberTooltip(null);
                        }
                      }}
                      // onBlur={formik.handleBlur}
                      onFocus={()=> setinvalidMobileError(false)}
                      onBlur={() => {
                        setinvalidMobileError(true)
                        // formik.handleBlur()
                        if (formik.values.mobileNumber) {
                          setUserMobileInputStyles(
                            'pl-10 w-full px-3 py-2 border-2 rounded-lg bg-green-100 text-green-600 border-green-600 pr-10 '
                          );
                        } else {
                          setUserMobileInputStyles(
                            'pl-10 w-full px-3 py-2 border-2 rounded-lg pr-10 '
                          ); 
                        }
                      }}
                      required
                      className={`${userMobileinputStyles} pl-10 w-full px-3 py-2 border rounded-lg focus:ring-blue-500 focus:border-blue-500 pr-10`}

                      placeholder="Mobile no"

       
                    />
                    </div>
          </div>
          {invalidDomainError && (
  <DomainNameTooltip content={showDomainNameTooltip} styles={{}} />
)}


          <div className="relative">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Domain Name
            </label>
            <div className="relative">
                    <MdBusinessCenter
                      className="text-gray-500 absolute top-1/2 transform -translate-y-1/2 left-3"
                      style={{ fontSize: "18px" }}
                    />
              <input
                      id="domainName"
                      name="domainName"
                      autoComplete="off"

                      value={formik.values.sanitizedDomainName}
                      onFocus={()=> setinvalidDomainError(false)}

                      onChange={(e) => {
                        const inputDomainName = e.target.value;
                        const sanitizedDomainName = inputDomainName.replace(/[^a-zA-Z0-9\s]/g, '');

                        formik.setFieldValue('domainName', sanitizedDomainName);
                        // if (invalidDomainError){
                        if (sanitizedDomainName.trim().length < 3 ) {
                          const tooltipMessage = "Domain Name should be at least 3 char";
                          setShowDomainNameTooltip(tooltipMessage);
                        } else {
                          setShowDomainNameTooltip(null);
                        }
                      // }
                      }}
                      // onBlur={formik.handleBlur}
                      onBlur={(e) => {
                        setinvalidDomainError(true)
                        const inputDomainName = e.target.value;
                        const sanitizedDomainName = inputDomainName.replace(/[^a-zA-Z0-9\s]/g, '');
                        // formik.handleBlur()
                        if (sanitizedDomainName) {
                          setUserDomainInputStyles(
                            'pl-10 w-full px-3 py-2 border-2 rounded-lg bg-green-100 text-green-600 border-green-600 pr-10 '
                          );
                        } else {
                          setUserDomainInputStyles(
                            'pl-10 w-full px-3 py-2 border-2 rounded-lg pr-10 '
                          ); 
                        }
                      }}
                      
                      type="text"
                      required
                      className={`${userDomaininputStyles} pl-10 w-full px-3 py-2 border rounded-lg focus:ring-blue-500 focus:border-blue-500`}

                      placeholder="Domain name"
  
                    />
                    </div>
          </div>
          {passwordError && <div style={{color:"red"}}>{passwordError}</div>}
          <button
            type="submit"
            className={`${ formik.values.domainName &&  formik.values.userName &&  formik.values.email.includes('@')
              && formik.values.email.includes('.') &&  (formik.values.mobileNumber).length > 13  ? 'bg-gradient-to-br from-[#235DB6] to-[#0044AB]  cursor-pointer' : 'bg-[#93c5fd] cursor-not-allowed'} transition transform active:scale-95  w-full  text-white font-bold py-2 rounded-lg transition`}
            onClick={handleSignUpClick}
            disabled={
              !formik.values.domainName || formik.values.domainName.length <= 3 ||
              !formik.values.userName || formik.values.userName.length <= 3 ||
              !formik.values.email || !formik.values.email.includes('@') || !formik.values.email.includes('.') ||
              !formik.values.mobileNumber || formik.values.mobileNumber.length <= 13 || invalidPhoneNumber === "Invalid number"
            }
          >
            SUBMIT
          </button>
          {/* <button
            type="submit"
            className="w-full bg-blue-600 text-white font-bold py-2 rounded-lg hover:bg-blue-700 transition"
            onClick={()=>handleSignUpClick()}
            >
            SIGN UP
          </button> */}
          <div className="text-left " onClick={handleCancelClick}>
              <a href="#" className="text-blue-500 text-sm hover:underline">
              Cancel
              </a>
            </div>
     
        </form>

        <div className="flex items-center mt-4">
          {/* <input
            type="checkbox"
            id="remember"
            className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
          />
          <label htmlFor="remember" className="ml-2 block text-sm text-gray-700">
            Remember me
          </label> */}
        </div>

        <div className="mt-[43px] text-center ">
          <div className="flex items-center text-gray-600 text-sm mb-3">
      <div className="flex-grow border-t border-gray-300"></div>
      <span className="px-2 font-bold">Follow Us</span>
      <div className="flex-grow border-t border-gray-300"></div>
    </div>
          
    <div className="flex justify-center space-x-1">
  <a
    href="#"
    className="text-gray-600 hover:bg-gray-200 w-10 h-10 flex items-center justify-center rounded-full"
  >
    <i className="fab fa-facebook-f  text-md"></i>
  </a>
  <a
    href="#"
    className="text-gray-600 hover:bg-gray-200 w-10 h-10 flex items-center justify-center rounded-full"
  >
    <i className="fab fa-instagram  text-md"></i>
  </a>
  <a
    href="#"
    className="text-gray-600 hover:bg-gray-200 w-10 h-10 flex items-center justify-center rounded-full"
  >
    <i className="fab fa-twitter  text-md"></i>
  </a>
  <a
    href="#"
    className="text-gray-600 hover:bg-gray-200 w-10 h-10 flex items-center justify-center rounded-full"
  >
    <i className="fab fa-linkedin-in  text-md"></i>
  </a>
</div>
        </div>

      </div>
    </div>
  </div>
  );
};

export default SignUpPage;



