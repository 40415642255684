import { ColorRing } from "react-loader-spinner"
import React from "react";

function Loader(){
    return (
      <div style={overlayStyle}>

<ColorRing
  visible={true}
  height="80"
  width="80"
  ariaLabel="blocks-loading"
  wrapperStyle={{}}
  wrapperclassName="blocks-wrapper"
  colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
/>
</div>

    )
}



export default Loader;
const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.4)',  // Semi-transparent background
  zIndex: 10000,  // High z-index to stay above all other elements
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};