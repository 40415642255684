import React, { useEffect, useState , useRef } from "react";
import Revenue from "./Revenue";
import ScrollToTop from "./ScrollTop";
import { createColumnHelper ,
          useReactTable, 
          flexRender,
          getCoreRowModel,
          getFilteredRowModel,
          getPaginationRowModel,
          getSortedRowModel, } from "@tanstack/react-table";
import { useSortBy } from "react-table";
import TableMenu from "./TableMenu";
import axios from "axios";
import dayjs from 'dayjs';

import { Navigate, json, useNavigate } from "react-router-dom";
import TableInvo from "./TableInvo";
import { Tooltip } from "react-tippy";
import DatePicker2 from "./DatePicker2";
import DatePicker1 from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker, Space } from "antd";

import IndeterminateCheckbox from "./IndeterminateCheckbox";
import { FiDownload } from "react-icons/fi";
import { AiFillEye } from "react-icons/ai";
import { logDOM } from "@testing-library/react";
import InvoiceViewModal from "./InvoiceViewModal";
import config  from "../../config/config"
import { useDispatch , useSelector } from "react-redux";
import { addInvoice  , addDraftInvoice , deleteAllInvoices } from "../../store/invoiceSlice";
import PopUpModal from "./InvoiceDeleteModal";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { FaArrowDownLong , FaArrowUpLong} from "react-icons/fa6";
import { MdFilterList } from "react-icons/md";
import Badge from '@mui/material/Badge';
import PopUpModalForSendInvoice from "./invoiceSendModel";
import ScrollingMessage from "./ScrollingMessage";
import { showToastForUpdateInvoice } from "../toasts/toastForInvoice";
import ToastNotificationBox from "./ToastBox/ToastNotificationBox";
import { addToast } from '../pages/ToastBox/ToastBoxMessageSlice';
import InvoicePayModal from "./InvoicePayModal";
import DraftsIcon from '@mui/icons-material/Drafts';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SendIcon from '@mui/icons-material/Send';
import WarningIcon from '@mui/icons-material/Warning';
import MoneyIcon from '@mui/icons-material/Money';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DescriptionIcon from '@mui/icons-material/Description';  // For document symbol
import TextField from '@mui/material/TextField'; // Make sure this import is at the top

import { colors } from "@mui/material";
function NewNotification() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filtering , setFiltering] = useState("")
  const [isfilterOpen, setIsfilterOpen] = useState(false);

  const [rightcardata , setrightcardata] = useState([])
    const [isModalOpencard, setIsModalOpencard] = useState(false);
    
    const [isCostToolTip, setIsCostToolTip] = useState(false);
//   const cardsData =
// sellerNames.length > 0 ?
// sellerNames.map((sellerName, index) => ({
//   id: sellerName.brand_id,
//   brandName: sellerName.seller_name, // Assuming brand_name is a property
//   logo: sellerName.brand_logo,
//   gallons: sellerName.total_gallons, // Adjust gallons data as needed
//   borderColor: colors[index % colors.length],
//   total_amount:sellerName.total_amount
// }))
// :[];
const cardsData = [
  {
    id: 1,
    brandName: 'Sunoco',
    logo: 'sunoco.png', // Assuming 'sunoco.png' is a string representing the path to the logo
    gallons: 1524, // Adjust gallons data as needed
    borderColor: 'green', // 'green' should be a string
    total_amount: 5478,
  },
  {
    id: 2, // Changed the ID to 2 to avoid duplicate IDs
    brandName: 'Shell',
    logo: 'shell.png', // Assuming 'shell.png' is another logo path
    gallons: 1400, // Adjust gallons data as needed
    borderColor: 'blue', // Changed the borderColor to differentiate
    total_amount: 4800,
  },
];


  const columnHelper = createColumnHelper();
  const [productOptions, setProductOptions] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openDeleteModalId, setOpenDeleteModalId] = useState(null);
  const [openSendInvoiceModalId, setOpenSendInvoiceModalId] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [clicked, setClicked] = useState(null);
  const [enterinvoiceinputstyles, setenterinputinvoicestyles] = useState('');
  
  const [invoiceTypeStyles, setinvoicetypestyles] = useState('');
  const [customerstyles, setcustomerStyles] = useState('');


  const isEditActive = selectedRows.length === 1;
  const isDeleteActive = selectedRows.length > 0;
  const [invoiceData, setInvoiceData] = useState([]);
  const [openModalId, setOpenModalId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [originalItemData, setOriginalItemData] = useState([]);
  const [businessType , setBusinessType] = useState()

  const [rowSelection, setRowSelection] = React.useState({});
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [sorting , setSorting] = React.useState([])
  const [columnFilters, setColumnFilters] = useState("")
  
  const [selectedCustomer , setSelectedCustomer] = useState("")
  const [filteredStatus, setFilteredStatus] = useState("all");
  const [selectedDateFrom, setSelectedDateFrom] = useState("");
  console.log(selectedDateFrom, 'selectedDateFrom')
  const [selectedDateTo, setSelectedDateTo] = useState("");
  const [selectedInvoiceNumber , setSelectedInvoiceNumber] = useState("");
 
  const user_id = localStorage.getItem("user_id")
  const userRole = localStorage.getItem("user_role")
  const business_id = localStorage.getItem("business_id")
  const managerId = localStorage.getItem("user_id")
  const [triggerFetch, setTriggerFetch] = useState(false);
  // const [customerFiltering , setCustomerFiltering] = useState("")
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [status , setStatus] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customerEmail , setCustomerEmail] = useState('')
  const [payModalInvoice, setpayModalInvoice] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false);
      const [isSubtotalExpanded, setSubtotalExpanded] = useState(false);
        const [isTaxesExpanded, setTaxesExpanded] = useState(false);
        
        const [productSubTotal, setProductSubTotal] = useState(0);
    
        const toggleSubtotal = (e) => {
          e.preventDefault();
          setSubtotalExpanded(!isSubtotalExpanded); // Toggle Subtotal
          setTaxesExpanded(false); // Ensure Taxes is closed
        };
        
        const toggleTaxes = (e) => {
          e.preventDefault();
          setTaxesExpanded(!isTaxesExpanded); // Toggle Taxes
          setSubtotalExpanded(false); // Ensure Subtotal is closed
        };

  // Function to handle scroll event
  const handleScroll = () => {
    if (window.scrollY > 220) {
      setIsScrolled(true);  // Add background when scrolling
    } else {
      setIsScrolled(false); // Remove background when at the top
    }
  };

  const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
  };
  // Adding the scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  // useEffect(()=> {
  //   setClicked('draft')
  // }, [])
  const invoicesAll = useSelector(state => state.invoice?.allInvoices[0]);
  // const fetchStatus = useSelector(state => state.invoice?.status);
  console.log(invoicesAll, 'fetchststus')

  const [tableData, setTableData] = useState([]);
  // console.log('tabledata', tableData);
  useEffect(() => {
    if (invoicesAll) {
      const formattedData = invoicesAll.filter((item) => item.invoice_status === "1" 
      || item.invoice_status === "2" ||  item.invoice_status === "3").
      map(invoice => ({
        ...invoice,
        invoice_number: (invoice.invoice_number),
        final_invoice_amount: Number(invoice.final_invoice_amount),
        final_total_amount : Number(invoice.final_total_amount)
      }));
      setTableData(formattedData);
    }
  }, [invoicesAll]);
  // const scrollingMessages = useSelector((state) => state.scrollingMessage?.message[0]);
  // console.log("invoicess all" , invoicesAll);
  // console.log("message from store " , scrollingMessages);
  
  const [value, setValue] = React.useState('draft');
  const handleChange = (newValue) => {
    // if(newValue === "overdue") return;
    setClicked(newValue)
    setValue(newValue);
    setFilteredStatus(newValue)
  };

  const handleFilterClick = () => {
    setIsFilterVisible(!isFilterVisible);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const businessId = localStorage.getItem("business_id")
  const datePickerFromRef = useRef(null);
  const datePickerToRef = useRef(null);

  const handleIconClick = (ref) => {
    if (ref.current) {
      ref.current.setFocus();
    }
  };

  const handleDateChangeFrom = (from) => {
    console.log(from , 'from date.....')
    if (!from || !dayjs(from).isValid()) {
      // If the date is invalid or null, clear the state
      setSelectedDateFrom(null);
      return;
    }
    setSelectedDateFrom(from);
  };

  const handleDateChangeTo = (to) => {
    if (!to || !dayjs(to).isValid()) {
      // If the date is invalid or null, clear the state
      setSelectedDateTo(null);
      return;
    }
    setSelectedDateTo(to);
  };

  const handleDeleteInvoices = async () => {
    if (selectedRows.length > 0) {

    } 
  };
  const handlePayModalInvoice = ()=>{

    setpayModalInvoice(true)
  }

  const handleDeleteModalInvoice = (invoiceId) => {
    // alert(selectedRows.invoice_number)
    if (selectedRows) {  
      // console.log("sel" , selectedRows);
      setOpenDeleteModalId(table.getSelectedRowModel().flatRows[0]?.original.invoice_number);
    }
  };

  useEffect(() => {
    async function fetchCustomerOptions() {
      try {
        const response = await axios.get(
          `${baseURL}/${btoa("customer/view")}/${btoa(businessId)}`
        );
        setProductOptions(response.data.customers_data);
      } catch (error) {
        console.error("Error fetching customer options:", error);
      }
    }
    fetchCustomerOptions();
  }, []);

  useEffect(() => {
    axios
      .get(`${baseURL}/${btoa("business/profile")}/${btoa(business_id)}/${btoa(user_id)}/${btoa(userRole)}`)
      .then((response) => {
        // console.log("response businesstype" , response);
        const Type = response.data.business_data[0].business_type
        setBusinessType(Type)
      })
      .catch((error) => {
        console.error("Error fetching business data:", error);
       
      });
  },[] );
  
  const handletheViewInvoice =(invoiceNumbers, customeridforInvoice, status,business_name,
    final_invoice_amount, dueDate
  )=>{
    navigate(`/invoiceview/${invoiceNumbers}`, {
      state: {
        status, // Pass the status as part of the state
        business_name,
        final_invoice_amount,
        dueDate
      },
    });      // fetchDataByInvoice(customeridforInvoice)
      localStorage.setItem('customerId',customeridforInvoice )
  }
  const handletheEditInvoice =(invoiceNumbers, customeridforInvoice)=>{
    navigate(`/editInvoice/${invoiceNumbers}`)
    // fetchDataByInvoice(customeridforInvoice)
    localStorage.setItem('customerId',customeridforInvoice )
}
  // Fetching Data from APi into tables
  const handleViewInvoice = () => {
    navigate("/OwnerNotification")

  }

  const handleEditInvoice =()=>{
    const invoiceNumbers =  table.getSelectedRowModel().flatRows[0]?.original.invoice_number;
    if(businessType === "1"){
      navigate(`/viewinvoiceform/${invoiceNumbers}`);
    }else if(businessType === "2"){
      navigate(`/viewinvoiceformtwo/${invoiceNumbers}`)
    }else{
      return;
    }
  }

  const handleViewAllInvoice = async()=>{
    const fetchData = async () => {
      try {
        const apiUrl = `${baseURL}/${btoa("invoice/viewinvoicefilter")}/${btoa(business_id)}/${btoa("all")}/${btoa("all")}/${btoa(null)}/${btoa(null)}`;
        const response = await axios.get(apiUrl);
        setInvoiceData(response.data.invoice_data);
        dispatch(deleteAllInvoices())
        dispatch(addInvoice(response.data.invoice_data))
        // console.log("Filtered invoices:", response);
      } catch (error) {
        console.error("Error fetching filtered invoices:", error);
      }
    };
    fetchData();
  }
 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `${baseURL}/${btoa("invoice/viewinvoicefilter")}`;
        const response = await axios.post(apiUrl, {
          customerId: "all",
          status:'all',
          reportType:null,
          monthName:null,
          quarterName:null,
          year:null,
          businessId:localStorage.getItem('business_id'),
          managerId:localStorage.getItem('user_id'),
        
          monthName: null,
          year:null,
          userRole:userRole
        });
        console.log(response, 'response ...................')
        setInvoiceData(response.data.invoice_data);
        dispatch(deleteAllInvoices());
        dispatch(addInvoice(response.data.invoice_data));

        setStatus(response.data);
        setFilteredStatus("")
        setValue("draft")
      } catch (error) {
        console.error("Error fetching draft invoices:", error);
      }
    };
    fetchData();
  }, [triggerFetch]);

  function formatDatefordatepicker(date) {
    // const day = date.getDate();
    // const month = date.getMonth() + 1; // Months are zero-based
    // const year = date.getFullYear().toString(); // Extract last two digits of year
    // return `${month}-${day}-${year}`;
    const year = date.$y;
    const month = String(date.$M+ 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.$D).padStart(2, '0');
    return `${month}-${day}-${year}`;
  }
  const formattedDatefrom = selectedDateFrom ? selectedDateFrom : '';

  // const formattedMonthName = () => {
  //   let [month]  = formattedDatefrom.format('MMMM-YYYY').split('-') 
  //   return month
  //   }
  //  const formattedYear = () => {
  //    let [ , year]  =   formattedDatefrom.format('MMMM-YYYY').split('-')
  //    return year
  //   }
    const formattedMonthName = selectedDateFrom ? selectedDateFrom.format('MMMM') : null; // e.g., "January"
const formattedYear = selectedDateFrom ? selectedDateFrom.format('YYYY') : null; // e.g., "2025"

useEffect(() => {

  if (initialRender.current) {
    initialRender.current = false;
    return; 
  }
  window.scrollTo(0, 0);
  const fetchData = async () => {
    try {
      const customerParam = selectedCustomer === "" ? "all" : selectedCustomer;
      const statusParam = filteredStatus === "" ? "all" : filteredStatus;
   
      const apiUrl = `${baseURL}/${btoa("invoice/viewinvoicefilter")}`;
      const response = await axios.post(apiUrl, {
        customerId: customerParam,
        status:statusParam,
        reportType:null,
        monthName:null,
        quarterName:null,
        year:null,
        businessId:localStorage.getItem('business_id'),
        managerId:localStorage.getItem('user_id'),
      
        monthName: formattedMonthName,
        year:formattedYear,
        userRole:userRole
      });
      setInvoiceData(response.data.invoice_data);
      dispatch(deleteAllInvoices())
      dispatch(addInvoice(response.data.invoice_data))
      // dispatch(fetchStatus(response.data))

      setStatus(response.data)
      setrightcardata({brandGallonsTotal:0,
        brandAmountsTotal:0
      })

      // console.log("Filtered invoices:", response);
    } catch (error) {
      console.error("Error fetching filtered invoices:", error);
    }
  };

  fetchData();
}, [business_id, selectedCustomer, filteredStatus, selectedDateFrom, selectedDateTo, selectedInvoiceNumber]);

// Define a ref to track the initial render
  const initialRender = useRef(true);
  let debounceTimer;

  // useEffect(() => {
  //   // Define a debounced version of fetchData
  //   const debouncedFetchData = () => {
  //     clearTimeout(debounceTimer);
  //     debounceTimer = setTimeout(() => {
  //       fetchDataByInvoice(customeridforInvoice);
  //     }, 300); 
  //   };

  //   // Check if selectedInvoiceNumber is truthy before executing the debounced fetch
  //   if (selectedInvoiceNumber) {
  //     debouncedFetchData();
  //   }
    
  //   // Clean up function to clear the timeout on unmount or when selectedInvoiceNumber changes
  //   return () => clearTimeout(debounceTimer);
  // }, [selectedInvoiceNumber]);

  const fetchDataByInvoice = async (customeridforInvoice) => {
    const invoiceNumbers = table.getSelectedRowModel().flatRows[0]?.original.invoice_number;
    try {
      const apiUrl = `${baseURL}${btoa("invoice/fetchinvoicebyinvooiocenumber")}/${btoa(invoiceNumbers)}/${btoa(businessId)}/${btoa(customeridforInvoice)}`;
      const response = await axios.get(apiUrl);
      // console.log("invoice on invoice number: " , response);
      setCustomerEmail(response.data.customerData[0].contact_email)
      // setInvoiceData(response.data.customerData);
      // dispatch(deleteAllInvoices())
      // dispatch(addInvoice(response.data.customerData))
    } catch (error) {
      console.error("Error fetching invoice by invoice number", error);
      if (error.response.data === "") {
          setInvoiceData([])
      }
    }
  };

  // useEffect(()=>{
  //     if(selectedRows)
  //   fetchDataByInvoice()
  // },[])
  
  function formatDate(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based
    const year = date.getFullYear().toString(); // Extract last two digits of year
    return `${month}-${day}-${year}`;
  }
  
  // useEffect(()=> {
  // const fetchData = async()=>{
  //   try {
  //     const response  = await axios.get(`${baseURL}${btoa("scrollingmsg/fetchscrollmsgforbusinesses")}/${btoa(business_id)}`)
  //     setScrollingMessage(response.data.scrollmsg_data[0].message)
  //     console.log("scrolling message" , response);
  //   } catch (error) {
  //     console.log("error fetching scrolling messages" , error);
  //   }   
  // }
  // fetchData()
  // },[])

  // useEffect(() => {
  //   fetchData();
  // }, [filteredStatus, searchQuery]);

  

  const handleApproveInvoice = async () => {
    const invoiceNumbers = table.getSelectedRowModel().flatRows[0]?.original.invoice_number;
    const businessName = table.getSelectedRowModel().flatRows[0]?.original.business_name;
    // console.log(invoiceNumbers)
    try {
      const response = await axios.get(`${baseURL}${btoa("invoice/approveinvoice")}/${btoa(invoiceNumbers)}/${btoa(managerId)}/${btoa(businessId)}/${btoa(userRole)}`);
      if(response.data) 
        // showToastForUpdateInvoice(`${businessName} Approved successfully` , "success")
      dispatch(addToast({ type: 'approve', message: `${businessName} Approved successfully`  }));

      // console.log("approved status :", response.data);
      table.resetRowSelection();
      setTriggerFetch(!triggerFetch); // Toggle the triggerFetch state
    } catch (error) {
      console.log("error details ", error);
    }
  }

  const handleDownloadInvoice = async () => {
    const invoiceNumbers = table.getSelectedRowModel().flatRows[0]?.original.invoice_number;
      // console.log("selected rrr" , selectedRows);
    try {
      const response = await axios.get(`${baseURL}/${btoa("invoice/downloadinvoice")}/${btoa(invoiceNumbers)}/${btoa(business_id)}/${btoa(managerId)}/${btoa(userRole)}`, {
        responseType: 'blob',
      });
      const filename = `${table.getSelectedRowModel().flatRows[0]?.original.business_name}_${table.getSelectedRowModel().flatRows[0]?.original.invoice_number}_${formatDate(new Date(table.getSelectedRowModel().flatRows[0]?.original.added_on))}.pdf`;
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename; // Set the filename here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      table.resetRowSelection();
      window.URL.revokeObjectURL(url);

     
      // console.log("Invoice downloaded successfully.");
    } catch (error) {
      console.log("Error downloading invoice:", error);
    }
  };
  
  const indicatorColor = (status) => {
    switch (status) {
      case 'draft':
        return 'gray';
      case 'approved':
        return 'blue';
      case 'sent':
        return 'green';
      case 'partialpaid':
        return 'orange';
      case 'fullpaid':
      return 'green';
      case 'overDue':
        return 'red';
      default:
        return 'gray'; // Default color if status doesn't match
    }
  };
 

  const isRowCheckboxDisabled = (row) => {
    if (rowSelection[row.id]) {
      return false;
    } else {
      // If the row is not selected, check if any other rows are selected
      return Object.values(rowSelection).some((selected) => selected);
    }
  };

  const capitalizeFirstLetterWordWords = (str) => {
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const handleSortingChange = (columnId) => {
    setSorting((old) => {
      if (old.length && old[0].id === columnId) {
        // If it is, toggle the sorting direction
        return [{
          id: columnId,
          desc: !old[0].desc,
        }];
      }
      
      // If the columnId is not in the sorting state, set it as descending by default
      return [{ id: columnId, desc: true }];
    });
  };
  
  const columns = [
    // {
    //   id: "checkbox",
    //   accessor: "",
    //   cell: ({ row }) => (
    //     <IndeterminateCheckbox
    //       {...{
    //         checked: row.getIsSelected(),
    //         disabled: isRowCheckboxDisabled(row),
    //         indeterminate: row.getIsSomeSelected(),
    //         onChange: row.getToggleSelectedHandler(),
    //         className: isRowCheckboxDisabled(row) ? "disabled-checkbox" : "",
    //       }}
    //     />
    //   ),
    //   enableColumnFilter: false, // Disable filter for checkbox column
    //   enableSorting: false, // Disable sorting for checkbox column
    // },
    columnHelper.accessor("invoice_number", {
      header: " #",
      enableColumnFilter: true,
      enableSorting: true,
      cell:(props)=> (
        
        <div className="text-center">{(props.row.original.invoice_number)}</div>
      )
    }),
    columnHelper.accessor("invoice_status", {
      header: "Date",
      cell: (props) => (
        <div className="flex justify-center">
        <div
            className={`text-black text-[14px] py-[5px] px-[12px] w-32 rounded-sm font-semibold ${
              filteredStatus === "overDue"
                ? "bg-red-200 text-red-600" // Red background and red text for "Past Due"
                : props.row.original.invoice_status === "1"
                ? "bg-gray-200"
                : props.row.original.invoice_status === "2"
                ? "bg-blue-200 text-blue-600"
                : props.row.original.invoice_status === "3"
                ? "bg-green-200 text-green-600"
                : props.row.original.invoice_status === "4"
                ? "bg-purple-200 text-purple-600"
                : props.row.original.invoice_status === "5"
                ? "bg-red-200 text-red-600"
                : props.row.original.invoice_status === "6"
                ? "bg-purple-200 text-purple-600"
                : props.row.original.invoice_status === "7"
                ? "bg-green-200 text-green-600"
                 : props.row.original.invoice_status === "8"
                ? "bg-red-200 text-red-600"
                : ""
            }`}
          >
            {filteredStatus === "overDue"
              ? "PastDue"
              : props.row.original.invoice_status === "1"
              ? "Draft"
              : props.row.original.invoice_status === "2"
              ? "Approved"
              : props.row.original.invoice_status === "3"
              ? "Sent"
              : props.row.original.invoice_status === "5"
              ? "Overdue"
              : props.row.original.invoice_status === "4"
              ? "Partial paid"
              : props.row.original.invoice_status === "7"
              ? "Full paid" 
              : props.row.original.invoice_status === "8"
              ? "PastDue"
              : ""}
          </div>
        </div>
      ),
      enableColumnFilter: false, // Disable filter for status column
      enableSorting: false, // Disable sorting for status column
    }),
    columnHelper.accessor("business_name", {
      header: <div className="text-left w-30">Notification Message</div>,
      cell: (props) => (
        <div className="text-center w-30">{capitalizeFirstLetterWordWords(props.row.original.business_name)}</div>
      ),
      enableColumnFilter: false,
      enableSorting: true,
    }),
    columnHelper.accessor("added_on", {
      header: "Type",
      cell: (props) => {
        // const date = new Date(props.row.original.added_on);
        // const options = { month: 'short', day: '2-digit', year: 'numeric' };
        // const formattedDate = `${date.toLocaleDateString("en-US", { month: 'short' })}-${date.toLocaleDateString("en-US", { day: '2-digit' })}-${date.toLocaleDateString("en-US", { year: 'numeric' })}`;
        // return (
        //   <div>{formattedDate}</div>
        // );
        const indianDate = props.row.original.added_on;
        const [day, month, year] = indianDate.split('/');      
        const date = new Date(`${year}-${month}-${day}`);      
    const formattedDate = `${date.toLocaleDateString("en-US", { month: 'short' })}-${date.toLocaleDateString("en-US", { day: '2-digit' })}-${date.toLocaleDateString("en-US", { year: 'numeric' })}`;

  return (
          <div>{formattedDate}</div>
        );

      },
      enableColumnFilter: false,
      enableSorting: true,
    }),
    columnHelper.accessor("due_date", {
      header: "Customer Name",
      cell: (props) => {
        // const date = new Date(props.row.original.added_on);
        // const options = { month: 'short', day: '2-digit', year: 'numeric' };
        // const formattedDate = `${date.toLocaleDateString("en-US", { month: 'short' })}-${date.toLocaleDateString("en-US", { day: '2-digit' })}-${date.toLocaleDateString("en-US", { year: 'numeric' })}`;
        // return (
        //   <div>{formattedDate}</div>
        // );
        const indianDate = props.row.original.due_date;
        const [day, month, year] = indianDate.split('/');      
        const date = new Date(`${year}-${month}-${day}`);      
    const formattedDate = `${date.toLocaleDateString("en-US", { month: 'short' })}-${date.toLocaleDateString("en-US", { day: '2-digit' })}-${date.toLocaleDateString("en-US", { year: 'numeric' })}`;

  return (
          <div>{formattedDate}</div>
        );

      },
      enableColumnFilter: false,
      enableSorting: true,
    }),
    
  
  ];


  const table = useReactTable({
    data: tableData || [],
    columns: columns,
    state: {
      rowSelection: rowSelection,
      globalFilter: filtering,
      sorting: sorting,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: setColumnFilters,
    enableRowSelection: true,
    enableColumnFilter: true,
  });

  // useEffect(()=>{
  //   const fetchDataByInvoiceForCustomerEmail = async () => {
  //     const invoiceNumbers = table.getSelectedRowModel().flatRows[0]?.original.invoice_number;
  //     try {
  //       const apiUrl = `${baseURL}${btoa("invoice/fetchinvoicebyinvooiocenumber")}/${btoa(invoiceNumbers)}/${btoa(businessId)}`;
  //       const response = await axios.get(apiUrl);
  //       // console.log("invoice for customer email: " , response);
  //       setCustomerEmail(response.data.customerData[0].contact_email)
  //     } catch (error) {
  //       console.error("Error fetching invoice for email", error);
  //       if (error.response.data === "") {
  //           setInvoiceData([])
  //       }
  //     }
  //   };
  //   fetchDataByInvoiceForCustomerEmail()
  // },[table.getSelectedRowModel()])

  useEffect(() => {
  const selected = table.getSelectedRowModel().flatRows.map(row => row.original);
  // if(selected){
  //   fetchDataByInvoice()
  // }
  // console.log("selected rows", selected);
  setSelectedRows(selected);
}, [rowSelection]);

// Effect to reset row selection if necessary
useEffect(() => {
  if (filteredStatus) {
    // console.log("Filtered status changed", filteredStatus);
    table.resetRowSelection();
  }
}, [filteredStatus]);

// Reset row selection function
const resetRowSelection = () => {
  table.resetRowSelection();
};


   const [activeItem, setActiveItem] = useState("View");
  
        const handleMenuClick = (item) => {
          setActiveItem(item);
        };

  return (
    <div className="flex justify-center items-center flex-col w-full pb-5 ">

      <ScrollingMessage/>

      <div className="w-full px-8 mt-1">
        {/* <h1 className="font-bold text-xl"  style={{ 
    maxWidth: '1200px', 
    boxSizing: 'border-box', 
    marginLeft: '8px', 
    fontFamily: 'Poppins, sans-serif', // Use Poppins font
    color: '#444444' // Lighter black color
  }}>Invoice Dashboard</h1> */}
   {/* <nav aria-label="breadcrumb">
    <ol className="flex space-x-2 text-lg font-bold text-gray-700">
      <li>
          Sales
      </li>
      <li>
        <span className="text-gray-400">{'>>'}</span>
      </li>
      <li>
          Invoices
      </li>
    </ol>
  </nav> */}
   <div className="flex mb-6 justify-center p-4 rounded-md font-bold text-md items-center w-full mx-auto py-2 bg-white mt-3 ">
      {/* View */}
      <div
        className={`flex items-center cursor-pointer px-4 py-1 ${
          activeItem === "View" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
        } border-b border-gray-300  `}
        onClick={() => handleMenuClick("View")}
      >
        {/* <FaEye className="mr-2" /> */}
        <span>Notification</span>
      </div>

    </div>
        <div>

        <div className="flex justify-start space-x-4 p-1 mb-2 mt-2">
  {/* Card 1 */}
  <div
className={`flex flex-col items-center justify-between w-1/6 bg-white rounded-lg p-4 relative hover:scale-105 hover:border-l-[6px] hover:border-gray-500 transition-all duration-300 ${clicked === 'draft' ? 'border-l-[6px] border-gray-500' : ''}`}
style={{
      boxShadow: '0 0 20px 0 rgba(0, 0, 0,.2)',
      height: '90px', // Adjust as per your desired height
    }}
    onClick={() =>{ setFilteredStatus('draft'); setClicked('draft')}}
  >
    <div className="flex justify-between w-full">
      <span className="text-sm text-gray-500 font-bold">SMS</span>
    </div>
    <div>
      <h2
        className="absolute bottom-2 left-4  text-2xl font-bold text-grey-500 mt-2"
      
      >
       0
      </h2>
    </div>
    <div className="absolute bottom-0 right-0 w-[100px] h-[55px] bg-gradient-to-tl from-[#ffffff] to-transparent rounded-tl-full flex items-center justify-center">
      <DescriptionIcon sx={{ fontSize: 30 }} className="text-grey-500 mt-2" />
    </div>
  </div>

  {/* Card 2 */}
  <div
    className={`flex flex-col items-center justify-between w-1/6 bg-white rounded-lg p-4 relative hover:scale-105 hover:border-l-[6px] hover:border-blue-500 transition-all duration-300 ${clicked === 'approved' ? 'border-l-[6px] border-blue-500' : ''}`}
    style={{
      boxShadow: '0 0 20px 0 rgba(0, 0, 0,.2)',
      height: '90px',
    }}
    onClick={() => {setFilteredStatus('approved'); setClicked('approved')}}
  >
    <div className="flex justify-between w-full">
      {/* <span className="text-sm text-blue-500 font-bold">APPROVED</span> */}
      <span className="text-sm text-blue-500 font-bold"> Email</span>

    </div>
    <div>
      <h2
        className="absolute bottom-2 left-4  text-2xl font-bold text-blue-500 mt-2"
      >
        0
      </h2>
    </div>
    <div className="absolute bottom-0 right-0 w-[100px] h-[50px] bg-gradient-to-tl from-[#ffffff] to-transparent rounded-tl-full flex items-center justify-center">
      <CheckCircleIcon sx={{ color: 'blue', fontSize: 30 }} className="mt-2" />
      
    </div>
  </div>  

  {/* Card 4 */}
  {/* <div
    className={`flex flex-col items-center justify-between w-1/5 bg-white rounded-lg p-4 relative hover:scale-105 hover:border-l-[6px] hover:border-red-500 transition-all duration-300 ${clicked === 'overDue' ? 'border-l-[6px] border-red-500' : ''}`}
    style={{
      boxShadow: '0 0 20px 0 rgba(0, 0, 0,.2)',
      height: '90px',
    }}
    onClick={() => {setFilteredStatus('overDue'); setClicked('overDue')}}
  >
    <div className="flex justify-between w-full">
      <span className="text-sm text-red-500 font-bold">Unpaid</span>
    </div>
    <div>
      <h2
        className="absolute bottom-2 left-4  text-2xl font-bold text-red-500 mt-2"
      >
        {status.total_overdueinvoices}
      </h2>
    </div>
    <div className="absolute bottom-0 right-0 w-[100px] h-[55px] bg-gradient-to-tl from-[#ffffff] to-transparent rounded-tl-full flex items-center justify-center">
      <WarningIcon sx={{ color: 'red', fontSize: 30 }} className="mt-2" />
    </div>
  </div> */}

  {/* Card 5 */}
  {/* <div
    className={`flex flex-col items-center justify-between w-1/5 bg-white rounded-lg p-4 relative hover:scale-105 hover:border-l-[6px] hover:border-purple-500 transition-all duration-300 ${clicked === 'partialpaid' ? 'border-l-[6px] border-purple-500' : ''}`}
    style={{
      boxShadow: '0 0 20px 0 rgba(0, 0, 0,.2)',
      height: '90px',
    }}
    onClick={() => {setFilteredStatus('partialpaid'); setClicked('partialpaid')}}
  >
    <div className="flex justify-between w-full">
      <span className="text-sm text-purple-500 font-bold">PARTIAL PAY</span>
    </div>
    <div>
      <h2
        className="absolute bottom-2 left-4   text-2xl font-bold text-purple-500 mt-2"
      >
        {status.total_partialpaidInvoices}
      </h2>
    </div>
    <div className="absolute bottom-0 right-0 w-[100px] h-[55px] bg-gradient-to-tl from-[#ffffff] to-transparent rounded-tl-full flex items-center justify-center">
      <ReceiptIcon sx={{ color: 'purple', fontSize: 30 }} className="mt-2" />
    </div>
  </div> */}
    { userRole === '2' && 

  <div className='flex flex-row'>
  <div className="w-1/2">
  <h3 className="text-lg font-semibold text-[#25caed] mb-4  underline absolute top-[120px] right-[4%]  ">
     {status.monthName && status.year ? `${status.year} ${status.monthName ? status.monthName.substring(0, 3).toUpperCase() : ''} Notifications Summary` : ''}
   </h3>
  </div>
<div className="lg:w-[18%] rounded-md p-4 mt-[20px]  ml-auto absolute top-[145px] right-[50px] bg-gray-100 border border-gray-300">
  {/* Subtotal Section */}
  <div className="flex justify-between items-center mb-2">
    <div className="flex items-center gap-2">
      <button onClick={toggleSubtotal} className="text-blue-500">
        {isSubtotalExpanded ? '▼' :  <span className="inline-block text-xl leading-none">►</span>}
      </button>
 
      <span className="text-gray-800 font-medium">Total Notifications:</span>
    </div>
    <span className="text-gray-800 font-medium">
    0{/* {formatNumberWithCommas( Number(status.total_draftInvoices+ status.total_approvedInvoices + status.total_sentInvoices) || 0 )}  */}
       </span>
  </div>

  {isSubtotalExpanded && (
    <>
      <div className="ml-6 mb-4">
          <div className="flex justify-between" >
            <span className="capitalize">SMS Notification :</span>
            <span className="font-normal">
            {/* {formatNumberWithCommas(status.total_draftInvoices)} */}0
            </span>
          </div>
          <div className="flex justify-between" >
            <span className="capitalize">Email Notification :</span>
            <span className="font-normal">
            {/* {formatNumberWithCommas(status.total_approvedInvoices)} */}0
            </span>
          </div>
          <div className="flex justify-between" >
            <span className="capitalize"> other Notification:</span>
            <span className="font-normal">
            {/* {formatNumberWithCommas(status.total_sentInvoices)} */}0
            </span>
          </div>
          <div className="flex justify-between font-normal pt-2">
    <span className="">Total Notifications:</span>
    <span className="border-t border-black  text-right">
    0{/* {formatNumberWithCommas( Number(status.total_draftInvoices+ status.total_approvedInvoices + status.total_sentInvoices) || 0 )}  */}

    </span>
  </div>
      </div>

    </>
  )}

  {/* Taxes Section */}
  <div className=" flex justify-between items-center">
    <div className="flex items-center gap-2">
      <button onClick={toggleTaxes} className="text-blue-500">
        {isTaxesExpanded ? '▼' :     <span className="inline-block text-xl leading-none">►</span>
        }
      </button>
      <span className="text-gray-800 font-medium">Total Notifications sent:</span>
    </div>
    <span className="text-gray-800 font-medium">
    {/* ${(
  (status?.invoice_sum_data?.[0]?.total_final_invoice_amount ? Number(status?.invoice_sum_data[0]?.total_final_invoice_amount) : 0) +
  (status?.invoice_sum_data?.[1]?.total_final_invoice_amount ? Number(status?.invoice_sum_data[1]?.total_final_invoice_amount) : 0) +
  (status?.invoice_sum_data?.[2]?.total_final_invoice_amount ? Number(status?.invoice_sum_data[2]?.total_final_invoice_amount) : 0)
).toFixed(2) || 0} */}
0

    </span>
  </div>

  {isTaxesExpanded && (
    <div className="ml-6 mt-2">
        <div className="flex justify-between">
          <span className="capitalize">SMS sent::</span>
          <span className="font-normal">
          {/* $ {Number(status.invoice_sum_data[0].total_final_invoice_amount).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })} */}0
          </span>
        </div>
        <div className="flex justify-between">
          <span className="capitalize">Email Sent:</span>
          <span className="font-normal">
          {/* $ {Number(status.invoice_sum_data[1].total_final_invoice_amount).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })} */}0
          </span>
        </div>
        <div className="flex justify-between">
          <span className="capitalize">Other Sent:</span>
          <span className="font-normal">
          {/* $ {Number(status.invoice_sum_data[2].total_final_invoice_amount).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })} */}0
          </span>
        </div>
      <li className="flex justify-between font-normal pt-2">
        <span>Total Sent:</span>
        <span className="border-t border-black  text-right">
0{/* ${(
  (status?.invoice_sum_data?.[0]?.total_final_invoice_amount ? Number(status?.invoice_sum_data[0]?.total_final_invoice_amount) : 0) +
  (status?.invoice_sum_data?.[1]?.total_final_invoice_amount ? Number(status?.invoice_sum_data[1]?.total_final_invoice_amount) : 0) +
  (status?.invoice_sum_data?.[2]?.total_final_invoice_amount ? Number(status?.invoice_sum_data[2]?.total_final_invoice_amount) : 0)
).toFixed(2) || 0} */}

        </span>
      </li>
    </div>
  )}
</div>
</div>
}
</div>
{/* 
<div className="flex justify-end items-end mt-[60px]">
          <button
            className="flex items-end justify-end font-bold mr-3 w-55 border-transparent border-2 text-white bg-[#25caed] hover:scale-105 transition-transform duration-300 ease-in-out focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-base px-4 py-1.5 focus:outline-none"
            type="button"
            onClick={handleViewInvoice}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="currentColor"
              className="w-5 h-5 mr-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v12m6-6H6"
              />
            </svg>
            New Invoice
          </button>
          </div> */}
        </div>
      </div>

     
    
      {/* {isFilterVisible && ( */}
      <div className="flex justify-between gap-2 items-center w-full px-10 mt-[250px]  h-[45px]">
      <div>
          {isfilterOpen &&

            <select
              id="category"
              value={filteredStatus}
              onChange={(e) => handleChange(e.target.value)}
              // onBlur={() => {
              //   if (filteredStatus) { // Ensuring the email is not just spaces
              //     setinvoicetypestyles("border border-green-600 bg-green-100 text-green-600");
              //   } else {
              //     setinvoicetypestyles("border-gray-300 text-gray-500 focus:ring-primary-600 focus:border-primary-600");
              //   }
              // }}
              className={`cursor-pointer font-medium border border-gray-400 h-[41px] text-gray-500 
                text-sm rounded-lg focus:ring-0 focus:ring-blue-500 
                focus:border-blue-500 focus:outline-none block w-fit p-2`}
            >
              <option value="" >--Invoice Type--</option>
              <option value="all">All statuses</option>
              <option value="draft">Draft</option>
              <option value="approved">  APPROVED</option>
              <option value="sent">Sent</option>
              {/* <option value="partialpaid">Partial Paid</option> */}
              {/* <option value="fullpaid">Full Paid</option> */}
              {/* <option value="overDue">Unpaid</option> */}
            </select>
            }

          </div>
          <div className="flex-grow" style={{width:'240px'}}>
            {isfilterOpen &&
              <select
                id="customerId"
                className={` border border-gray-400 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 p-2.5`}
                onChange={(e) => {setSelectedCustomer( e.target.value); 
                  
                }}
         
              >
                                <option value="">--Select Customer--</option>

                <option value="all">All Customers</option>
                {productOptions.map((customer) => (
                  <option key={customer.customer_id} value={customer.customer_id}>
                    {customer.business_name}
                  </option>
                ))}
              </select>
              }
          </div>
       

{isfilterOpen &&
          <div className="flex items-center">
            <div className="h-fit font-normal cursor-pointer border border-gray-300 rounded-l-lg flex items-center justify-between">
            <Space direction="vertical" style={{height:'100%'}}>
  <DatePicker
    label="Select Month"
    picker="month"
    format="MMMM-YYYY"
    views={["year", "month"]}
    value={selectedDateFrom}
    onChange={handleDateChangeFrom}
    placeholder="Select Month"
    // format="MM-DD-YYYY" 
    // className={selectedDateFrom ? 'selected-date-invoice' : ''}

    renderInput={(params) => (
      <TextField
        {...params}
        style={{height:'35px'}}
        value={selectedDateFrom ? selectedDateFrom.format('MMMM-YYYY') : ''}
        sx={{
          '& .MuiOutlinedInput-root': {
            height: '100px', // Increase container height
            display: 'flex', // Align items properly
            alignItems: 'center',
            fontSize: '1.2rem', // Increase font size
            '& input': {
              height: '80px', // Adjust the input field's height
              padding: '40px 20px', // Add padding for larger appearance
            },
            '&.Mui-focused': {
              borderColor: 'blue', // Change focus border color
              boxShadow: '0 0 8px rgba(0, 0, 255, 0.5)', // Add shadow on focus
            },
            border: '1px solid #ccc', // Default border
            borderRadius: '6px', // Rounded corners
          },
          '& .MuiInputLabel-root': {
            fontSize: '1rem', // Increase label font size
          },
          '& .MuiOutlinedInput-input::placeholder': {
                   fontWeight: 'bold',
}
        }}
      />
    )}
  />
</Space>

            </div>

            {/* <div className="h-fit font-normal border-gray-300 cursor-pointer border rounded-r-lg flex items-center justify-between">
              
                    <Space direction="vertical" style={{height:'100%'}}>
  <DatePicker
    label="To"
    views={['year', 'month', 'day']}
    value={selectedDateTo}
    onChange={handleDateChangeTo}
    placeholder="To"
    // className={selectedDateTo ? 'selected-date-invoice' : ''}

    renderInput={(params) => (
      <TextField
        {...params}
        style={{height:'35px'}}
        value={selectedDateTo ? selectedDateTo.format('MM-DD-YYYY') : ''}
        sx={{
          '& .MuiOutlinedInput-root': {
            height: '100px', // Increase container height
            display: 'flex', // Align items properly
            alignItems: 'center',
            fontSize: '1.2rem', // Increase font size
            '& input': {
              height: '80px', // Adjust the input field's height
              padding: '40px 20px', // Add padding for larger appearance
            },
            '&.Mui-focused': {
              borderColor: 'blue', // Change focus border color
              boxShadow: '0 0 8px rgba(0, 0, 255, 0.5)', // Add shadow on focus
            },
            border: '1px solid #ccc', // Default border
            borderRadius: '6px', // Rounded corners
          },
          '& .MuiInputLabel-root': {
            fontSize: '1rem', // Increase label font size
          },
          '& .MuiOutlinedInput-input::placeholder': {
                   fontWeight: 'bold',
}
        }}
      />
    )}
  />
</Space>
            </div> */}
          </div>
}
{isfilterOpen &&
          <div className="flex-grow items-center border border-gray-400 rounded-lg hover:border-[#3479E0]">
            <div className="relative w-full h-full">
              <input
                type="text"
                id="search-dropdown"
                className="block p-2.5 w-full h-[38px] text-sm text-gray-900 rounded-lg border border-gray-400"

                placeholder="Enter invoice #"

                // onChange={(e) =>
                //   setSelectedInvoiceNumber(e.target.value)
                // }
            
                value={filtering}

                onChange={e => setFiltering(e.target.value)}

              /> 
              <button
                type="submit"
                className="absolute bottom-[2px] right-0 p-2.5 mr-[3px] text-sm font-medium h-[32px] text-white bg-gray-300 rounded-lg border focus:ring-0"
                >
                <svg
                  className="w-3 h-3 text-black "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
                <span className="sr-only">Search</span>
              </button>
            </div>
          </div>
}
          <div className="flex items-center justify-center bg-white p-2 rounded-lg shadow-lg cursor-pointer"
           style={{   boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",}}
    //  onClick={() => setIsfilterOpen(!isfilterOpen)}
     >
  {/* MdFilterList Icon */}
  <MdFilterList className=" w-5 h-[16px]  mr-2" />
  {/* Filters Text */}
 {!isfilterOpen &&  <span className="text-sm ">Filters</span>}
</div>
          <div className="flex justify-end">

          <button
      className="text-white bg-[#25caed] flex items-center font-bold w-55 h-[40px] hover:scale-105 transition-transform duration-300 ease-in-out focus:ring-4 focus:ring-primary-300 rounded-lg text-base px-4"
      type="button"
      onClick={handleViewInvoice}
      >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={3}
        stroke="currentColor"
        className="w-5 h-5 mr-2"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 6v12m6-6H6"
        />
      </svg>
      New Notification
    </button>
          </div>

        </div>
        {/* )} */}
        
        
           <div className={`flex justify-end items-end w-full pl-9 pr-9 ml-6 ${isFilterVisible ? 'mt-[0px]' : ''}`}>
           {/* <div >
            <Box sx={{ width: '100%' }}>
              <Tabs
                value={filteredStatus}
                // onChange={handleChange}
                indicatorColor="primary" // Default color for indicators
                sx={{
                  borderBottom: 3,
                  borderColor: 'divider', // Default border color
                  '& .MuiTabs-indicator': {
                    backgroundColor: indicatorColor(filteredStatus),
                  },
                }}
              >
                <Tab
                  value="draft"
                  onClick={()=> setFilteredStatus("draft")}
                  style={{
                    color: filteredStatus === 'draft' ? 'gray' : 'gray', // Gray color if filteredStatus is 'draft'
                    borderBottom: filteredStatus === 'draft' ? '1px solid gray' : 'none', // Gray border bottom if filteredStatus is 'draft'
                  }}
                  label={
                    <Badge
                      badgeContent={status.total_draftInvoices}
                      color=""
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: filteredStatus === 'draft' ? 'gray' : 'gray', // Gray or green background based on filteredStatus
                          color: 'white',
                          top: '-3px',
                          right: '-10px',
                          fontSize: '0.75rem',
                          height: '18px',
                          minWidth: '18px',
                          padding: '0 6px',
                          borderRadius: '50%'
                        }
                      }}
                    >
                      Draft
                    </Badge>
                  }
                />
                <Tab
                  value="approved"
                  onClick={()=> setFilteredStatus("approved")}
                  sx={{ width: "122px" }}
                  style={{
                    color: filteredStatus === 'approved' ? 'blue' : 'gray', // Blue color if filteredStatus is 'approved'
                    borderBottom: filteredStatus === 'approved' ? '1px solid blue' : 'none', // Blue border bottom if filteredStatus is 'approved'
                  }}
                  label={
                    <Badge
                      badgeContent={status.total_approvedInvoices}
                      color="primary"
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: filteredStatus === 'approved' ? 'blue' : 'gray',
                          color: 'white',
                          top: '-3px',
                          right: '-10px',
                          fontSize: '0.75rem',
                          height: '18px',
                          minWidth: '18px',
                          padding: '0 6px',
                          borderRadius: '50%'
                        }
                      }}
                    >
                      Approved
                    </Badge>
                  }
                />
                <Tab
                  value="sent"
                  onClick={()=> setFilteredStatus("sent")}
                  style={{
                    color: filteredStatus === 'sent' ? 'green' : 'gray', // Green color if filteredStatus is 'sent'
                    borderBottom: filteredStatus === 'sent' ? '1px solid green' : 'none', // Green border bottom if filteredStatus is 'sent'
                  }}
                  label={
                    <Badge
                      badgeContent={status.total_sentInvoices}
                      color="primary"
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: filteredStatus === 'sent' ? 'green' : 'gray',
                          color: 'white',
                          top: '-3px',
                          right: '-10px',
                          fontSize: '0.75rem',
                          height: '18px',
                          minWidth: '18px',
                          padding: '0 6px',
                          borderRadius: '50%'
                        }
                      }}
                    >
                      Sent
                    </Badge>
                  }
                />
                   <Tab
                  value="overDue"
                  onClick={()=> setFilteredStatus("overDue")}
                  sx={{ width: "110px" }}
                  style={{
                    color: filteredStatus === 'overDue' ? 'red' : 'gray', // Red color if filteredStatus is 'overdue'
                    borderBottom: filteredStatus === 'overDue' ? '1px solid red' : 'none', // Red border bottom if filteredStatus is 'overdue'
                  }}
                  label={
                    <Badge
                      badgeContent={status.total_overdueinvoices} // Default to "0" if undefined
                      color="primary"
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: filteredStatus === 'overDue' ? 'red' : 'gray',
                          color: 'white',
                          top: '-3px',
                          right: '-10px',
                          fontSize: '0.75rem',
                          height: '18px',
                          minWidth: '18px',
                          padding: '0 6px',
                          borderRadius: '50%'
                        }
                      }}
                    >
                      Past Due
                    </Badge>
                  }
                />
                <Tab
                  value="partialpaid"
                  onClick={()=> setFilteredStatus("partialpaid")}
                  sx={{ width: "126px" }}
                  style={{
                    color: filteredStatus === 'partialpaid' ? 'orange' : 'gray', // Orange color if filteredStatus is 'partialpaid'
                    borderBottom: filteredStatus === 'partialpaid' ? '1px solid orange' : 'none', // Orange border bottom if filteredStatus is 'partialpaid'
                  }}
                  label={
                    <Badge
                      badgeContent={status.total_partialpaidInvoices}
                      color="primary"
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: filteredStatus === 'partialpaid' ? 'orange' : 'gray',
                          color: 'white',
                          top: '-3px',
                          right: '-10px',
                          fontSize: '0.75rem',
                          height: '18px',
                          minWidth: '18px',
                          padding: '0 6px',
                          borderRadius: '50%'
                        }
                      }}
                    >
                      Partial Pay
                    </Badge>
                  }
                />
                <Tab
                  value="fullpaid"
                  onClick={()=> setFilteredStatus("fullpaid")}
                  sx={{ width: "110px" }}
                  style={{
                    color: filteredStatus === 'fullpaid' ? 'green' : 'gray', // Green color if filteredStatus is 'fullpaid'
                    borderBottom: filteredStatus === 'fullpaid' ? '1px solid green' : 'none', // Green border bottom if filteredStatus is 'fullpaid'
                  }}
                  label={
                    <Badge
                      badgeContent={status.total_fullpaidInvoices}
                      color="primary"
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: filteredStatus === 'fullpaid' ? 'green' : 'gray',
                          color: 'white',
                          top: '-3px',
                          right: '-10px',
                          fontSize: '0.75rem',
                          height: '18px',
                          minWidth: '18px',
                          padding: '0 6px',
                          borderRadius: '50%'
                        }
                      }}
                    >
                      Full Paid
                    </Badge>
                  }
                />
             
              </Tabs>
            </Box>
          </div> */}
          

                  {/* <div className="flex items-center gap-3 mr-6 " style={{borderBottom: '3px solid #DCDCDC'}}>  */}
    
<div
  className={`flex items-end gap-3 mr-6 transition-all ease-in-out `}
  style={{
    // borderBottom: '3px solid #DCDCDC',

  }}
>
                          {/* <Tooltip title="Filter" position="top" trigger="mouseenter">
                                          <button
                                              className="items-end mt-[-5px] text-sm font-medium text-center focus:outline-none text-gray-500 hover:text-blue-800 rounded-lg"
                                              type="button"
                                              onClick={handleFilterClick} 
                                          >
                                              <div className="rounded-full p-2">
                                                  <MdFilterList  size={24}     
 />
                                              </div>
                                          </button>
                              </Tooltip>                  */}
                          {table.getSelectedRowModel().flatRows.length > 0 && (
                              <div className={`flex flex-col items-center gap-3 mb-0  `}
                              style={{
                                

                                position:(isScrolled &&  selectedRows.length === 1) ? 'fixed': 'fixed',
                                right:(isScrolled &&  selectedRows.length === 1) ? '8px':'8px',
                                bottom: '12%'
                            
                              }}
                              
                              >
                                  {businessType === "2" && (            // show view and download of if it is gas type (businessType 2)
                                    <>
                                        <Tooltip title="View" position="top" trigger="mouseenter">
                                          <button
className="ml-1 items-center text-sm font-medium text-center focus:outline-none border-black text-black rounded-full bg-white hover:bg-gray-200 border p-2"
type="button"
                                              onClick={openModal}
                                                // onClick={() => navigate('/invoiceview')}

                                              // onClick={()=>  console.log(selectedRows)}
                                          >
                                              <div className="rounded-full p-1">
                                                  <AiFillEye  size={20} />
                                              </div>
                                          </button>
                                      </Tooltip>
                                      {!table.getSelectedRowModel().flatRows.some(el => el.original.invoice_status === "1") && (
                                        <Tooltip title="Download" position="top" trigger="mouseenter">
                                            <button
className="ml-1 items-center text-sm font-medium text-center focus:outline-none border border-blue-800 text-blue-800 rounded-full bg-white hover:bg-blue-300 p-2"
type="button"
                                                onClick={handleDownloadInvoice}
                                            >
                                                <div className="rounded-full p-1">
                                                    <FiDownload size={20} />
                                                </div>
                                            </button>
                                        </Tooltip>  )}
                                      </>
                                )}

                                  {table.getSelectedRowModel().flatRows.map((el) => (
                                      el.original.invoice_status === "1" ? (
                                          <div key={el.id} className="flex flex-col gap-3 ml-2">
                                              <Tooltip title="Approve" position="top" trigger="mouseenter">
                                                  <button
className="items-center text-sm font-medium text-center focus:outline-none border border-green-800 text-green-800 rounded-full bg-white hover:bg-green-300 p-2"
type="button"
                                                      onClick={handleApproveInvoice}
                                                  >
                                                      <div className="rounded-full p-1"
                                                      >
                                                          <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              fill="none"
                                                              viewBox="0 0 24 24"
                                                              strokeWidth={3}
                                                              stroke="currentColor"
                                                              className="w-5 h-5"
                                                          >
                                                              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                                          </svg>
                                                      </div>
                                                  </button>
                                              </Tooltip>
                                              <Tooltip title="Edit" position="top" trigger="mouseenter">
                                                  <button
className="items-center text-sm font-medium text-center focus:outline-none border border-purple-800 text-purple-800 rounded-full bg-white hover:bg-purple-300 p-2"
type="button"
                                                      // onClick={handleEditInvoice}
                                                  >
                                                      <div className="rounded-full p-1" style={{
                                                      }}>
                                                          <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              viewBox="0 0 24 24"
                                                              fill="currentColor"
                                                              className="w-5 h-5"
                                                          >
                                                              <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                                                              <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                                                          </svg>
                                                      </div>
                                                  </button>
                                              </Tooltip>
                                          </div>
                                      ) : (
                                          (el.original.invoice_status === "7" || el.original.invoice_status === "4" || el.original.invoice_status === "3") ? null : (
                                              <>
                                              <Tooltip title="Send" position="top" trigger="mouseenter" key={el.id}>
                                                  <button
className="ml-1 items-center text-sm font-medium text-center focus:outline-none border border-pink-600 text-pink-600 rounded-full bg-white hover:bg-pink-300 p-1"
type="button"
                                                      disabled={businessType === '1'}
                                                      onClick={()=> {
                                                        // fetchDataByInvoice()
                                                          const invoiceNumber = table.getSelectedRowModel().flatRows[0]?.original.invoice_number
                                                          setOpenSendInvoiceModalId(invoiceNumber)
                                                      }}
                                                  >
                                                      <div className="rounded-full p-1"
                                                      >
                                                          <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              fill="pink"
                                                              viewBox="0 0 24 24"
                                                              strokeWidth={2}
                                                              stroke="currentColor"
                                                              className="w-5 h-5"
                                                              
                                                          >
                                                              <path strokeLinecap="round" strokeLinejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                                                          </svg>
                                                      </div>
                                                  </button>
                                              </Tooltip>
                                                <Tooltip title="Delete" position="top" trigger="mouseenter">
                                                <button
    className="ml-2 items-center text-sm font-medium text-center focus:outline-none border border-red-600 text-red-600 rounded-full bg-white hover:bg-red-300 p-2"
    type="button"
                                                    onClick={handleDeleteModalInvoice}
                                                >
                                                    <div className="rounded-full p-1"
    
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 24 24"
                                                            fill="currentColor"
                                                            className="w-5 h-5"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                    </div>
                                                </button>
                                            </Tooltip>
                                            </>
                                          )
                                      )
                                  ))}

                                    {table.getSelectedRowModel().flatRows.some(el => el.original.invoice_status === "1") && (
                                        <Tooltip title="Delete" position="top" trigger="mouseenter">
                                            <button
className="ml-2 items-center text-sm font-medium text-center focus:outline-none border border-red-600 text-red-600 rounded-full bg-white hover:bg-red-300 p-2"
type="button"
                                                onClick={handleDeleteModalInvoice}
                                            >
                                                <div className="rounded-full p-1"

                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        fill="currentColor"
                                                        className="w-5 h-5"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                </div>
                                            </button>
                                        </Tooltip>
                                    )}
                                      {table.getSelectedRowModel().flatRows.some(el => el.original.invoice_status === "3") && (
                                        <Tooltip title="Pay" position="top" trigger="mouseenter">
                                            <button
className="ml-1 items-center text-sm font-medium text-center focus:outline-none border border-purple-600 text-purple-600 rounded-full bg-white hover:bg-purple-300 p-1"
type="button"

                                                onClick={()=> {
                                                  // fetchDataByInvoice()
                                                    handlePayModalInvoice()
                                                }}
                                            >
<div className="rounded-full p-2 "

>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="red"
    stroke="none"
    strokeWidth="1"
    className="w-5 h-5 "
  >
    <circle cx="12" cy="12" r="10" stroke="currentColor" fill="none" />

    <text x="12" y="16" textAnchor="middle" fontSize="16" stroke="currentColor" fill="none" >
      $
    </text>
  </svg>
</div>






                                            </button>
                                        </Tooltip>
                                    )}


                              </div>
                          )}
                  </div>

            </div>

            {/* <hr className="border border-gray-300 w-[93%] mb-1 mt-1.5 mb-2"></hr> */}
        
            {/* <hr className=" border border-gray-300 w-[93%]"/> */}
            <div  style={{
                                

                                position:(isScrolled &&  selectedRows.length === 1) ? 'fixed': 'fixed',
                                left:(isScrolled &&  selectedRows.length === 1) ? '8px':'8px',
                                bottom: '3%',
                                width:'500px'
                            
                              }}>
            <ToastNotificationBox/>
            </div>
                        {/* <ToastNotificationBox/> */}


          <div className=" w-[99%] rounded-xl pl-8 pr-8 bg-white ">                                        {/*Table starts from here */}
            <div className="w-full mt-4">
             <table className="w-full table-auto bg-transparent rounded-xl mr-5 shadow-lg">
              <thead className="bg-gray-500/20 text-gray-500">
              {/* <thead className="bg-green-100 text-green-600 overflow-hidden rounded-lg"> */}

                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className="text-center text-xs">
                    {headerGroup.headers.map((header, index) => {
                      const isFirstHeader = index === 0;
                      const isLastHeader = index === headerGroup.headers.length - 1;
                      const headerClasses = [
                        'p-3 font-bold uppercase',
                        isFirstHeader ? 'rounded-l-xl' : '',
                        isLastHeader ? 'rounded-r-xl' : '',
                      ];

                      const isSorted = header.column.getIsSorted();
                      const showSortingIcons = header.column.columnDef.enableSorting !== false;
                      const ArrowUpIcon = (
                        <FaArrowUpLong
                          className={`${isSorted === 'asc' ? 'text-black' : 'text-gray-400'}`}
                          size={12}
                        />
                      );
                      const ArrowDownIcon = (
                        <FaArrowDownLong
                          className={`${isSorted === 'desc' ? 'text-black' : 'text-gray-400'}`}
                          size={12}
                        />
                      );

                      return (
                        <th
                          key={header.id}
                          className={headerClasses.join(' ')}
                          colSpan={header.colSpan}
                          onClick={() => showSortingIcons && handleSortingChange(header.column.id)}
                        >
                          <div className="flex items-center justify-center">
                            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                            {showSortingIcons && (
                              <div className="flex items-center ml-2">
                                {ArrowDownIcon}
                                {ArrowUpIcon}
                              </div>
                            )}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody style={{display:'none'}}>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id} className="cursor-pointer text-center hover:bg-gray-500/10"
                                   onClick={() => 
      handletheViewInvoice(row.original.invoice_number, row.original.customer_id, row.original.invoice_status, 
        row.original.business_name, row.original.final_invoice_amount, row.original.due_date)}
                                  // onClick={() => console.log(row.original,'row....')}
                      // onClick={() => handletheEditInvoice(row.original.invoice_number, row.original.customer_id)}


                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className="p-3 font-semibold text-[#081159] text-[14px] text-center">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
             </table>

           
              
              <InvoiceViewModal isOpen={isModalOpen} closeModal={closeModal} invoiceNumber={table.getSelectedRowModel().flatRows[0]?.original.invoice_number} />
              
              {
              !invoiceData || !invoiceData.length === 0 &&  (
                <div className="w-full h-[200px] flex items-center justify-center ">
                  There is not to show here
                </div>
              )}
                </div>
          </div>
     

        {openDeleteModalId && (
        <PopUpModal
          onClose={() => setOpenDeleteModalId(null)}
          invoiceNumber={openDeleteModalId}
          invoiceDelete={handleDeleteInvoices}
          invoiceName={table.getSelectedRowModel().flatRows[0]?.original.business_name}
          resetRowSelection={table.resetRowSelection}
          setTriggerFetch={setTriggerFetch}
          triggerFetch={triggerFetch}
        />
      )}

      {openSendInvoiceModalId && (
        <PopUpModalForSendInvoice
          onClose={() => setOpenSendInvoiceModalId(null)}
          invoiceNumber={openSendInvoiceModalId}
          invoiceName={table.getSelectedRowModel().flatRows[0]?.original.business_name}
          resetRowSelection={resetRowSelection}
          customerEmail={customerEmail}
          finalInvoiceAmount={table.getSelectedRowModel().flatRows[0]?.original.final_invoice_amount}
          dueDate={table.getSelectedRowModel().flatRows[0]?.original.due_date}
          setTriggerFetch={setTriggerFetch}
          triggerFetch={triggerFetch}
        />
      )}

{
  payModalInvoice && (
    <InvoicePayModal open= {payModalInvoice}
    handleClose= {() => setpayModalInvoice(false)}
    customerName={table.getSelectedRowModel().flatRows[0]?.original.business_name}
    invoiceNumber={table.getSelectedRowModel().flatRows[0]?.original.invoice_number}
    finalInvoiceAmount={(table.getSelectedRowModel().flatRows[0]?.original.final_invoice_amount)}
    setTriggerFetch={setTriggerFetch}
    triggerFetch={triggerFetch}
    resetRowSelection={table.resetRowSelection}

     
      />
  )
}

      <ScrollToTop />
    </div>
  );
}

export default NewNotification;
