import React from "react";

const NotificationUI = () => {
  return (
    <div className="flex justify-center  py-4"
   
    >
    <div className="flex flex-col items-start p-4 w-[880px]"
     style={{
        boxShadow: '0 0 20px 0 rgba(0, 0, 0,.3)',
      }}
    >
      {/* Page Heading */}
      <h1 className="text-xl font-semibold text-left mb-8">Customer Notifications</h1>
      
      {/* Customer Dropdown */}
      <div className="mb-4 w-full">
        <select className="w-50 block border-gray-300 rounded-md shadow-sm p-2">
          <option value="">-- Select Customer --</option>
          {/* Add more customer options as needed */}
        </select>
      </div>
  
      {/* Notifications List */}
      <div className="flex flex-col gap-4 w-full">
        {["Price change notification", "Past due notification", "No payment notification"].map(
          (notification, index) => (
            <div
              key={index}
              className="flex items-center justify-between bg-white w-full p-4 rounded-lg"
              style={{
                boxShadow: '0 0 20px 0 rgba(0, 0, 0,.1)',
              }}
            >
              {/* Notification Text */}
              <div className="flex-1 text-sm font-medium">
                {index + 1}. {notification}
              </div>
              {/* Actions */}
              <div className="ml-4 flex gap-2">
                <button
                  className="w-40 px-2 py-1 border-2 border-[#25caed]
                    hover:font-bold rounded-md hover:bg-[#25caed] hover:shadow-[#25caed]-700/50
                    uppercase duration-200 hover:text-white hover:shadow-lg hover:border-transparent active:scale-90"
                >
                  SMS
                </button>
                <button
                  className="w-40 px-2 py-1 border-2 border-purple-600
                    hover:font-bold rounded-md hover:bg-purple-600 hover:shadow-purple-600/50
                    uppercase duration-200 hover:text-white hover:shadow-lg hover:border-transparent active:scale-90"
                >
                  Email
                </button>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  </div>
  
  );
};

export default NotificationUI;
