import React from "react";
import { Routes, Route } from "react-router-dom";
import { routes } from "./components/constants/routes.constants";
import Home from "./components/pages/Home";
import InvoiceForms from "./components/forms/InvoiceForms";
import CustomerForm from "./components/forms/CustomerForm";
import Invoice from "./components/pages/Invoice";
import Reports from "./components/pages/Reports";
import SalesTax from "./components/pages/SalesTax";
import Sales from "./components/pages/Sales/Sales";
import InvoiceReport from "./components/pages/Invoice/InvoiceReport";
import Invoiceview from './components/forms/invoiceview'
import Customer from "./components/pages/Customer";
import Product from "./components/pages/Product";
import Inventory from "./components/pages/Inventory";
import Billing from "./components/pages/Billing";
import Payments from "./components/pages/Payments";
import Setup from "./components/pages/Setup";
import ViewCustomer from "./components/forms/ViewCustomer";
import ProductForm from "./components/forms/ProductForm";
import ItemForm from "./components/forms/ItemForm";
import ViewProduct from "./components/forms/ViewProduct";
import ViewItem from "./components/forms/ViewItem";
import { TabsWithComponent } from "./components/pages/Tabs";
import Items from "./components/pages/Items";
import Business from "./components/pages/Business";
import BusinessDashboard from "./components/pages/BusinessDashboard";
import ViewBusiness from "./components/forms/ViewBusiness";
import LoginPage from "./components/pages/LoginPage";
import PurchaseReport from "./components/pages/PurchaseReport";
import PriceLog from "./components/pages/PriceLog";
import ForgotPassword from "./components/pages/ForgotPassword";
import SignUpPage from "./components/pages/SignUpPage";
import OtpPage from "./components/pages/OtpPage";
import ViewBusinessUsers from "./components/forms/ViewBusinessUsers";
import CreateOwner from "./components/Admin/CreateOwner";
import CreateBusiness from "./components/Admin/CreateBusiness";
import UsersDashBoard from "./components/Admin/UsersDashBoard";
import AdminBusinessDashboard from "./components/Admin/AdminBusinessDashboard";
import UserLoginPage from "./components/pages/UserLoginPage";
import ItemPrices from "./components/pages/ItemPrices";
import InvoiceFormTwo from "./components/forms/InvoiceFormTwo";
import ViewInvoiceForm from "./components/forms/ViewInvoiceForm";
import ViewInvoiceFormTwo from "./components/forms/ViewInvoiceFormTwo";
import Purchases from "./components/pages/Purchases";
import Expenses from "./components/pages/Expenses";
import Losses from "./components/pages/Losses";
import PayIns from "./components/pages/PayIns";
import PayOuts from "./components/pages/PayOuts";
import Deposits from "./components/pages/Deposits";
import Documents from "./components/pages/Documents";
import Vendors from "./components/pages/Vendors";
import CreateVendor from "./components/gasForms/CreateVendor";
import ViewVendor from "./components/gasForms/ViewVendor";
import ItemDashboard from "./components/pages/ItemDashboard";
import Notifications from "./components/Admin/Notifications";
import EditNotification from "./components/Admin/EditNotification";
import EditBusiness from "./components/Admin/EditBusiness";
import EditOwner from "./components/Admin/EditOwner"
import CreateOwnerMain from "./components/Admin/CreateOwnerMain";
import CustomerPortalPage from "./components/CustomerPortal/CustomerPortalPage";
import Operations from "./components/pages/Operations/Operations";
import Accounting from "./components/pages/Accounting/Accounting";
// import Analytics from "./components/pages/Analytics/Analytics";
import Messages from "./components/pages/Messages/Messages";
import MyDocs from "./components/pages/MyDocs/MyDocs";
import SalesHome from "./components/pages/SalesHome/SalesHome";
import SalesReport from './components/pages/Sales/Sales'
import AuditLogTable from "./components/pages/AuditLogTable";
import DraftAgreements from './components/pages/DraftAgrrements/DraftAgreements'
import ApprovedContracts from './components/pages/ApprovedContracts/ApprovedContracts'
import Licenses from './components/pages/Licenses/Licenses'
import TankReading from "./components/pages/TankReading/TankReading";
import CustomerReport from "./components/CustomerPortal/CustomerReport";
import Termsandconditions from "./components/pages/Termsandconditions";
import SalesTaxCustomers from './components/pages/SalesTaxCustomers'
import CustomerTax from "./components/pages/CustomerTax";
import Pricing from "./components/pages/Pricing";
import NewPrice from "./components/pages/NewPrice";
import EditInvoice from "./components/forms/EditInvoice";
import Users from './components/pages/Users'
import OwnerNotification from "./components/pages/OwnerNotification";
import NewNotification from "./components/pages/NewNotification";
import NewDocument from "./components/pages/NewDocument";
import UsersDashBoardScreen from "./components/pages/UserDashBoard";
import PayInvoice from "./components/pages/PayInvoice";
import FileUploadModal from "./components/pages/FileUploadModal";
const AppRoutes = () => {

  return (
    <Routes>
       <Route path={routes.SIGNUP_PAGE} element={<SignUpPage />} />
       <Route path={routes.OTP_PAGE} element={<OtpPage />} />
       {/* <Route path={routes.LOGIN_PAGE} element={<LoginPage />} /> */}
       <Route path={routes.ADMIN_LOGIN} element={<LoginPage />} />
       <Route path={routes.USER_LOGIN_PAGE} element={<UserLoginPage />} />
       {/* <Route path={routes.FORGOT_PASSWORD} element={<ForgotPassword />} /> */}
      <Route path={routes.HOME} element={<Home />} />
      <Route path={routes.INVOICE} element={<Invoice />} />
      <Route path={routes.REPORTS} element={<Reports />} />
      <Route path={routes.CUSTOMERREPORTS} element={<CustomerReport />} />

      <Route path={routes.SALESTAX} element={<SalesTax />} />
      <Route path={routes.SALES} element={<Sales />} />
      <Route path={routes.INVOICEREPORT} element={<InvoiceReport />} />
      <Route path={routes.CUSTOMERS} element={<Customer />} />
      <Route path={routes.PURCHASES} element={<Purchases />} />
      <Route path={routes.EXPENSES} element={<Expenses />} />
      <Route path={routes.LOSSES} element={<Losses />} />
      <Route path={routes.PAYINS} element={<PayIns />} />
      <Route path={routes.PAYOUTS} element={<PayOuts />} />
      
      <Route path={routes.PURCHASEREPORT} element={<PurchaseReport />} />

      <Route path={routes.PAYMENTS} element={<Payments />} />
      <Route path={routes.DEPOSITS} element={<Deposits />} />
      <Route path={routes.DOCUMENTS} element={<Documents />} />

      <Route path={routes.BUSINESS} element={<Business />} />
      <Route path={routes.PRODUCTS} element={<Product />} />
      <Route path={routes.ITEMS} element={<Items />} />
      <Route path={routes.VENDORS} element={<Vendors />} />
      <Route path={routes.PRICELOG} element={<PriceLog />} />

      <Route path={routes.INVENTORY} element={<Inventory />} />
      <Route path={routes.BILLING} element={<Billing />} />

      {/* <Route path={routes.PAYMENTS} element={<Payments />} /> */}
      <Route path={routes.SETUP} element={<TabsWithComponent />} />
      <Route path={routes.BUSINESS_DASHBOARD} element={<BusinessDashboard />} />

      <Route path={routes.INVOICE_FORM} element={<InvoiceForms />} />
      <Route path={routes.CUSTOMER_FORM} element={<CustomerForm />} />
      <Route path={routes.VIEW_CUSTOMER} element={<ViewCustomer />} />
      <Route path={routes.VIEW_BUSINESS} element={<ViewBusiness />} />
      <Route path={routes.VIEW_BUSINESS_USERS} element={<ViewBusinessUsers />} />
      <Route path={routes.CREATE_OWNER} element={<CreateOwner />} />
      <Route path={routes.CREATE_OWNER_MAIN} element={<CreateOwnerMain />} />
      <Route path={`${routes.PAYINVOICE}/:invoiceNumber`} element={<PayInvoice />} />
      <Route path={`${routes.FILEUPLOAD}`} element={<FileUploadModal />} />

      <Route path={routes.CREATE_BUSINESS} element={<CreateBusiness />} />
      <Route path={routes.USERS_DASHBOARD} element={<UsersDashBoard />} />
      <Route path={routes.ADMIN_BUSINESS_DASHBOARD} element={<AdminBusinessDashboard />} />
      <Route path={routes.ITEM_PRICE} element={<ItemPrices />} />
      <Route path={routes.INVOICE_FORM_TWO} element={<InvoiceFormTwo />} />
      <Route path={routes.VIEW_INVOICE_FORM} element={<ViewInvoiceForm />} />
       <Route path={routes.VIEW_INVOICE_FORM_TWO} element={<ViewInvoiceFormTwo />} />
       <Route path={routes.CREATE_VENDOR} element={<CreateVendor />} />
       <Route path={routes.VIEW_VENDOR} element={<ViewVendor />} />
       <Route path={routes.ITEMS_DASHBOARD} element={<ItemDashboard />} />
       <Route path={routes.NOTIFICATIONS} element={<Notifications />} />
       <Route path={routes.EDIT_NOTIFICATIONS} element={<EditNotification />} />
       <Route path={routes.EDIT_ADMIN_BUSINESS} element={<EditBusiness />} />
       <Route path={routes.EDIT_ADMIN_OWNER} element={<EditOwner />} />
       <Route path="/customerportal" component={<CustomerPortalPage/>} />
       <Route path={routes.OPERATIONS} element={<Operations />} />
       <Route path={routes.ACCOUNTING} element={<Accounting />} />
       {/* <Route path={routes.ANALYTICS} element={<Analytics />} /> */}
       <Route path={routes.MESSAGES} element={<Messages />} />
       <Route path={routes.MYDOCS} element={<MyDocs />} />
       <Route path={routes.TANKREADING} element={<TankReading />} />
       <Route path={`${routes.INVOICEVIEW}/:invoicenumber`} element={<Invoiceview />} />
       <Route path={routes.SALESTAXCUSTOMERS} element={<SalesTaxCustomers />} />
       <Route path={routes.CUSTOMERTAX} element={<CustomerTax />} />
       <Route path={routes.PRICING} element={<Pricing />} />
       <Route path={routes.NEWPRICE} element={<NewPrice />} />
       <Route path={`${routes.EDITINVOICE}/:invoicenumber`} element={<EditInvoice />} />
       <Route path={routes.USERS} element={<Users />} />
       <Route path={routes.OWNERNOTIFICATIONS} element={<OwnerNotification />} />
       <Route path={routes.NEWNOTIFICATION} element={<NewNotification />} />
       <Route path={routes.NEWDOCUMENT} element={<NewDocument />} />
       
       <Route path={routes.USERDASHBOARD} element={<UsersDashBoardScreen />} />

       <Route path={routes.SALESHOME} element={<SalesHome />} />
       <Route path={routes.SALESREPORT} element={<SalesReport />} />
       <Route path={routes.AUDITLOG} element={<AuditLogTable />} />
       <Route path={routes.DRAFTAGREEMENTS} element={<DraftAgreements />} />
       <Route path={routes.APPROVEDCONTRACTS} element={<ApprovedContracts />} />
       <Route path={routes.LICENSES} element={<Licenses />} />
       <Route path={routes.TERMSANDCONDITIONS} element={<Termsandconditions />} />

      {/* Dynamic routes for CustomerForm and ViewCustomer */}
      <Route path={`${routes.SETUP}/signup`} element={<SignUpPage />} />
      {/* <Route path={`${routes.SETUP}/loginpage`} element={<LoginPage />} /> */}
      <Route path={`${routes.SETUP}/adminlogin`} element={<LoginPage />} />


      <Route path={`${routes.SETUP}/userloginpage`} element={<UserLoginPage />} />
      <Route path={`${routes.SETUP}/otp`} element={<OtpPage />} />
      {/* <Route path={`${routes.SETUP}/forgotpassword`} element={<ForgotPassword />} /> */}
      <Route path={`${routes.SETUP}/customerform`} element={<CustomerForm />} />
      <Route path={`${routes.SETUP}/:products`} element={<TabsWithComponent />} />
      <Route path={`${routes.SETUP}/productform`} element={<ProductForm />} />
      <Route path={`${routes.SETUP}/business`} element={<Business />} />
      <Route path={`${routes.SETUP}/businessdashboard`} element={<BusinessDashboard />} />
      <Route path={`${routes.SETUP}/viewbusinessusers`} element={<ViewBusinessUsers />} />
      <Route path={`${routes.SETUP}/itemform`} element={<ItemForm />} />
      <Route path={`${routes.CUSTOMERS}/viewcustomer/:customerId`} element={<ViewCustomer />} />
      <Route path={`${routes.SETUP}/viewproduct/:productId`} element={<ViewProduct />} />
      <Route path={`${routes.SETUP}/viewitem/:itemId`} element={<ViewItem />} />
      <Route path={`${routes.SETUP}/viewbusiness/:businessId`} element={<ViewBusiness />} />

      <Route path={`${routes.SETUP}/createowner`} element={<CreateOwner />} />
      <Route path={`${routes.SETUP}/createownermain`} element={<CreateOwnerMain />} />
      <Route path={`${routes.SETUP}/editadminowner/:id`} element={<EditOwner />} />

      <Route path={`${routes.SETUP}/createbusiness`} element={<CreateBusiness />} />
      <Route path={`${routes.EDIT_ADMIN_BUSINESS}/editadminbusiness/:id`} element={<EditBusiness />} />
      
      <Route path={`${routes.SETUP}/usersdashboard`} element={<UsersDashBoard />} />
      <Route path={`${routes.SETUP}/adminbusinessdashboard`} element={<AdminBusinessDashboard />} />
      <Route path={`${routes.ITEMS_DASHBOARD}/itemsdashboard`} element={<ItemDashboard />} />
      <Route path={`${routes.NOTIFICATIONS}/notifications`} element={<Notifications />} />
      <Route path={`${routes.EDIT_NOTIFICATIONS}/editnotifications/:id`} element={<EditNotification />} />
      
      {/* Dynamic routes for InvoiceForms */}
      <Route path={`${routes.INVOICE}/invoiceforms`} element={<InvoiceForms />} />
      <Route path={`${routes.CUSTOMERS}/customerforms`} element={<CustomerForm />} />
      <Route path={`${routes.PRODUCTS}/productform`} element={<ProductForm />} />
      
      <Route path={`${routes.ITEMS}/itemform`} element={<ItemForm />} />
      <Route path={`${routes.ITEM_PRICE}/itemprice`} element={<ItemPrices />} />
      <Route path={`${routes.INVOICE_FORM_TWO}/invoiceformtwo`} element={<InvoiceFormTwo />} />
      <Route path={`${routes.VIEW_INVOICE_FORM}/:invoicenumber`} element={<ViewInvoiceForm />} />
      
      <Route path={`${routes.VIEW_INVOICE_FORM_TWO}/:invoicenumber`} element={<ViewInvoiceFormTwo />} />
      <Route path={`${routes.CREATE_VENDOR}/createvendor`} element={<CreateVendor />} />
      <Route path={`${routes.VIEW_VENDOR}/:vendorid`} element={<ViewVendor />} />

     

    </Routes>
  );
};

export default AppRoutes;
