
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { pdfjs, Document, Page } from 'react-pdf';
import { DisabledByDefault } from '@mui/icons-material';
import config  from "../../config/config"


Modal.setAppElement('#root');

const CustomerPortalImage = ({  isModalOpen, closeModal, invoiceNumber }) => {
  const [imageUrls, setImageUrls] = useState([]);
  const business_id = localStorage.getItem("business_id");
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const managerId = localStorage.getItem("user_id")
  const userRole = localStorage.getItem("user_role")
  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await axios.get(`${baseURL}${btoa("invoice/downloadinvoice")}/${btoa(invoiceNumber)}/${btoa(business_id)}/${btoa(managerId)}/${btoa(userRole)}`, {
          responseType: 'arraybuffer',
        });
  
        // Convert the PDF data to image URLs
        const urls = await convertPdfToImages(response.data);
        setImageUrls(urls);
      } catch (error) {
        console.log("Error viewing invoice:", error);
      }
    };
  
    if (isModalOpen) {
      document.body.style.overflow = 'hidden'; // Disable scrolling when modal is open
      fetchPdf();
    } else {
      document.body.style.overflow = 'auto'; // Enable scrolling when modal is closed
    }
  
    return () => {
      document.body.style.overflow = 'auto'; // Ensure scrolling is restored on unmount
    };
  }, [isModalOpen, invoiceNumber, business_id]);
  

  return (
    <Modal isOpen={isModalOpen} onRequestClose={closeModal} style={customStyles} shouldCloseOnOverlayClick={false}>
      <div className="flex justify-end sticky top-0 right-0 m-2 z-50">
        <button onClick={closeModal} className="text-gray-500 hover:text-gray-700">
          <FaTimes />
        </button>
      </div>

      <div className="max-h-[calc(100vh-4rem)]">
        {imageUrls.map((url, index) => (
          <img key={index} src={url} alt={`Page ${index + 1}`} />
        ))}
      </div>
    </Modal>

  
  
  );
};

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Background color with opacity
    zIndex: 9999,
  
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    padding: '20px',
    backgroundColor: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    // Prevent scrolling inside the modal
  },
};


// Function to convert PDF to images
const convertPdfToImages = async (pdfData) => {
  try {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const loadingTask = pdfjs.getDocument(new Uint8Array(pdfData));
    const pdf = await loadingTask.promise;

    const urls = [];
    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const viewport = page.getViewport({ scale: 2 });
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const renderContext = {
        canvasContext: context,
        viewport: viewport
      };
      await page.render(renderContext).promise;

      // Convert canvas to data URL
      const imageUrl = canvas.toDataURL('image/jpeg');
      urls.push(imageUrl);
    }

    return urls;
  } catch (error) {
    console.log("Error converting PDF to images:", error);
    return [];
  }
};

export default CustomerPortalImage;
