import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
} from "@material-tailwind/react";

function CustomerTableMenu({onSearch}) {
  const navigate = useNavigate();
  const handleSearch = (e) => {
    const searchQuery = e.target.value.trim();
    onSearch(searchQuery);
  };

  return (
    <div className="flex justify-between items-center w-full  mt-16 px-9">
      {/* left */}
      <div className="flex-grow">
        <form>
          <div className="flex items-center  border border-gray-300 rounded-lg hover:border-2 hover:border-[#3479E0] ">
      

            <div className="relative w-full h-full">
              <input
                type="search"
                id="search-dropdown"
                className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-white rounded-lg border-0  focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-l-gray-700 focus:ring-0 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                placeholder="Search Customers Name, Number..."
                onChange={handleSearch}

              />
              <button
                type="submit"
                className="absolute bottom-[1px] right-[1.5px] p-2.5 text-sm font-medium h-[38px] text-black bg-gray-300 rounded-lg border  focus:ring-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
                <span className="sr-only">Search</span>
              </button>
            </div>
          </div>
        </form>
      </div>
      {/* right */}
      <div className="flex items-center space-x-6">
    
      </div>
    </div>
  );
}

export default CustomerTableMenu;
