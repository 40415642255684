import React, { useState } from 'react';
import { useNavigate} from 'react-router-dom';


const NewPrice = () => {
    const navigate = useNavigate()

  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [fuelData, setFuelData] = useState([
    {
      id: 1,
      fuelBrand: 'Brand A',
      fuelGrade: 'Grade 1',
      oldSalesPrice: 3.50,
      newPoPrice: 3.80,
      newSalesPrice: '',
    },
    {
      id: 2,
      fuelBrand: 'Brand B',
      fuelGrade: 'Grade 2',
      oldSalesPrice: 4.00,
      newPoPrice: 4.30,
      newSalesPrice: '',
    },
    {
      id: 3,
      fuelBrand: 'Brand C',
      fuelGrade: 'Grade 3',
      oldSalesPrice: 3.75,
      newPoPrice: 4.00,
      newSalesPrice: '',
    },
  ]);

  const handleNewSalesPriceChange = (id, value) => {
    setFuelData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, newSalesPrice: value } : item
      )
    );
  };

  return (
    <div className="p-6">
      <h2 className="text-lg font-semibold mb-4 mx-10">Sales &gt;&gt; Fuel Pricing</h2>
      <div className="mb-6 mx-10">
        <label htmlFor="customer-select" className="block font-medium mb-2">
          Select Customer
        </label>
        <select
          id="customer-select"
          className="block w-30 rounded-md p-2"
          onChange={(e) => setSelectedCustomer(e.target.value)}
          value={selectedCustomer}
        >
          <option value="">-- Select Customer --</option>
          <option value="customer1">Customer 1</option>
          <option value="customer2">Customer 2</option>
        </select>
      </div>
      <table className="w-70 mx-10">
        <thead className="bg-blue-100 border-blue-600 text-blue-600 rounded-lg">
          <tr>
            <th className="px-4 py-2 text-left text-blue-600">Fuel Brand</th>
            <th className="px-4 py-2 text-left text-blue-600">Old Po Price</th>
            <th className="px-4 py-2 text-center text-blue-600">Old Sales Price</th>
            <th className="px-4 py-2 text-center text-blue-600">New PO Price</th>
            <th className="px-4 py-2 text-center text-blue-600">New Sales Price</th>
          </tr>
        </thead>
        <tbody>
          {fuelData.map((item) => (
            <tr key={item.id}>
              <td className="px-4 py-1 text-left">{item.fuelBrand}</td>
              <td className="px-4 py-1 text-left">{item.fuelGrade}</td>
              <td className="px-4 py-1 text-center">${item.oldSalesPrice.toFixed(2)}</td>
              <td className="px-4 py-1 text-center">${item.newPoPrice.toFixed(2)}</td>
              <td className="px-4 py-1 text-center">
                <input
                  type="number"
                  className="w-full rounded-md p-1 border border-gray-300"
                  value={item.newSalesPrice}
                  onChange={(e) => handleNewSalesPriceChange(item.id, e.target.value)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-4 text-right mx-16">
        <button className="bg-orange-600 text-white px-4 py-2 rounded-md hover:bg-orange-600"
                    onClick={() =>navigate('/Pricing')}

        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default NewPrice;
