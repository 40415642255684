import { Button, Modal } from "flowbite-react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import config from "../../config/config";
import { ShowToastForDeleteVendor } from "../toasts/toastForVendor";
import { deleteItem } from "../../store/itemSlice";
import { showToastForDeleteItem } from "../toasts/toastForItem";
import { deletePurchases } from "../../store/purchasesSlice";
import { toast } from "react-toastify";
import { addToast } from "../pages/ToastBox/ToastBoxMessageSlice";

export default function PopUpModalForDeletePurchases({ onClose, id , imageName , resetRowSelection, brandInvoiceNumber }) {

    const dispatch = useDispatch();
    const businessId = localStorage.getItem("business_id")
    const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
    const [openModal, setOpenModal] = useState(true);
    const managerId = localStorage.getItem("user_id")
    const userRole = localStorage.getItem("user_role");

  const handleDelete = async() => {

    // console.log("mod" , itemId , itemName);
    // const encodeId = btoa(vendorId)
    try{
        const response = await axios.delete(`${baseURL}${btoa("purchase/delete")}/${btoa(id)}/${btoa(businessId)}/${btoa(brandInvoiceNumber)}/${btoa(managerId)}/${btoa(userRole)}`);
        console.log("delete purchase" , response)
        if (response.data && response.data.deleteStatus === true) {
            dispatch(deletePurchases(id));
            window.scroll(0,0)
            onClose();
            resetRowSelection();
            // toast.error(`Invoice  ${brandInvoiceNumber} deleted successfully`, {
            //     autoClose: 2000,
            //   });
    dispatch(addToast({ type: 'danger', message: `Invoice  ${brandInvoiceNumber} deleted successfully` }));        

        } else {
            console.error("Failed to delete the item");
        }
    }catch(error){
        console.log("error deleting item" , error);
        // toast.error(`Invoice  ${brandInvoiceNumber} deleting failed`, {
        //     autoClose: 2000,
        //   });
        dispatch(addToast({ type: 'danger', message: `Invoice  ${brandInvoiceNumber} deleting failed` }));        

    } 
  };

  return (
    <Modal show={openModal} size="md" popup onClose={onClose}>
      <Modal.Header />
      <Modal.Body>
        <div className="text-center">
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400 mt-3">
            Are you sure you want to delete
            <span className="block uppercase p-2 font-semibold text-red-700 underline underline-offset-8">" {`Invoice ${brandInvoiceNumber}`} "</span>
          </h3>
          <div className="flex justify-center gap-4">
            <Button color="failure" onClick={handleDelete}>
              Yes, I'm sure
            </Button>
            <Button color="gray" onClick={onClose}>
              No, cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
