
import React from 'react'

import { useState , useEffect } from 'react';
import axios from "axios";
import { useRef } from 'react';
import {addInvoice,} from "../../actions/invoiceActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate} from 'react-router-dom';
import { DatePicker, Space } from "antd";
import dayjs from 'dayjs';
import config  from "../../config/config"
import FullScreenLoader from '../pages/FullScreenLoader';
import { number } from 'yup';
import { useSearchParams, useParams } from 'react-router-dom';


const EditInvoice = () => {
          const {invoicenumber} = useParams()
    
      const [CustomerData, setCustomerData] = useState([])
          const [TaxesView, setTaxesView] = useState([]);
      
        
         const [items, setItems] = useState([
              { 
                // productId : "",
                // itemId : "",
                // item: "",
                // description : "",
                // quantity: "",
                // unitPrice: "",
                // total: 0,
              },
            ]);
        const [BillShip, setBillShip] = useState([])
  
  const dispatch = useDispatch()
    const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
    const [showDescription, setShowDescription] = useState(false);
    const [quantityErrors, setQuantityErrors] = React.useState({});
    const [discount, setDiscount] = useState(0);
    const [waterBill, setwaterBill] = useState(0);
    const [Rebates, setRebates] = useState(0);
    const [freightsurcharge, setfreightsurcharge] = useState(0);
    const [freightCharges, setfreightCharges] = useState(0);
    const [brandInvoiceNumber, setBrandInvoiceNumber] = useState('');
    const [invoiceFuelData, setInvoiceFuelData] = useState([]);
    console.log(invoiceFuelData, 'invoicefueldata')
    const [InvoiceCustomerData, setInvoiceCustomerData] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(''); // Selected product
    const [selectedDiscountType, setSelectedDiscountType] = useState(''); // Type of discount (amount, %, gallons)
    const [isSubtotalExpanded, setSubtotalExpanded] = useState(false);
    const [isTaxesExpanded, setTaxesExpanded] = useState(false);
    
    const [productSubTotal, setProductSubTotal] = useState(0);

    const toggleSubtotal = (e) => {e.preventDefault(); setSubtotalExpanded(!isSubtotalExpanded)};
    const toggleTaxes = (e) => {e.preventDefault(); setTaxesExpanded(!isTaxesExpanded)};

    
    const addRow = (product) => {

      setDiscountRebateError('')
      if (!product) return; 
    
      // Check for conflicting conditions: Discount and Rebate
      const discountExists = InvoiceCustomerData.some(item => item.isDiscount);
      const rebateExists = InvoiceCustomerData.some(item => item.product_name.toLowerCase() === "rebates/credits");
    
      if (product.toLowerCase() === "discount" && rebateExists) {
        setDiscountRebateError("Both discount and rebate cannot be added at the same time.");
        return; // Prevent adding the discount if rebate exists
      }
    
      if (product.toLowerCase() === "rebates/credits" && discountExists) {
        setDiscountRebateError("Both discount and rebate cannot be added at the same time.");
        return; // Prevent adding the rebate if discount exists
      }
    
      let newRow;
    
      if (product.toLowerCase() === "miscellaneous") {
        const miscCount = InvoiceCustomerData.filter((item) =>
          item.product_name.toLowerCase().includes("miscellaneous")
        ).length;
    
        const miscProduct = `Miscellaneous${miscCount + 1}`;
        newRow = {
          product_id: InvoiceCustomerData.length + 1,
          product_name: miscProduct,
          quantity: "",
          unitPrice: "",
          total: 0.0,
          description: "",
          isCustomRow: true,
          isDiscount: product.toLowerCase() === 'discount',
          isFreight: product.toLowerCase() === 'freight charges' || product.toLowerCase() === 'freight surcharge',
          isAddedRow: true, // Mark the row as added
          displayPercentage: product.toLowerCase() === 'discount' 
          ? (DiscountedPercentage ? `${DiscountedPercentage}` :"") 
          : "", 
        
        };
    
        const updatedProducts = remainingProducts.filter(
          (item) => item.toLowerCase() !== "miscellaneous" || miscCount < 4
        );
        setRemainingProducts(updatedProducts);
      } else {
        newRow = {
          product_id: InvoiceCustomerData.length + 1,
          product_name: product,
          quantity: "",
          unitPrice: "",
          total: 0.0,
          description: "",
          isCustomRow: true,
          isDiscount: product.toLowerCase() === 'discount',
          isFreight: product.toLowerCase() === 'freight charges' || product.toLowerCase() === 'freight surcharge',
          isAddedRow: true, // Mark the row as added
          displayPercentage: product.toLowerCase() === 'discount' 
          ? (DiscountedPercentage ? `${DiscountedPercentage}` : "") 
          : "", // Set displayPercentage for discount
        
        };
    
        const updatedProducts = remainingProducts.filter((item) => item !== product);
        setRemainingProducts(updatedProducts);
      }
    
      // Add the new row to the invoice data
      setInvoiceCustomerData([...InvoiceCustomerData, newRow]);
    
      setSelectedProduct("");
      setShowDescription(true); // Show description column when a product is added
    };
    
  
    const handleDiscountTypeChange = (e, index) => {
      
     
      const newDiscountType = e.target.value;
      setSelectedDiscountType(newDiscountType);
    
  
      const updatedData = [...InvoiceCustomerData];
      if (newDiscountType === "") {
        updatedData[index].quantity = 0;
        updatedData[index].unitPrice = 0;
        updatedData[index].total = 0;
        setDiscount(0);
      } else {
        updatedData[index].quantity = 0;
        updatedData[index].unitPrice = 0;

        updatedData[index].total = 0;
        setDiscount(0);

      }
      updatedData[index].description = newDiscountType; // Set discount type in description
      setInvoiceCustomerData(updatedData);
  
    };
 
    const [loading, setLoading] = useState(true);
    
    const [DiscountRebateError, setDiscountRebateError] = useState('');

    const [latestPurchaseInvoices, setLatestPurchaseInvoices] = useState(null);

    const [customerIdInvoice, setCustomerIdInvoice] = useState('');

    const today = new Date().toISOString().split("T")[0];
    const [sellerNames , setSellerNames] = useState([])

    const [tax, setTax] = useState("");
    const [feesOrDiscounts, setFeesOrDiscounts] = useState("");
  
    const [termsAndConditions, setTermsAndConditions] = useState(""); 

    const [latestInvoiceNumber , setLatestInvoiceNumber] = useState("");
  
    const [dueDate, setDueDate] = useState("");

    const [createdDate , setCreatedOnDate ] = useState(""); 
    const [invoiceDate , setInvoiceDate ] = useState(dayjs()); 

    const businessId = localStorage.getItem("business_id")
  
    
    const [customerOptions , setCustomerOptions] = useState([])
    const [taxTotal ,setTaxTotal] = useState("");
   
    const navigate = useNavigate()
    const [imageSource, setImageSource] = useState();
    const [imageResponse, setimageResponse] = useState([]);
    useEffect(() => {
      axios
        .get(`${baseURL}/${btoa("business/profile")}/${btoa(business_id)}/${btoa(user_id)}/${btoa(user_role)}`)
        .then((response) => {
          console.log(response);
          // console.log("response businesstype", response);
          setImageSource(response.data.business_data[0].image_file)
          setimageResponse(response.data.business_data[0])
        })
        .catch((error) => {
          console.error("Error fetching business data:", error);
  
        });
        console.log(imageResponse);
  
    }, []);
    // console.log("new total is ", newTotal);
    
    const [products, setProducts] = useState([
      { 
        product:"",
        productCode : "",
        quantity: "",
        unitPrice: "",
        total: 0,
        editableDescription: false,
      
      },
    ]);

    // console.log("products", products);

    const [formField, setFormField] = useState({
      invoice_number:  "",
      bill_from: "",
      bill_to: null,
      vehicleNumber : "",
      sellersId :"",
      originTerminal : "",
      destinationTerminal  : "",
      created_on: today,
      due_on: today,
      invoiceDate: today
    });
    useEffect(()=>{
        const fetchDataByInvoice = async () => {
          const customerId = localStorage.getItem('customerId' )
  
          try {
            const apiUrl = `${baseURL}/${btoa("invoice/fetchinvoicebyinvooiocenumber")}/${btoa(invoicenumber)}/${btoa(businessId)}/${btoa(customerId)}`;
            const response = await axios.get(apiUrl);
            console.log("invoice on on invoice number: " , response);
              setItems(response.data.invoiceItemsData);
              setCustomerData(response.data.businessData[0])
              setBillShip(response.data.customerData[0])
              setTaxesView(response.data.customersTaxesAndValuesAndResult)
              setFormField((prev) => {
                if (!prev.bill_to) {
                  return { ...prev, bill_to: response.data.customerData[0]?.customer_id };
                }
                return prev;
              });
              setCustomerIdInvoice(response.data.customerData[0]?.customer_id)
              setBrandInvoiceNumber(response.data.customerData[0]?.purchased_invoicepo)
              setInvoiceDate(dayjs(response.data.customerData[0]?.added_on))
              setCreatedOnDate(dayjs(response.data.customerData[0]?.delivery_date))
              
              setDueDate(dayjs(response.data.customerData[0]?.due_date))
              setTermsAndConditions(response.data.customerData[0]?.terms_conditions)

          } catch (error) {
            console.error("Error fetching invoice by invoice number", error);
          }
        };
        fetchDataByInvoice()
      },[])
     
    const isFormComplete = () => {
      // Check if customer information is filled
      const isCustomerInfoComplete =
        formField.bill_to &&
        createdDate &&
        dueDate &&
        invoiceDate;
    
      const areProductsComplete =
        InvoiceCustomerData.length > 0 &&
        InvoiceCustomerData.some(
          (product) =>
            !product.isCustomRow && // Exclude custom rows
            product.quantity > 0 
        );
    
      const isPOSelected = Boolean(brandInvoiceNumber);
    
      return isCustomerInfoComplete && areProductsComplete && isPOSelected;
    };
    const existingRows = InvoiceCustomerData.filter((row) => !row.isAddedRow);
    const totalQuantity = existingRows.reduce((acc, product) => {
      if (product.product_name !== "" && product.quantity !== 0 ) {
        console.log(product, products, 'item checking');
        return acc + parseFloat(product.quantity || 0);
      }
      return acc; // If the condition is not met, return the current accumulator
    }, 0); // Initial accumulator value
    console.log(totalQuantity, InvoiceCustomerData, 'total quantity');
    const subTotal = InvoiceCustomerData
    .filter((product) => !product.isCustomRow) // Include only rows where iscustom is false
    .reduce((acc, product) => acc + parseFloat(product.total || 0), 0);
    //  console.log(subTotal, 'subtotal checking')
    const grandTotal = parseFloat(taxTotal) + parseFloat(productSubTotal);
    const [customerIndividualData, setCustomerIndividualData] = useState(grandTotal);
    const handleChange = (event) => {
      setfreightCharges(0)
      setfreightsurcharge(0)
      setDiscount(0)
      setwaterBill(0)
      setRebates(0)
      setLatestPurchaseInvoices(null)

      setRemainingProducts(availableProducts)
        setCustomerIdInvoice(event.target.value)
      console.log(event.target.value)
      if (event instanceof Date) {
        // setCreatedOnDate(event);
      } else {
        setInvoiceFuelData([])

        const { name, value } = event.target;
        setFormField({
          ...formField,
          [name]: value,
        });
      }
    };

    const handleDeliveryDateChange = (date) => {
      console.log(date, "date....")
      setCreatedOnDate(date);
      setFormField({
        ...formField,
        created_on: date,
      });
    };
    
    const handleInvoiceDateChange = (date) => {
      setInvoiceDate(date);
      setFormField({
        ...formField,
        invoiceDate: date,
      });
    };
    const handleDueDateChange = (date) => {
      setDueDate(date);
      setFormField({
        ...formField,
        due_on: date,
      });
    };
    
    
    const datePickerRef = useRef(null);
    const deliveryDatePickerRef = useRef(null);

    useEffect(() => {
      const fetchLatestInvoiceNumber = async () => {
        try {
          const response = await axios.get(`${baseURL}${btoa("invoice/latest_invoice_number")}/${btoa(businessId)}`);
          const latestInvoiceNumber = response.data;
          // console.log("latestInvoiceNumber", latestInvoiceNumber);
          setLatestInvoiceNumber(latestInvoiceNumber)
          // setFormField((prev) => ({ ...prev, invoice_number: latestInvoiceNumber }));
        } catch (error) {
          console.error("Error fetching latest invoice number:", error);
        } 
      };
      fetchLatestInvoiceNumber();
    }, []);


    const handleSubmit = (e) => {
      setLoading(true)
       e.preventDefault();
 try { 
       const formData = new FormData();
   
       formData.append("businessId", localStorage.getItem("business_id"));
       formData.append("managerId", localStorage.getItem("user_id"));
       formData.append("userRole", user_role);

       formData.append("managerId", localStorage.getItem("user_id"));

       formData.append("billFromName", formField.bill_from);
       formData.append("customerId", formField.bill_to);
       formData.append("deliveryDate", dayjs(createdDate).format('YYYY-MM-DD'));
       formData.append("invoiceDate", dayjs(invoiceDate).format('YYYY-MM-DD'));
 
       formData.append("dueDate", dayjs(dueDate).format('YYYY-MM-DD'));
   
       formData.append("invoiceNumber", latestInvoiceNumber);
       formData.append("subTotalAmount", productSubTotal);
       formData.append("finalTotalAmount", (productSubTotal +
        taxTotal1));
       formData.append("discountType", selectedDiscountType);
     
       formData.append("discountsAmount", discount);
      
      formData.append("waterBillAmount", waterBill);
      
      formData.append("rebatesCreditsAmount", Rebates);


       formData.append("finalInvoiceAmount", (
        productSubTotal +  (parseFloat(freightCharges) ) +
        (parseFloat(freightsurcharge) )+
        (parseFloat(waterBill) ) - 
        (parseFloat(discount) ) - 
        (parseFloat(Rebates))+
        taxTotal1
      ).toFixed(2));
       
       formData.append("freightCharge", freightCharges);
       formData.append("freightSurcharge", freightsurcharge);
       const taxMapping = {
        "Sales Tax 9": "federalExciseTax",
        "Sales Tax 11": "federalOilSpil",
        "Pre Paid Tax": "nysPrepaid",
        "Sales Tax 17": "nysExcise",
        "Sales Tax 15": "nysSpillRecovery",
        "Sales Tax 14": "nysPetroleumTestingFee",
        "Sales Tax 13": "nysPetroleumBusRcvry",
      };
      
      TaxDataForInvoice.forEach((item) => {
        const taxKey = taxMapping[item.customer_taxes];
      
        if (taxKey) {
          const taxValue = Number(item[item.po_customertax_name] * totalQuantity).toFixed(5);
          formData.append(taxKey, taxValue);
        }
      });
      
       formData.append("salesTaxAmount" , parseFloat(taxTotal1).toFixed(5) ); 
       formData.append("termsCondition", termsAndConditions);
       
       formData.append("purchasedInvoicePO", brandInvoiceNumber);
 
      //  let validIndex = 0; 
       const addedRows = InvoiceCustomerData.filter((row) => row.isAddedRow);
       const existingRows = InvoiceCustomerData.filter((row) => !row.isAddedRow);
   
       // Log or send added rows and other rows separately
       console.log("Added Rows:", addedRows);
       console.log("Existing Rows:", existingRows);

   addedRows.forEach((row) => {
    console.log(row, 'backend checking')
    if(row.isCustomRow && selectedDiscountType === "gallons"){
      formData.append("discountsGallons",row.quantity );
      formData.append("discountsPrice",row.unitPrice );
    }
    if(row.product_name === "Freight Charges"){
    formData.append(`freightDescription`, row.description);

    } else if(row.product_name === "Freight Surcharge"){
      formData.append(`freightSurchargeDescription`, row.description);

    } else if(row.product_name === "Water Bill"){
      formData.append(`waterBillDescription`, row.description);

    } else if (row.product_name ===  "Rebates/Credits") {
      formData.append(`rebatesCreditsDescription`, row.description);

    }
});

           let validIndex = 0;

       existingRows.forEach((row, index) => {
        if(row.total > 0) {
         
          if(row.isCustomRow && selectedDiscountType === "gallons"){
            formData.append("discountsGallons",row.quantity );
            formData.append("discountsPrice",row.unitPrice );
          } else {
            formData.append(`productId[${validIndex}]`, row.product_id);
            // formData.append(`addedRow[${index}][productName]`, row.product_name);
            formData.append(`quantities[${validIndex}]`, row.quantity);
            formData.append(`unitPrices[${validIndex}]`, 2);
            formData.append(`unitTotals[${validIndex}]`, row.total);
            // formData.append(`addedRow[${index}][description]`, row.description);
            validIndex++;
          }
       
        } 
       });
   
 
      //  taxValuesResults.forEach((values, index) => {
      //    Object.entries(values).forEach(([key, value]) => {
      //      const parsedValue = parseFloat(value);
       
      //      if (!isNaN(parsedValue) && parsedValue > 0) {
      //        formData.append(`${key}[${index}]`, parsedValue);
      //      }
      //    });
      //  });
       
       console.log("form data: " , formData);
     
       dispatch(addInvoice(formData));
  
       navigate("/invoice"); // Use navigate function to redirect
     } catch(error) {
 console.log(error, 'invoice creation error');
     } finally {
       setLoading(false)
     }
     };
      
    useEffect(() => {
      async function fetchCustomerOptions() {
        try {
          const response = await axios.get(
            `${baseURL}/${btoa("customer/view")}/${btoa(businessId)}`
          );
          setCustomerOptions(response.data.customers_data);
          console.log("customer options" , response.data.customers_data);
        } catch (error) {
          console.error("Error fetching customer options:", error);
        }
      }
      fetchCustomerOptions();
    }, []);

    useEffect(()=>{
     
    }, [])
   
    
    const business_id = localStorage.getItem("business_id")
    const user_id = localStorage.getItem("user_id")
    const user_role = localStorage.getItem("user_role")

    useEffect(() => {
      const fetchCustomerTermsAndConditions = async () => {
        try {
          const response = await axios.get(`${baseURL}${btoa("invoice/fetchtermsconditions")}/${btoa(formField.bill_to)}/${btoa(businessId)}`);
          // console.log("tandc",response , );
          if(response.data.termsconditions_data?.length === 0){
            setTermsAndConditions("")
          }
          setTermsAndConditions(response.data.termsconditions_data[0].terms_conditions);
        } catch (error) {
          console.error('Error fetching customer terms and conditions in create invoice', error);
        }
      };
      fetchCustomerTermsAndConditions();
    }, [formField.bill_to]); 
  
    useEffect(() => {
      axios
        .get(`${baseURL}${btoa("business/profile")}/${btoa(business_id)}/${btoa(user_id)}/${btoa(user_role)}`)
        .then((response) => {
          // console.log(response);
          const businessData = response.data.business_data[0];
          // console.log(businessData);
          // setCustomerDetails(businessData);
          setFormField({
            ...formField,
            bill_from: businessData.business_name,
            // Other fields as needed
          });
          // setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching business data:", error);
          // setLoading(false);
        });
    }, []);


    const handleProductChange = (index, field, value) => {      
      const updatedData = [...InvoiceCustomerData];
   
      updatedData[index][field] = value; 
      const quantityInput = document.querySelector(`#quantity-input-${index}`);
    
      // Skip calculation if the quantity input is disabled
      if (quantityInput && quantityInput.disabled) {
        setInvoiceCustomerData(updatedData);
        return;
      }
    
      if (field === "description") {
        setInvoiceCustomerData(updatedData);
        return;
      }
      // Parse quantity and unitPrice as numbers and ensure they are valid
      const quantity = isNaN(parseFloat(updatedData[index]?.quantity)) ? 0 : parseFloat(updatedData[index]?.quantity);
      const unitPrice = isNaN(parseFloat(updatedData[index]?.unitPrice)) || parseFloat(updatedData[index]?.unitPrice) === "" ? 2.00 : parseFloat(updatedData[index]?.unitPrice);
    
      // const quantity = isNaN(parseFloat(updatedData[index]?.quantity)) || updatedData[index]?.quantity === "" ? 0 : parseFloat(updatedData[index]?.quantity);
      // const unitPrice = isNaN(parseFloat(updatedData[index]?.unitPrice)) || updatedData[index]?.unitPrice === "" ? 0 : parseFloat(updatedData[index]?.unitPrice);
    

      // Calculate the total (ensure valid numbers)
      const total = isNaN(parseFloat(quantity * unitPrice)) ? 0: parseFloat(quantity * unitPrice);
        
      updatedData[index].total = total;

      // Calculate the product sub-total (exclude invalid values)
      const productSubTotal = updatedData
        .filter((item) => item.product_name !== "Discount" && !item.isCustomRow)
        .reduce((acc, item) => acc + (isNaN(item.total) ? 0 : Number(item.total)), 0);
      
      setProductSubTotal(Number(productSubTotal));
      // Recalculate the newSubtotal with validation
      const newSubtotal = updatedData.reduce((acc, item) => acc + (isNaN(item.total) ? 0 : item.total), 0);
    
      updatedData.forEach((item) => {
        if (item.product_name === "Discount") {
          if (selectedDiscountType === "amount") {
            item.total = item.unitPrice; // Fixed amount
            setDiscount(Number(item.total));
          } else if (selectedDiscountType === "percentage") {
            const discountAmount = item.unitPrice; // Fixed amount entered
            const percentage = productSubTotal ? (discountAmount / productSubTotal) * 100 : 0;
            const formattedPercentage = percentage % 1 === 0 ? percentage : percentage.toFixed(2); // Apply toFixed(2) only if it's a decimal

            setDiscountedPercentage(formattedPercentage); // Set the percentage value
            item.total = discountAmount; // Update total for discount
            setDiscount(Number(item.total));
          } else if (selectedDiscountType === "gallons") {
            item.total = item.quantity * item.unitPrice; // Gallons
            setDiscount(Number(item.total));
          }
        }
      });
      
    
      if (updatedData[index]?.product_name === "Freight Charges") {
        let freightcharges = updatedData[index]?.unitPrice;
        setfreightCharges(freightcharges);
        updatedData[index].total = freightcharges;
      } 
    
      if (updatedData[index]?.product_name === "Freight Surcharge") {
        let freightsurcharge = updatedData[index]?.unitPrice;
        setfreightsurcharge(freightsurcharge);
        updatedData[index].total = freightsurcharge;
      } 
    
      if (updatedData[index]?.product_name === "Water Bill") {
        let waterbill = updatedData[index]?.unitPrice;
        setwaterBill(waterbill);
        updatedData[index].total = waterbill;
      }
    
      if (updatedData[index]?.product_name === "Rebates/Credits") {
        let Rebates = updatedData[index]?.unitPrice;
        setRebates(Rebates);
        updatedData[index].total = Rebates;
      }
    
      // Update the state
      setInvoiceCustomerData(updatedData);
    
      // Debugging log
      console.log("Updated Row:", {
        productName: updatedData[index]?.product_name || "N/A",
        quantity,
        unitPrice,
        total,
      });
    
      const newTotal = newSubtotal + newSubtotal * (tax / 100) + feesOrDiscounts;
      setProducts(updatedData);
    };
    
    const customerId = customerIdInvoice
    useEffect(() => {
     
        const encodedId = btoa(customerId)
        const businessId = localStorage.getItem("business_id")
    
        axios
          .get(`${baseURL}Y3VzdG9tZXIvZWRpdA==/${encodedId}/${btoa(businessId)}`)               // http://localhost/InvoiceFlow/customer/edit/{customerId}
          // .get(`${baseURL}/customer/edit/${customerId}`)      
          .then((response) => {
            // const fuelGradedata = response.data.customersFuelsData
            const fuelGradedata = response.data.customersFuelsData;
            const customerIndividualData = response.data.customers_data[0];
            setCustomerIndividualData(customerIndividualData)
            setLatestPurchaseInvoices(response.data.purchasedInvoiceNumbers)
            // setTaxNamesForInvoice(response.data.customersTaxes)
            console.log(fuelGradedata, '');
            setInvoiceCustomerData(fuelGradedata);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching customer data:", error);
            setLoading(false);
          });
      }, [customerId, brandInvoiceNumber]);
      useEffect(() => {
        const fetchLatestPurchaseInvoices = async () => {
          try {
            const response = await axios.get(`${baseURL}${btoa("purchasedinvoice/fetchPurchasedInvoiceNumber")}/${btoa(businessId)}`);
            console.log(response, 'response new ......')
            // setLatestPurchaseInvoices(response.data.purchasedInvoiceNumbers)
          } catch (error) {
            console.error("Error fetching latest purchase invoices:", error);
          } 
        };
        fetchLatestPurchaseInvoices();
      }, []);
      const [TaxDataForInvoice , setTaxDataForInvoice] = useState([])
      
      const [DiscountedPercentage , setDiscountedPercentage] = useState('')
     useEffect(()=> {
      setDiscountedPercentage('')
     }, [] )

      useEffect(() => {
        const fetchLatestPurchaseInvoicedata = async () => {
          try {
            const response = await axios.get(`${baseURL}${btoa("purchasedinvoice/fetchpurchasedinvoice")}/${btoa(brandInvoiceNumber)}/${btoa(businessId)}/${btoa(customerId)}`);
            console.log(response, 'response 3 boxes ......')
            // setLatestPurchaseInvoices(response.data)
            setInvoiceFuelData(response.data.purchasedInvoiceData[0].fuelgrades)
            setTaxDataForInvoice(response.data.customersTaxesAndValues)
          } catch (error) {
            console.error("Error fetching latest purchase invoices:", error);
          } 
        };
        fetchLatestPurchaseInvoicedata();
      }, [brandInvoiceNumber]);

    //   for business logo fetching

    const [businessData , setBusinessData] = useState([])   
    console.log('TaxNamesForInvoice', TaxDataForInvoice)
    const taxTotal1 = TaxDataForInvoice.reduce((acc, item) => {
      // Access the tax value using the dynamic key from po_customertax_name
      const taxValue = parseFloat(item[item.po_customertax_name]* totalQuantity) || 0; 
      return acc + taxValue; // Accumulate the tax value
    }, 0);

    useEffect(() => {
      window.scrollTo(0, 0);
      axios
      .get(`${baseURL}${btoa("business/profile")}/${btoa(business_id)}/${btoa(user_id)}/${btoa(user_role)}`)
        .then((response) => {
          setBusinessData(response.data.business_data[0].image_file);
        })
        .catch((error) => {
          console.error("Error fetching business data:", error);
         ;
        });
    }, []); 
   const [MinLengthError,setMinLengthError] = useState('')
   const handleDelete = (productId, e) => {
    setSelectedProduct('');
  
    e.preventDefault();
  
    // Ensure that we don't delete the last item
    if (InvoiceCustomerData.length === 1) {
      setMinLengthError("You cannot delete the last product.");
      return; // Exit the function to prevent deletion
    }
  
    // Find the product to be deleted
    const deletedProduct = InvoiceCustomerData.find(
      (row) => row.product_name === productId
    );
  
    // If the product to delete exists, proceed
    if (deletedProduct) {
      const updatedData = InvoiceCustomerData.filter(
        (row) => row.product_name !== productId
      );
  
      // Set the updated data back to the state
      setInvoiceCustomerData(updatedData);
  
      // Recalculate product subTotal after deletion
      
      const productSubTotal = updatedData
        .filter((row) => row.product_name !== "Discount") // Exclude "Discount" from the subtotal
        .reduce((acc, row) => acc + (isNaN(row.total) ? 0 : Number(row.total)), 0);
      setProductSubTotal(productSubTotal);
  
      // Update remaining products for the dropdown (only if the deleted product was custom row)
      if (deletedProduct?.isCustomRow) {
        setRemainingProducts((prev) => {
          let updatedProducts = [...prev];
  
          // Handle "Miscellaneous" logic
          if (
            deletedProduct.product_name.startsWith("Miscellaneous") &&
            !prev.some((product) => product === "Miscellaneous")
          ) {
            // Add "Miscellaneous" back to the dropdown only if no "Miscellaneous" is present
            updatedProducts.push("Miscellaneous");
          } else if (!deletedProduct.product_name.startsWith("Miscellaneous")) {
            // Add non-Miscellaneous custom rows back to the dropdown
            updatedProducts.push(deletedProduct.product_name);
          }
  
          // Sort the dropdown based on the availableProducts order
          return updatedProducts.sort(
            (a, b) => availableProducts.indexOf(a) - availableProducts.indexOf(b)
          );
        });
      }
  
      // Check if there are any rows with "isCustomRow" and update visibility
      const hasCustomRows = updatedData.some((row) => row.isCustomRow);
      setShowDescription(hasCustomRows);
  
      // If the deleted product was a special one, reset the corresponding value
      if (deletedProduct?.product_name === "Discount") {
        setDiscount(0);
      }
      if (deletedProduct?.product_name === "Freight Charges") {
        setfreightCharges(0);
      }
      if (deletedProduct?.product_name === "Freight Surcharge") {
        setfreightsurcharge(0);
      }
      if (deletedProduct?.product_name === "Water Bill") {
        setwaterBill(0);
      }
      if (deletedProduct?.product_name === "Rebates/Credits") {
        setRebates(0);
      }
    }
  };
  
  
      
      const formatNumberWithCommas = (value) => {
        if (!value || isNaN(value)) return ""; // Handle invalid or empty values
        return parseFloat(value).toLocaleString("en-US");
      };
      
      const disablefutureDates = (current) => {
        return current && current > dayjs().startOf('day');
      };
  
      const availableProducts = 
      [
        "Freight Charges",
        "Freight Surcharge",
        "Water Bill",
        "Discount",
        "Rebates/Credits",
        "Miscellaneous",
      ]    

      const [remainingProducts, setRemainingProducts] = useState(availableProducts); 
      useEffect(()=> {
        setRemainingProducts(availableProducts)
      },[])
       
  return (
    <>
    {loading && <FullScreenLoader/>}
    <nav aria-label="breadcrumb">
    <ol className="flex space-x-2 text-lg font-bold text-gray-700 ml-[190px] mt-[20px] ]" style={{visibility:'hidden'}}>
      <li>
        {/* <a  href="/operations" className="text-blue-600 hover:text-blue-800"> */}
          Sales
        {/* </a> */}
      </li>
      <li>
        <span className="text-gray-400">{'>>'}</span>
      </li>
      <li>
        {/* <a href="/purchases" className="text-blue-600 hover:text-blue-800"> */}
          Create Invoice
        {/* </a> */}
      </li>
    </ol>
  </nav>
    <form>
    <div className="flex justify-center items-start min-h-screen " style={{fontFamily:'poppins'}}>
      <div className="w-3/4 bg-white rounded-lg space-y-0  mt-2 mb-[60px]">
        {/* First Row */}
          {/* Left Section */}
          <div className="grid grid-cols-1 gap-6 mb-4 mt-4">
          {/* Left Section */}
          <div
  className="space-y-4 p-4 shadow-md rounded-md"
  style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)" }}
>
  {/* Header Section */}
  <div className="flex justify-between items-center border-b pb-4">
    {/* Customer Name Section */}
    <div className="flex flex-col items-start w-1/3 gap-2">
    <div>
      <span className="text-md font-semibold ml-2">Customer Name:</span>
      <span className="text-red-500 ml-1">*</span>
      </div>
      <select
        className="ml-2 w-55 p-2 border rounded-md shadow-sm h-[38px]"
        id="bill_to"
        name="bill_to"
        required
        value={formField.bill_to}
        onChange={handleChange}
      >
        <option value="">--Select Customer--</option>
        {customerOptions.map((customer) => (
          <option key={customer.customer_id} value={customer.customer_id}>
            {customer.business_name}
          </option>
        ))}
      </select>
    </div>

    {/* Account and Invoice Info */}
    <div className="flex flex-col items-center justify-start w-1/3 text-lg gap-2">
  

      <span>
        <strong className=''>INVOICE</strong> 
      </span>
      <span>
      <strong>{latestInvoiceNumber}</strong>
      </span>
    </div>

    {/* PO Section */}
    <div className="flex  items-end justify-end w-1/3">
  {/* Logo Section */}
  <div className="">
    {customerIndividualData.brand_logo ? (
        <img
        src={`${baseURL}/img/${customerIndividualData.brand_logo}`}
        alt="Business Logo"
        className="w-15 h-10 rounded-full" // Adjust width and height as needed
      />
    ) : null}
  </div>

  {/* PO Section */}
  <div className="flex flex-col items-end w-[190px]">
    {/* PO Number Label */}
    <div className="flex items-center justify-end">
      <span className="text-md font-semibold">PO #:</span>
      <span className="text-red-500 ml-1">*</span>
    </div>
    {/* Dropdown */}
    <select
      className="mt-2 w-[180px] p-2 border rounded-md shadow-sm h-[38px]"
      onChange={(e) => setBrandInvoiceNumber(e.target.value)}
      value={brandInvoiceNumber}
    >
      <option>--Select PO--</option>
      {latestPurchaseInvoices?.map((po) => (
        <option key={po.brand_invoice_number} value={po.brand_invoice_number}>
          {po.brand_invoice_number}
        </option>
      ))}
    </select>
  </div>
</div>

  </div>

</div>
        </div>
        
          {/* Right Section */}
         
          <div
  className="flex justify-between p-4 shadow-md rounded-md px-4"
  style={{ backgroundColor: "#00b8ec", color: "#ffffff" }}
>
  {/* Left Section: Customer Information */}
  
  <div className="w-1/3 space-y-2">   

      <div key={ formField.bill_to !== "" && customerIndividualData.contact_no}> {/* Ensure each child has a unique key */}
        <p className="text-lg font-bold">{ formField.bill_to !== "" && customerIndividualData.business_name}</p>
        <p>{ formField.bill_to !== "" && customerIndividualData.business_address}</p>
        <p>
          { formField.bill_to !== "" && customerIndividualData.billing_city} { formField.bill_to !== "" && customerIndividualData.billing_city && ','} {formField.bill_to !== "" &&customerIndividualData.billing_state} {formField.bill_to !== "" &&customerIndividualData.billing_zip_code}
        </p>
        <p>{formField.bill_to !== "" &&customerIndividualData.contact_email}</p>
        <p>{formField.bill_to !== "" &&customerIndividualData.contact_no}</p>
        <span>
  {/* <strong>{customerIndividualData.business_account && <>Account #:</>}</strong> */}

      {/* <span key={customerIndividualData.contact_id}> <>{customerIndividualData.business_account} </></span> */}
  
</span>
      </div>
</div>

<div className="flex flex-col items-center justify-start w-1/3 text-lg gap-2">
  

  <span>
    <strong className=''>{formField.bill_to !== "" && "ACCOUNT"} </strong> 
  </span>
  <span>
  <strong>{formField.bill_to !== "" &&customerIndividualData.business_account}</strong>
  </span>
</div>


  {/* Right Section: Date Fields */}
  <div className="w-1/3 space-y-4">
    {/* Invoice Date */}
    <div className="flex items-center justify-end">
      <span className="w-3/5 text-right font-semibold mr-2">Invoice Date:</span>
      <Space direction="vertical" className="w-[160px]">
        <DatePicker
          id="invoiceDate"
          disabledDate={disablefutureDates}
          format="MM-DD-YYYY"
          className="w-50 h-[35px] rounded-md border border-gray-300"
          value={invoiceDate}
          onChange={handleInvoiceDateChange}
          placeholder="MM-DD-YYYY"
        />
      </Space>
    </div>

    {/* Delivery Date */}
    <div className="flex items-center justify-end">
      <span className="w-3/5 text-right font-semibold mr-2">Delivery Date:</span>
      <Space direction="vertical" className="w-[160px]">
        <DatePicker
          id="deliveryDate"
          format="MM-DD-YYYY"
          className="w-50 h-[35px] rounded-md border border-gray-300"
          value={createdDate}
          onChange={handleDeliveryDateChange}
          placeholder="MM-DD-YYYY"
        />
      </Space>
    </div>

    {/* Payment Due Date */}
    <div className="flex items-center justify-end">
      <span className="w-3/5 text-right font-semibold mr-2">
        Payment Due Date:
      </span>
      <Space direction="vertical" className="w-[160px]">
        <DatePicker
          id="dueDate"
          format="MM-DD-YYYY"
          className="w-50 h-[35px] rounded-md border border-gray-300"
          value={dueDate}
          onChange={handleDueDateChange}
          placeholder="MM-DD-YYYY"
        />
      </Space>
    </div>
  </div>
</div>

        {/* Second Row */}
        <div style={{   boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",}}>
         

        <div>
        {/* <table className="w-full table-auto border-collapse border mt-0" style={{ fontFamily: 'poppins' }}>
  <thead>
    <tr className="bg-white border-b-[3px]" >
      <th className="px-4 py-2 text-center" style={{ width: "30px" }}>#</th>
      <th className="px-4 py-2 text-left" style={{ width: "180px" }}>Product/Service</th>

        <th className="px-4 py-2 text-left" >Available</th>

      {showDescription && (
        <th className="px-4 py-2 text-center">Description</th>
      )}
      
      {!showDescription && (
        <th className="px-4 py-2 text-center" style={{width: "120px" }}></th>
      )}

      <th className="px-4 py-2 text-center" style={{ width: "120px" }}>Quantity</th>
      <th className="px-4 py-2 text-center" style={{ width: "120px" }}>Unit Price</th>
      <th className="px-4 py-2 text-center" style={{ width: "150px" }}>
        Line Total
      </th>
      <th className="px-4 py-2 text-left" style={{ width: "60px" }}>Action</th>
    </tr>
  </thead>
  {formField.bill_to !== "" &&
  <tbody>
  {items.map((row, index) => {
    const filteredItems = invoiceFuelData.filter(
      (item) => row.product_name === item.fuelgrade_name
    );

    const grossQuantity =
      filteredItems.length > 0 ? filteredItems[0].gross_quantity : 0;

    return (
      <tr key={row.product_id} className="border-t">
        <td className="px-4 py-2">{index + 1}</td>
        <td className="text-left px-4 py-2">
  {row.product_name} 
  {discount && DiscountedPercentage && DiscountedPercentage >0 && row.product_name.toLowerCase() === "discount" 
  && selectedDiscountType === "percentage" && selectedDiscountType !== "" ?
  <span className='bg-red-500 text-white ml-3 px-4 py-1 rounded-sm'>
    {discount && DiscountedPercentage && DiscountedPercentage >0 && row.product_name.toLowerCase() === "discount" && selectedDiscountType === "percentage" 
      ? `(${DiscountedPercentage})%` 
      : ""}
  </span>
  : ''
    }

</td>


        {!row.isCustomRow ? (
  <td>
    {filteredItems.length > 0 ? (
      filteredItems.map((filteredItem, idx) => (
        <div key={idx} className="flex justify-start space-x-2 px-4">
          <label
            htmlFor={`fuel-${index}`}
            className="text-left font-normal flex justify-start"
          >
            {formatNumberWithCommas(filteredItem.gross_quantity || 0)} gl
          </label>
        </div>
      ))
    ) : (
      <div className="flex justify-start space-x-2 px-4">
        <label className="text-left font-normal flex justify-start">
          0 gl
        </label>
      </div>
    )}
  </td>
) : (
  <td></td>
)}

<td className="px-4 py-2" 
colSpan={row.isFreight || row.product_name.toLowerCase() === "water bill" || 
   row.product_name.toLowerCase() === "rebates/credits" 
|| (row.isDiscount && selectedDiscountType !== "gallons") ? 2 : 1}
>
{row.isFreight && row.isCustomRow ? (
                    // Freight Charges/surcharge: Show description only
                    <input
                      type="text"
                      placeholder="Enter description"
                      value={row.description || ""}
                      onChange={(e) => handleProductChange(index, "description", e.target.value)}
                      className="w-full p-2 border rounded h-[30px]"
                    
                    />
                  ) : row.isDiscount && row.isCustomRow? (
                    // Discount: Show dropdown for Amount, %, Gallons
                    <select
                      className="w-full p-1 border rounded h-[30px]"
                      onChange={(e) => handleDiscountTypeChange(e, index)}
                      value={row.description}
                    >
                      <option value="">--Select Discount Type--</option>
                      <option value="amount">Amount</option>
                      <option value="percentage">%</option>
                      <option value="gallons">Gallons</option>
                    </select>
                  ) : (
                    // Normal product: Show input for description
                     row.isCustomRow &&
                    <input
                      type="text"
                      placeholder="Enter description"
                      value={row.description || ""}
                      onChange={(e) => handleProductChange(index, "description", e.target.value)}
                      className="w-full p-2 border rounded h-[30px]"
                    />
                  
                  )}
</td>


        {!row.isFreight &&  
          (!row.isDiscount || selectedDiscountType === "gallons") &&
          row.product_name.toLowerCase() !== "water bill" &&
          row.product_name.toLowerCase() !== "rebates/credits" && (
            <td className="px-4 py-2 relative">
              <input
                type="number"
                disabled={(grossQuantity <=300 || grossQuantity <= 0) && !row.isCustomRow}
                value={row.quantity || ""}
                id={`quantity-input-${index}`}
                onChange={(e) => {
                  const newQuantity = Number(e.target.value);

                  if (newQuantity > grossQuantity &&  selectedDiscountType !== "gallons") {
                    setQuantityErrors((prev) => ({
                      ...prev,
                      [row.product_id]: `Enter less than ${formatNumberWithCommas(
                        grossQuantity
                      )}`,
                    }));
                    return;
                  }

                  setQuantityErrors((prev) => {
                    const updatedErrors = { ...prev };
                    delete updatedErrors[row.product_id];
                    return updatedErrors;
                  });
                  handleProductChange(index, "quantity", newQuantity);

                }}
             
                onBlur={() => {
                  setQuantityErrors((prev) => {
                    if (!row.product_id) {
                      console.warn("Missing product_id for row:", row);
                      return prev; 
                    }
                    const updatedErrors = { ...prev };
                    if (updatedErrors[row.product_id]) {
                      delete updatedErrors[row.product_id];
                    }
                    return updatedErrors;
                  });
                }}
                
                placeholder="0"
                className={`w-full p-2 border rounded h-[30px] ${
                  quantityErrors[row.product_id] ? "border-red-500" : ""
                } ${((grossQuantity <=300 || grossQuantity <= 0) && !row.isCustomRow) ? 'bg-gray-200' : 'bg-white'}`}
              />
              {quantityErrors[row.product_id] && (
                <div className="absolute left-0 bottom-[110%] px-2 py-1 bg-red-500 text-white text-xs rounded-md arrow-tooltip">
                  {quantityErrors[row.product_id]}
                  <div className="absolute left-1/2 bottom-[-5px] transform -translate-x-1/2 w-0 h-0 border-x-[5px] border-x-transparent border-t-[5px] border-t-red-500"></div>
                </div>
              )}
            </td>
          )}

        <td className="px-4 py-2">
  {row.product_name.toLowerCase() === "freight charges" ||
  row.product_name.toLowerCase() === "freight surcharge" ? (
    <div className="w-full text-center flex items-center justify-center h-[30px]">
      <input
        type="number"
        value={row.unitPrice || ""}
        min={0}
        onChange={(e) =>
          handleProductChange(index, "unitPrice", Number(e.target.value), row.product_name)
        }
        placeholder="0.00"
        className="w-full text-center p-2 border rounded h-[30px]"
      />
    </div>
  ) : row.product_name.toLowerCase() === "water bill" ||
    row.product_name.toLowerCase() === "rebates/credits" ||
    row.isDiscount ? (
    selectedDiscountType === "" && row.isDiscount ? (
      <div className="w-full p-2 text-center flex items-center justify-center h-[30px]">
        {"0.00"} 
      </div>
    ) : (
      <input
        type="number"
        value={row.unitPrice || ""}
        min={0}
        onChange={(e) =>
          handleProductChange(index, "unitPrice", Number(e.target.value), row.product_name)
        }
        placeholder="0.00"
        className="w-full p-2 text-center border rounded h-[30px]"
      />
    )
  ) : (
    <div className="w-full p-2 text-center flex items-center justify-center h-[30px]">
      {row.unitPrice || "2.00"} 
    </div>
  )}
</td>

        <td className="px-4 py-2">
  {row.product_name.toLowerCase() === "freight charges" ? (
    <div className="w-full p-2 text-center flex items-center justify-center h-[30px]">
      $ {Number(freightCharges).toFixed(2)}
    </div>
  ) : row.product_name.toLowerCase() === "freight surcharge" ? (
    <div className="w-full p-2 text-center flex items-center justify-center h-[30px]">
      $ {Number(freightsurcharge).toFixed(2)}
    </div>
  ) : row.product_name.toLowerCase() === "discount" && selectedDiscountType === "" ? (
    <div className="w-full p-2 text-center flex items-center justify-center h-[30px]">
      $ {Number(0).toFixed(2)}
    </div>
  ) : (
    <div className="w-full p-2 text-center flex items-center justify-center h-[30px]">
      $ {Number(row.total || 0).toFixed(2)}
    </div>
  )}
</td>
        <td className="px-4 py-2">
          <button
            onClick={(e) => handleDelete(row.product_name, e)}
            className="text-gray-500 hover:text-red-600"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z" />
            </svg>
          </button>
        </td>
      </tr>
    );
  })}
</tbody>
}

</table> */}

       {brandInvoiceNumber && formField.bill_to !== "" &&
      <div className='flex justify-start items-center pl-4 gap-4 '>
      {remainingProducts.length > 0 && formField.bill_to ? (
  <select
    className="px-4 py-1 text-left w-[210px] border rounded-md  my-2 h-[35px]"
    value={selectedProduct}
    onChange={(e) => {
      const selected = e.target.value;
      setSelectedProduct(selected); // Update state
      addRow(selected); // Add row and filter dropdown
    }}
  >
    <option value="">--Add Product/Service--</option>
    {remainingProducts.map((product, index) => (
      <option key={index} value={product}>
        {product}
      </option>
    ))}
  </select>
) : (
  <p className="text-green-500 ml-4"></p>
)}
<div className='text-red-500'>{DiscountRebateError} </div>

<div className='text-red-500'>{MinLengthError} </div>

</div>
 }

{subTotal &&  formField.bill_to !== ""  ? (
<div className="flex flex-col lg:flex-row p-6 pt-4 gap-6">
  
<div className="lg:w-1/2" style={{display:'hidden'}}>
      
      </div>
      {/* Right Half: Subtotal and Taxes */}
      <div className="lg:w-1/2  rounded-md p-4 ">
        {/* Subtotal Section */}
        <div className="flex justify-between items-center mb-2">
          <div className="flex items-center gap-2">
            <button onClick={toggleSubtotal} className="text-blue-500">
              {isSubtotalExpanded ?  '▼' : '►'}
            </button>
            <span className="text-gray-800 font-medium">Subtotal</span>
          </div>
          <span className="text-gray-800 font-medium">
  $ {(
    parseFloat(productSubTotal) + 
    (parseFloat(freightCharges) ) +
    (parseFloat(freightsurcharge) )+
    (parseFloat(waterBill) ) - 
    (parseFloat(discount) ) - 
    (parseFloat(Rebates))
  ).toFixed(2)} {/* Adjusting decimal precision to 2 places */}
</span>


        </div>

        {isSubtotalExpanded && (
          <div className="ml-6 mb-4">
            <div className="flex justify-between">
              <span>Fuel Total:</span>
              <span>$ {productSubTotal ? Number(productSubTotal).toFixed(2) : (0.00).toFixed(2)}</span>
            </div>
            {
              freightCharges ?
              <div className="flex justify-between">
              <span>Freight Charges:</span>
              <span>+$ {freightCharges? freightCharges : (0.00).toFixed(2)}</span>
            </div>
            :''
            }
           {freightsurcharge ?
            <div className="flex justify-between">
              <span>Freight Surcharges:</span>
              <span>+$ {freightsurcharge ? freightsurcharge : (0.00).toFixed(2)}</span>
            </div> : '' }
            {waterBill ? 
            <div className="flex justify-between">
              <span>Water Bill:</span>
              <span>+$ {waterBill ? waterBill : (0.00).toFixed(2)}</span>
            </div> : '' }
            {discount ? 
            <div className="flex justify-between">
<span>Discounts:
{discount && DiscountedPercentage && DiscountedPercentage >0
  && selectedDiscountType === "percentage" && selectedDiscountType !== "" ?
  <span className='text-red-500 font-semibold ml-1'>
    {discount && DiscountedPercentage && DiscountedPercentage >0  && selectedDiscountType === "percentage" 
      ? `(${DiscountedPercentage})%` 
      : ""}
  </span>
  : ''
    }



  </span>
<span className='text-red-500 '>-$ {discount ? discount : (0.00).toFixed(2)}</span>
            </div> : '' }
            {Rebates ? 
            <div className="flex justify-between">
              <span>Rebates/Credits:</span>
              <span className='text-red-500'>-$ {Rebates ? Rebates : (0.00).toFixed(2)}</span>
            </div> : ''}
          </div>
        )}

        {/* Taxes Section */}
        <div className="border-b-2 flex justify-between items-center">
          <div className="flex items-center gap-2">
            <button onClick={toggleTaxes} className="text-blue-500">
              {isTaxesExpanded ? '▼' : '►'}
            </button>
            <span className="text-gray-800 font-medium">Taxes</span>
          </div>
          <span className="text-gray-800 font-medium">$ {Number(taxTotal1).toFixed(2)}</span>
        </div>

        {isTaxesExpanded &&  (
          <div className="ml-6 mt-2">
      
{TaxesView.map((item) => (
  <div className="flex justify-between" key={item.po_customertax_name}>
    <span>{item.customer_taxes}</span>
    <span>$ {Number(item[item.po_customertax_name] * totalQuantity).toFixed(2)}</span>
  </div>
))}
          </div>
        )}
         <div className="mt-2 flex justify-between items-center">
          <div className="flex items-center gap-2">
            <span className="text-gray-800 font-medium ml-[30px]">Invoice Amount</span>
          </div>
          <span className="text-gray-800 font-medium">${(
    productSubTotal + 
    (parseFloat(freightCharges) ) +
    (parseFloat(freightsurcharge) )+
    (parseFloat(waterBill) ) - 
    (parseFloat(discount) ) - 
    (parseFloat(Rebates)) 
    + (parseFloat(taxTotal1))
  ).toFixed(2)}</span>
        </div>
      </div>
    </div>)
    :''
 }      
          <div className=" rounded-lg mb-4">
  </div>
        </div>
        </div>
        <div style={{   boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",}} className='mt-2'>

{/* Left Half: Terms and Conditions */}

<div className="lg:w-full rounded-md p-4 ">
<h2 className="text-lg font-semibold mb-2">Terms & Conditions</h2>
<textarea
className="w-full h-20 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
placeholder="Enter terms and conditions here..."
value={termsAndConditions}
onChange={(e) => setTermsAndConditions(e.target.value)}
maxLength={600}

></textarea>
</div>
</div>
{ isFormComplete() && (
              <div className="flex justify-end  ">
                <button
                 onClick={handleSubmit}
                 style={{fontFamily:'poppins'}}
                  type="submit"
                  className=" w-[140px] h-[35px] mt-4 mr-2hover:bg-yellow-100 bg-yellow-100 border-1 border-yellow-300 border-2 text-black px-6 py-1.5 text-normal font-bold  hover:shadow-lg hover:shadow-[#0044AB]/50 text-center  focus:ring-4 focus:ring-primary-200 "
                  // disabled={!isFormValid}
                >
                  SUBMIT
                </button>
              </div>
            )}    
        </div>        
      </div>
    </form>

    </>
  )
}

export default EditInvoice



