import React, { useEffect , useRef} from 'react'
import { useNavigate, useParams   } from 'react-router-dom';
import { MdOutlineBusinessCenter } from "react-icons/md";
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import config  from "../../config/config"
import EditOwner from './EditOwner'
import { useDispatch } from "react-redux";
import { addToast } from '../pages/ToastBox/ToastBoxMessageSlice';

const EditBusiness = () => {
  const dispatch = useDispatch()

    const navigate = useNavigate()
    const [businessNameExist , setBusinessNameExist] = useState("")

    const [busNameError, setbusNameError] = useState("");
    const [busAddNameError, setBusAddNameError] = useState("");
    const [busCityNameError, setbusCityNameError] = useState("");
    const [zipCodeError, setZipCodeError] = useState("");

    const [ownerDetails, setOwnerDetails] = useState([]);
    const [selectedOwner, setSelectedOwner] = useState('');
    const [invalidBusinessZipCode , setInvalidBusinessZipCode] = useState("")
    const [businessDetails , setBusinessDetails] = useState([])
    const [contactDetails , setContactDetails] = useState([])
    const [fileName, setFileName] = useState(null);
    const [file, setFile] = useState(null); 
    const fileInputRef = useRef(null);

    const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
    const businessId = localStorage.getItem("business_id")
    const user_id = localStorage.getItem("user_id")
    const user_role = localStorage.getItem("user_role")
    const {id} = useParams()
    // alert(id)

    const [formData, setFormData] = useState({

        ownerId: "",
        businessType : "",
        ownerName: "",
        businessName: "",
        businessAddress: "",
        businessCity: "",
        businessState: "",
        businessZipCode: "",
        businessLogo : "",
      });

      const BusinessNameExistTooltip = ({ content, show, marginLeft }) => {
        return (
          <div
            style={{
              display: show ? "block" : "none",
              position: "absolute",
              backgroundColor: "#ffffff",
              color: "#fff",
              padding: "5px",
              borderRadius: "4px",
              border: "1px solid red",
              marginTop: "-35px",
              marginLeft: `${marginLeft}px`, // Use the provided marginLeft prop
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div
              style={{
                position: "absolute",
                bottom: "-7px",
                left: "50%",
                marginLeft: "-10px",
                width: "0",
                height: "0",
                borderLeft: "7px solid transparent",
                borderRight: "7px solid transparent",
                borderTop: "7px solid #f98080",
              }}
            ></div>
            <p className="text-xs text-red-500">{content}</p>
          </div>
        );
      };

      const CustomTooltip1 = ({ content, show, marginLeft }) => {
        return (
          <div
            style={{
              display: show ? "block" : "none",
              position: "absolute",
              backgroundColor: "#ffffff",
              color: "#fff",
              padding: "5px",
              borderRadius: "4px",
              border: "1px solid red",
              marginTop: "-35px",
              marginLeft: `${marginLeft}px`, // Use the provided marginLeft prop
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div
              style={{
                position: "absolute",
                bottom: "-7px",
                left: "50%",
                marginLeft: "-10px",
                width: "0",
                height: "0",
                borderLeft: "7px solid transparent",
                borderRight: "7px solid transparent",
                borderTop: "7px solid #f98080",
              }}
            ></div>
            <p className="text-xs text-red-500">{content}</p>
          </div>
        );
      };

      const BusinessAddressTooltip = ({ content, show, marginLeft }) => {
        return (
          <div
            style={{
              display: show ? "block" : "none",
              position: "absolute",
              backgroundColor: "#ffffff",
              color: "#fff",
              padding: "5px",
              borderRadius: "4px",
              border: "1px solid red",
              marginTop: "-35px",
              marginLeft: `${marginLeft}px`,
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div
              style={{
                position: "absolute",
                bottom: "-7px",
                left: "50%",
                marginLeft: "-10px",
                width: "0",
                height: "0",
                borderLeft: "7px solid transparent",
                borderRight: "7px solid transparent",
                borderTop: "7px solid #f98080",
              }}
            ></div>
            <p className="text-xs text-red-500">{content}</p>
          </div>
        );
      };

      const handleBusinessTypeChange = (e) => {
        setFormData({
          ...formData,
          businessType : e.target.value
        })
      }

      const handleTextInputClick = () => {
        if (fileInputRef.current) {
          fileInputRef.current.click();
        }
      };

      const capitalizeWords = (input) => {
        return input.charAt(0).toUpperCase() + input.slice(1);
              // .toLowerCase()
          // .split(" ")
          // .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          // .join(" ");
      };

      const handlebusNameChange = (e) => {
        setBusinessNameExist("")
        const { name, value } = e.target;
        let inputValue = value.replace(/[^a-zA-Z0-9\s]/g, ""); 
        setFormData({
          ...formData,
          [name]: capitalizeWords(inputValue),
        });
        console.log(formData);
    
        // Set error message if item name is not empty and less than 3 characters
        if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
          setbusNameError("Name must be at least 3 letters");
        } else {
          setbusNameError("");
        }
      };

      const handlebusCityChange = (e) => {
        const { name, value } = e.target;
        let inputValue = value.replace(/[^a-zA-Z0-9\s]/g, ""); 
    
        setFormData({
          ...formData,
          [name]: capitalizeWords(inputValue),
        });
    
        if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
          setbusCityNameError(" City Name must be at least 3 letters");
        } else {
          setbusCityNameError("");
        }
      };

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });    
      };
      
      const handleFaxChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });    
      };
      const handleBusinessPhoneChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });    
      };

      const handleInputZipChange = (e) => {
        const { name, value } = e.target;
        let inputValue = value.replace(/\D/g, ""); 
        inputValue = inputValue.slice(0, 5); 

        const containsNonZero = /[1-9]/.test(e.target.value);
        if(containsNonZero){
          setInvalidBusinessZipCode("valid")
        }else{
          setInvalidBusinessZipCode("Invalid number")
        }
    
        if(e.target.value === ""){
          setInvalidBusinessZipCode("")
        }
    
        setFormData({
          ...formData,
          [name]: inputValue,
        });
        if (inputValue.length > 0 && inputValue.length < 5) {
          setZipCodeError("Zip code must be at most 5 digits");
        } else {
          setZipCodeError("");
        }
      };

      const handleBusinessLogoUpload = (event) => {
        const selectedFile = event.target.files[0];
        // console.log("selected logo" , selectedFile);
        // setFormData({
        //   ...formData,
        //   businessLogo: selectedFile,
        // });
        if (selectedFile) {
          setFileName(selectedFile.name);
          setFile(selectedFile);
        } else {
          setFileName('');
          setFile(null);
        }
      };

      useEffect(() => {
        const fetchOwnerDetails = async () => {
          try {
            const response = await axios.get(`${baseURL}/${btoa("owner/view")}`);
            
            // Assuming the response contains the owner details in the 'owners_list' property
            setOwnerDetails(response.data.owners_list);
            console.log("Owner details:", response.data.owners_list);
          } catch (error) {
            console.error('Error fetching owner details', error);
          }
        };
    
        fetchOwnerDetails();
      }, []); // Emp


      useEffect(() => {
        const fetchBusinessDetails = async () => {
          try {
            const response = await axios.get(`${baseURL}${btoa("business/fetch")}/${btoa(id)}`);
            console.log(response)
            const businessData = response.data.business_data[0]; // Assuming there's only one business object
                 setContactDetails(response.data.owner_data);
                setFormData({
                    ownerId: businessData.ownerId || "",
                    businessType: businessData.business_type || "",
                    ownerName: businessData.ownerName || "",
                    businessName: businessData.business_name || "",
                    businessAddress: businessData.business_address || "",
                    businessCity: businessData.business_city || "",
                    businessState: businessData.business_state || "",
                    businessZipCode: businessData.business_zip_code || "",
                    businessLogo: businessData.image_file || "",
                    businessPhoneNumber:businessData.business_contact_no,
                    faxNumber:businessData.fax_number
                });
            console.log("business details:", response.data.business_data);
          } catch (error) {
            console.error('Error fetching business details', error);
          }
        };
    
        fetchBusinessDetails();
      }, []); // Emp

      // useEffect(() => {
      //   const fetchBusinessDetails = async () => {
      //     try {
      //       const response = await axios.get(`${baseURL}/${btoa("business/view")}`);
      //       console.log("business details", response);
      //       const businessData = response.data.business_data[0];
      //       setBusinessDetails(businessData);
      //       setContactDetails(businessData.contacts);
      //                 setFormData({
      //                     ownerId: businessData.ownerId || "",
      //                     businessType: businessData.business_type || "",
      //                     ownerName: businessData.ownerName || "",
      //                     businessName: businessData.business_name || "",
      //                     businessAddress: businessData.business_address || "",
      //                     businessCity: businessData.business_city || "",
      //                     businessState: businessData.business_state || "",
      //                     businessZipCode: businessData.business_zip_code || "",
      //                     businessLogo: businessData.image_file || "",
      //                 });
      //             console.log("business details:", response.data.business_data);
           
      //     } catch (error) {
      //       console.error('Error fetching business details', error);
      //     }
      //   };
    
      //   fetchBusinessDetails();
      // }, []);

      
      const handleBusinessDetails = async ()=>{
        // console.log("business details" ,formData);
        // console.log("logo" ,file);
        const businessData = new FormData();
        businessData.append("adminId" , localStorage.getItem("id") )
        businessData.append("edit_businessId" , id)
        businessData.append("edit_businessType" , formData.businessType)
        businessData.append("edit_businessName" , formData.businessName)
        businessData.append("edit_businessAddress" , formData.businessAddress )
        businessData.append("edit_businessCity" , formData.businessCity )
        businessData.append("edit_businessState" , formData.businessState )
        businessData.append("edit_businessZipCode" , formData.businessZipCode )
        businessData.append("uploadLogo" , file )

        // for (let [key, value] of businessData.entries()) {
        //   console.log(`${key}: ${value}`);
        // }
        // console.log("logo" ,file);

        try{
            const response = await axios.post(`${baseURL}${btoa("business/update")}` , businessData , {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            console.log("response business update" , response);
            // toast.success((`${formData.businessName} business has been Updated`),{
            //   autoClose : 2000
            // })
  dispatch(addToast({ type: 'success', message: `${formData.businessName} business has been Updated` }));       
            navigate("/adminbusinessdashboard")
        }catch(error){
          console.log("error creating business details" , error);
          if(error.response.data.businessStatus === "Business name already exist"){
            setBusinessNameExist("Business name already exist")
          }
        }
      }

  return (
    <div className="pt-20 pb-20">
          <h1 className="mx-8 font-bold mb-24 text-gray-400 text-xl mt-[-34px]">
             Edit Business
          </h1>

          <div className="w-auto  bg-white rounded-box  mx-8 rounded-xl">
            <div className="flex items-center">
              <MdOutlineBusinessCenter className="text-gray-700 w-6 h-6 mr-2 mb-10" />
              <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                 Edit Business Info
              </h2>
            </div>
            <div className="grid gap-4 sm:grid-cols-3 sm:gap-6">

              <div className="w-full ">
                  <label htmlFor="ownerSelect" className="block mb-2 text-sm font-medium text-gray-900">
                  Business type
                    {/* <span className="text-red-500 text-xl font-extrabold ml-1">*</span> */}
                  </label>
                  <select
                    id="selectBusinessType"
                    name="selectBusinessType"
                    value={formData.businessType}
                    onChange={handleBusinessTypeChange}
                    // readOnly
                    disabled
                    // style={{ color: '#333', backgroundColor: 'grey'}}
                    className="border border-gray-300 disabled:bg-gray-150 disabled:text-black text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-100 "
                  >
                    <option value="" >Select Business type</option>
                    
                      <option key={"Retail"} value={"1"}>
                        Retail distribution
                      </option>
                      <option key={"Gas"} value={"2"}>
                        Gas distribution
                      </option>
                   
                  </select>
              </div>
              
              <div className="w-full">
                <label
                  htmlFor="businessName"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Business Name{" "}
                  {/* <span className="text-red-500 text-xl font-extrabold">*</span> */}
                </label>

                <BusinessNameExistTooltip content={businessNameExist} show={!!businessNameExist}   marginLeft={140}/>
                <CustomTooltip1 content={busNameError} show={!!busNameError}   marginLeft={140}/>

                <input
                  type="text"
                  id="businessName"
                  name="businessName"
                  value={formData.businessName}
                  onChange={handlebusNameChange}
                  required
                  readOnly
                  className={`border border-gray-300  text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${"bg-gray-100"}`}
                  placeholder="Enter Business Name"
                 
                />
              </div>

              <div className="w-full">
                <label
                  htmlFor="businessAddress"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Business Address{" "}
                  {/* <span className="text-red-500 text-xl font-extrabold">*</span> */}
                </label>
                <BusinessAddressTooltip
                  content="Business Address should be at least three char."
                  show={formData.businessAddress.length >0 && formData.businessAddress.length < 3}
                  marginLeft={140}
                />

                <input
                  type="text"
                  id="businessAddress"
                  name="businessAddress"
                  value={formData.businessAddress}
                  readOnly
                  onChange={(e) => {
                    const inputValue = e.target.value;
                
                    // Allow only commas, numbers, and text
                    const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9, \-/#]/g, '');
                    setFormData((prevData) => ({
                      ...prevData,
                      businessAddress: sanitizedValue,
                    }));
                  }}
                  required
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                     "bg-gray-100"
                  }`}
                  placeholder="Enter Business Address"
               
                />
              </div>

              <div className="w-full">
                <label
                  htmlFor="businessCity"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  City{" "}
                  {/* <span className="text-red-500 text-xl font-extrabold">*</span> */}
                </label>
                <CustomTooltip1
                  content={busCityNameError}
                  show={!!busCityNameError}
                  marginLeft={155}
                />

                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    "bg-gray-100"
                  }`}
                  placeholder="Enter City"
                  type="text"
                  id="businessCity"
                  name="businessCity"
                  readOnly
                  value={formData.businessCity}
                  onChange={handlebusCityChange}
                  required
            
                />
              </div>

              <div className="w-full">
                <label
                  htmlFor="businessState"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  State{" "}
                  {/* <span className="text-red-500 text-xl font-extrabold">*</span> */}
                </label>
                <select
                  id="businessState"
                  name="businessState"
                  disabled
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    "bg-gray-100"
                  }`}
                  value={formData.businessState}
                  onChange={handleInputChange}
                  // onChange={handleChange}
                  required
                
                >
                  <option value="">Select State</option>
                  <option value="AL">Alabama (AL)</option>
                  <option value="AK">Alaska (AK)</option>
                  <option value="AZ">Arizona (AZ)</option>
                  <option value="AR">Arkansas (AR)</option>
                  <option value="CA">California (CA)</option>
                  <option value="CO">Colorado (CO)</option>
                  <option value="CT">Connecticut (CT)</option>
                  <option value="DE">Delaware (DE)</option>
                  <option value="FL">Florida (FL)</option>
                  <option value="GA">Georgia (GA)</option>
                  <option value="HI">Hawaii (HI)</option>
                  <option value="ID">Idaho (ID)</option>
                  <option value="IL">Illinois (IL)</option>
                  <option value="IN">Indiana (IN)</option>
                  <option value="IA">Iowa (IA)</option>
                  <option value="KS">Kansas (KS)</option>
                  <option value="KY">Kentucky (KY)</option>
                  <option value="LA">Louisiana (LA)</option>
                  <option value="ME">Maine (ME)</option>
                  <option value="MD">Maryland (MD)</option>
                  <option value="MA">Massachusetts (MA)</option>
                  <option value="MI">Michigan (MI)</option>
                  <option value="MN">Minnesota (MN)</option>
                  <option value="MS">Mississippi (MS)</option>
                  <option value="MO">Missouri (MO)</option>
                  <option value="MT">Montana (MT)</option>
                  <option value="NE">Nebraska (NE)</option>
                  <option value="NV">Nevada (NV)</option>
                  <option value="NH">New Hampshire (NH)</option>
                  <option value="NJ">New Jersey (NJ)</option>
                  <option value="NM">New Mexico (NM)</option>
                  <option value="NY">New York (NY)</option>
                  <option value="NC">North Carolina (NC)</option>
                  <option value="ND">North Dakota (ND)</option>
                  <option value="OH">Ohio (OH)</option>
                  <option value="OK">Oklahoma (OK)</option>
                  <option value="OR">Oregon (OR)</option>
                  <option value="PA">Pennsylvania (PA)</option>
                  <option value="RI">Rhode Island (RI)</option>
                  <option value="SC">South Carolina (SC)</option>
                  <option value="SD">South Dakota (SD)</option>
                  <option value="TN">Tennessee (TN)</option>
                  <option value="TX">Texas (TX)</option>
                  <option value="UT">Utah (UT)</option>
                  <option value="VT">Vermont (VT)</option>
                  <option value="VA">Virginia (VA)</option>
                  <option value="WA">Washington (WA)</option>
                  <option value="WV">West Virginia (WV)</option>
                  <option value="WI">Wisconsin (WI)</option>
                  <option value="WY">Wyoming (WY)</option>
                </select>
              </div>

              <div className="w-full">
                <label
                  htmlFor="businessZipCode"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Zip Code{" "}
                  {/* <span className="text-red-500 text-xl font-extrabold">*</span> */}
                </label>
                <CustomTooltip1 content={zipCodeError} show={!!zipCodeError}      marginLeft={167}/>
                {invalidBusinessZipCode === "Invalid number" && !zipCodeError &&  (
                <CustomTooltip1
                  content={"Invalid ZipCode"}
                  show={"Invalid ZipCode"}
                  marginLeft={163}
                />
                )} 
                <input
                   className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    "bg-gray-100"
                  }`}
                  placeholder="Enter Zipcode"
                  type="text"
                  id="businessZipCode"
                  name="businessZipCode"
                  readOnly
                  value={formData.businessZipCode}
                  onChange={handleInputZipChange}
                  required
                  
                />
                {/* {zipCodeError && (
                  <div className="text-red-500 text-sm mt-1">
                    {zipCodeError}
                  </div>
                )} */}
              </div>

              <div className="w-full">
                <label htmlFor="businessLogo" className="block mb-2 text-sm font-medium text-gray-900">
                Business Logo
                </label>
                <div className="flex w-full relative">
                  <input
                    type="file"
                    disabled
                    id="fileInput"
                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                    onChange={handleBusinessLogoUpload}
                    ref={fileInputRef}
                  />
                  <input
                    type="text"
                    placeholder="Choose file"
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-100"
                    value={fileName}
                    readOnly
                    onClick={handleTextInputClick}
                  />
                </div>
              </div>
              <div className="w-full">
                <label
                  htmlFor="faxNumber"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Fax Number{" "}
                  {/* <span className="text-red-500 text-xl font-extrabold">*</span> */}
                </label>
                {/* <CustomTooltip1 content={zipCodeError} show={!!zipCodeError}      marginLeft={167}/>
                {invalidBusinessZipCode === "Invalid number" && !zipCodeError &&  (
                <CustomTooltip1
                  content={"Invalid ZipCode"}
                  show={"Invalid ZipCode"}
                  marginLeft={163}
                />
                )}  */}
                <input
                   className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    "bg-gray-100"
                  }`}
                  placeholder="faxNumber"
                  type="text"
                  id="faxNumber"
                  name="faxNumber"
                  readOnly
                  value={formData.faxNumber}
                  onChange={handleFaxChange}
                  required
                  
                />
                {/* {zipCodeError && (
                  <div className="text-red-500 text-sm mt-1">
                    {zipCodeError}
                  </div>
                )} */}
              </div>
              <div className="w-full">
                <label
                  htmlFor="businessPhoneNumber"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Business phone Number{" "}
                  {/* <span className="text-red-500 text-xl font-extrabold">*</span> */}
                </label>
                {/* <CustomTooltip1 content={zipCodeError} show={!!zipCodeError}      marginLeft={167}/>
                {invalidBusinessZipCode === "Invalid number" && !zipCodeError &&  (
                <CustomTooltip1
                  content={"Invalid ZipCode"}
                  show={"Invalid ZipCode"}
                  marginLeft={163}
                />
                )}  */}
                <input
                   className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    "bg-gray-100"
                  }`}
                  placeholder="Enter Business Phone Number"
                  type="text"
                  id="businessPhoneNumber"
                  name="businessPhoneNumber"
                  readOnly
                  value={formData.businessPhoneNumber}
                  onChange={handleBusinessPhoneChange}
                  required
                  
                />
                {/* {zipCodeError && (
                  <div className="text-red-500 text-sm mt-1">
                    {zipCodeError}
                  </div>
                )} */}
              </div>

            </div>

            {/* <div className="flex justify-end space-x-3">
                <>
                  <button
                    onClick={()=> navigate("/")}
                    type="submit"
                    className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleBusinessDetails}
                    type="submit"
                    className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                    disabled={
                      formData.businessType === "" ||
                      formData.businessName.length < 3 ||
                      formData.businessAddress.length < 3 ||
                      formData.businessCity.length < 3 ||
                      formData.businessState === '' || 
                      formData.businessZipCode.length < 5 || invalidBusinessZipCode === "Invalid number"
                    }
                  >
                    Save
                  </button>
                </>
           
            </div> */}
         </div>
        
        <EditOwner contactDetails={contactDetails} businessId={businessDetails.business_id} />

         </div>
  )
}

export default EditBusiness