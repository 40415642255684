import { Button, Modal } from "flowbite-react";
import { useState } from "react";
import { deleteInvoice, fetchStatus } from "../../store/invoiceSlice";
import { useDispatch } from "react-redux";
import axios from "axios";
import config from "../../config/config";
import { showToastForDeleteInvoice } from "../toasts/toastForInvoice";
import { addToast } from '../pages/ToastBox/ToastBoxMessageSlice';
import { useNavigate} from 'react-router-dom';

export default function PopUpModal({setTriggerFetch, triggerFetch, fetchStatus, onClose, invoiceNumber, invoiceDelete, invoiceName , resetRowSelection}) {
  console.log(invoiceNumber, 'invoiceNumber')
  const dispatch = useDispatch();
      const navigate = useNavigate()
      const loggedInUserId = localStorage.getItem("user_id")
      const userRole = localStorage.getItem("user_role")
  const businessId = localStorage.getItem("business_id")
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const [openModal, setOpenModal] = useState(true);
  const handleDelete = async() => {
    try{
      const response = await axios.delete(`${baseURL}/${btoa("invoice/delete")}/${btoa(invoiceNumber)}/${btoa(businessId)}/${btoa(loggedInUserId)}/${btoa(userRole)}`)
      console.log("delete invoice" , response)
        if (response.data && response.data.deleteStatus === true) {
            dispatch(deleteInvoice(invoiceNumber));
            window.scroll(0,0)
            onClose();
            // resetRowSelection();
            // showToastForDeleteInvoice(`${invoiceName} deleted successfully`, "error" , "")
            dispatch(addToast({ type: 'danger', message: `${invoiceNumber} of ${invoiceName} deleted successfully` }));
            // dispatch(fetchStatus(fetchStatus))
            setTriggerFetch(!triggerFetch);
          } else {
            console.error("Failed to delete the invoice on the server.");
        }
        navigate('/invoice')

    }catch(error){
        console.log("error deleting invoice" , error);
    }
  };

  return (
    <Modal show={openModal} size="md" popup onClose={onClose}>
      <Modal.Header />
      <Modal.Body>
        <div className="text-center">
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400 mt-3">
            Are you sure you want to delete
            <span className=" block uppercase p-2 font-semibold text-red-700 underline underline-offset-8">"{invoiceNumber}"</span>
            <span className="mt-2 text-lg font-normal text-gray-500 dark:text-gray-400"><span className="capitalize">For</span>  {invoiceName} </span>
          </h3>
          <div className="flex justify-center gap-4">
            <Button color="failure" onClick={handleDelete}>
              Yes, I'm sure
            </Button>
            <Button color="gray" onClick={onClose}>
              No, cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
