import React, { useState, useEffect  } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import {
  MdOutlineLocalShipping,
  MdOutlinePayment,
  MdOutlinePhoneInTalk,
  MdPersonOutline,
} from "react-icons/md";
import UserPasswordChangeModal from '../pages/UserPasswordChangeModal';
import { TbUserStar } from 'react-icons/tb';
import { toast } from "react-toastify";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { MdOutlineBusinessCenter } from "react-icons/md";
import config  from "../../config/config"
import profileImage from '../../../src/assets/images/profilePic.jpg'
import { Tooltip } from "react-tippy";
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import FaxIcon from '../../../src/assets/images/fax image.jpg'
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import { useDispatch } from "react-redux";
import { addToast } from '../pages/ToastBox/ToastBoxMessageSlice';

function capitalizeFirstLetter(email) {
  return email.charAt(0).toUpperCase() + email.slice(1).toLowerCase();
}

const ViewBusinessUsers = () => {
  const dispatch = useDispatch()

  const business_id = localStorage.getItem("business_id")
  const user_id = localStorage.getItem("user_id")
  const user_role = localStorage.getItem("user_role")
  // console.log("user_role" , user_role);
  // console.log("bus id is " , business_id);
  // console.log("user id is " , user_id);

  const navigate = useNavigate()
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [contactInfoEditMode, setContactInfoEditMode] = useState(false);

  const [managerData , setManagerData ] = useState([])
    const[ownerData , setOwnerData] = useState([])  

  const [contactNameError, setContactNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [inputPhoneError, setInputPhoneError] = useState("");
  const [emailToggle, setIsEmailToggle] = useState(false);
  const [phoneToggle, setIsPhoneToggle] = useState(false);
  
  const [OwnerPhoneToggle, setIsOwnerPhoneToggle] = useState(false);
  const [OwnerEmailToggle, setIsOwnerEmailToggle] = useState(false);
  const [UserNameToggle, setIsUserNameToggle] = useState(false);
  
  const [OwnerNameToggle, setIsOwnerNameToggle] = useState(false);

  
  const handleOpenModal = () => {
    console.log("Button clicked'");
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden'; 
  };

  const handleCloseModal = () => {
    console.log('Modal closed');
    setIsModalOpen(false);
    document.body.style.overflow = '';
  };

  const capitalizeWords = (input) => {
    return input
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const [userData, setUserData] = useState({
    contact_name: "",
    contact_email: "",
    contact_no: "",
  });

  const handleContactNameChange = (e) => {                          // Contact name setting and validation
    const { name, value } = e.target;
    let inputValue = value.replace(/[^a-zA-Z\s]/g, "");
    setUserData({
      ...userData,
      [name]: capitalizeWords(inputValue),
    });
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setContactNameError(" Name must be at least 3 letters");
    } else {
      setContactNameError("");
    }
      
  };

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const isValidEmail = validateEmail(value);

    setUserData({
      ...userData,
      [name]: value,
    });
    if (value.length > 0 && !isValidEmail) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }

  };

  const handleInputPhoneChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/\D/g, "");
    inputValue = inputValue.slice(0, 10);
    if (inputValue.length === 10) {
      inputValue = `(${inputValue.slice(0, 3)})${inputValue.slice(
        3,
        6
      )}-${inputValue.slice(6, 10)}`;
    }
    setUserData({
      ...userData,
      [name]: inputValue,
    });
    if (inputValue.length > 0 && !/^\(\d{3}\)\d{3}-\d{4}$/.test(inputValue)) {
      setInputPhoneError("Please enter a valid 10-digit phone number");
    } else {
      setInputPhoneError("");
    }
  }

  const filteredOwnersData = ownerData.filter((owner) => owner.contact_email !== userData.contact_email);

  
  const handleUserEditClick = async ()=>{

    try{
      const response = await axios.put(`${baseURL}/${btoa("manager/update/")}` , {
          edit_managerId: user_id,
          email_db : localStorage.getItem("userEmail"),
          edit_contactPersonName: userData.contact_name,
          edit_contactPersonEmail: userData.contact_email,
          edit_contactPersonPhoneNumber: userData.contact_no
      })
            console.log("1" ,localStorage.getItem("userEmail") );
            console.log("2" ,userData.contact_name );
            console.log("3" ,userData.contact_email );
            console.log("4" ,userData.contact_no );
             console.log("owner data posting " , response);
            //  navigate("/")
            setContactInfoEditMode(!contactInfoEditMode);
              let successMessage = `${userData.contact_name}, details updated successfully`;
            //   showToast(
            //   successMessage,
            //   "success",
            // );
                    dispatch(addToast({ type: 'success', message: `${successMessage}` }));        
            
    }catch(error){
      console.log("error posting owner details" , error);
              dispatch(addToast({ type: 'danger', message: `error Posting Owner Details` }));        
      
  }
}


    const [businessData, setBusinessData] = useState({
          business_name : "",
          business_address : "",
          business_city : "",
          business_state : "",
          business_zip_code : ""
  });

 

          
   

 

  useEffect(() => {
    axios
      .get(`${baseURL}${btoa("business/profile")}/${btoa(business_id)}/${btoa(user_id)}/${btoa(user_role)}`)
      .then((response) => {
        console.log("response" , response);

        const business = response.data.business_data[0]
        setBusinessData({
          business_name : business.business_name,
          business_address : business.business_address,
          business_city : business.business_city,
          business_state : business.business_state,
          business_zip_code : business.business_zip_code,
          business_contact_no:business.business_contact_no,
          fax_number:business.fax_number
        })

        const user = response.data.manager_data[0]
        setUserData({    
          contact_name: user.contact_name,
          contact_email: user.contact_email,
          contact_no: user.contact_no,
        });

        
        setOwnerData(response.data.owner_data)

        
        setManagerData(businessData)
      })
      .catch((error) => {
        console.error("Error fetching managers data:", error);
       
      });
  }, []);

  console.log("business details after setting" , businessData);
  console.log("owner details after setting" , ownerData);
  console.log("user details after setting" , userData);


  const showToast = (message, type, businessName, filledFrames) => {
    let toastColorClass;
    let iconComponent;

    if (type === "success") {
      toastColorClass = "bg-blue-50 text-blue-500";
      iconComponent = (
        <FaCheckCircle className="mr-6 text-[#1D72F3] bg-blue-50" size={24} />
      );
    } else {
      toastColorClass = "bg-red-50 text-red-500";
      iconComponent = <FaTimesCircle className="mr-6 text-red-500" size={24} />;
    }

    toast.error(
      <div className={`flex justify-between ${toastColorClass}`}>
        <div className="rounded-lg" style={{ whiteSpace: "nowrap" }}>
          {message}
        </div>
        <div className="rounded-lg  ">{iconComponent}</div>
      </div>,
      {
        position: "top-left", 
        hideProgressBar: true,
        autoClose: 4000,
        icon: false,
        className: " w-[843px] ml-[240px] mt-[60px]", // Full width
        style: {
          borderLeft:
            type === "success" ? "5px solid #1D72F3" : "5px solid red",
          background: type === "success" ? "#ebf5ff" : "#fdf2f2",
          minHeight: "10px",
          
        },
      }
    );
  };

  const CustomTooltip1 = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: "1px solid red",
          marginTop: "-35px",
          marginLeft: `${marginLeft}px`, // Use the provided marginLeft prop
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "-7px",
            left: "50%",
            marginLeft: "-10px",
            width: "0",
            height: "0",
            borderLeft: "7px solid transparent",
            borderRight: "7px solid transparent",
            borderTop: "7px solid #f98080",
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };

  const formatEmail = (email) => {
    if (!email) { return ''; }
    const [localPart, domain] = email.split('@');
    const maskedLocalPart = localPart.slice(0, 2) + "*****";
    const domainTLD = domain.slice(domain.lastIndexOf('.'));
    return `${maskedLocalPart}${domainTLD}`;
  };

  const handlePhoneToggle =()=> {
    setIsPhoneToggle((prev) => !prev);
  
  }
  const handleEmailToggle =()=> {
    setIsEmailToggle((prev) => !prev);
  
  }
  
  const handleUserNameToggle =()=> {
    setIsUserNameToggle((prev) => !prev);
  
  }
  const handleOwnerEmailToggle =()=> {
    setIsOwnerEmailToggle((prev) => !prev);
  
  }
  
  const handleOwnerNameToggle =()=> {
    setIsOwnerNameToggle((prev) => !prev);
  
  }
   const handleOwnerPhoneToggle =()=> {
    setIsOwnerPhoneToggle((prev) => !prev);
  
  }
  return (
    <div>

      {/* <div>
          <h1 className="mx-8 font-bold text-gray-400 text-xl mt-10 mb-10">
                     User Contact Info
          </h1>
      </div> */}
      <div className="flex p-4">
  <div className=" p-4 w-1/3 h-50">
   
  <div className="bg-white rounded-box rounded-xl" style={{height:'98%'}}>

            <div className="flex items-center">
              <MdOutlineBusinessCenter className="text-gray-700 w-6 h-6 mr-2 mb-10" />
              <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                 User Profile
              </h2>
            </div>

            <div className="w-32 h-32 rounded-full bg-gray-200 flex items-center justify-center mb-4 mx-auto">
    <img
        src={profileImage} // Replace with actual profile image URL
        alt="Profile"
        className="w-full h-full object-cover rounded-full"
    />
</div>


      {/* Display user details */}
      <div className="w-full text-center">
        <h2 className="text-xl font-bold text-gray-900 mb-2">{businessData.business_name}</h2>
        {/* <p className="text-md text-gray-700 mb-2"><strong>Business Name:</strong> {businessData.business_name}</p> */}
        {/* <p className="text-md text-gray-700 mb-2"><strong>Business Address:</strong> {businessData.business_address}</p> */}
        <p className="text-md text-gray-700 mb-2"> {businessData.business_address}</p>
     
        <p className="text-md text-gray-700 mb-2"><strong></strong> {businessData.business_city},{businessData.business_state}, {businessData.business_zip_code}</p>
        {businessData.fax_number ?
        <p className="text-md text-gray-700 mb-2 flex items-center justify-center">
  <img src={FaxIcon} alt="Fax Icon" className="w-6 h-6 mr-2" />
 { businessData.fax_number || ''}
</p> :''
}
{businessData.business_contact_no &&
        <p className="text-md text-gray-700 mb-2"><strong> <ContactPhoneOutlinedIcon/></strong>  {businessData.business_contact_no || ''}</p>
}
        {/* <p className="text-md text-gray-700 mb-2"><strong>State:</strong> {businessData.business_state}</p> */}
        {/* <p className="text-md text-gray-700 mb-2"><strong>Zip Code:</strong> {businessData.business_zip_code}</p> */}

    

      </div>

           
            </div>
  </div>

  <div className=" p-0 w-2/3">
    <div>
          {filteredOwnersData.map((owner , index) => (
          <div key={owner.id} className="w-auto bg-white rounded-box  mx-8  mt-4 mb-6 rounded-xl">
              
              <div className="flex items-center">
              <MdOutlinePhoneInTalk className="text-gray-700 w-6 h-6 mr-2 mb-10" />
              <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                Business Owner Contact Info
              </h2>
             </div>

          <div className="grid gap-4 sm:grid-cols-1 sm:gap-6">
            
              <div className="w-full">
                <label
                  htmlFor="contactPersonName"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Contact Person Name
                </label>
                {/* <CustomTooltip1
                  content={contactNameError}
                  show={!!contactNameError}
                  marginLeft={120}
                /> */}
                <div className='flex'>

                <input
                  className={`border border-gray-300 mb-1 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                  "bg-gray-200"
                  }`}
                  placeholder="Enter Name"
                  type="text"
                  id={`contactPersonName-${index}`}
                  name={`contactPersonName-${index}`}
                  value={owner.contact_name}
                  // onChange={handleContactNameChange}
                  required
                  readOnly
                />
                      {/* <label htmlFor="ownerNameToggle" className="switch-toggle flex items-center ml-4 mt-2 mb-0">
    <input
      type="checkbox"
      onChange={handleOwnerNameToggle}
      id="ownerNameToggle"
      name="ownerNameToggle"
      className="hidden"
      aria-label="ownerNameToggle"
    />
    <span className="slider-toggle round"></span>
  </label> */}
  </div>
              </div>



            <div className="w-full">
                <label
                  htmlFor="contactPersonEmail"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Email
                </label>
                {/* <CustomTooltip1 content={emailError} show={!!emailError}   marginLeft={206}/> */}
<div className='flex'>
                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    "bg-gray-200"
                  }`}
                  placeholder="Enter Email"
                  type="email"
                  id={`contactPersonEmail-${index}`}
                  name={`contactPersonEmail-${index}`}
                  value={capitalizeFirstLetter(formatEmail(owner.contact_email))}
                  // onChange={handleEmailChange}
                  required
                  readOnly
                />

{/*      
                 <label htmlFor="OwnerEmailToggle" className="switch-toggle flex items-center ml-4 mt-2 mb-0">

    <input
      type="checkbox"
      onChange={handleOwnerEmailToggle}
      id="OwnerEmailToggle"
      name="OwnerEmailToggle"
      className="hidden"
      aria-label="OwnerEmailToggle"
    />
    <span className="slider-toggle round"></span>
  </label> */}
  </div>
                {/* {emailError && (
                  <div className="text-red-500 text-sm mt-1">{emailError}</div>
                )} */}
              </div>

              <div className="w-full">
                <label
                  htmlFor="contactPersonPhoneNumber"
                  className="block mb-2 text-sm font-medium text-gray-900  "
                >
                  Phone Number
                </label>
                {/* <CustomTooltip1
                  content={inputPhoneError}
                  show={!!inputPhoneError}
                  marginLeft={163}
                /> */}
<div className='flex'>
                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                  "bg-gray-200"
                  }`}
                  placeholder="Enter Phone Number"
                  type="text"
                  id={`contactPersonPhoneNumber-${index}`}
                  name={`contactPersonPhoneNumber-${index}`}
                  value={owner.contact_no}
                  // onChange={handleInputPhoneChange}
                  required
                  readOnly
                />
                 {/* <label htmlFor="OwnerPhoneToggle" className="switch-toggle flex items-center ml-4 mt-2 mb-0">
    <input
      type="checkbox"
      onChange={handleOwnerPhoneToggle}
      id="OwnerPhoneToggle"
      name="OwnerPhoneToggle"
      className="hidden"
      aria-label="OwnerPhoneToggle"
    />
    <span className="slider-toggle round"></span>
  </label> */}
  </div>
              
              </div>
            </div>

            <div className="flex justify-end space-x-3">
              {/* <>
                
              <button
                onClick={() => {
                  if (contactInfoEditMode) {
                    handleEditClick();
                  } else {
                    setContactInfoEditMode(!contactInfoEditMode);
                  }
                }}
            type="submit"
                className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  if (contactInfoEditMode) {
                    handleEditClick();
                  } else {
                    setContactInfoEditMode(!contactInfoEditMode);
                  }
                }}
                type="submit"
               
                className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
              >
                {contactInfoEditMode ? "Save" : "Edit"}
              </button>
            </> */}
          </div>
        </div>
      ))}
         </div>


        <div className="w-auto bg-white rounded-box  mx-8 rounded-xl mt-6 mb-6">
            <div className="flex items-center">
              <MdOutlinePhoneInTalk className="text-gray-700 w-6 h-6 mr-2 mb-10" />
              <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                Business User Contact Info
              </h2>
            </div>

            <div className="grid gap-4 sm:grid-cols-1
             sm:gap-6">
              <div className="w-full">
                <label
                  htmlFor="contactPersonName"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Contact Person Name
                  <span className="text-red-500 text-xl font-extrabold ml-1">*</span>
                </label>
                <CustomTooltip1
                  content={contactNameError}
                  show={!!contactNameError}
                  marginLeft={120}
                />
<div className='flex'>
                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    contactInfoEditMode ? "bg-white" : "bg-gray-200"
                  }`}
                  placeholder="Enter Name"
                  type="text"
                  id="contact_name"
                  name="contact_name"
                  value={userData.contact_name}
                  onChange={handleContactNameChange}
                  required
                  readOnly={!contactInfoEditMode}
                />
                       <label className="switch-toggle ml-4 flex items-center mt-2 mb-0" htmlFor='userNameToggle'>
                
                <input
                  type="checkbox"
                  onChange={handleUserNameToggle}
                  // checked={isSameAsAboveSelected}
                  id="userNameToggle"
                  name="userNameToggle"
                />
                <span className="slider-toggle round"></span>
              </label>
  </div>
              </div>

              <div className="w-full">
                <label
                  htmlFor="contactPersonEmail"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Email
                  <span className="text-red-500 text-xl font-extrabold ml-1">*</span>
                </label>
              
                <CustomTooltip1 content={emailError} show={!!emailError}   marginLeft={206}/>
              <div className='flex'>
                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    contactInfoEditMode ? "bg-white" : "bg-gray-200"
                  }`}
                  placeholder="Enter Email"
                  type="email"
                  id="contact_email"
                  name="contact_email"
                  value={capitalizeFirstLetter(formatEmail(userData.contact_email))}
                  onChange={handleEmailChange}
                  required
                  readOnly={!contactInfoEditMode}
                />
                  <label className="switch-toggle ml-4 flex items-center mt-2 mb-0" htmlFor='EmailToggle'>
                
                <input
                  type="checkbox"
                  onChange={handleEmailToggle}
                  // checked={isSameAsAboveSelected}
                  id="EmailToggle"
                  name="EmailToggle"
                />
                <span className="slider-toggle round"></span>
              </label>
              </div>
              </div>

              <div className="w-full">
           <div className="flex items-center justify-between">
  <label
    htmlFor="contactPersonPhoneNumber"
    className="text-sm font-medium text-gray-900"
  >
    Phone Number
    <span className="text-red-500 text-xl font-extrabold ml-1">*</span>
  </label>

</div>

                <CustomTooltip1
                  content={inputPhoneError}
                  show={!!inputPhoneError}
                  marginLeft={163}
                />
           <div className='flex'>
                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    contactInfoEditMode ? "bg-white" : "bg-gray-200"
                  }`}
                  placeholder="Enter Phone Number"
                  type="text"
                  id="contact_no"
                  name="contact_no"
                  value={userData.contact_no}
                  onChange={handleInputPhoneChange}
                  required
                  readOnly={!contactInfoEditMode}
                />
                
  <label htmlFor="phoneToggle" className="switch-toggle flex items-center ml-4 mt-2 mb-0">
    <input
      type="checkbox"
      onChange={handlePhoneToggle}
      id="phoneToggle"
      name="phoneToggle"
      className="hidden"
      aria-label="phoneToggle"
    />
    <span className="slider-toggle round"></span>
  </label>
  </div>
              </div>
            </div>



            <div className="flex justify-end space-x-3">
            
                <>
                  {/* <button
                    onClick={()=> navigate("/")}
                    type="submit"
                    className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  >
                    Cancel
                  </button> */}

                  {/* <button
                      onClick={() => {
                        if (contactInfoEditMode) {
                          // Perform save logic here
                          handleUserEditClick();
                        } else {
                          // Toggle edit mode
                          setContactInfoEditMode(!contactInfoEditMode);
                        }
                      }}
                    type="submit"
                    disabled={
                      userData.contact_name.length < 3 || 
                      userData.contact_email.length < 3 ||
                      !userData.contact_email.includes('@') ||
                      !userData.contact_email.includes('.') || 
                      userData.contact_no.length < 13 ||
                      emailError

                    // Add additional conditions for other business info fields if needed
                  }
                    className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  >
                    {contactInfoEditMode ? "Save" : "Edit"}
                  </button> */}
{/* 
                  <button
                    type="submit"
                    onClick={handleOpenModal}
                     className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                  >
                    change password
                  </button>
                  {isModalOpen && (
                    <UserPasswordChangeModal onClose={handleCloseModal} />
                  )} */}
                </>
           
            </div>
          </div>
    <>    
    <UserPasswordChangeModal />
    </>
  </div>
</div>
        

    </div>
  )
}

export default ViewBusinessUsers