import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import config from '../../config/config';
import CloudUpload from '@mui/icons-material/CloudUpload';

const FileUploadModal = ({ onClose, handleFileChange, fileInputRef, fileName, setFileName, handleUploadImage, selectedFuelBrand, onFuelBrandChange, errorMessage }) => {
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const [sellerNames, setSellerNames] = useState([]);
  const [localFuelBrand, setLocalFuelBrand] = useState('');

  const modalRef = useRef(null);

  const handleSelectChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    setLocalFuelBrand(selectedOption.value);
    onFuelBrandChange(selectedOption);
  };

  useEffect(() => {
    const fetchSellersNames = async () => {
      try {
        const response = await axios.get(`${baseURL}${btoa("invoice/allsellers")}`);
        setSellerNames(response.data);
      } catch (error) {
        console.error("Error fetching seller names", error);
      }
    };

    fetchSellersNames();
  }, []);

  useEffect(() => {
    setLocalFuelBrand('');
    setFileName('');
  }, []);

  // Close modal when clicking outside the modal content
  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div ref={modalRef} className="bg-white rounded-lg shadow-lg w-1/3 p-6 relative">
        {/* Close Button */}
        <button
        onClick={onClose}
        className="absolute top-2 right-2 text-red-500 hover:text-red-700 text-4xl font-bold mr-3 mb-3"
         aria-label="Close modal"
            >
            &times;
         </button>


        <h2 className="text-xl font-bold text-gray-800 mb-4 text-center mt-[30px]">Purchase Invoice Upload</h2>

        {/* Dropdown */}
        <div className="relative w-full mb-6">
          <select
            className="block w-full px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            value={localFuelBrand}
            onChange={handleSelectChange}
          >
            <option value="" disabled>Select Brand Name</option>
            {sellerNames.map((seller) => (
              <option key={seller.id} value={seller.id}>
            {seller.seller_name.charAt(0).toUpperCase() + seller.seller_name.slice(1).toLowerCase()}
            </option>
            ))}
          </select>
        </div>

        {/* File Upload */}
        
        <div style={{ border: '1px dashed gray', borderRadius: '10px', padding: '20px' }}>
          <div className="flex flex-col items-center justify-center w-full relative m-auto">
            {!fileName &&
            <>
            <CloudUpload style={{ color: 'gray', fontSize: 80, marginRight: '8px' }} />
            <p className="mt-2 text-gray-500">Drop Your File Here</p>
            <p className="text-gray-400 p-2">Or</p>
            </>
            }
             {fileName &&
            <>
            <CloudUpload style={{ color: 'gray', fontSize: 80, marginRight: '8px' }} />
            <p className="mt-2 text-gray-500">Please click on Done to Upload</p>
            {/* <p className="text-gray-400 p-2">Or</p> */}
            </>
            }
            <input
              type="file"
              id="fileInput"
              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
              onChange={handleFileChange}
              ref={fileInputRef}
              accept="image/pdf/*"
              disabled={!localFuelBrand}
            />
            <div className="flex flex-col w-full mt-2">
              {fileName && (
                // <input
                //   type="text"
                //   placeholder="Choose file"
                //   className="w-full p-2 border border-gray-300 rounded-l-lg cursor-pointer"
                //   value={fileName}
                //   readOnly
                // />
                <span className='m-auto'>{fileName}</span>
              )}
              { (
                <label
                  htmlFor="fileInput"
                  className={`bg-blue-500 text-white py-2 px-4 rounded cursor-pointer mb-0 m-auto mt-[5px]
                  ${!localFuelBrand? "bg-gray-300 text-gray-600 cursor-not-allowed": "bg-blue-500 text-white"}
                  `}
                  style={{width:'80px'}}

                >
                  Browse
                </label>
              )}
            </div>
            {errorMessage && (
              <p className="text-red-500 font-medium text-sm">{errorMessage}</p>
            )}
          </div>
            {/* Submit Button */}
         
        </div>

        {fileName &&
        <div className="mt-2 flex justify-center"  
                       
>
          <button
            onClick={handleUploadImage}
            disabled={!localFuelBrand || !fileName}
            className={`bg-blue-500 text-white py-2 px-4 rounded cursor-pointer w-[80px] ${
              !localFuelBrand || !fileName ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            Done
          </button>
        </div>
        }
      </div>
    </div>
  );
};

export default FileUploadModal;
