// AddItemForm.js
import React, { useEffect, useLayoutEffect, useState , useRef } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { createColumnHelper } from "@tanstack/react-table";
import Table from "../pages/Table";
import InvoiceDataTable from "../pages/InvoiceDataTable";
import InvoiceItems from "./InvoiceItems";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux"; // Import useDispatch from react-redux
import {
  addInvoice,
} from "../../actions/invoiceActions";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { MdCalendarMonth } from "react-icons/md";
import config  from "../../config/config"



function InvoiceForms() {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize the navigate function

  const { invoice_number } = useSelector((state) => state.invoice);

  const [isInputFocused, setIsInputFocused] = useState(true);
  const today = new Date().toISOString().split("T")[0];
  const [issuedOn, setIssuedOn] = useState(today);
  const [dueOn, setDueOn] = useState(today);
  const [productOptions, setProductOptions] = useState([]);

  const [subtotal, setSubtotal] = useState(0);
  const [tax, setTax] = useState("");
  const [feesOrDiscounts, setFeesOrDiscounts] = useState("");
  const [total, setTotal] = useState(0);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [comments, setComments] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState(""); 
  const [latestInvoiceNumber , setLatestInvoiceNumber] = useState("");

  const [dueDate, setDueDate] = useState(new Date());
  const [createdDate , setCreatedOnDate ] = useState(new Date()); 
  const businessId = localStorage.getItem("business_id")
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;

  const [items, setItems] = useState([
    { 
      product:"",
      item: "",
      description: "",
      quantity: "",
      unitPrice: "",
      total: 0,
      editableDescription: false,
    },
  ]);
  console.log("items", items);
  const [formField, setFormField] = useState({
    invoice_number: invoice_number ?? "",
    bill_from: "",
    bill_to: "",
    created_on: today,
    due_on: today,
  });

  const handleChange = (event) => {
    if (event instanceof Date) {
      setCreatedOnDate(event);
    } else {
      const { name, value } = event.target;
      setFormField({
        ...formField,
        [name]: value,
      });
    }
  };
  const handleDueDateChange = (date) => {
    setDueDate(date);

    setFormField({
      ...formField,
      due_on: date,
    });
  };

  const datePickerRef = useRef(null);

  const showDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formattedDueDate = formField.due_on instanceof Date && !isNaN(formField.due_on)
    ? formField.due_on.toISOString()
    : new Date().toISOString();
      
  console.log("Formatted due date" , formattedDueDate );
    const formData = new FormData();

    formData.append("businessId", localStorage.getItem("business_id"));
    formData.append("managerId", localStorage.getItem("user_id"));
    formData.append("billFromName", formField.bill_from);
    formData.append("customerId", formField.bill_to);
    formData.append("createdOn", formField.created_on);
    formData.append("dueDate", formattedDueDate);
    formData.append("invoiceNumber", latestInvoiceNumber);
    formData.append("subTotalAmount", subtotal);
    formData.append("salesTaxAmount", tax);
    formData.append("discountsAmount", feesOrDiscounts);
    formData.append("finalTotalAmount", total);
    formData.append("comment", comments);
    formData.append("termsCondition", termsAndConditions);

    items.forEach((item, index) => {
      if (item.item && item.quantity && item.total) {
        const [item_id, item_name] = item.item.split("#");
        formData.append(`productId[${index}]`, item.product);
        formData.append(`itemIds[${index}]`, item_id);
        // formData.append(`itemNames[${index}]`, item_name);
        formData.append(`itemDescriptions[${index}]`, item.description);
        formData.append(`quantities[${index}]`, item.quantity);
        formData.append(`unitPrices[${index}]`, item.unitPrice);
        formData.append(`unitTotals[${index}]`, item.total);
      }
    });
    // for (const [key, value] of formData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }
    // console.log("form data: " , formData);
    dispatch(addInvoice(formData));
    navigate("/invoice"); // Use navigate function to redirect
  };

  useEffect(() => {
    const fetchCustomerTermsAndConditions = async () => {
      try {
        const response = await axios.get(`${baseURL}${btoa("invoice/fetchtermsconditions")}/${btoa(formField.bill_to)}/${btoa(businessId)}`);
        // console.log("tandc",response , );
        if(response.data.termsconditions_data?.length === 0){
          setTermsAndConditions("")
        }
        setTermsAndConditions(response.data.termsconditions_data[0].terms_conditions);
      } catch (error) {
        console.error('Error fetching customer terms and conditions', error);
      }
    };
    fetchCustomerTermsAndConditions();
  }, [formField.bill_to]);
 

  useEffect(() => {
    async function fetchCustomerOptions() {
      try {
        const response = await axios.get(
          `${baseURL}${btoa("customer/view")}/${btoa(businessId)}`
        );
        setProductOptions(response.data.customers_data);
      } catch (error) {
        console.error("Error fetching customer options:", error);
      }
    }
    fetchCustomerOptions();
  }, []);


  
  
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
    .get(`${baseURL}/${btoa("invoice/latest_invoice_number")}/${btoa(businessId)}`)
      .then((response) => {
        const latestInvoiceNumber = response.data;
        setLatestInvoiceNumber(latestInvoiceNumber)
        // console.log("latestInvoiceNumber", latestInvoiceNumber);
        // setFormField((prev) => ({ ...prev, invoice_number: latestInvoiceNumber }));
      })
      .catch((error) => {
        console.error("Error fetching latest invoice number:", error);
      });
  }, []);


  const columnHelper = createColumnHelper();
  // const columns = [
  //   columnHelper.accessor("no", {
  //     header: "#",
  //   }),
  //   columnHelper.accessor("item", {
  //     header: "Item",
  //   }),
  //   columnHelper.accessor("itemDescription", {
  //     header: "Item Description",
  //   }),
  //   columnHelper.accessor("quantity", {
  //     header: "Quantity",
  //   }),
  //   columnHelper.accessor("unitPrice", {
  //     header: "Unit Price",
  //   }),
  //   columnHelper.accessor("amount", {
  //     header: "Amount",
  //   }),
  // ];

  const business_id = localStorage.getItem("business_id")
  const user_id = localStorage.getItem("user_id")
  const user_role = localStorage.getItem("user_role")

  useEffect(() => {
    axios
    .get(`${baseURL}${btoa("business/profile")}/${btoa(business_id)}/${btoa(user_id)}/${btoa(user_role)}`)
      .then((response) => {
        console.log(response);
        const businessData = response.data.business_data[0];
        console.log(businessData);
        // setCustomerDetails(businessData);
        setFormField({
          ...formField,
          bill_from: businessData.business_name,
          // Other fields as needed
        });
        // setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
        // setLoading(false);
      });
  }, []);

  const shouldRenderButtons = items.some(
    item => item.item !== "" && item.unitPrice !== "" && item.unitPrice !== 0 && item.quantity !== "" &&
     item.quantity !== 0 && tax !== ""
  );

  console.log("al the items" , items);

  const isDisabled = items.every(
    item =>
      (item.item === "" && item.unitPrice === "" && (item.quantity === "" || isNaN(item.quantity)) && item.product === "")
      ||
      (item.item !== "" && item.unitPrice !== "" && (item.quantity !== "" && !isNaN(item.quantity)) && item.product !== "")
  );
  
  console.log("disable " , isDisabled);
  
  const handleOuterDivClick = () => {
    // Simulate a click on the input element of the date picker
    if (datePickerRef.current) {
      datePickerRef.current.querySelector('input').click();
    }
  };
  

  
  return (
    <div className="pt-8 pb-5">
      <div className="max-w-7xl mx-8 bg-white rounded-box p-10 rounded-xl">
        <div className="flex items-center justify-between w-full">
          <h2 className="mb-10 text-2xl font-bold text-gray-900 ">
            Create New Invoice
          </h2>
          <div className="flex items-center">
            <label
              for="invoice_number"
              className="  text-base font-bold text-gray-900 w-28 mr-2"
            >
              Invoice #
            </label>
            <input
              readOnly
              id="invoice_number"
              name="invoice_number"
              type="text"
              value={latestInvoiceNumber}
              onChange={handleChange}
              placeholder="Enter Invoice Number"
              className="bg-gray-200 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
            />
          </div>
        </div>

        <form action="#" onSubmit={handleSubmit}>
          <div className="grid gap-4 mb-4 sm:grid-cols-2">
            <div>
              <label
                for="bill_from"
                className="block mb-2 text-base font-medium text-gray-900 "
              >
                Bill From
              </label>
              <input
                id="bill_from"
                type="text"
                name="bill_from"
                required
                value={formField.bill_from}
                onChange={handleChange}
                placeholder="Enter From details"
                className="bg-gray-200 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                readOnly
              />
            </div>
            <div className="">
              <label
                htmlFor="created_on"
                className="block mb-2 text-base font-medium text-gray-900 "
              >
                Created On
              </label>
           
              <div className="h-11 text-gray-700 font-normal border-gray-300 bg-gray-200  cursor-pointer border rounded-lg flex items-center justify-between">
                  <DatePicker
                    id="created_on"
                    name="created_on"
                    selected={createdDate}
                    dateFormat="MM/dd/yyyy"        
                    placeholderText="From"
                    readOnly
                    className="w-40 outline-none border-none rounded-r-lg cursor-pointer focus:outline-none focus:ring-0 bg-transparent"
                  />
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5 mr-2"
                >
                  <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                  <path
                    fillRule="evenodd"
                    d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                    clipRule="evenodd"
                  />
                  </svg>
            </div>
             

            </div>

            <div>
              <label
                for="bill_to"
                className="block mb-2 text-base font-medium text-gray-900 "
              >
                Bill To
              </label>
              <select
                id="bill_to"
                name="bill_to"
                required
                value={formField.bill_to}
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
              >
                <option value="">Select Customer</option>
                {productOptions.map((customer) => (
                  <option
                    key={customer.customer_id}
                    value={customer.customer_id}
                  >
                    {customer.business_name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label
                htmlFor="due_on"
                className="block mb-2 text-base font-medium text-gray-900 "
              >
                Due Date
              </label>
           <div
                onClick={handleOuterDivClick} 
                className="h-11  font-normal border-gray-300  cursor-pointer border rounded-lg flex items-center justify-between">
                  <div ref={datePickerRef}>
                      <DatePicker
                        name="due_on"
                        id="due_on"
                        dateFormat="MM/dd/yyyy"
                        minDate={new Date()} 
                        selected={dueDate}
                        onChange={handleDueDateChange}
                        ref={datePickerRef}
                        placeholderText="To"
                        className="w-40 outline-none border-none rounded-r-lg cursor-pointer focus:outline-none focus:ring-0 bg-transparent"
                      />
                  </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5 mr-2"
                >
                  <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                  <path
                    fillRule="evenodd"
                    d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                    clipRule="evenodd"
                  />
                </svg>
          </div>

            </div>
            
          </div>


          <InvoiceItems
            items={items}
            setItems={setItems}
            total={total}
            setTotal={setTotal}
            subtotal={subtotal}
            setSubtotal={setSubtotal}
            tax={tax}
            setTax={setTax}
            feesOrDiscounts={feesOrDiscounts}
            setFeesOrDiscounts={setFeesOrDiscounts}
            comments={comments}
            termsAndConditions={termsAndConditions}
            // onFocus={() => setIsInputFocused(true)}
            // onBlur={() => setIsInputFocused(false)}
          />

          {/* comments */}
          <div className="sm:col-span-2 mb-8">
            <label
              for="description"
              className="block mb-2 text-base font-medium text-gray-900 "
            >
              Comments
            </label>
            <textarea
              id="description"
              rows="4"
              name="description"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              className="block p-2.5 w-full text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 "
              placeholder="Write your Comments here..."
              maxLength={100}
            ></textarea>
          </div>
          <div className="flex justify-between items-center">
            <div className="border-2 border-[#111FFF] py-2 px-5 w-[550px]">
              <h1 className="font-semibold text-blue-500 underline underline-offset-4">
                Terms & Conditions
              </h1>
              <textarea
                 id="termsAndConditions"
                rows="4"
                name="termsAndConditions"
                value={termsAndConditions}
                onChange={(e) => setTermsAndConditions(e.target.value)}
                className="block p-2.5 mt-3 mb-3 w-full text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 "
                placeholder="Add your Terms & Conditions here..."
                maxLength={100}
              ></textarea>
            </div>

           




            
                 <div>
    {/* Your code for displaying each item */}
    
    {/* Conditionally render the buttons if any item meets the conditions */}
              {shouldRenderButtons && (
                <div className="flex justify-end space-x-3 mt-6">
                  <button
                    onClick={() => navigate(`/invoice`)}
                    type="button"
                    className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                   
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                    disabled={!isDisabled || !formField.bill_to}
                  >
                    Done
                  </button>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default InvoiceForms;
