import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config/config";
import { useSelector, useDispatch } from "react-redux";
import { addCustomer } from "../../store/customerSlice";
import { addToast } from "../../components/pages/ToastBox/ToastBoxMessageSlice";

const CustomerTax = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;

  const [taxesNames, setTaxNames] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");

  const customersAll = useSelector((state) => state.customer.allCustomers);
  const businessId = localStorage.getItem("business_id");

  // Fetch customers and save to Redux store
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${baseURL}Y3VzdG9tZXIvdmlldw==/${btoa(businessId)}`) //
      .then((response) => {
        const sortedCustomerData = response.data.customers_data.sort(
          (a, b) => b.customer_id - a.customer_id
        );
        dispatch(addCustomer(sortedCustomerData))
     
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
      });
  }, []);


  // Fetch all taxes initially
  useEffect(() => {
    const fetchBusinessDetails = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/${btoa("salestax/fetchallfuelbrandtaxes")}`
        );
        const enrichedData = response.data.map((item) => ({
          ...item,
          isChecked: false, // Initialize as unchecked
          disabled: !item.po_1006tabletax_name,
        }));
        setTaxNames(enrichedData);
      } catch (error) {
        console.error("Error fetching tax details", error);
      }
    };

    fetchBusinessDetails();
  }, [baseURL]);

  // Fetch customer-specific taxes when a customer is selected
  useEffect(() => {
    if (selectedCustomer) {
      const fetchCustomerTaxes = async () => {
        try {
          const businessId = localStorage.getItem("business_id");
          const response = await axios.get(
            `${baseURL}${btoa(
              "customersalestax/singlecustomeralltaxes"
            )}/${btoa(businessId)}/${btoa(selectedCustomer)}`
          );

          const customerTaxes =
            response.data.singleCustomeralltaxesData || [];
          const customerTaxIds = customerTaxes.map(
            (tax) => tax.po_customertax_name
          );

          setTaxNames((prevTaxes) =>
            prevTaxes.map((tax) => ({
              ...tax,
              isChecked: customerTaxIds.includes(tax.po_1006tabletax_name),
            }))
          );
        } catch (error) {
          console.error("Error fetching customer taxes:", error);
        }
      };

      fetchCustomerTaxes();
    } else {
      // Reset to all taxes unchecked when no customer is selected
      setTaxNames((prevTaxes) =>
        prevTaxes.map((tax) => ({ ...tax, isChecked: false }))
      );
    }
  }, [selectedCustomer, baseURL]);

  // Handle checkbox state changes
  const handleCheckboxChange = (id) => {
    setTaxNames((prev) =>
      prev.map((item) =>
        item.po_1006tabletax_name === id
          ? { ...item, isChecked: !item.isChecked }
          : item
      )
    );
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const selectedTaxes = taxesNames
      .filter((item) => item.isChecked)
      .map((item) => item.po_1006tabletax_name);

    const data = {
      businessId: localStorage.getItem("business_id"),
      customerId: selectedCustomer,
      loggedInUserId: localStorage.getItem("user_id"),
      customerTaxesSelected: selectedTaxes,
    };

    try {
      const response = await axios.post(
        `${baseURL}${btoa("customersalestax/createcustomertaxes")}`,
        data
      );
      if (response.data.success) {
        dispatch(
          addToast({ type: "success", message: "Tax Created Successfully!" })
        );
        navigate("/salesTaxCustomers");
      }
    } catch (error) {
      console.error("Error submitting customer taxes:", error);
      dispatch(
        addToast({ type: "danger", message: "Failed to Create Customer Taxes!" })
      );
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-lg font-semibold mb-4 mx-10">Sales &gt;&gt; Tax</h2>
      <div className="mb-6 mx-10">
        <label htmlFor="customer-select" className="block font-medium mb-2">
          Select Customer
        </label>
        <select
          id="customer-select"
          className="block w-30 rounded-md p-2"
          onChange={(e) => setSelectedCustomer(e.target.value)}
          value={selectedCustomer}
        >
          <option value="">Select Customer</option>
          {customersAll.map((customer) => (
            <option key={customer.customer_id} value={customer.customer_id}>
              {customer.business_name}
            </option>
          ))}
        </select>
      </div>
      <table className="w-70 mx-10">
        <thead className="bg-blue-100 border-blue-600 text-blue-600 rounded-lg">
          <tr>
            <th>#</th>
            <th className="px-4 py-2 text-left text-blue-600 w-[40%]">
              Tax Items from Branding PO's
            </th>
            <th className="px-4 py-2 text-center text-blue-600">
              Tax to Customer
            </th>
            <th className="px-4 py-2 text-left text-blue-600">Tax Name</th>
          </tr>
        </thead>
        <tbody>
          {taxesNames.map((item, index) => (
            <tr key={item.id}
            className={`even:bg-gray-100 odd:bg-white `}
            >
              <td>{index + 1}</td>
              <td className="px-4 py-1 text-left">{item.fuelbrand_taxes}</td>
              <td className="px-4 py-1 text-center">
                <input
                  type="checkbox"
                  checked={item.isChecked}
                  disabled={item.disabled} // Disable the checkbox if the `disabled` property is true
                  className={item.disabled ? 'cursor-not-allowed opacity-50 bg-gray-200' : ''}
                  onChange={() =>
                    handleCheckboxChange(item.po_1006tabletax_name)
                  }
                />
              </td>
              <td className="px-4 py-1 text-left">{item.customer_taxes}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-4 text-right mx-16">
        <button
          className={`px-4 py-2 rounded-md ${
            !selectedCustomer
              ? "bg-gray-300 text-black"
              : "bg-pink-500 text-white"
          }`}
          onClick={handleSubmit}
          disabled={!selectedCustomer}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default CustomerTax;
