import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function useHideUrl() {
  const location = useLocation();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if(localStorage.getItem('token')) {
  //   const savedRoute = localStorage.getItem('currentRoute');
  //   if (savedRoute && savedRoute !== location.pathname) {
  //     localStorage.removeItem('currentRoute'); // Clear to prevent loops
  //     navigate(savedRoute, { replace: true });
  //   }}
  // }, [location.pathname, navigate]);





  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     localStorage.setItem('currentRoute', location.pathname);
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, [location.pathname, navigate]);
  useEffect(() => {
    // Save the current route when the location changes
    localStorage.setItem('currentRoute', location.pathname);
    
  }, [location.pathname]);
  useEffect(() => {
    const savedRoute = localStorage.getItem('currentRoute');
    if (savedRoute && savedRoute !== location.pathname) {
      navigate(savedRoute, { replace: true });
    }
  }, [location.pathname, navigate]);
  

  return null;
}

export default useHideUrl;
