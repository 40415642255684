import React from "react";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  createColumnHelper
} from "@tanstack/react-table";
import './customerportal.css'
import config from "../../config/config";
import { FaEye, FaTrashAlt, FaDownload } from 'react-icons/fa';
import { useState, useMemo, useEffect, useRef } from 'react';
import CustomerPortalImage from './CustomerPortalImage';
import axios from "axios";
// import FullScreenLoader from "../pages/FullScreenLoader";
const CustomerPortalTable = ({customerResponse}) => {
  console.log('customerresponse', customerResponse)
  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;

const columnHelper = createColumnHelper();
const business_id = localStorage.getItem('business_id')
const [isModalOpen, setIsModalOpen] = useState(false);
const [pdfUrl, setPdfUrl] = useState(null);
const [invoicenumber , setinvoiceNumber] = useState(null)
// const [loading, setLoading] = useState(true);

const closeModal = () => {
  setIsModalOpen(false);
};
const formatDate = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-based
  const year = date.getFullYear().toString(); // Extract last two digits of year
  return `${month}-${day}-${year}`;
}


const handleDownloadInvoice = async ( row) => {
  console.log(row, 'row.............')
  const invoice_number = row?.original.invoice_number;
  const managerId = localStorage.getItem("user_id")
  const userRole = localStorage.getItem("user_role")
    // console.log("selected rrr" , selectedRows);
  try {
    const response = await axios.get(`${baseURL}/${btoa("invoice/downloadinvoice")}/${btoa(invoice_number)}/${btoa(business_id)}/${btoa(managerId)}/${btoa(userRole)}`, {
      responseType: 'blob',
      headers: {
        Accept: 'application/pdf', // Explicitly ask for a PDF
      },
    });
    console.log(response, 'download response')
    const filename = `${row?.original?.business_name}_${row?.original?.invoice_number}_${formatDate(new Date(row?.original.added_on))}.pdf`;

      //  const filename = `${customerResponse?.data?.customerName}_${customerResponse?.data?.invoice_data[0]?.invoice_number}_${formatDate(new Date(customerResponse?.data?.invoice_data[0]?.added_on))}.pdf`;
const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename; // Set the filename here
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // table.resetRowSelection();
    window.URL.revokeObjectURL(url);
    // console.log("Invoice downloaded successfully.");
  } catch (error) {
    console.log("Error downloading invoice:", error);
  }
  // finally{
  //   setLoading(false)
  // }
};

// const handleView = (row) => {
//   // console.log('View action for row:', row);
//   // console.log('Constructed Image Path:', imagePath);
//   // setSelectedImagePath(row)
//   setIsModalOpen(true)
// };


const handleView = async (invoice_number) => {
  const invoiceNumber = invoice_number;
  setinvoiceNumber(invoiceNumber)
      setIsModalOpen(true);


};
 const columnDef = [
  // columnHelper.accessor("id", {
  //   header: "Id",
  // }),
  {
    accessorFn: (row, i) => i + 1,
    header: 'Sl No',
    },
  {
    accessorKey: "invoice_number",
    header: "invoice_number",
  },

  {
    accessorKey: "delivery_date",
    header: "delivery_date",
    cell: (props) => {
      const date = new Date(props.row.original.due_date);
      const formattedDate = `${date.toLocaleDateString("en-US", { month: 'short' })}-${date.toLocaleDateString("en-US", { day: '2-digit' })}-${date.toLocaleDateString("en-US", { year: 'numeric' })}`;
      return (
        <div>{formattedDate}</div>
      );
    },
  },
  {
    accessorKey: "added_on",
    header: "invoice_date",
    cell: (props) => {
      const date = new Date(props.row.original.added_on);
      const options = { month: 'short', day: '2-digit', year: 'numeric' };
      const formattedDate = `${date.toLocaleDateString("en-US", { month: 'short' })}-${date.toLocaleDateString("en-US", { day: '2-digit' })}-${date.toLocaleDateString("en-US", { year: 'numeric' })}`;
      return (
        <div>{formattedDate}</div>
      );
    },
  },
  {
    accessorKey: "due_date",
    header: "Due_date",
    cell: (props) => {
      const date = new Date(props.row.original.due_date);
      const formattedDate = `${date.toLocaleDateString("en-US", { month: 'short' })}-${date.toLocaleDateString("en-US", { day: '2-digit' })}-${date.toLocaleDateString("en-US", { year: 'numeric' })}`;
      return (
        <div>{formattedDate}</div>
      );
    },
  },
  {
    accessorKey: "final_invoice_amount",
    header: "Invoice_amount",
    cell: (props) => (
      <div className="text-right">
        {
           Number(props.row.original.final_invoice_amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        }
      </div>
    ),
  },
  {
    accessorKey: "nys_prepaid",
    header: "PPT (Prepaid Tax)",
    cell: (props) => (
      <div className="text-right">
        {
           Number(props.row.original.nys_prepaid).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        }
      </div>
    ),
  },
  columnHelper.accessor('c', {
    header: <div className="text-center">Action</div>,
    cell: ({ row }) => {
      // const { invoice_number, id } = row.original;
      return (
        <div className="flex justify-center space-x-3"> 
           <button
              onClick={() => handleView(row.original.invoice_number)}
              className="text-gray-500 hover:text-blue-700 hover:bg-blue-200 p-2 rounded-full cursor-pointer"
            >
              <FaEye size={20} />
            </button>
            <button
              onClick={()=> handleDownloadInvoice( row)}
              className="text-gray-500 hover:text-blue-700 hover:bg-blue-200 p-2 rounded-full cursor-pointer"
            >
              <FaDownload size={20} />
            </button>
        </div>
      );
    },
    enableColumnFilter: false,
    enableSorting: false,
  })
];

const finalData = React.useMemo(() => {
  return customerResponse.data && customerResponse.data.invoice_data ? customerResponse.data.invoice_data : [];
}, [customerResponse]); 
 const finalColumnDef = React.useMemo(() => columnDef, []);

  const tableInstance = useReactTable({
    columns: finalColumnDef,
    data: finalData,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 25,  // Set the initial page size to 25
      },
    },
  });
console.log('tableInstance', tableInstance);
  const currentPage = tableInstance.options.state.pagination.pageIndex;
  const totalPages = tableInstance.getPageCount();
  
  // Determine start and end page for pagination display
  const startPage = Math.max(0, Math.min(currentPage - 1, totalPages - 3));
  const endPage = Math.min(startPage + 3, totalPages);

  //   console.log("test", tableInstance.getHeaderGroups());

  return (
    <>
      {/* <h4>
        Current page size: {tableInstance.options.state.pagination.pageSize}
      </h4> */}
  
 
  <div>
  {/* {loading && <FullScreenLoader/> } */}

<div>
  {customerResponse?.data?.invoice_data?.length >0 ?
  <table className="table-auto bg-transparent rounded-xl overflow-hidden">
  <thead className="bg-gray-500/20 text-gray-500">
    {tableInstance.getHeaderGroups().map((headerEl) => {
      return (
        <tr key={headerEl.id} className="text-center text-xs">
          {headerEl.headers.map((columnEl, index) => {
            const isFirstHeader = index === 0;
            const isLastHeader = index === headerEl.headers.length - 1;
            const headerClasses = [
              'p-3 font-bold uppercase',
              isFirstHeader ? 'rounded-l-xl' : '',
              isLastHeader ? 'rounded-r-xl' : '',
            ];

            return (
              <th
                key={columnEl.id}
                colSpan={columnEl.colSpan}
                className={headerClasses.join(' ')}
              >
                {columnEl.isPlaceholder ? null : flexRender(columnEl.column.columnDef.header, columnEl.getContext())}
              </th>
            );
          })}
        </tr>
      );
    })}
  </thead>
  <tbody>
    {tableInstance.getRowModel().rows.map((rowEl) => {
      return (
        <tr key={rowEl.id} className="cursor-pointer text-center hover:bg-gray-500/10">
          {rowEl.getVisibleCells().map((cellEl) => {
            return (
              <td key={cellEl.id} className="p-4 font-semibold text-[#081159] text-[14px]">
                {flexRender(cellEl.column.columnDef.cell, cellEl.getContext())}
              </td>
            );
          })}
        </tr>
      );
    })}
  </tbody>
</table>
:(
  <p style={{ color: 'red', fontSize: '18px', fontWeight: 'bold', textAlign: 'center', paddingTop:'20px', marginRight:'90px' }}>
  No Data Available
</p>)}
</div>
{customerResponse?.data?.invoice_data?.length > 25 && (
  <div style={paginationContainerStyle}>
    <div style={selectContainerStyle}>
      <label style={labelStyle}>Rows per page:</label>
      <select
        value={tableInstance.options.state.pagination.pageSize}
        onChange={(e) => tableInstance.setPageSize(Number(e.target.value))}
        style={selectStyle}
      >
        {[25, 50, 100].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            {pageSize}
          </option>
        ))}
      </select>
    </div>

    <div style={paginationControlsStyle}>
      {/* First Page Button */}
      <button
        onClick={() => tableInstance.setPageIndex(0)}
        disabled={!tableInstance.getCanPreviousPage()}
        style={buttonStyle(tableInstance.getCanPreviousPage())}
      >
        {"<<"}
      </button>

      <button
        onClick={() => tableInstance.previousPage()}
        disabled={!tableInstance.getCanPreviousPage()}
        style={buttonStyle(tableInstance.getCanPreviousPage())}
      >
        Previous
      </button>

      {/* Page Numbers */}
      <div style={pageNumbersStyle}>
        {Array.from({ length: endPage - startPage }, (_, i) => (
          <button
            key={startPage + i}
            onClick={() => tableInstance.setPageIndex(startPage + i)}
            style={pageButtonStyle(startPage + i, currentPage)}
          >
            {startPage + i + 1}
          </button>
        ))}
      </div>

      <button
        onClick={() => tableInstance.nextPage()}
        disabled={!tableInstance.getCanNextPage()}
        style={buttonStyle(tableInstance.getCanNextPage())}
      >
        Next
      </button>

      {/* Last Page Button */}
      <button
        onClick={() => tableInstance.setPageIndex(totalPages - 1)}
        disabled={!tableInstance.getCanNextPage()}
        style={buttonStyle(tableInstance.getCanNextPage())}
      >
        {">>"}
      </button>
    </div>
  </div>
)}

      </div>     
{/* <CustomerPortalImage isModalOpen={isModalOpen} closeModal={closeModal} pdfUrl={pdfUrl} /> */}
<CustomerPortalImage isModalOpen={isModalOpen} closeModal={closeModal} invoiceNumber={invoicenumber} />
 
    </>
  );
};

export default CustomerPortalTable;
const paginationContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px',
  backgroundColor: '#f9f9f9', // Light background
  borderRadius: '8px', // Rounded corners
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Subtle shadow
};

const selectContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px', // Space between label and dropdown
};

const labelStyle = {
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#333', // Dark text
};

const selectStyle = {
  padding: '5px 10px',
  borderRadius: '4px',
  width:'60px',
  border: '1px solid #ddd', // Light border
  cursor: 'pointer',
};

const paginationControlsStyle = {
  display: 'flex',
  alignItems: 'center',
  // gap: '8px', // Space between buttons
};

const buttonStyle = (isEnabled) => ({
  backgroundColor: '#FFFFFF',
  color: '#3479E0',
  border: 'none',
  borderRadius: '4px',
  padding: '8px 8px',
  cursor: isEnabled ? 'pointer' : 'not-allowed',
  opacity: isEnabled ? '1' : '0.6',
  transition: 'background-color 0.3s ease', // Transition for hover effect
  '&:hover': isEnabled ? { backgroundColor: '#245ea6' } : {},
});

// Page button styles based on active state
const pageButtonStyle = (index, currentIndex) => ({
  backgroundColor: index === currentIndex ? '#3479E0' : '#FFFFFF',
  color: index === currentIndex ? '#FFFFFF' : '#3479E0',
  border: '1px solid #3479E0',
  borderRadius: '50%',
  padding: '8px 16px',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease', // Transition for hover effect
  '&:hover': {
    backgroundColor: index === currentIndex ? '#245ea6' : '#f0f0f0', // Change color on hover
  },
});

const pageNumbersStyle = {
  display: 'flex',
  gap: '8px',
};