const config = {
  development: {
    baseURL: "http://localhost/InvoiceFlow/",

  },
  production: {
    baseURL: "https://invoicefile.com/InvoiceFlow/",

  },
};

export default config;