
import React, { useState } from 'react';
import { DatePicker, Space } from "antd";
import dayjs from 'dayjs';
import {  useEffect } from 'react';
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import ToastNotificationBox from "../../components/pages/ToastBox/ToastNotificationBox";

import bankImg from './../../../src/assets/images/bank.png';
import cardImg from './../../../src/assets/images/card.png';
import card1Img from './../../../src/assets/images/card1.png';
import cashImg from './../../../src/assets/images/cash.png';
import checkImg from './../../../src/assets/images/check.png';
import { useSearchParams, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useNavigate} from 'react-router-dom';
import config  from "../../config/config"
import axios from "axios";
import { useDispatch } from "react-redux";
import { addToast } from './ToastBox/ToastBoxMessageSlice';
import InvoiceView from '../forms/invoiceview';
import { FaEye, FaCheck, FaPaperPlane, FaTrash, FaArrowLeft, FaDownload , FaEdit} from "react-icons/fa";


function PayInvoice() {
      // const navigate = useNavigate()
        // const dispatch = useDispatch();
      
  
  const location = useLocation();
  const { state } = location;

  const customerName = state?.businessName;
  const finalInvoiceAmount = Number(state?.FinalInvoiceAmount);
  const invoicenumberforpayinvoice = state?.invoicenumber;
  // const triggerFetch = state?.triggerFetch;
  // const setTriggerFetch = state?.setTriggerFetch;

  const imageMap = {
    bank: bankImg,
    card: cardImg,
    card1: card1Img,
    cash: cashImg,
    check: checkImg,
  };
        const {invoicenumber} = useParams()
  
    const [invoiceDate1, setInvoiceDate1] = useState(dayjs());
    const [paymentType, setPaymentType] = useState('fullPaid');
  const [amount, setAmount] = useState(finalInvoiceAmount);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [comment, setComment] = useState('');
  const [remainingAmount, setRemainingAmount] = useState(null);
 
const handleAmount =(e)=> {
  const inputValue = e.target.value;

  const parsedFinalInvoiceAmount = parseFloat(finalInvoiceAmount);
  const parsedAmount = inputValue === "" ? 0 : parseFloat(inputValue);
  setAmount(e.target.value);
  if (paymentType === 'fullPaid') {
    setAmount(''); // Full invoice amount
    setRemainingAmount(null);
  } else if (paymentType === 'partialPaid') {
    setRemainingAmount(parsedFinalInvoiceAmount - parsedAmount);
  }
}
  const handlePaymentTypeChange = (e) => {
    const inputValue = e.target.value;

    const parsedFinalInvoiceAmount = parseFloat(finalInvoiceAmount);
    const parsedAmount = inputValue === "" ? 0 : parseFloat(inputValue);
    setPaymentType(e.target.value);
    if (paymentType === 'fullPaid') {
      setAmount(''); // Full invoice amount
      setRemainingAmount(null);

    } else if (paymentType === 'partialPaid') {
      setAmount(inputValue); // Set readonly amount
      setRemainingAmount(parsedFinalInvoiceAmount - parsedAmount);

    }
  };

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
  };
  const handleInvoiceDateChange1 = (date) => {
    setInvoiceDate1(date);
    // setFormField({
    //   ...formField,
    //   invoiceDate: date,
    // });
  };
  // const disablefutureDates = (current) => {
  //   return current && current > dayjs().startOf('day');
  // };
  // const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;

  // const userRole = localStorage.getItem("user_role");
  const [isClicked, setIsClicked] = useState(false)
const handleSubmit = async (e) => {
  e.preventDefault()
  if (isClicked) return;
    const url = `${baseURL}/${btoa("invoice/customerpayment")}`;
    console.log('Request URL:', url);
    setIsClicked(true)

    try {
      const formattedReceivedAmount = paymentType === 'fullPaid' ? parseFloat(finalInvoiceAmount.toFixed(2)) : Number(amount || 0).toFixed(2);
      const formattedDueAmount = paymentType === 'fullPaid' ? 0 : Number(remainingAmount || 0).toFixed(2);
  
      const response = await axios.post(url, {
          custName: customerName,
          businessId: localStorage.getItem('business_id'),
          managerId: localStorage.getItem('user_id'),
          invoiceNumber: invoicenumberforpayinvoice,
          finalInvoiceAmount:parseFloat(finalInvoiceAmount.toFixed(2)), // ensure it's a number
          payType: paymentType,
          receivedAmount: Number(formattedReceivedAmount),
          dueAmount: Number(formattedDueAmount), // convert to a number for backend
          comment: paymentType === 'fullPaid' ? comment : comment || null,
          userRole:userRole,
          paymentDate:dayjs(invoiceDate1).format('YYYY-MM-DD')
      });
      
      console.log('Payment modal response:', response.data);

      if (response.data.dataSavingStatus === true) {
          dispatch(addToast({ 
              type: 'success', 
              message: paymentType === "fullPaid" 
                  ? `${customerName} Paid Full Amount successfully` 
                  : `${customerName} Paid Partial Amount successfully` 
          }));
  
          // handleClose();
          // resetRowSelection();
          // setTriggerFetch(!triggerFetch);
      }
  }
  catch (error) {
        if (error.response) {
            console.log("Error response data:", error.response.data);
            console.log("Error response status:", error.response.status);
            console.log("Error response headers:", error.response.headers);
        } else if (error.request) {
            console.log("Error request:", error.request);
        } else {
            console.log("Error message:", error.message);
        }
    }
finally {
  setIsClicked(false)
}
    console.log(`Payment type: ${paymentType}`);
    if (paymentType === 'partialPaid') {
        console.log(`Partial Amount Paid: ${amount}`);
        console.log(`Remaining Amount: ${remainingAmount}`);
    }
    // handleClose();
    navigate('/payIns')

};

// useEffect(() => {
//   if (location.pathname.includes('PayInvoice')) {
//     // Trigger the API call or any function you need
//     console.log('Path includes payinvoice. Triggering API...');
//     fetchDataByInvoice(); // Replace this with your API call function
//   }
// }, [location.pathname]);
const dispatch = useDispatch()
// const location = useLocation();

    // const {invoicenumber} = useParams()
    // const { state } = location;
    // useEffect(()=>{
    //   const status = state?.status; // Use optional chaining to avoid undefined errors

    // },[])
    // const businessName = state?.business_name ? state?.business_name : ''
    const final_invoice_amount = state?.final_invoice_amount ? state?.final_invoice_amount : 0
    console.log(final_invoice_amount, 'final_invoice_amount');
    const dueDateinvoice = state?.dueDate
    // console.log(status, 'statusstatus')

    console.log(invoicenumber, localStorage.getItem('customerId'))

  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const [showDescription, setShowDescription] = useState(false);
    const [payModalInvoice, setpayModalInvoice] = useState(false)
    const handlePayModalInvoice = ()=>{

      setpayModalInvoice(true)
    }
  
    const [customerEmail , setCustomerEmail] = useState('')
    
    const [FinalInvoiceAmount , setFinalInvoiceAmount] = useState('')

    const [businessName , setBusinessName] = useState('')

      const [triggerFetch, setTriggerFetch] = useState(false);
    
  
  const [openSendInvoiceModalId, setOpenSendInvoiceModalId] = useState(null);
  
  const [quantityErrors, setQuantityErrors] = React.useState({});
  const [discount, setDiscount] = useState(0);
  const [waterBill, setwaterBill] = useState(0);
  const [Rebates, setRebates] = useState(0);
  const [freightsurcharge, setfreightsurcharge] = useState(0);
  const [freightCharges, setfreightCharges] = useState(0);
  const [brandInvoiceNumber, setBrandInvoiceNumber] = useState('');
  const [invoiceFuelData, setInvoiceFuelData] = useState([]);
  console.log(invoiceFuelData, 'invoicefueldata')
  const [InvoiceCustomerData, setInvoiceCustomerData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(''); // Selected product
  const [selectedDiscountType, setSelectedDiscountType] = useState(''); // Type of discount (amount, %, gallons)
  const [isSubtotalExpanded, setSubtotalExpanded] = useState(false);
  const [isTaxesExpanded, setTaxesExpanded] = useState(false);
  
  const [productSubTotal, setProductSubTotal] = useState(0);
  const [CustomerData, setCustomerData] = useState([])
  
  const [BillShip, setBillShip] = useState([])
  
  const [CustomerInfo, setCustomerInfo] = useState([])

  const toggleSubtotal = (e) => {e.preventDefault(); setSubtotalExpanded(!isSubtotalExpanded)};
  const toggleTaxes = (e) => {e.preventDefault(); setTaxesExpanded(!isTaxesExpanded)};
  // const handleDeleteInvoices = async () => {
  //   if (selectedRows.length > 0) {

  //   } 
  // };
  const [status, setStatus] = useState('')
 

  const fetchDataByInvoice = async () => {
    const customerId = localStorage.getItem('customerId' )
  
    try {
      const apiUrl = `${baseURL}/${btoa("invoice/fetchinvoicebyinvooiocenumber")}/${btoa(invoicenumberforpayinvoice)}/${btoa(businessId)}/${btoa(customerId)}`;
      const response = await axios.get(apiUrl);
      console.log("invoice on on invoice number: " , response);
      setCustomerEmail(response.data.customerData[0].contact_email)
      
      setBusinessName(response.data.customerData[0].business_name)
      setFinalInvoiceAmount(response.data.customerData[0].final_invoice_amount)
       setStatus(response.data.customerData[0].invoice_status)
        appendCustomRows(response);
        setCustomerData(response.data.businessData[0])
        setBillShip(response.data.customerData[0])
        setTaxesView(response.data.customersTaxesAndValuesAndResult)
        setCustomerInfo(response.data.customerInfo[0])
        setTermsAndConditions(response.data.customerInfo[0].terms_conditions)
    } catch (error) {
      console.error("Error fetching invoice by invoice number", error);
    }
  };
  const appendCustomRows = (response) => {
    const invoiceItems = response.data.invoiceItemsData;
  
    // Start index after the existing items
    let currentIndex = invoiceItems.length + 1;
  
    const customRows = [
      {
        product_name: "Freight Charges",
        description: response.data.customerData[0]?.freight_charge_descr,
        item_price: response.data.customerData[0]?.freight_charge,
        unit_total: response.data.customerData[0]?.freight_charge,
        isCustomRow: true,
        isAdded: true
      },
      {
        product_name: "Freight Surcharge",
        description: response.data.customerData[0]?.freight_surcharge_descr,
        item_price: response.data.customerData[0]?.freight_surcharge,
        unit_total: response.data.customerData[0]?.freight_surcharge,
        isCustomRow: true,
        isAdded: true
      },
      {
        product_name: "Discount",
        description: response.data.customerData[0]?.discounts_type,
        item_quantity: response.data.customerData[0]?.discount_gallons,
        item_price: response.data.customerData[0]?.discount_price || response.data.customerData[0]?.discounts_amount,
        unit_total: response.data.customerData[0]?.discounts_amount,
        isCustomRow: true,
        isAdded: false
      },
      {
        product_name: "Rebates/Credits",
        description: response.data.customerData[0]?.rebatescredits_description,
        item_price: response.data.customerData[0]?.rebatescredits_amount,
        unit_total: response.data.customerData[0]?.rebatescredits_amount,
        isCustomRow: true
      },
      {
        product_name: "Water Bill",
        description: response.data.customerData[0]?.waterbill_description,
        item_price: response.data.customerData[0]?.waterbill_amount,
        unit_total: response.data.customerData[0]?.waterbill_amount,
        isCustomRow: true,
        isAdded: true
      }
    ];
   

    // Filter and assign indices dynamically
    const validCustomRows = customRows
      .filter(row => parseFloat(row.item_price) > 0) // Only include valid rows
      .map((row) => ({
        ...row,
        index: currentIndex++ // Assign index and increment
      }));
      console.log(validCustomRows, 'validCustomRows')
  
    // Combine original invoice items and valid custom rows
    const updatedItems = [...invoiceItems, ...validCustomRows];
  
    // Update the state
    setItems(updatedItems);
  };
  

const calculateSubtotal = (subTotal, customRows) => {
  const additionalCharges = customRows.reduce((total, row) => {
      if (row.isCustomRow && row.item_price && parseFloat(row.item_price) > 0) {
          if (row.product_name === "Discount" || row.product_name === "Rebates/Credits") {
              return total - parseFloat(row.item_price); // Subtract discounts or rebates
          }
          return total + parseFloat(row.item_price); // Add charges like freight
      }
      return total;
  }, 0);

  return (parseFloat(subTotal) + additionalCharges).toFixed(2);
};
const calculateInvoiceAmount = (subTotal, customRows, taxes) => {
const additionalCharges = customRows.reduce((total, row) => {
    if (row.isCustomRow && row.item_price && parseFloat(row.item_price) > 0) {
        if (row.product_name === "Discount" || row.product_name === "Rebates/Credits") {
            return total - parseFloat(row.item_price); // Subtract discounts or rebates
        }
        return total + parseFloat(row.item_price); // Add charges like freight
    }
    return total;
}, 0);

return (parseFloat(subTotal) + additionalCharges + parseFloat(taxes)).toFixed(2);
};


  useEffect(()=>{
    const fetchDataByInvoice = async () => {
      const customerId = localStorage.getItem('customerId' )

      try {
        const apiUrl = `${baseURL}/${btoa("invoice/fetchinvoicebyinvooiocenumber")}/${btoa(invoicenumberforpayinvoice)}/${btoa(businessId)}/${btoa(customerId)}`;
        const response = await axios.get(apiUrl);
        console.log("invoice on on invoice number: " , response);
        setCustomerEmail(response.data.customerData[0].contact_email)
        setFinalInvoiceAmount(response.data.customerData[0].final_invoice_amount)
        setBusinessName(response.data.customerData[0].business_name)
        setStatus(response.data.customerData[0].invoice_status)

          appendCustomRows(response);
          setCustomerData(response.data.businessData[0])
          setBillShip(response.data.customerData[0])
          setCustomerInfo(response.data.customerInfo[0])

          setTaxesView(response.data.customersTaxesAndValuesAndResult)
      } catch (error) {
        console.error("Error fetching invoice by invoice number", error);
      }
    };
    fetchDataByInvoice()
  },[])



  
  const addRow = (product) => {

    setDiscountRebateError('')
    setSelectedDiscountType('')
    if (!product) return; 
  
    // Check for conflicting conditions: Discount and Rebate
    const discountExists = InvoiceCustomerData.some(item => item.isDiscount);
    const rebateExists = InvoiceCustomerData.some(item => item.product_name.toLowerCase() === "rebates/credits");
  
    if (product.toLowerCase() === "discount" && rebateExists) {
      setDiscountRebateError("Both discount and rebate cannot be added at the same time.");
      return; // Prevent adding the discount if rebate exists
    }
  
    if (product.toLowerCase() === "rebates/credits" && discountExists) {
      setDiscountRebateError("Both discount and rebate cannot be added at the same time.");
      return; // Prevent adding the rebate if discount exists
    }
  
    let newRow;
  
    if (product.toLowerCase() === "miscellaneous") {
      const miscCount = InvoiceCustomerData.filter((item) =>
        item.product_name.toLowerCase().includes("miscellaneous")
      ).length;
  
      const miscProduct = `Miscellaneous${miscCount + 1}`;
      newRow = {
        product_id: InvoiceCustomerData.length + 1,
        product_name: miscProduct,
        quantity: "",
        unitPrice: "",
        total: 0.0,
        description: "",
        isCustomRow: true,
        isDiscount: product.toLowerCase() === 'discount',
        isFreight: product.toLowerCase() === 'freight charges' || product.toLowerCase() === 'freight surcharge',
        isAddedRow: true, // Mark the row as added
        displayPercentage: product.toLowerCase() === 'discount' 
        ? (DiscountedPercentage ? `${DiscountedPercentage}` :"") 
        : "", 
      
      };
  
      const updatedProducts = remainingProducts.filter(
        (item) => item.toLowerCase() !== "miscellaneous" || miscCount < 4
      );
      setRemainingProducts(updatedProducts);
    } else {
      newRow = {
        product_id: InvoiceCustomerData.length + 1,
        product_name: product,
        quantity: "",
        unitPrice: "",
        total: 0.0,
        description: "",
        isCustomRow: true,
        isDiscount: product.toLowerCase() === 'discount',
        isFreight: product.toLowerCase() === 'freight charges' || product.toLowerCase() === 'freight surcharge',
        isAddedRow: true, // Mark the row as added
        displayPercentage: product.toLowerCase() === 'discount' 
        ? (DiscountedPercentage ? `${DiscountedPercentage}` : "") 
        : "", // Set displayPercentage for discount
      
      };
  
      const updatedProducts = remainingProducts.filter((item) => item !== product);
      setRemainingProducts(updatedProducts);
    }
  
    // Add the new row to the invoice data
    setInvoiceCustomerData([...InvoiceCustomerData, newRow]);
  
    setSelectedProduct("");
    setShowDescription(true); // Show description column when a product is added
  };
  

  const handleDiscountTypeChange = (e, index) => {
    
   
    const newDiscountType = e.target.value;
    setSelectedDiscountType(newDiscountType);
  

    const updatedData = [...InvoiceCustomerData];
    if (newDiscountType === "") {
      updatedData[index].quantity = 0;
      updatedData[index].unitPrice = 0;
      updatedData[index].total = 0;
      setDiscount(0);
    } else {
      updatedData[index].quantity = 0;
      updatedData[index].unitPrice = 0;

      updatedData[index].total = 0;
      setDiscount(0);

    }
    updatedData[index].description = newDiscountType; // Set discount type in description
    setInvoiceCustomerData(updatedData);

  };

  const [loading, setLoading] = useState(true);
  
  const [TaxesView, setTaxesView] = useState([]);

const [items, setItems] = useState([
    { 
      productId : "",
      itemId : "",
      item: "",
      description : "",
      quantity: "",
      unitPrice: "",
      total: 0,
    },
  ]);
  console.log(items, 'items')
  const [DiscountRebateError, setDiscountRebateError] = useState('');

  const [latestPurchaseInvoices, setLatestPurchaseInvoices] = useState(null);

  const [customerIdInvoice, setCustomerIdInvoice] = useState('');

  const today = new Date().toISOString().split("T")[0];
  const [sellerNames , setSellerNames] = useState([])

  const [tax, setTax] = useState("");
  const [feesOrDiscounts, setFeesOrDiscounts] = useState("");

  const [termsAndConditions, setTermsAndConditions] = useState(""); 

  const [latestInvoiceNumber , setLatestInvoiceNumber] = useState("");

  const [dueDate, setDueDate] = useState("");

  const [createdDate , setCreatedOnDate ] = useState(""); 
  const [invoiceDate , setInvoiceDate ] = useState(dayjs()); 

  const businessId = localStorage.getItem("business_id")

  
  const [customerOptions , setCustomerOptions] = useState([])
  const [taxTotal ,setTaxTotal] = useState("");
 
  const navigate = useNavigate()
  const [imageSource, setImageSource] = useState();
  const [imageResponse, setimageResponse] = useState([]);
  
  const [products, setProducts] = useState([
    { 
      product:"",
      productCode : "",
      quantity: "",
      unitPrice: "",
      total: 0,
      editableDescription: false,
    
    },
  ]);

  // console.log("products", products);

  const [formField, setFormField] = useState({
    invoice_number:  "",
    bill_from: "",
    bill_to: "",
    vehicleNumber : "",
    sellersId :"",
    originTerminal : "",
    destinationTerminal  : "",
    created_on: today,
    due_on: today,
    invoiceDate: today
  });

  const isFormComplete = () => {
    // Check if customer information is filled
    const isCustomerInfoComplete =
      formField.bill_to &&
      createdDate &&
      dueDate &&
      invoiceDate;
  
    const areProductsComplete =
      InvoiceCustomerData.length > 0 &&
      InvoiceCustomerData.some(
        (product) =>
          !product.isCustomRow && // Exclude custom rows
          product.quantity > 0 
      );
  
    const isPOSelected = Boolean(brandInvoiceNumber);
  
    return isCustomerInfoComplete && areProductsComplete && isPOSelected;
  };
  const existingRows = InvoiceCustomerData.filter((row) => !row.isAddedRow);
  const totalQuantity = items.reduce((acc, product) => {
    if (product.product_name !== "" && product.item_quantity !== 0 ) {
      return acc + parseFloat(product.item_quantity || 0);
    }
    return acc; // If the condition is not met, return the current accumulator
  }, 0); // Initial accumulator value
  console.log(totalQuantity, InvoiceCustomerData,items,  'total quantity');
  const subTotal = items.filter((row) => !row.isCustomRow)
  .reduce((acc, product) => acc + parseFloat(product.unit_total || 0), 0);
  //  console.log(subTotal, 'subtotal checking')
  const grandTotal = parseFloat(taxTotal) + parseFloat(productSubTotal);
  const [customerIndividualData, setCustomerIndividualData] = useState(grandTotal);
  const handleChange = (event) => {
    setfreightCharges(0)
    setfreightsurcharge(0)
    setDiscount(0)
    setwaterBill(0)
    setRebates(0)
    setLatestPurchaseInvoices(null)

    setRemainingProducts(availableProducts)
      setCustomerIdInvoice(event.target.value)
    console.log(event.target.value)
    if (event instanceof Date) {
      // setCreatedOnDate(event);
    } else {
      setInvoiceFuelData([])

      const { name, value } = event.target;
      setFormField({
        ...formField,
        [name]: value,
      });
    }
  };

  const handleDeliveryDateChange = (date) => {
    console.log(date, "date....")
    setCreatedOnDate(date);
    setFormField({
      ...formField,
      created_on: date,
    });
  };
  
  const handleInvoiceDateChange = (date) => {
    setInvoiceDate(date);
    setFormField({
      ...formField,
      invoiceDate: date,
    });
  };
  const handleDueDateChange = (date) => {
    setDueDate(date);
    setFormField({
      ...formField,
      due_on: date,
    });
  };
    
  const business_id = localStorage.getItem("business_id")
  const user_id = localStorage.getItem("user_id")
  const user_role = localStorage.getItem("user_role")

  const handleProductChange = (index, field, value) => {      
    const updatedData = [...InvoiceCustomerData];
 
    updatedData[index][field] = value; 
    const quantityInput = document.querySelector(`#quantity-input-${index}`);
  
    // Skip calculation if the quantity input is disabled
    if (quantityInput && quantityInput.disabled) {
      setInvoiceCustomerData(updatedData);
      return;
    }
  
    if (field === "description") {
      setInvoiceCustomerData(updatedData);
      return;
    }
    // Parse quantity and unitPrice as numbers and ensure they are valid
    const quantity = isNaN(parseFloat(updatedData[index]?.quantity)) ? 0 : parseFloat(updatedData[index]?.quantity);
    const unitPrice = isNaN(parseFloat(updatedData[index]?.unitPrice)) || parseFloat(updatedData[index]?.unitPrice) === "" ? 2.00 : parseFloat(updatedData[index]?.unitPrice);
  
    // const quantity = isNaN(parseFloat(updatedData[index]?.quantity)) || updatedData[index]?.quantity === "" ? 0 : parseFloat(updatedData[index]?.quantity);
    // const unitPrice = isNaN(parseFloat(updatedData[index]?.unitPrice)) || updatedData[index]?.unitPrice === "" ? 0 : parseFloat(updatedData[index]?.unitPrice);
  

    // Calculate the total (ensure valid numbers)
    const total = isNaN(parseFloat(quantity * unitPrice)) ? 0: parseFloat(quantity * unitPrice);
      
    updatedData[index].total = total;

    // Calculate the product sub-total (exclude invalid values)
    const productSubTotal = updatedData
      .filter((item) => item.product_name !== "Discount" && !item.isCustomRow)
      .reduce((acc, item) => acc + (isNaN(item.total) ? 0 : Number(item.total)), 0);
    
    setProductSubTotal(Number(productSubTotal));
    // Recalculate the newSubtotal with validation
    const newSubtotal = updatedData.reduce((acc, item) => acc + (isNaN(item.total) ? 0 : item.total), 0);
  
    updatedData.forEach((item) => {
      if (item.product_name === "Discount") {
        if (selectedDiscountType === "amount") {
          item.total = item.unitPrice; // Fixed amount
          setDiscount(Number(item.total));
        } else if (selectedDiscountType === "percentage") {
          const discountAmount = item.unitPrice; // Fixed amount entered
          const percentage = productSubTotal ? (discountAmount / productSubTotal) * 100 : 0;
          const formattedPercentage = percentage % 1 === 0 ? percentage : percentage.toFixed(2); // Apply toFixed(2) only if it's a decimal

          setDiscountedPercentage(formattedPercentage); // Set the percentage value
          item.total = discountAmount; // Update total for discount
          setDiscount(Number(item.total));
        } else if (selectedDiscountType === "gallons") {
          item.total = item.quantity * item.unitPrice; // Gallons
          setDiscount(Number(item.total));
        }
      }
    });
    
  
    if (updatedData[index]?.product_name === "Freight Charges") {
      let freightcharges = updatedData[index]?.unitPrice;
      setfreightCharges(freightcharges);
      updatedData[index].total = freightcharges;
    } 
  
    if (updatedData[index]?.product_name === "Freight Surcharge") {
      let freightsurcharge = updatedData[index]?.unitPrice;
      setfreightsurcharge(freightsurcharge);
      updatedData[index].total = freightsurcharge;
    } 
  
    if (updatedData[index]?.product_name === "Water Bill") {
      let waterbill = updatedData[index]?.unitPrice;
      setwaterBill(waterbill);
      updatedData[index].total = waterbill;
    }
  
    if (updatedData[index]?.product_name === "Rebates/Credits") {
      let Rebates = updatedData[index]?.unitPrice;
      setRebates(Rebates);
      updatedData[index].total = Rebates;
    }
  
    // Update the state
    setInvoiceCustomerData(updatedData);
  
    // Debugging log
    console.log("Updated Row:", {
      productName: updatedData[index]?.product_name || "N/A",
      quantity,
      unitPrice,
      total,
    });
  
    const newTotal = newSubtotal + newSubtotal * (tax / 100) + feesOrDiscounts;
    setProducts(updatedData);
  };
  
  const customerId = customerIdInvoice
    const [TaxDataForInvoice , setTaxDataForInvoice] = useState([])
    
    const [DiscountedPercentage , setDiscountedPercentage] = useState('')

  const [businessData , setBusinessData] = useState([])   
  console.log('TaxNamesForInvoice', TaxDataForInvoice)
  const taxTotal1 = TaxesView && TaxesView.reduce((acc, item) => {
    // Access the tax value using the dynamic key from po_customertax_name
    const taxValue = parseFloat(item[item.po_customertax_name]* totalQuantity) || 0; 
    return acc + taxValue; // Accumulate the tax value
  }, 0);

 const [MinLengthError,setMinLengthError] = useState('')
 const handleDelete = (productId, e) => {
  setSelectedProduct('');

  e.preventDefault();

  // Ensure that we don't delete the last item
  if (InvoiceCustomerData.length === 1) {
    setMinLengthError("You cannot delete the last product.");
    return; // Exit the function to prevent deletion
  }

  // Find the product to be deleted
  const deletedProduct = InvoiceCustomerData.find(
    (row) => row.product_name === productId
  );

  // If the product to delete exists, proceed
  if (deletedProduct) {
    const updatedData = InvoiceCustomerData.filter(
      (row) => row.product_name !== productId
    );

    // Set the updated data back to the state
    setInvoiceCustomerData(updatedData);

    // Recalculate product subTotal after deletion
    
    const productSubTotal = updatedData
      .filter((row) => row.product_name !== "Discount") // Exclude "Discount" from the subtotal
      .reduce((acc, row) => acc + (isNaN(row.total) ? 0 : Number(row.total)), 0);
    setProductSubTotal(productSubTotal);

    // Update remaining products for the dropdown (only if the deleted product was custom row)
    if (deletedProduct?.isCustomRow) {
      setRemainingProducts((prev) => {
        let updatedProducts = [...prev];

        // Handle "Miscellaneous" logic
        if (
          deletedProduct.product_name.startsWith("Miscellaneous") &&
          !prev.some((product) => product === "Miscellaneous")
        ) {
          // Add "Miscellaneous" back to the dropdown only if no "Miscellaneous" is present
          updatedProducts.push("Miscellaneous");
        } else if (!deletedProduct.product_name.startsWith("Miscellaneous")) {
          // Add non-Miscellaneous custom rows back to the dropdown
          updatedProducts.push(deletedProduct.product_name);
        }

        // Sort the dropdown based on the availableProducts order
        return updatedProducts.sort(
          (a, b) => availableProducts.indexOf(a) - availableProducts.indexOf(b)
        );
      });
    }

    // Check if there are any rows with "isCustomRow" and update visibility
    const hasCustomRows = updatedData.some((row) => row.isCustomRow);
    setShowDescription(hasCustomRows);

    // If the deleted product was a special one, reset the corresponding value
    if (deletedProduct?.product_name === "Discount") {
      setDiscount(0);
    }
    if (deletedProduct?.product_name === "Freight Charges") {
      setfreightCharges(0);
    }
    if (deletedProduct?.product_name === "Freight Surcharge") {
      setfreightsurcharge(0);
    }
    if (deletedProduct?.product_name === "Water Bill") {
      setwaterBill(0);
    }
    if (deletedProduct?.product_name === "Rebates/Credits") {
      setRebates(0);
    }
  }
};


    
    const formatNumberWithCommas = (value) => {
      if (!value || isNaN(value)) return ""; // Handle invalid or empty values
      return parseFloat(value).toLocaleString("en-US");
    };
    
    const disablefutureDates = (current) => {
      return current && current > dayjs().startOf('day');
    };

    const availableProducts = 
    [
      "Freight Charges",
      "Freight Surcharge",
      "Water Bill",
      "Discount",
      "Rebates/Credits",
      "Miscellaneous",
    ]    

    const [remainingProducts, setRemainingProducts] = useState(availableProducts); 
 
    const [activeItem, setActiveItem] = useState("View");

    const handleMenuClick = (item) => {
      setActiveItem(item);
    };
    const userRole = localStorage.getItem("user_role")
    const managerId = localStorage.getItem("user_id")
    function replaceBeforeHyphen(input) {
      return input.replace(/^.*?(?=-)/, '');
    }

    const handleApproveInvoice = async (e) => {
      e.preventDefault();
      const invoiceNumbers = invoicenumber;
      // console.log(invoiceNumbers)
      try {
        const response = await axios.get(`${baseURL}${btoa("invoice/approveinvoice")}/${btoa(invoiceNumbers)}/${btoa(managerId)}/${btoa(businessId)}/${btoa(userRole)}`);
        if(response.data) 
          // showToastForUpdateInvoice(`${businessName} Approved successfully` , "success")
        dispatch(addToast({ type: 'approve', message: `${businessName} Approved successfully`  }));
  
        // console.log("approved status :", response.data);
        // table.resetRowSelection();
        // setTriggerFetch(!triggerFetch); // Toggle the triggerFetch state
        navigate('/invoice')
      } catch (error) {
        console.log("error details ", error);
      }
    }
      const [openDeleteModalId, setOpenDeleteModalId] = useState(null);

    const handleDeleteModalInvoice = (invoiceId) => {
      // alert(selectedRows.invoice_number)
        // console.log("sel" , selectedRows);

        setOpenDeleteModalId(invoicenumber);

    };
    function formatDate(date) {
      const day = date.getDate();
      const month = date.getMonth() + 1; // Months are zero-based
      const year = date.getFullYear().toString(); // Extract last two digits of year
      return `${month}-${day}-${year}`;
    }
    const handleDownloadInvoice = async () => {
      const invoiceNumbers = invoicenumber;
        // console.log("selected rrr" , selectedRows);
      try {
        const response = await axios.get(`${baseURL}/${btoa("invoice/downloadinvoice")}/${btoa(invoiceNumbers)}/${btoa(business_id)}/${btoa(managerId)}/${btoa(userRole)}`, {
          responseType: 'blob',
        });
        const filename = `${businessName}_${invoicenumber}_${formatDate(new Date())}.pdf`;
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename; // Set the filename here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // table.resetRowSelection();
        window.URL.revokeObjectURL(url);
  
             dispatch(addToast({ type: 'success', message: `Invoice for ${businessName} downloaded successfully`  }));
       
        // console.log("Invoice downloaded successfully.");
      } catch (error) {
        console.log("Error downloading invoice:", error);
      }
    };
    const handletheEditInvoice =(invoiceNumbers, customeridforInvoice)=>{
      navigate(`/editInvoice/${invoiceNumbers}`)
      // fetchDataByInvoice(customeridforInvoice)
      localStorage.setItem('customerId',customeridforInvoice )
  }

  return  (
    <div className="flex flex-col justify-center items-start mt-4">
      <div className="w-[880px] mx-auto p-6 border border-gray-200 rounded-md shadow-md"
       style={{fontStyle:'popins', fontSize:'16px', fontWeight:'bold'}}>
        <div className='mx-[180px]'>
        <h2 className="text-xl font-bold text-center mb-8 border-b border-gray-200 ">Record a payment for this invoice: {invoicenumberforpayinvoice}</h2>

        <div className="flex flex-col gap-6">
          {/* Date Field */}
          <div className="flex items-center justify-between">
            <div  className="block text-sm font-medium text-gray-700 w-[160px]">Date</div>
            <Space direction="vertical" className="w-full">
              <DatePicker
                id="invoiceDate"
                disabledDate={disablefutureDates}
                format="MM-DD-YYYY"
                className="w-full h-[40px] rounded-md border border-gray-300"
                value={invoiceDate}
                onChange={handleInvoiceDateChange}
                placeholder="MM-DD-YYYY"
              />
            </Space>
          </div>

          {/* Payment Type */}
          <div className="flex flex-row items-center justify-between space-x-5 h-[30px]">
            <div className='w-[-10px]'></div>
  <div className="flex items-center h-[16px]" >
    <input
      id="fullPaid"
      type="radio"
      name="paymentType"
      value="fullPaid"
      checked={paymentType === 'fullPaid'}
      onChange={handlePaymentTypeChange}
      className="mr-2 h-6 w-6 accent-pink-500"
    />
    <label htmlFor="fullPaid" className="text-sm text-gray-700 mt-2">
      FULL PAY
    </label>
  </div>
  <div className="flex items-center h-[16px]">
    <input
      id="partialPaid"
      type="radio"
      name="paymentType"
      value="partialPaid"
      checked={paymentType === 'partialPaid'}
      onChange={handlePaymentTypeChange}
      className="mr-2 h-6 w-6 accent-pink-500"
    />
    <label htmlFor="partialPaid" className="text-sm text-gray-700 mt-2">
      PARTIAL PAY
    </label>
  </div>
</div>
          {/* Amount */}
          <div className="flex items-center justify-between">
            <div className="block text-sm font-medium text-gray-700 w-[160px]">Amount</div>
            {paymentType === 'partialPaid' ? (
              <div className="relative w-full flex">
                <div className="absolute left-0 top-0 h-full flex items-center bg-gray-200 px-2 rounded-l-md">
                  <span className="text-gray-600 w-[150px]">${finalInvoiceAmount}</span>
                </div>
                <input
                  type="number"
                  id="amount"
                  name="amount"
                  placeholder="Enter amount"
                  value={amount}
                  onChange={(e)=> handleAmount(e)}
                  className=" w-full pl-[11rem] rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
            ) : (
              <input
                type="text"
                id="amount"
                name="amount"
                value={`$${finalInvoiceAmount}`}
                readOnly
                className="bg-gray-200 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            )}
          </div>

          {/* Payment Method */}
          <div className="flex items-center justify-start">
            <div className="block text-sm font-medium text-gray-700 w-[120px]"> Method</div>
            <div className="mt-2 flex items-center gap-4">
              {['bank', 'card','card1' , 'cash','check'].map((method) => (
                <img
                  key={method}
                  src={imageMap[method]}

                  alt={method}
                  className={`w-[52px] cursor-pointer ${paymentMethod === method ? 'border border-indigo-500' : ''}`}
                  onClick={() => handlePaymentMethodChange(method)}
                />
              ))}
            </div>
          </div>

          {/* Bank Details */}
          {paymentMethod === 'bank' && (
            <div className="flex flex-col gap-4">
              <div className='flex items-center justify-between'>
                <div htmlFor="bankRouting" className="block text-sm font-medium text-gray-700 w-[160px]">Bank Routing #</div>
                <input
                  type="text"
                  id="bankRouting"
                  name="bankRouting"
                  value={CustomerInfo.bank_routing}
                  readOnly
                  className="bg-gray-200 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
              <div className='flex items-center justify-between'>
                <div  className="block text-sm font-medium text-gray-700 w-[160px]">Bank A/c #</div>
                <input
                  type="text"
                  id="bankAccount"
                  name="bankAccount"
                  value={CustomerInfo.bank_account}
                   readOnly
                  className="bg-gray-200 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
            </div>
          )}
 {paymentMethod === 'card' && (
            <div className="flex flex-col gap-4">
              <div className='flex items-center justify-between'>
                <div htmlFor="bankRouting" className="block text-sm font-medium text-gray-700 w-[160px]">Card #</div>
                <input
                  type="text"
                  id="bankRouting"
                  name="bankRouting"
                  value={CustomerInfo.card_number}
                  readOnly
                  className="bg-gray-200 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
             
            </div>
          )}
          {/* Memo/Notes */}
          <div className="flex items-center justify-between">
            <div className="block text-sm font-medium text-gray-700 w-[160px]">Memo/Notes</div>
            <textarea
              id="memo"
              name="memo"
              rows="1"
              onChange={(e) => setComment(e.target.value)}

              value={comment}
              // placeholder="Memo/Notes"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            ></textarea>
          </div>
        </div>

        {/* Buttons */}
        <div className="flex justify-end gap-4 mt-10">
          
              <button
                 style={{fontFamily:'poppins'}}
                  type="submit"
               
                  className='w-40 px-2 px-12 py-1 border-2 
                  border-blue-500 text-blue-500 hover:font-bold rounded-md hover:bg-blue-700 
                  hover:shadow-blue-700/50 uppercase duration-200 hover:text-white 
                  hover:shadow-lg hover:border-transparent active:scale-90'
onClick={(e)=> {handleSubmit(e); console.log('submitting')}}
disabled={!invoicenumberforpayinvoice || !invoiceDate || !paymentType || !amount  }
                >
                  PAY
                </button>
                <button 
                onClick={()=>{navigate(`/invoiceview/${invoicenumberforpayinvoice}`)}}
              className="w-40 px-2 px-12 py-1 border-2 border-red-500 text-red-500 hover:font-bold
               rounded-md hover:bg-[#FF0001] hover:shadow-red-700/50
               uppercase duration-200 hover:text-white hover:shadow-lg hover:border-transparent active:scale-90">
                CANCEL
              </button>
            </div>
      </div>
      </div>
      <>
    {/* {loading && <FullScreenLoader/>} */}
              <ToastNotificationBox/>
  
    <nav aria-label="breadcrumb">
    <ol className="flex space-x-2 text-lg font-bold text-gray-700 ml-[190px] mt-[20px] ]" style={{visibility:'hidden'}}>
      <li>
        {/* <a  href="/operations" className="text-blue-600 hover:text-blue-800"> */}
          Sales
        {/* </a> */}
      </li>
      <li>
        <span className="text-gray-400">{'>>'}</span>
      </li>
      <li>
        {/* <a href="/purchases" className="text-blue-600 hover:text-blue-800"> */}
          Create Invoice
        {/* </a> */}
      </li>
    </ol>
  </nav>
  <div className='relative w-[880px]  border mx-auto mb-5' 
  style={{
    boxShadow: '0 0 20px 0 rgba(0, 0, 0,.3)',
  }}
  >
  <div className="absolute top-[400px] left-1/2 -translate-x-1/2 -translate-y-1/2 z-0">
    <p className="text-red-400 opacity-20 font-bold text-[5rem] rotate-[-45deg] select-none">
      {status === "1" && "DRAFT"}
      {status === "2" && "APPROVED"}
      {status === "3" && "SENT"}
      {status === "7" && "FULLPAID"}
      {status === "8" && "PASTDUE"}
      {status === "4" && "PARTIALPAY"}


    </p>
  </div>
    <form className=''>
    <div 
    // style={{display:'none'}}
    className="flex  justify-center p-4 rounded-md font-bold text-md items-center w-full mx-auto py-2 bg-white mt-3 ">
      {/* View */}
      <div
        className={`flex items-center cursor-pointer px-4 py-1 ${
          activeItem === "View" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
        } border-b border-gray-300  `}
        onClick={() => handleMenuClick("View")}
      >
        <FaEye className="mr-2" />
        <span>View</span>
      </div>

      {/* Approve */}
      {status !== "1"  &&
      <div
        className={`flex items-center cursor-pointer px-4 py-1 ${
          activeItem === "Download" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
        } border-b border-gray-300 `}
        onClick={() =>{handleMenuClick("Download"); handleDownloadInvoice()}}
      >
        <FaDownload className="mr-2" />
        <span>Download</span>
      </div>
      }
      {/* {
        (status === "1" ) &&
      <div
        className={`flex items-center cursor-pointer px-4 py-1 ${
          activeItem === "Edit" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
        } border-b border-gray-300 `}
      onClick={() => {handleMenuClick("Edit"); handletheEditInvoice(invoicenumber, customerId)}}

      >
        <FaEdit className="mr-2" />
        <span>Edit</span>
      </div>
      } */}
      {/* Delete */}
      {
        (status === "1" || status === "2") &&
      <div
        className={`flex items-center cursor-pointer px-4 py-1 ${
          activeItem === "Delete" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
        } border-b border-gray-300 `}
        onClick={() => {handleMenuClick("Delete");handleDeleteModalInvoice();  }}

      >
        <FaTrash className="mr-2" />
        <span>Delete</span>
      </div>
      }

      {/* Back */}
      <div
        className={`flex items-center cursor-pointer px-4 py-1 ${
          activeItem === "Back" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
        } border-b border-gray-300 `}
        onClick={() => {
          handleMenuClick("Back");
          if (status === "8" || status === "4") {
            navigate('/payIns');
          } else {
            navigate('/invoice');
          }
        }}
              >
        <FaArrowLeft className="mr-2" />
        <span>Back</span>
      </div>
    </div>
    <div className="flex justify-between items-center  px-[65px] py-8 " style={{ fontStyle:'poppins'}}>
          <div >
            <h1 className="font-bold text-xl">{CustomerData.business_name}</h1>
            <p className="">{CustomerData.business_address} {CustomerData.business_city}</p>
            <p className="">{CustomerData.business_state}{CustomerData.business_zip_code}</p>
          </div>
          <div className="text-right">
            <h2 className="   text-green-600 font-bold text-4xl mb-2 text-left">INVOICE</h2>
            <p className="  text-green-600 text-xl font-bold text-left">{(invoicenumberforpayinvoice)}</p>
          </div>
        </div>
        <div>
        {status === "1" &&
    <div className="flex justify-end items-end mb-4 mr-[60px]">
  <button
  className='w-[150px] px-2 px-12 py-1 border-2 border-orange-600 text-orange-600 hover:font-bold rounded-md hover:bg-[#FF0001] hover:shadow-red-700/50 uppercase duration-200 hover:text-white hover:shadow-lg hover:border-transparent active:scale-90 '
    // className="mb-4 px-6 py-2 text-green-600 font-bold text-lg border-2 border-green-600 bg-green-100 hover:bg-green-200 rounded-md"
    onClick={(e) => handleApproveInvoice(e)}
  >
    APPROVE
  </button>
</div>
 }
    {status === "2" &&
    <div className="flex justify-end items-end mb-4 mr-[60px]">
  <button
    className='w-[150px] px-2 px-12 py-1 border-2 border-orange-600 text-orange-600 hover:font-bold rounded-md hover:bg-[#FF0001] hover:shadow-red-700/50 uppercase duration-200 hover:text-white hover:shadow-lg hover:border-transparent active:scale-90 '

    // className="mb-4 px-6 py-2 text-green-600 font-bold text-lg border-2 border-green-600 bg-green-100 hover:bg-green-200 rounded-md"
    onClick={(e)=> {
      e.preventDefault()
      fetchDataByInvoice()
        const invoiceNumber = invoicenumber
        setOpenSendInvoiceModalId(invoiceNumber)
    }}
  >
    SEND
  </button>
</div>
 }
 
        </div>
        <div className="flex flex-wrap gap-6 mt-[60px] px-[60px]">
  {/* Bill To */}
  <div className="flex-1 p-2 rounded-md">
    <p className="font-bold text-xl uppercase text-green-600">Bill To</p>
    <p>{BillShip.business_name}</p>
    <p>
      {CustomerInfo.billing_address} {CustomerInfo.billing_city}
    </p>
    <p>
      {CustomerInfo.billing_state} {CustomerInfo.billing_zip_code}
    </p>
  </div>

  {/* Ship To */}
  <div className="flex-1 p-2 rounded-md">
    <p className="font-bold text-xl uppercase text-green-600">Ship To</p>
    <p>{CustomerInfo.shipping_to}</p>
    <p>
      {CustomerInfo.shipping_address} {CustomerInfo.shipping_city}
    </p>
    <p>
      {CustomerInfo.shipping_state} {CustomerInfo.shipping_zip_code}
    </p>
  </div>

  {/* Invoice Details */}
  <div className=" p-2 rounded-md">
  <div className="flex justify-between items-center mb-1">
      <span className="font-bold text-[14px] uppercase text-green-600">
        Account #:
      </span>
      <span className="text-gray-700 text-[14px] ml-[10px]">{BillShip.business_account}</span>
    </div>
    <div className="flex justify-between items-center mb-1">
      <h3 className="font-bold text-[14px] uppercase text-green-600">
        Delivery Date:
      </h3>
      <p className="text-gray-700 ml-[10px] text-[14px]">{BillShip.delivery_date}</p>
    </div>
    <div className="flex justify-between items-center mb-1">
      <h3 className="font-bold text-[14px] uppercase text-green-600 text-[14px]">
        Invoice Date:
      </h3>
      <p className="text-gray-700 ml-[10px] text-[14px]">{BillShip.added_on}</p>
    </div>
    <div className="flex justify-between items-center">
      <h3 className="text-[14px] font-bold  uppercase text-green-600">
        Due Date:
      </h3>
      <p className="text-gray-700 ml-[10px] text-[14px]">{BillShip.due_date}</p>
    </div>
  </div>
</div>
<table
          className="table-auto border-collapse border w-[85%]  mt-[30px] mx-auto"
          style={{ fontFamily: 'Poppins', fontSize: '14px', tableLayout: 'auto' }}
        >
          <thead>
            <tr>
              <th colSpan="100%" className="border-b-2 border-black bg-white h-0 p-0 m-0"></th>
            </tr>
            <tr className="bg-white border-b-[2px] border-black">
              <th className="px-4 py-2 text-center w-[5%]">#</th>
              <th className="px-4 py-2 text-left uppercase w-[35%]">Product/Service</th>
              {showDescription ? (
                <th className="px-4 py-2 text-center uppercase w-[20%]">Description</th>
              ) : (
                <th className="px-4 py-2 text-center w-[20%]"></th>
              )}
              <th className="px-4 py-2 text-center uppercase w-[10%]">Quantity</th>
              <th className="px-4 py-2 text-center uppercase w-[15%]" style={{ whiteSpace: 'nowrap' }}>Unit Price</th>
              <th className="px-4 py-2 text-center uppercase w-[15%]">Amount</th>
            </tr>
            <tr>
              <th colSpan="100%" className="border-t-2 border-black bg-white h-0 p-0 m-0"></th>
            </tr>
          </thead>
          <tbody style={{ fontSize: '16px' }}>
            {items.map((row, index) => {
              if (row.item_price <= 0) return null;
              return (
                <tr key={index} className="border-t">
                  <td className="px-4 py-2 text-center">{index + 1}</td>
                  <td className="text-left px-4 py-2">{row.product_name}</td>
                  <td className="px-2 py-2 text-left" colSpan={row.description && row.isCustomRow || (row.product_name === "Water Bill" || 
                    row.product_name === "Freight Charges"  || row.product_name === "Freight Surcharge"
                    || row.product_name === "Rebates/Credits"
                     ) ? 2 : 1}>
                    {row.description || ''}
                  </td>
                  {!row.isCustomRow && (
                    <td className="px-2 py-2 text-center">{row.item_quantity || ''}</td>
                  )}
                  
                  <td className="px-2 py-2 text-center">{row.item_price || '2.00'}</td>
                  <td className="px-4 py-2 text-center">$ {Number(row.unit_total || 0).toFixed(2)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

    <div className=" mb-4  mr-[40px]" style={{fontFamily:'poppins'}}>
      <div className="w-100 bg-white rounded-lg space-y-0  mt-2">

        <div className={`w-[750px] mt-6 px-4 lg:px-0`}>
      
    </div>
    {/* checking */}
{brandInvoiceNumber && formField.bill_to !== "" &&
      <div className='flex justify-start items-center pl-4 gap-4 '>
      {remainingProducts.length > 0 && formField.bill_to ? (
  <select
    className="px-4 py-1 text-left w-[210px] border rounded-md  my-2 h-[35px]"
    value={selectedProduct}
    onChange={(e) => {
      const selected = e.target.value;
      setSelectedProduct(selected); // Update state
      addRow(selected); // Add row and filter dropdown
    }}
  >
    <option value="">--Add Product/Service--</option>
    {remainingProducts.map((product, index) => (
      <option key={index} value={product}>
        {product}
      </option>
    ))}
  </select>
) : (
  <p className="text-green-500 ml-4"></p>
)}
<div className='text-red-500'>{DiscountRebateError} </div>

<div className='text-red-500'>{MinLengthError} </div>

</div>
 }

{subTotal ? (
<div className="flex flex-col lg:flex-row p-6 px-2 pt-4 gap-6">
  
<div className="lg:w-1/2" style={{display:'hidden'}}>
      
      </div>
      {/* Right Half: Subtotal and Taxes */}
      <div className="lg:w-1/2  rounded-md p-4 "  style={{fontSize:'16px'}}>
        {/* Subtotal Section */}
        <div className="flex justify-between items-center mb-2">
          <div className="flex items-center gap-2">
            <button onClick={toggleSubtotal} className="text-blue-500">
              {isSubtotalExpanded ?  '▼' : '►'}
            </button>
            <span className="text-gray-800 font-medium">Subtotal</span>
          </div>
          <span className="text-gray-800 font-medium">
  $ {calculateSubtotal(subTotal, items)} {/* Adjusts based on custom rows */}
</span>
        </div>

        {isSubtotalExpanded && (
  <div className="ml-6 mb-4">
    <div className="flex justify-between">
      <span>Fuel Total:</span>
      <span>$ {subTotal ? Number(subTotal).toFixed(2) : (0.00).toFixed(2)}</span>
    </div>

    {items
      .filter((row) => row.isCustomRow ) 
      .map((row) => (
        <div key={row.index} className="flex justify-between">
          <span>{row.unit_total >0  && row.product_name} {row.unit_total >0  && ":"}</span>
          {
            row.unit_total >0 && 
          <span>
            {["Discount", "Rebates/Credits"].includes(row.product_name?.trim())
              ? `- $${Number(row.unit_total).toFixed(2)}`
              : `+ $${Number(row.unit_total).toFixed(2)}`}
          </span>
                    }

        </div>
      ))}
  </div>
)}


        {/* Taxes Section */}
        <div className="border-b-2 flex justify-between items-center">
          <div className="flex items-center gap-2">
            <button onClick={toggleTaxes} className="text-blue-500">
              {isTaxesExpanded ? '▼' : '►'}
            </button>
            <span className="text-gray-800 font-medium">Taxes</span>
          </div>
          <span className="text-gray-800 font-medium">$ {Number(taxTotal1).toFixed(2)}</span>
        </div>

        {isTaxesExpanded &&  (
          <div className="ml-6 mt-2">
      
{TaxesView.map((item) => (
  <div className="flex justify-between" key={item.po_customertax_name}>
    <span>{item.customer_taxes}</span>
    <span>$ {Number(item[item.po_customertax_name] * totalQuantity).toFixed(2)}</span>
  </div>
))}
          </div>
        )}
         <div className="mt-2 flex justify-between items-center">
          <div className="flex items-center gap-2">
            <span className="text-gray-800 font-[20px] font-bold ml-[20px]">Invoice Amount</span>
          </div>
          <span className="text-gray-800 font-[20px] font-bold ">
  $ {calculateInvoiceAmount(subTotal, items, taxTotal1)} {/* Includes all products, custom rows, and taxes */}
</span>

        </div>
        {status === "4" && 
         <div className="mt-2 flex justify-between items-center">
         <div className="flex items-center gap-2">
           <span className="text-gray-800 font-[20px] font-bold ml-[20px]">Amount Paid</span>
         </div>
         <span className="text-gray-800 font-[20px] font-bold ">
 $ {Number(BillShip.invoice_amount_paid).toFixed(2)} {/* Includes all products, custom rows, and taxes */}
</span>

       </div>
        }
         {status === "4" && 
         <div className="mt-2 flex justify-between items-center">
         <div className="flex items-center gap-2">
           <span className="text-gray-800 font-[20px] font-bold ml-[20px]">Due Amount</span>
         </div>
         <span className="text-gray-800 font-[20px] font-bold ">
 $ {Number(BillShip.due_amount).toFixed(2)} {/* Includes all products, custom rows, and taxes */}
</span>

       </div>
        }
      </div>
    </div>)
    :''
 }  
      <div className='mx-[70px] mb-2' style={{marginBottom:'80px', marginTop:'80px'}}>
  <h2 className="text-lg font-semibold mb-4  mt-2">Terms & Conditions</h2>
  <div className="relative">
    <LightbulbOutlinedIcon 
      className="absolute left-3 top-2 text-white bg-black rounded-lg" 
      style={{borderRadius:'50%', fontSize:'30px'}}
    />
    <textarea
      className="w-full h-12 p-2 pl-16 rounded-md focus:outline-none focus:border-none bg-gray-100 border-0"
      placeholder="Enter terms and conditions here..."
      value={BillShip.terms_conditions}
      // onChange={(e) => setTermsAndConditions(e.target.value)}
      maxLength={600}
    />
  </div>
</div>

    <div className="text-center font-sans border-t-2 border-black pt-4 mt-12">
      <p className="text-gray-600 font-bold text-sm">
      {BillShip.footer}
      {/* <span className="font-normal">{CustomerData.contact_no}</span> */}
      </p>
      <div className="flex mt-6 mb-[20px] items-start justify-between">
  {/* Left Section: Image */}
  <div>
  <img
        src={`${baseURL}/uploads/img/business/${CustomerData.image_file}`}
        alt="Business Logo"
        style={{visibility:'hidden'}}
        className="w-20 h-20 object-contain ml-[90px]" // Ensures the image scales proportionally
      />  </div>

  {/* Center Section: Thank you message */}
  <p className="text-lg font-bold text-center">
    Thank you for being a loyal customer!
  </p>

  {/* Right Section: Customer Data */}
  <div className="text-right mr-[20px]" style={{visibility:'hidden'}}>
    <h1 className="font-bold text-xl">{CustomerData.business_name}</h1>
    <p>{CustomerData.business_address} {CustomerData.business_city}</p>
    <p>{CustomerData.business_state} {CustomerData.business_zip_code}</p>
  </div>
</div>

     
    </div>
     {/* {openSendInvoiceModalId && (
            <PopUpModalForSendInvoice
              onClose={() => setOpenSendInvoiceModalId(null)}
              invoiceNumber={openSendInvoiceModalId}
              invoiceName={businessName}
              // resetRowSelection={resetRowSelection}
              customerEmail={customerEmail}
              finalInvoiceAmount={final_invoice_amount}
              dueDate={dueDateinvoice}
              setTriggerFetch={setTriggerFetch}
              triggerFetch={triggerFetch}
            />
          )} */}
          {/* {
  payModalInvoice && (
    <InvoicePayModal open= {payModalInvoice}
    handleClose= {() => setpayModalInvoice(false)}
    customerName={businessName}
    invoiceNumber={invoicenumber}
    finalInvoiceAmount={Number(FinalInvoiceAmount)}
    setTriggerFetch={setTriggerFetch}
    triggerFetch={triggerFetch}
    // resetRowSelection={table.resetRowSelection}

     
      />
  )
}
 {openDeleteModalId && (
        <PopUpModal
          onClose={() => setOpenDeleteModalId(null)}
          invoiceNumber={openDeleteModalId}
          // invoiceDelete={handleDeleteInvoices}
          invoiceName={businessName}
          // resetRowSelection={table.resetRowSelection}
          setTriggerFetch={setTriggerFetch}
          triggerFetch={triggerFetch}
        />
      )} */}
{/* </div> */}
   
        </div>        
      </div>
    </form>
    </div>

    </>
    </div>
  );
}

export default PayInvoice;
