import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import Revenue from "./Revenue";
import TableMenu from "./TableMenu";
import ScrollToTop from "./ScrollTop";
import { createColumnHelper } from "@tanstack/react-table";
import CustomerTableMenu from "./CustomerTableMenu";
import CustomerCount from "./CustomerCount";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaCheckCircle } from "react-icons/fa";
import PopUpModal from "./DeleteModal";
import { FaTimesCircle } from "react-icons/fa";
import Table from "./Table";
import { deleteCustomer } from "../../actions/customerActions";
import { Tooltip } from "react-tippy";
import { routes } from  "../constants/routes.constants"
import config  from "../../config/config"
import { addToast } from "./ToastBox/ToastBoxMessageSlice";

const columnHelper = createColumnHelper();

function BusinessDashboard() {

  const [selectedRows, setSelectedRows] = useState([]);
  const isEditActive = selectedRows.length === 1;
  const isDeleteActive = selectedRows.length > 0;
  const [activeCustomerCount, setActiveCustomerCount] = useState(0);
  const [inactiveCustomerCount, setInactiveCustomerCount] = useState(0);
  
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const showToast = (businessName) => {
    toast.error(
      <div className="flex justify-between   text-red-500">
        <div className=" rounded-lg">{businessName} Deleted successfully</div>
        <div className=" rounded-lg ">
          {" "}
          <FaTimesCircle className="mr-4  text-red-500 " size={24} />
        </div>
      </div>,
      {
        position: "top-left",
        hideProgressBar: true,
        autoClose: 4000,
        icon: false,
        className: " w-[843px] ml-[470px] mt-[290px]", // Full width
        style: {
          borderLeft: "4px solid red", // Right circle with right tick mark
          background: "#fdf2f2",
          minHeight: '10px',
        },
      }
    );
  };
  const handleDeleteItems = () => {
    if (selectedRows.length > 0) {
      const deletedCustomerNames = [];
      selectedRows.forEach((row) => {
        const customerToDelete = customerData.find(
          (item) => item.business_id === row.business_id
        );
        if (customerToDelete) {
          deletedCustomerNames.push(customerToDelete.business_name);
          dispatch(deleteCustomer(row.business_id)); // Dispatch the deleteItem action
        }
      });

      if (deletedCustomerNames.length > 0) {
        const toastMessage = (
          <div className="flex justify-between  text-red-500">
            <div className="rounded-lg">
              {deletedCustomerNames.join(", ")} deleted successfully
            </div>
            <div className="rounded-lg ">
              <FaTimesCircle className="mr-4 text-red-500 " size={24} />
            </div>
          </div>
        );

        toast.error(toastMessage, {
          position: "top-left",
          hideProgressBar: true,
          autoClose: 4000,
          icon: false,
          className: " w-[843px] ml-[470px] mt-[290px]", // Full width
          style: {
            borderLeft: "4px solid red",
            background: "#fdf2f2",
            minHeight: '10px',
          },
        });
        window.scrollTo(0, 0);
        // Reload the page after showing the toaster
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const handleViewBusiness = (businessId) => {
    // navigate(`/setup/viewcustomer/${customerId}`);
      navigate(`/viewbusiness/${businessId}`);
       console.log(businessId);
  };
  const handleDeleteBusiness = (businessId) => {
    // Set the loading state to true when the delete operation starts
    dispatch(deleteCustomer(businessId)); // Dispatch the deleteCustomer action
    closeModal(businessId);
    showToast();

    // Reload the page after 3 seconds (3000 milliseconds)
    setTimeout(() => {
      window.location.reload(); // Reload the page
    }, 500);
  };
  // state management from api
  const [customerData, setCustomerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModalId, setOpenModalId] = useState(null);
  // fetching data from api
  const [openDeleteModalId, setOpenDeleteModalId] = useState(null);

  const handleDeleteItem = (businessId) => {
    // Assuming you have an array of items called 'itemData'
    const itemToDelete = customerData.find(
      (item) => item.business_id === businessId
    );
    if (itemToDelete) {
      const itemName = itemToDelete.business_name;

      // Dispatch the deleteItem action with itemId
      dispatch(deleteCustomer(businessId));

      // Show a toast message with the item name
      // showToast(itemName);
      dispatch(addToast({ type: 'danger', message: `${itemName} deleted Succesfully` }));        
      // Reload the page after a delay
      // setTimeout(() => {
      //   window.location.reload(); // Reload the page
      // }, 500);
    }
  };

  const handleDeleteModalItem = (itemId) => {
    // Open the confirmation modal when the delete button is clicked
    if (selectedRows.length > 0) {
      // Open the confirmation modal when the delete button is clicked
      setOpenDeleteModalId(itemId);
      // Pass the item name to the handleDeleteItem function
    }
  };
  const [originalItemData, setOriginalItemData] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${baseURL}/${btoa("customer/view")}`)
      .then((response) => {
        const sortedCustomerData = response.data.customers_data.sort(
          (a, b) => b.customer_id - a.customer_id
        );
        setCustomerData(sortedCustomerData);
        setOriginalItemData(sortedCustomerData);
       
  
        // Calculate counts
        const activeCount = sortedCustomerData.filter(
          (item) => item.customer_status === "1"
        ).length;
        const inactiveCount = sortedCustomerData.filter(
          (item) => item.customer_status === "0"
        ).length;
  
        setActiveCustomerCount(activeCount);
        setInactiveCustomerCount(inactiveCount);
  
        setLoading(false); // Set loading to false when data is fetched
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
        setLoading(false); // Set loading to false in case of error
      });
  }, []);
  

  const openModal = (rowIndex) => {
    console.log("Opening modal for row index:", rowIndex); // Log the rowIndex
    setOpenModalId(rowIndex);
  };

  const closeModal = () => {
    setOpenModalId(null);
  };

  const isModalOpen = (rowIndex) => {
    return openModalId === rowIndex;
  };
  const handleSearch = (searchQuery) => {
    if (!searchQuery || searchQuery.trim() === '' || searchQuery === '\n') {
      // If the search query is null, undefined, empty, or only contains a newline character,
      // show all data immediately
      setCustomerData(originalItemData);
    } else {
      // Filter the item data based on multiple fields
      const filteredItems = originalItemData.filter((item) =>
        (item.business_name && item.business_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item.customer_status === "1" && "Active".toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item.customer_status === "0" && "Inactive".toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item.contact_person_name && item.contact_person_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item.contact_person_email && item.contact_person_email.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item.contact_person_phone_number && item.contact_person_phone_number.toLowerCase().includes(searchQuery.toLowerCase()))
      );
  
      // Set the filtered data to be displayed in the table
      setCustomerData(filteredItems);
    }
  };
  
  const columns = [
    columnHelper.accessor("business_name", {
      header: "Business",
    }),
    columnHelper.accessor("customer_status", {
      header: "Status",
      cell: (props) => (
        <div className="flex justify-center">
          <div
            className={`text-black bg-[#EBF8F1] text-[12px] py-[5px] px-[12px] rounded-3xl font-semibold ${
              props.row.original.customer_status === "0"
                ? "bg-orange-100"
                : props.row.original.customer_status === "1"
                ? "bg-green-100"
                : ""
            }`}
          >
            {props.row.original.customer_status === "0"
              ? "Inactive"
              : props.row.original.customer_status === "1"
              ? "Active"
              : ""}
          </div>
        </div>
      ),
    }),
    columnHelper.accessor(
      (data) => {
        const {
          business_address,
          business_city,
          business_zip_code,
          business_state,
        } = data;
        return `${business_address}, ${business_city}, ${business_state}, ${business_zip_code}`;
      },
      {
        header: "Location",
      }
    ),


    columnHelper.accessor("contact_person_name", {
      header: "Contact Name",
    }),
    columnHelper.accessor("contact_person_phone_number", {
      header: "Contact No",
    }),
    columnHelper.accessor("contact_person_email", {
      header: "Contact Email",
    }),
  ];


  return (
    <div className=" flex justify-center items-center flex-col w-full pb-5">
      <div className="flex justify-between items-center w-full px-8 mt-10 mb-8">
        <h1 className="font-bold text-lg text-gray-400">Business Dashboard</h1>
        <div>
          <button
            className="flex items-center justify-center hover:font-bold border-[#25caed] border-2 text-black hover:bg-[#25caed] hover:text-white hover:border-transparent hover:shadow-lg  hover:shadow-[#25caed]/50 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-base w-48  px-4 py-1.5  focus:outline-none "
            type="button"
            onClick={() => navigate(`/business`)}
          >
             <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="currentColor"
              className="w-5 h-5 mr-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v12m6-6H6"
              />
            </svg>
            New Business
          </button>
        </div>
      </div>
      {/* <CustomerCount /> */}
      <CustomerTableMenu  onSearch={handleSearch}/>
      {loading ? (
        <p>Loading customer data...</p>
      ) : (
        <div className="border border-gray-500/6 w-[94%] rounded-xl p-8 mt-2 bg-white">
          <div className="flex justify-between items-center px-4">
            <div className="flex items-center">
            <h1 className="font-bold text-lg text-gray-400 mr-12">
              Business: {customerData.length}
            </h1>
           <div className="flex space-x-4 items-center">
           <p className="text-black bg-green-100 text-[12px] py-[5px] px-[12px] rounded-3xl font-semibold">Active: <span>{activeCustomerCount}</span></p>
              <p className="text-black bg-orange-100 text-[12px] py-[5px] px-[12px] rounded-3xl font-semibold">Inactive: <span>{inactiveCustomerCount}</span></p>
           </div>
            </div>
      
            
            
            <div className="flex items-center gap-3">
             
              {isEditActive && (
                <Tooltip
                title="Edit"
                position="top"
                trigger="mouseenter"
              >
                  <button
                  className="items-center text-sm font-medium text-center focus:outline-none text-blue-600 hover:text-blue-700 rounded-lg"
                  type="button"
                  onClick={() => {
                    const businessId = selectedRows[0].business_id;
                    navigate(`/setup/viewbusiness/${businessId}`);
                    console.log(businessId);

                    // const customerId = selectedRows[0].customer_id;
                    // navigate(`/customers/viewcustomer/${customerId}`);  
                  }}

                  
                >
                  <div className="hover:rounded-full  bg-blue-100 rounded-full p-2 hover:bg-blue-200">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-5 h-5"
                    >
                      <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                      <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                    </svg>
                  </div>
                </button>
              </Tooltip>
            
              )}
              {/* delte */}
              {isDeleteActive && (
                <Tooltip
                title="Delete"
                position="top"
                trigger="mouseenter"
              >
                  <button
                  className="items-center text-sm font-medium text-center focus:outline-none text-red-600 hover:text-res-700 rounded-lg"
                  type="button"
                  onClick={handleDeleteModalItem}
                >
                  <div className="hover:rounded-full  bg-red-100 rounded-full p-2 hover:bg-red-200">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </button>
              </Tooltip>
             
              )}
            </div>


          </div>
          <div className="">
            <Table
              tableData={customerData}
              columns={columns}
              enableRowSelection={true}
              selectedRows={setSelectedRows}
            />
          </div>

        </div>
      )}{" "}
      {openDeleteModalId && (
        <PopUpModal
          onClose={() => setOpenDeleteModalId(null)}
          itemId={openDeleteModalId}
          itemDelete={handleDeleteItems}
          itemName={selectedRows[0].business_name}
        />
      )}
      <ScrollToTop />
    </div>
  );
}

export default BusinessDashboard;


