// AddItemForm.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { useDispatch } from "react-redux";
import { addCustomer } from "../../actions/customerActions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { useTabs } from "../pages/TabContext";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import CustomerData from "./Customers Validation/Customer";
import config  from "../../config/config"
import ReactDOM from 'react-dom';
import { Tooltip } from "react-tippy";


function CustomerForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { changeTab } = useTabs();
  const handleCancel = () => {
    // Change tab to 'items'
    navigate(`/customers`); // Navigate to the 'items' route
  };
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;

  const businessId = localStorage.getItem("business_id")
  const managerId = localStorage.getItem("user_id")

  const [formData, setFormData] = useState({
    businessId : businessId,
    managerId : managerId,
    businessName: "",
    businessAccount: "",
    businessAddress: "",
    businessCity: "",
    businessState: "",
    businessZipCode: "",
    contactPersonName: "",
    contactPersonEmail: "",
    contactPersonPhoneNumber: "",
    currency: "",
    billingAddress: "",
    billingCity: "",
    billingState: "",
    billingZipCode: "",
    shippingTo: "",
    shippingAddress: "",
    shippingCity: "",
    shippingState: "",
    shippingZipCode: "",
    shippingContactNumber: "",
    shippingDeliveryInstructions: "",
    bankCurrency: "",
    bankRouting: "",
    bankAccount: "",
    cardNumber: "",
    cardExpiryDate: "",
    cardCVV: "",
    nameOnCard: "",
    cardZipCode: "",
    paymentType: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleCvvChange = (e) => {
    let inputValue = e.target.value;
  
    // Validate if input is a number and has at most 4 digits
    const isValidInput = /^[0-9]{0,4}$/.test(inputValue);
  
    // Update the state with the cleaned value if input is valid
    if (isValidInput) {
      setFormData({
        ...formData,
        [e.target.name]: inputValue,
      });
    } else {
      // Display an error message or take appropriate action
      // For example, you can set an error state or show a message to the user
      console.error("Invalid input for cardCVV");
    }
  };
  const handleCardChange = (e) => {
    let inputValue = e.target.value;
  
    // Validate if input is a number and has at most 4 digits
    const isValidInput = /^[0-9]{0,19}$/.test(inputValue);
  
    // Update the state with the cleaned value if input is valid
    if (isValidInput) {
      setFormData({
        ...formData,
        [e.target.name]: inputValue,
      });
    } else {
      // Display an error message or take appropriate action
      // For example, you can set an error state or show a message to the user
      console.error("Invalid input for cardCVV");
    }
  };
  // const handleCardChange = (e) => {
  //   let inputValue = e.target.value;
  
  //   // Validate if input is a number and has at least 10 digits and at most 19 digits
  //   const isValidInput = /^[0-9]{10,19}$/.test(inputValue);
  
  //   // Update the state with the cleaned value if input is valid
  //   if (isValidInput) {
  //     setFormData({
  //       ...formData,
  //       [e.target.name]: inputValue,
  //     });
  //   } else {
  //     // Display an error message or take appropriate action
  //     // For example, you can set an error state or show a message to the user
  //     console.error("Invalid input for cardNumber");
  //   }
  // };
  
  const handleZipCodeChange = (e) => {
    // Get the input value and remove non-numeric characters
    let inputValue = e.target.value.replace(/\D/g, "");

    // Limit the length to 5 digits
    inputValue = inputValue.slice(0, 5);

    // Update the state with the cleaned value
    setFormData({
      ...formData,
      [e.target.name]: inputValue,
    });
  };
  const handlePhoneNumberChange = (e) => {
    // Get the input value and remove non-numeric characters
    let inputValue = e.target.value.replace(/\D/g, '');
  
    // Apply the US phone number format (xxx) xxx-xxxx
    let formattedValue = '';
  
    if (inputValue.length > 0) {
      formattedValue += `(${inputValue.slice(0, 3)}`;
  
      if (inputValue.length > 3) {
        formattedValue += `)${inputValue.slice(3, 6)}`;
  
        if (inputValue.length > 6) {
          formattedValue += `-${inputValue.slice(6, 10)}`;
        }
      }
    }
  
    // Update the state with the cleaned and formatted value
    setFormData({
      ...formData,
      [e.target.name]: formattedValue,
    });
  };
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Data Submitted:", formData);

    try {
      const response = await axios.post(
        `${baseURL}/${btoa("customer/create")}/`,
        formData
      );
      console.log("form data" , formData);

      if (response.status === 200) {
        // Request was successful (status code 200)
        const responseData = await response.data;
        if (responseData.dataSavingStatus === true) {
          showToast(
            "New Customer added successfully",
            "success",
            formData.businessName
          );
          // Clear form data after a successful submission
        } else {
          showToast("Unable to add new customer,please try again", "error");
        }
      } else {
        // Handle non-successful responses here
        console.log("Request failed with status: " + response.status);
        // You can also log the response data for debugging
        response.data.then((data) => {
          console.log(data);
        });
        showToast("Unable to add new customer,please try again", "error");
      }
    } catch (error) {
      // Handle unexpected errors here
      console.error(error);
    
      showToast("Unable to add new customer,please try again", "error");
    }

    navigate(`/customers`);
  };
  console.log(formData);

  const showToast = (message, type, businessName) => {
    const toastColorClass = type === "success" ? "bg-green-50 text-green-500" : "bg-red-50 text-red-500";
    const iconComponent = type === "success" ? <FaCheckCircle className="mr-2 text-[#2BDE3F]" size={24} /> : <FaTimesCircle className="mr-2 text-red-500" size={24} />;
  
    const iconContainer = document.createElement('div');
    ReactDOM.render(iconComponent, iconContainer);
    
    const toastContainer = document.createElement('div');
    toastContainer.className = `toast-container flex justify-between items-center ${toastColorClass}`;
    
    toastContainer.innerHTML = `
      <div className="rounded-lg" style="white-space: nowrap; width: 600px;"> 
        ${message}
      </div>
      <div className="rounded-lg">${iconContainer.innerHTML}</div>
    `;
    
    toastContainer.style.position = 'fixed';
    toastContainer.style.top = '45%'; // Initial position
    toastContainer.style.left = '50%';
    toastContainer.style.transform = 'translate(-50%, -50%)'; // Center the container horizontally and vertically
    toastContainer.style.zIndex = '9999';
    toastContainer.style.padding = '10px';
    toastContainer.style.borderLeft = type === "success" ? "5px solid #2BDE3F" : "5px solid red";
    toastContainer.style.background = type === "success" ? "#f3faf7" : "#fdf2f2";
    toastContainer.style.minHeight = "10px";
    toastContainer.style.display = "flex";
    toastContainer.style.justifyContent = "space-between";
    toastContainer.style.width = "auto"; // Adjust width as needed
  
    document.body.appendChild(toastContainer);
  
    const initialTop = parseFloat(getComputedStyle(toastContainer).top);
  
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const distance = initialTop - scrollTop;
      toastContainer.style.top = `${distance}px`; // Adjust position based on scroll
    };
  
    window.addEventListener('scroll', handleScroll);
  
    setTimeout(() => {
      document.body.removeChild(toastContainer);
      window.removeEventListener('scroll', handleScroll);
      window.scrollTo(0, 0); // Move screen back to top after dismissing toast
    }, 3500);
  };

  // Swtich between Card and Bank in Payment Type
  const [isCardSelected, setIsCardSelected] = useState(false);

  const handleToggle = () => {
    setIsCardSelected(!isCardSelected);
  };

  // Cancel Button to cancel the creation of form which redirect to the Customer Page onClick

  // Return Statement to return the code
  const handleBankRouting = (e) => {
    let inputValue = e.target.value;
  
    // Validate if input is a number and has at most 9 digits
    const isValidInput = /^[0-9]{0,9}$/.test(inputValue);
  
    if (!isValidInput) {
      // Display an error message or take appropriate action
      // For example, you can set an error state or show a message to the user
      console.error("Invalid input for bankRouting");
      return; // Stop further processing if the input is invalid
    }
  
    // Update the state with the cleaned value
    setFormData({
      ...formData,
      [e.target.name]: inputValue,
    });
  };
  const handleBankAccount = (e) => {
    let inputValue = e.target.value;
  
    // Validate if input is a number and has at most 17 digits
    const isValidInput = /^[0-9]{0,17}$/.test(inputValue);
  
    // if (!isValidInput) {
    //   // Throw an error with a custom message
    //   throw new Error("Bank Account # must be a number with a maximum of 17 digits.");
    // }
  
    // Update the state with the cleaned value
    if (!isValidInput) {
      // Display an error message or take appropriate action
      // For example, you can set an error state or show a message to the user
      console.error("Invalid input for bankRouting");
      return; // Stop further processing if the input is invalid
    }
    setFormData({
      ...formData,
      [e.target.name]: inputValue,
    });
  };
  
  return (
    <div className="pt-20 pb-20">
      <h1 className="mx-8 font-bold mb-3 text-gray-400 text-xl mt-[-35px]">
        Customers {">>"} New Customer
      </h1>
      <CustomerData/>
      <form onSubmit={handleSubmit}>
        
        {/* contact */}
        {/* <div className="w-auto bg-white rounded-box  mx-8 rounded-xl mt-16">
          <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
            Contact Info
          </h2>

          <div className="grid gap-4 sm:grid-cols-3 sm:gap-6">
            <div className="w-full">
              <label
                htmlFor="contactPersonName"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Contact Person Name
              </label>
              <input
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                placeholder="Enter Name"
                type="text"
                id="contactPersonName"
                name="contactPersonName"
                value={formData.contactPersonName}
                onChange={handleChange}
              />
            </div>
            <div className="w-full">
              <label
                htmlFor="contactPersonEmail"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Email
              </label>
              <input
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                placeholder="Enter Email"
                type="email"
                id="contactPersonEmail"
                name="contactPersonEmail"
                value={formData.contactPersonEmail}
                onChange={handleChange}
              />
            </div>
            <div className="w-full">
              <label
                htmlFor="contactPersonPhoneNumber"
                className="block mb-2 text-sm font-medium text-gray-900  "
              >
                Phone Number
              </label>
              <input
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                placeholder="Enter Phone Number"
                type="text"
                id="contactPersonPhoneNumber"
                name="contactPersonPhoneNumber"
                value={formData.contactPersonPhoneNumber}
                onChange={handlePhoneNumberChange}
              />
            </div>
          </div>
          <div className="flex justify-end space-x-3">
            <button
              onClick={handleCancel}
              type="submit"
              className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
            >
              Done
            </button>
          </div>
        </div> */}

        {/* <div className="w-auto bg-white rounded-box  mx-8 rounded-xl mt-16">
          <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
            Billing Info
          </h2>

          <div className="grid gap-4 sm:grid-cols-4 sm:gap-6">
            <div className="w-full">
              <label
                htmlFor="billingAddress"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Billing Address
              </label>
              <input
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                placeholder="Enter Billing Address"
                type="text"
                id="billingAddress"
                name="billingAddress"
                value={formData.billingAddress}
                onChange={handleChange}
              />
            </div>
            <div className="w-full">
              <label
                htmlFor="billingCity"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                City
              </label>
              <input
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                placeholder="Enter City"
                type="text"
                id="billingCity"
                name="billingCity"
                value={formData.billingCity}
                onChange={handleChange}
              />
            </div>
            <div className="w-full">
              <label
                htmlFor="billingState"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                State
              </label>
              <select
                id="billingState"
                name="billingState"
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                value={formData.billingState}
                onChange={handleChange}
              >
                <option value="">Select State</option>
                <option value="AL">Alabama (AL)</option>
                <option value="AK">Alaska (AK)</option>
                <option value="AZ">Arizona (AZ)</option>
                <option value="AR">Arkansas (AR)</option>
                <option value="CA">California (CA)</option>
                <option value="CO">Colorado (CO)</option>
                <option value="CT">Connecticut (CT)</option>
                <option value="DE">Delaware (DE)</option>
                <option value="FL">Florida (FL)</option>
                <option value="GA">Georgia (GA)</option>
                <option value="HI">Hawaii (HI)</option>
                <option value="ID">Idaho (ID)</option>
                <option value="IL">Illinois (IL)</option>
                <option value="IN">Indiana (IN)</option>
                <option value="IA">Iowa (IA)</option>
                <option value="KS">Kansas (KS)</option>
                <option value="KY">Kentucky (KY)</option>
                <option value="LA">Louisiana (LA)</option>
                <option value="ME">Maine (ME)</option>
                <option value="MD">Maryland (MD)</option>
                <option value="MA">Massachusetts (MA)</option>
                <option value="MI">Michigan (MI)</option>
                <option value="MN">Minnesota (MN)</option>
                <option value="MS">Mississippi (MS)</option>
                <option value="MO">Missouri (MO)</option>
                <option value="MT">Montana (MT)</option>
                <option value="NE">Nebraska (NE)</option>
                <option value="NV">Nevada (NV)</option>
                <option value="NH">New Hampshire (NH)</option>
                <option value="NJ">New Jersey (NJ)</option>
                <option value="NM">New Mexico (NM)</option>
                <option value="NY">New York (NY)</option>
                <option value="NC">North Carolina (NC)</option>
                <option value="ND">North Dakota (ND)</option>
                <option value="OH">Ohio (OH)</option>
                <option value="OK">Oklahoma (OK)</option>
                <option value="OR">Oregon (OR)</option>
                <option value="PA">Pennsylvania (PA)</option>
                <option value="RI">Rhode Island (RI)</option>
                <option value="SC">South Carolina (SC)</option>
                <option value="SD">South Dakota (SD)</option>
                <option value="TN">Tennessee (TN)</option>
                <option value="TX">Texas (TX)</option>
                <option value="UT">Utah (UT)</option>
                <option value="VT">Vermont (VT)</option>
                <option value="VA">Virginia (VA)</option>
                <option value="WA">Washington (WA)</option>
                <option value="WV">West Virginia (WV)</option>
                <option value="WI">Wisconsin (WI)</option>
                <option value="WY">Wyoming (WY)</option>
              </select>
            </div>
            <div className="w-full">
              <label
                htmlFor="billingAZipCode"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Zip Code
              </label>
              <input
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                placeholder="Enter Zipcode"
                type="text"
                id="billingZipCode"
                name="billingZipCode"
                value={formData.billingZipCode}
                onChange={handleZipCodeChange}
              />
            </div>
          </div>
          <div className="flex justify-end space-x-3">
            {/* <button
              onClick={clearBillingForm}
              type="submit"
              className="hover:bg-[#3479E0] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#3479E0]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
            >
              Clear
            </button> */}
            {/* <button
              onClick={handleCancel}
              type="submit"
              className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
            >
              Done
            </button>
          </div> */}
        {/* </div> */} 

        {/* shipping */}
        {/* <div className="w-auto  bg-white rounded-box  mx-8 rounded-xl mt-16">
          <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
            Shipping Info
          </h2>

          <div className="grid gap-4 sm:grid-cols-4 sm:gap-6">
            <div className="w-full sm:col-span-2">
              <label
                htmlFor="shippingTo"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Shipping To
              </label>
              <input
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                placeholder="Enter Contact Person Name who will recieve the delivery"
                type="text"
                id="shippingTo"
                name="shippingTo"
                value={formData.shippingTo}
                onChange={handleChange}
              />
            </div>
            <div className="w-full sm:col-span-2">
              <label
                htmlFor="shippingContactNumber"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Contact Number
              </label>
              <input
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                placeholder="Enter Contact Number"
                type="text"
                id="shippingContactNumber"
                name="shippingContactNumber"
                value={formData.shippingContactNumber}
                onChange={handlePhoneNumberChange}
              />
            </div>
            <div className="w-full">
              <label
                htmlFor="shippingAddress"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Shipping Address
              </label>
              <input
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                placeholder="Enter Shipping Address"
                type="text"
                id="shippingAddress"
                name="shippingAddress"
                value={formData.shippingAddress}
                onChange={handleChange}
              />
            </div>
            <div className="w-full">
              <label
                htmlFor="shippingCity"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                City
              </label>
              <input
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                placeholder="Enter City"
                type="text"
                id="shippingCity"
                name="shippingCity"
                value={formData.shippingCity}
                onChange={handleChange}
              />
            </div>
            <div>
              <label
                htmlFor="shippingState"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                State
              </label>
              <select
                id="shippingState"
                name="shippingState"
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                value={formData.shippingState}
                onChange={handleChange}
              >
                <option value="">Select State</option>
                <option value="AL">Alabama (AL)</option>
                <option value="AK">Alaska (AK)</option>
                <option value="AZ">Arizona (AZ)</option>
                <option value="AR">Arkansas (AR)</option>
                <option value="CA">California (CA)</option>
                <option value="CO">Colorado (CO)</option>
                <option value="CT">Connecticut (CT)</option>
                <option value="DE">Delaware (DE)</option>
                <option value="FL">Florida (FL)</option>
                <option value="GA">Georgia (GA)</option>
                <option value="HI">Hawaii (HI)</option>
                <option value="ID">Idaho (ID)</option>
                <option value="IL">Illinois (IL)</option>
                <option value="IN">Indiana (IN)</option>
                <option value="IA">Iowa (IA)</option>
                <option value="KS">Kansas (KS)</option>
                <option value="KY">Kentucky (KY)</option>
                <option value="LA">Louisiana (LA)</option>
                <option value="ME">Maine (ME)</option>
                <option value="MD">Maryland (MD)</option>
                <option value="MA">Massachusetts (MA)</option>
                <option value="MI">Michigan (MI)</option>
                <option value="MN">Minnesota (MN)</option>
                <option value="MS">Mississippi (MS)</option>
                <option value="MO">Missouri (MO)</option>
                <option value="MT">Montana (MT)</option>
                <option value="NE">Nebraska (NE)</option>
                <option value="NV">Nevada (NV)</option>
                <option value="NH">New Hampshire (NH)</option>
                <option value="NJ">New Jersey (NJ)</option>
                <option value="NM">New Mexico (NM)</option>
                <option value="NY">New York (NY)</option>
                <option value="NC">North Carolina (NC)</option>
                <option value="ND">North Dakota (ND)</option>
                <option value="OH">Ohio (OH)</option>
                <option value="OK">Oklahoma (OK)</option>
                <option value="OR">Oregon (OR)</option>
                <option value="PA">Pennsylvania (PA)</option>
                <option value="RI">Rhode Island (RI)</option>
                <option value="SC">South Carolina (SC)</option>
                <option value="SD">South Dakota (SD)</option>
                <option value="TN">Tennessee (TN)</option>
                <option value="TX">Texas (TX)</option>
                <option value="UT">Utah (UT)</option>
                <option value="VT">Vermont (VT)</option>
                <option value="VA">Virginia (VA)</option>
                <option value="WA">Washington (WA)</option>
                <option value="WV">West Virginia (WV)</option>
                <option value="WI">Wisconsin (WI)</option>
                <option value="WY">Wyoming (WY)</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="shippingZipCode"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Zip Code
              </label>
              <input
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                placeholder="Enter Zipcode"
                type="text"
                id="shippingZipCode"
                name="shippingZipCode"
                value={formData.shippingZipCode}
                onChange={handleZipCodeChange}
              />
            </div>

            <div className="sm:col-span-4 mb-8">
              <label
                htmlFor="shippingDeliveryInstructions"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Delivery Instructions
              </label>
              <textarea
                rows="4"
                className="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 "
                placeholder="Enter Delivery Instructions if any..."
                id="shippingDeliveryInstructions"
                name="shippingDeliveryInstructions"
                value={formData.shippingDeliveryInstructions}
                onChange={handleChange}
              ></textarea>
            </div>
          </div>

          <div className="flex justify-end space-x-3">
        
            <button
              onClick={handleCancel}
              type="submit"
              className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
            >
              Done
            </button>
          </div>
        </div> */}

        {/* <div className="w-auto  bg-white rounded-box  mx-8 rounded-xl mt-16">
          <div className="flex justify-between">
            <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
              Payment Info
            </h2>
            <div className="flex items-center mb-6">
              <span className="text-gray-700 font-semibold">Bank</span>
              <label className="switch-toggle ml-4">
                <input
                  type="checkbox"
                  onChange={handleToggle}
                  checked={isCardSelected}
                />
                <span className="slider-toggle round"></span>
              </label>
              <span className="ml-4 font-semibold">Card</span>
            </div>
          </div>

          {isCardSelected ? (
            <div className="grid gap-4 sm:grid-cols-4 sm:gap-6">
                 <div className="w-full sm:col-span-1">
                <label
                  htmlFor="bankCurrency"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Currency
                </label>

                <select
                  id="bankCurrency"
                  name="bankCurrency"
                  value={formData.bankCurrency}
                  onChange={handleChange}
                  className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                >
                  <option value="">Select Currency</option>
                  <option value="USD">USD ($) - United States dollar</option>
                  <option value="CAD">CAD ($) Canadian dollar</option>
                </select>
              </div>
              <div className="w-full sm:col-span-1">
                <label
                  htmlFor="bankCurrency"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Credit Card
                </label>

                <select
                  id="bankCardType"
                  name="bankCardType"
                  // value={formData.bankC}
                  // onChange={handleChange}
                  className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                >
                  <option value="">Select Card Type</option>
                  <option value="USD">American Express</option>
                  <option value="CAD">Bank of America</option>
                  <option value="USD">Barclays</option>
                  <option value="CAD">Capital One</option>
                  <option value="USD">Chase</option>
                  <option value="CAD">Citi</option>
                  <option value="USD">Discover</option>
                  <option value="CAD">Synchrony</option>
                  <option value="USD">U.S. Bank</option>
                  <option value="CAD">Wells Fargo</option>
                </select>
              </div>
                   <div className="flex sm:col-span-2 gap-3">
                <div className="w-full">
                  <label
                    htmlfor="nameOnCard"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Name on the Card
                  </label>
                  <input
                    type="text"
                    id="nameOnCard"
                    name="nameOnCard"
                    value={formData.nameOnCard}
                    onChange={handleChange}
                    className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                    placeholder="Enter exact name shows on the card"
                  />
                </div>
              </div>
           
              <div>
                <label
                  htmlFor="cardNumber"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Card #
                </label>
                <input
                  type="number"
                  id="cardNumber"
                  name="cardNumber"
                  value={formData.cardNumber}
                  onChange={handleCardChange}
                  className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Card Number"
                />
              </div>
              <div>
                <label
                  htmlFor="cardExpiryDate"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Exp Date
                </label>
                <input
                  type="date"
                  id="cardExpiryDate"
                  name="cardExpiryDate"
                  value={formData.cardExpiryDate}
                  onChange={handleChange}
                  className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Date"
                />
              </div>
              <div>
                <label
                  htmlFor="cardCVV"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  CVV #
                </label>
                <input
                  type="number"
                  id="cardCVV"
                  name="cardCVV"
                  value={formData.cardCVV}
                  onChange={handleCvvChange}
                  className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter CVV"
                />
              </div>
              <div>
                <label
                  htmlFor="cardZipCode"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Zip Code
                </label>
                <input
                  type="number"
                  id="cardZipCode"
                  name="cardZipCode"
                  value={formData.cardZipCode}
                  onChange={handleZipCodeChange}
                  className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Zip Code"
                />
              </div>
         
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-6">
              <div className="w-full sm:col-span-2">
                <label
                  htmlFor="bankCurrency"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Currency
                </label>

                <select
                  id="bankCurrency"
                  name="bankCurrency"
                  value={formData.bankCurrency}
                  onChange={handleChange}
                  className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                >
                  <option value="">Select Currency</option>
                  <option value="USD">USD ($) - United States dollar</option>
                  <option value="CAD">CAD ($) Canadian dollar</option>
                </select>
              </div>
              <div className="w-full">
                <label
                  htmlFor="bankRouting"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Bank Routing #
                </label>
                <input
                  className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Routing Number"
                  type="text"
                  id="bankRouting"
                  name="bankRouting"
                  value={formData.bankRouting}
                  onChange={handleBankRouting}
                />
              </div>
              <div className="w-full">
                <label
                  htmlFor="bankAccount"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Bank Account #
                </label>
                <input
                  className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Account Number"
                  type="text"
                  id="bankAccount"
                  name="bankAccount"
                  value={formData.bankAccount}
                  onChange={handleBankAccount}
                />
              </div>
            </div>
          )}
          <div className="flex justify-end space-x-3">
            {/* <button
              onClick={clearPaymentsForm}
              type="submit"
              className="hover:bg-[#3479E0] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#3479E0]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
            >
              Clear
            </button> */}
            {/* <button
              onClick={handleCancel}
              type="submit"
              className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
            >
              Done
            </button>
          </div>
        </div> */} 
      </form>
    </div>
  );
}

export default CustomerForm;
