import axios from "axios";
import React, { useEffect, useMemo, useState , useRef} from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { Calendar } from 'primereact/calendar';
import { createColumnHelper } from "@tanstack/react-table";
import { DatePickerComponent  } from '@syncfusion/ej2-react-calendars';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { addYears, subYears } from 'date-fns';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import {
  useReactTable,
  flexRender,
  getCoreRowModel,
} from "@tanstack/react-table";
import Table from "./Table";
import { Tooltip } from "react-tippy";
import Search from "../Search/Search";
import { useDispatch , useSelector } from "react-redux";
// import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MdCalendarMonth } from "react-icons/md";
import moment from "moment";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primereact/resources/themes/saga-blue/theme.css'; // theme css
import 'primereact/resources/primereact.min.css'; // core css
import 'primeicons/primeicons.css';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import config  from "../../config/config"



const ItemPrices = () => {

  const dispatch = useDispatch()
  
  // const  businessDetails = useSelector((state) => state.business.data)
  // console.log("business details " , businessDetails);

  // const [businessDetails, setBusinessDetails] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  // const isEditActive = selectedRows.length === 1;
  // const isDeleteActive = selectedRows.length > 0; // for empty
  const [openDeleteModalId, setOpenDeleteModalId] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [itemPricesData , setItemPricesData]  = useState([])
    const [productOptions, setProductOptions] = useState([]);
    const [selectedProductItems, setSelectedProductItems] = useState([]);
  const [contactInfoEditMode, setContactInfoEditMode] = useState(false);
  const [today, setToday] = useState(null);
  const columnHelper = createColumnHelper();
  const [loading, setLoading] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [selectedItemId , setSelectedItemId] = useState("")
  const [selectedItemName , setSelectedItemName] = useState("")
  const [itemData, setItemData] = useState([]);
  const [businessType , setBusinessType] = useState()

  const [date , setDate] = useState()
  const [minDate, setMinDate] = useState(new Date('2019-01-01')); // Default to January 1st, 2019
  const [maxDate, setMaxDate] = useState(new Date('2029-12-31'));


  const businessId = localStorage.getItem("business_id")
  const user_id = localStorage.getItem("user_id")
  const user_role = localStorage.getItem("user_role")
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  
  
  function GradientCircularProgress() {
    return (
      <React.Fragment>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#e01cd5" />
              <stop offset="100%" stopColor="#1CB5E0" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
      </React.Fragment>
    );
  }

  useEffect(() => {
    axios
    .get(`${baseURL}${btoa("business/profile")}/${btoa(businessId)}/${btoa(user_id)}/${btoa(user_role)}`)
      .then((response) => {
        console.log("response" , response);

        const Type = response.data.business_data[0].business_type
        setBusinessType(Type)
        // localStorage.setItem("business_type" , Type)
        console.log("business type" , businessType);
       
      })
      .catch((error) => {
        console.error("Error fetching managers data:", error);
       
      });
  }, );
  
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${baseURL}/items/view/${businessId}`)
      .then((response) => {
        const sortedItemData = response.data.items_data.sort(
          (a, b) => b.Item_id - a.Item_id
        );
       
        setItemData(sortedItemData.reverse());
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
      });
  }, []);
  console.log(itemData)

  useEffect(() => {
    async function fetchProductOptions() {
      try {
        const response = await axios.get(
          `${baseURL}/${btoa("product/view")}/${btoa(businessId)}`
        );
        console.log("response");
        // Assuming the response.data is an array of products
        setProductOptions(response.data.products_data);
        console.log("product options" , productOptions);
      } catch (error) {
        console.error("Error fetching product options:", error);
      }
    }
    fetchProductOptions();
  }, []);

  const [items, setItems] = useState([
    { 
      product:"",
      item: "",
      description: "",
      quantity: "",
      unitPrice: "",
      total: 0,
      editableDescription: false,
    
    },
  ]);
  


  const navigate = useNavigate();

 



  const handleChange = (value) => {
    if (value) {
        setItemPricesData([]);
        // console.log("val", value);
        const currentDate = value;
        const monthYearString = `${(currentDate.getMonth() + 1).toString().padStart(2, '0')}/${currentDate.getFullYear()}`;
        setDate(monthYearString.replaceAll("/", "-"));
    }
};

  // const handleDateChange = (date) => {
  //   const selectedMonth = date.$M + 1; 
  //   const selectedYear = date.$y;
  //   const formattedDate = selectedMonth.toString().padStart(2, '0') + '-' + selectedYear;
  //   console.log("Selected Date: ", formattedDate);
  //   setDate(formattedDate);
  // };
  
  useEffect(() => {
    if (date && selectedItemId){
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseURL}${btoa("items/priceview")}/${btoa(businessId)}/${btoa(date)}/${btoa(selectedItemId)}`
        );
        setItemPricesData(response.data);
        setSelectedItemName(response.data[0].Item_name)
        console.log("response price", response);
      } catch (error) {
        console.error("Error fetching item prices", error);
        setItemPricesData([])
      }
    };
  
    fetchData();
   } // Call fetchData when date or selectedItemId changes
  }, [date, selectedItemId]);

 

  const handleProductChange = (index, field, value) => {
    setSelectedProductId(value);
    console.log("selected product id" , value);
    
    if (!value) {
      // If the user deselects the product, clear the selectedProductItems and update items
      const updatedItems = [...items];
      updatedItems[index].selectedProductItems = [];
      updatedItems[index][field] = ""; // Clear the product value
      setItems(updatedItems);
    } else {
      // If a product is selected, fetch and update selectedProductItems
      axios
        .get(`${baseURL}/items/getitemsbyproduct/${businessId}/${value}`)
        .then((response) => {
          setSelectedProductItems(response.data.itemsByProduct);
          const updatedItems = [...items];
          updatedItems[index].selectedProductItems = response.data.itemsByProduct;
          updatedItems[index][field] = value; // Set the product value
          setItems(updatedItems);
        })
        .catch((error) => {
          console.error("Error fetching items for the selected product:", error);
        });
      }
  };

  const handleItemChange = (index, field, value ,selectedItemName) => {
    setItemPricesData([])
    setSelectedItemId(value);
    // setSelectedItemName(selectedItemName)
  };
  useEffect(() => {
    if (date && selectedItemId){
    const fetchData = async () => {
      try {
          const response = await axios.get(
          `${baseURL}/items/priceview/${businessId}/${date}/${selectedItemId}`
        );
        setItemPricesData(response.data);
        setSelectedItemName(response.data[0].Item_name)
        console.log("response price", response);
      } catch (error) {
        console.error("Error fetching item prices", error);
        setItemPricesData([])
      }
    };
    
    fetchData(); // Call fetchData when selectedItemId changes
  }
  }, [selectedItemId , date]);
  

  const columns = [
  
    {
      id: "itemColumn", 
      header: selectedItemName, // Static header for the item name column
    
      columns: [
        {
          header: "#",
          accessorFn: (row, rowIndex) => rowIndex + 1
        },
        {
          header: "Old Price",
          accessorKey: "old_unit_price",
        },
        {
          header: "Last Updated Date",
          accessorKey: "old_date",
          accessorFn: (row) => {
            const value = row && row.added_on;
            if (value && typeof value === "string") {
              const [year, month, day] = value.split('-');
              const formattedDate = `${month}-${day}-${year}`;
              return formattedDate;
            } else {
              return ""; 
            }
          }
        },
        
        {
          header: "New Price",
          accessorKey: "new_unit_price",
        },
        {
          header: "Date",
          accessorKey: "added_on",
          accessorFn: (row) => {
            const value = row && row.added_on;
            if (value && typeof value === "string") {
              const [year, month, day] = value.split('-');
              const formattedDate = `${month}-${day}-${year}`;
              return formattedDate;
            } else {
              return ""; 
            }
          }
        },
      ]
    }
  ];
  
  

  const finalData = React.useMemo(() => itemPricesData,[itemPricesData])
  const finalColumnDef = React.useMemo(() => columns, [columns])

  const tableInstance = useReactTable({
    columns : finalColumnDef,
    data : finalData,
    getCoreRowModel : getCoreRowModel()
  })

  return (
    <>  
        <div className="flex justify-between">
            <h1 className="mx-8 font-bold text-gray-400 text-xl mt-10 mb-10 ml-12">
                      Item Price Log
            </h1>
            
            <div className="mt-24 position : absolute ml-9">
              {items.map((item, index) => (
                  <>
                  <div key={index} className="flex space-x-5">

                    {businessType === "1" && (
                    <>
                         <div className="p-2 space-y-2">
                        <label className="text-gray-500 font-semibold">Select Product</label>
                        <select
                        // value={item.item}
                        onChange={(e) => {
                          handleProductChange(index, "product", e.target.value );
                        }}
                        className="bg-gray-50 border border-gray-300  text-base rounded-md focus:ring-primary-600 focus:border-primary-600 block p-1"
                        style={{
                          width: "210px",
                          height : "45px"
                        }}
                      >
                        <option value="" >Select Product</option>
                        {productOptions?.map((product) => {
                          return (
                            <option
                              key={product.product_id}
                              // value={`${item.Item_id}#${item.Item_name}`}
                              value={product.product_id}
                            > 
                              {product.product_name}
                            </option>
                          );
                        })}
                        </select>
                        </div>

                        <div className="p-2 space-y-2">
                            <label className="text-gray-500 font-semibold">Select Item</label>
                            <select
                              // value={item.item}
                              onChange={(e) => {
                                const selectedItemName = e.target.options[e.target.selectedIndex].text;
                                  handleItemChange(index, "item", e.target.value , selectedItemName);
                                  
                                
                              }}
                              className="bg-gray-50 border  border-gray-300 text-base rounded-md focus:ring-primary-600 focus:border-primary-600 block p-1"
                              style={{
                                width: "210px",
                                marginLeft : "",
                                height : "45px"
                              }}
                            >
                              <option value="">Select Item</option>
                              {item.selectedProductItems?.map((selectedItem) => {
                                return (
                                  <option
                                  key={selectedItem.Item_id}
                                  value={selectedItem.Item_id}
                                  >
                                    {selectedItem.Item_name}
                                  </option>
                                );
                              })}
                            </select>
                        </div>
                    </>
                    )}

                    {
                      businessType === "2" && (
                      <div className="p-2 space-y-2">
                          <label className="text-gray-500 k font-semibold">Select Item</label>
                          <select
                            // value={item.item}
                            onChange={(e) => {
                              const selectedItemName = e.target.options[e.target.selectedIndex].text;
                                handleItemChange(index, "item", e.target.value , selectedItemName);
                                // setSelectedItemName(selectedItemName)
                              
                            }}
                            className="bg-gray-50 border border-gray-300 rounded-md  text-base  focus:ring-primary-600 focus:border-primary-600 block p-1"
                            style={{
                              width: "210px",
                              marginLeft : "",
                              height : "45px"
                            }}
                          >
                            <option value="">Select Item</option>
                            {itemData?.map((selectedItem) => {
                              return (
                                <option
                                key={selectedItem.Item_id}
                                value={selectedItem.Item_id}
                                >
                                  {selectedItem.Item_name}
                                </option>
                              );
                            })}
                          </select>
                      </div>
                    )}

                <div className="p-2 flex flex-col space-y-2 relative">
                    <label className="text-gray-500 font-semibold">Select month</label>
                    <div className="relative">
                        <Calendar 
                            className="custom-calendar"
                            value={today} 
                            onChange={(e) => handleChange(e.value)} 
                            view="month"
                            dateFormat="M-yy"
                            placeholder="Select month"
                            maxDate={new Date()} 
                            pt={{
                              input: {
                                  root: { className: 'border-gray-300 rounded-md bg-gray-50 ' }
                              },
                              dropdownButton: {
                                  root: { className: 'bg-gray-500 border-gray-300 rounded-md bg-gray-50' }
                              }
                          }}
                          
                        />
                        <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                            <MdCalendarMonth size={20} />
                        </div>
                    </div>
                </div>

                  </div>
                  </>
                ))}
              </div>

               <div className="flex space-x-3 mt-32 mb-32 mr-32">
                  {/* <div className="relative"> */}
                    {/* <Calendar 
                        
                        id="priceLogCalender"
                        value={today} 
                        onChange={(e) => handleChange(e.value)} 
                        view="month" 
                        dateFormat="mm/yy"
                        placeholder="Select month"
                        showIcon
                        maxDate={new Date()} 
                        style={{ backgroundColor: '#3fd7f2' }}
                      //   style={{
                      //     transition: 'box-shadow 0.3s ease-in-out',
                      // }}
                    /> */}

                      
                     {/* <div className="mr-28 w-60 mt-11">

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                              <DatePicker 
                              className="priceDatePicker"
                              sx={{
                                width: '100%',
                                height: '20%',
                                display: 'flex',
                                justifyContent: 'center',
                                position: 'relative',
                              }}
                             
                              label={'Select month'} 
                              views={['month', 'year']} 
                              onChange={(date)=> handleDateChange(date)}
                              // minDate={moment('01-01-1000').toDate()}
                              // maxDate={moment('12-31-2023').toDate()}
                              />
                          </DemoContainer>
                          </LocalizationProvider>
                          
                     </div> */}

                      {/* <div className="absolute top-[-40px] left-[-10px] w-full h-6 flex justify-center items-center text-gray-500 font-semibold">
                        Select month
                    </div> */}
                   {/* </div> */}
              
             


              </div>
        </div>

                
    
        {/* <div className="flex justify-center items-center mb-24 mx-auto left-1/2">
            <Stack spacing={2} sx={{ flexGrow: 1 }}>
                <GradientCircularProgress />
            </Stack>
        </div>  */}

   

        {date  && selectedItemId && itemPricesData.length === 0 ? (
          // <div className="flex justify-center items-center p-52">
          //   <Box sx={{ display: 'flex' }}>
          //     <CircularProgress />
          //   </Box>
          // </div>
              <div className="flex justify-center items-center mb-24 mx-auto left-1/2">
              <Stack spacing={2} sx={{ flexGrow: 1 }}>
                  <GradientCircularProgress />
              </Stack>
              </div> 

        ) : (
          itemPricesData.length > 0 && (
            <div className="border border-gray-500/6 w-[94%] rounded-xl p-8 mt-2 bg-white ml-10">
                <div className="flex justify-between items-center px-4">
           
            </div>

          <table className="w-full table-auto bg-transparent rounded-xl">
            <thead className="bg-gray-500/20 text-gray-500 ">
                {tableInstance.getHeaderGroups().map((headerEl) => {
                  return <tr key={headerEl.id} className="text-center text-xs">
                    {headerEl.headers.map(columnEl => {
                           const isFirstHeader = columnEl === 0;
                           const isLastHeader = columnEl === columnEl.length - 1;
         
                           const headerClasses = [
                             "p-3 font-bold uppercase",
                             isFirstHeader ? "rounded-l-xl" : "", // Add 'rounded-tl-xl' to the first header
                             isLastHeader ? "rounded-r-xl" : "", // Add 'rounded-tr-xl' to the last header
                           ];

                      return <th key={columnEl.id} colSpan={columnEl.colSpan} className={headerClasses.join(" ")} >

                        {
                          columnEl.isPlaceholder ? null : flexRender(
                            columnEl.column.columnDef.header,
                            columnEl.getContext()
                          )
                        }
                          
                      </th>
                    })}
                  </tr>
                })}
                  </thead>
            <tbody> 
                {tableInstance.getRowModel().rows.map((rowEl) => {  
                  return (<tr key={rowEl.id}  className={`cursor-pointer text-center hover:bg-gray-500/10`}>
                    {rowEl.getVisibleCells().map((cellEl) => {
                      return (
                      <td key={cellEl.id} className={`p-4 font-semibold text-[#081159] text-[14px]`}>
                        {flexRender(cellEl.column.columnDef.cell, cellEl.getContext() )}
                      </td>)
                    })}
                    </tr>)
                })}
          </tbody>
          </table>

            </div>
          )
        )}

    

    
        
    
                        
        
    </>
  );
};

export default ItemPrices;
