import React, { useState } from 'react';
import { useDispatch } from "react-redux";

import { Modal, Box, Typography, Radio, RadioGroup, FormControlLabel, Button, TextField } from '@mui/material';
import axios from "axios";
import config  from "../../config/config"
import { addToast } from '../pages/ToastBox/ToastBoxMessageSlice';
import { useNavigate} from 'react-router-dom';


const InvoicePayModal = ({ open, handleClose, customerName, invoiceNumber, finalInvoiceAmount,
    resetRowSelection,
    setTriggerFetch,
  triggerFetch

 }) => {
  const [paymentType, setPaymentType] = useState(''); // full or partial
  const [amount, setAmount] = useState(''); // for partial payment
  const [tempamount, settempAmount] = useState(''); // for partial payment

  const [remainingAmount, setRemainingAmount] = useState(null);
  const [error, setError] = useState(false);
  const [comment, setComment] = useState('');
  const [isClicked, setIsClicked] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;

  // For demonstration, assume a fixed total amount
  const totalAmount = finalInvoiceAmount;
  const CustomTooltip1 = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? 'block' : 'none',
          position: 'absolute',
          backgroundColor: '#ffffff',
          color: '#fff',
          padding: '5px',
          borderRadius: '4px',
          border:'1px solid red',
          marginTop: '-80px',
          marginLeft: `${marginLeft}px`,  // Use the provided marginLeft prop
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div
          style={{
            position: 'absolute',
            bottom: '-7px',
            left: '50%',
            marginLeft: '-10px',
            width: '0',
            height: '0',
            borderLeft: '7px solid transparent',
            borderRight: '7px solid transparent',
            borderTop: '7px solid #f98080',
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };

  const handlePaymentTypeChange = (event) => {
    const type = event.target.value;
    setPaymentType(type);

    // Reset amount and remaining amount if payment type changes
    if (type === 'fullPaid') {
      setAmount('');
      setRemainingAmount(null);
    }
    
  };

  
  const handleAmountChange = (e) => {
    const inputValue = e.target.value;
    console.log('input value',  formatNumberWithCommas(inputValue))
    const formattedamount =  formatNumberWithCommas(inputValue);
    // Convert `finalInvoiceAmount` to a number by removing commas
    const parsedFinalInvoiceAmount = parseFloat(finalInvoiceAmount);
    
    // Set `parsedAmount` as 0 if `inputValue` is empty; otherwise, parse it to a number
    const parsedAmount = inputValue === "" ? 0 : parseFloat(inputValue);
    
    // Ensure only valid numbers are processed
    if (!isNaN(parsedAmount)) {
      setAmount(inputValue);
      settempAmount((inputValue))
      setRemainingAmount(parsedFinalInvoiceAmount - parsedAmount);
  
      // Set error if the entered amount exceeds the final invoice amount
      setError(parsedAmount > parsedFinalInvoiceAmount);
    } else {
      setError(true); // Set error if input is not a valid number
    }
  };


const formatNumberWithCommas = (number) => {
  return new Intl.NumberFormat('en-US').format(number);
};
const handleBlur = () => {
  const number = parseFloat(tempamount.replace(/,/g, ''));
  if (!isNaN(number)) {
    settempAmount(number.toLocaleString("en-US"));
  }
};
const handleFocus = () => {
  settempAmount(amount)
}
const userRole = localStorage.getItem("user_role");
const handleSubmit = async () => {
  if (isClicked) return;
    const url = `${baseURL}/${btoa("invoice/customerpayment")}`;
    console.log('Request URL:', url);
    setIsClicked(true)

    try {
      const formattedReceivedAmount = paymentType === 'fullPaid' ? parseFloat(finalInvoiceAmount.toFixed(2)) : Number(amount || 0).toFixed(2);
      const formattedDueAmount = paymentType === 'fullPaid' ? 0 : Number(remainingAmount || 0).toFixed(2);
  
      const response = await axios.post(url, {
          custName: customerName,
          businessId: localStorage.getItem('business_id'),
          managerId: localStorage.getItem('user_id'),
          invoiceNumber: invoiceNumber,
          finalInvoiceAmount:parseFloat(finalInvoiceAmount.toFixed(2)), // ensure it's a number
          payType: paymentType,
          receivedAmount: Number(formattedReceivedAmount),
          dueAmount: Number(formattedDueAmount), // convert to a number for backend
          comment: paymentType === 'fullPaid' ? '' : comment || null,
          userRole:userRole
      });
      
      console.log('Payment modal response:', response.data);

      if (response.data.dataSavingStatus === true) {
          dispatch(addToast({ 
              type: 'partialPay', 
              message: paymentType === "fullPaid" 
                  ? `${customerName} Paid Full Amount successfully` 
                  : `${customerName} Paid Partial Amount successfully` 
          }));
  
          handleClose();
          resetRowSelection();
          setTriggerFetch(!triggerFetch);
      }
  }
  catch (error) {
        if (error.response) {
            console.log("Error response data:", error.response.data);
            console.log("Error response status:", error.response.status);
            console.log("Error response headers:", error.response.headers);
        } else if (error.request) {
            console.log("Error request:", error.request);
        } else {
            console.log("Error message:", error.message);
        }
    }
finally {
  setIsClicked(false)
}
    console.log(`Payment type: ${paymentType}`);
    if (paymentType === 'partialPaid') {
        console.log(`Partial Amount Paid: ${amount}`);
        console.log(`Remaining Amount: ${remainingAmount}`);
    }
    // handleClose();
    navigate('/invoice')

};
  return (


<Modal open={open} onClose={handleClose}>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      borderRadius: 2,
      boxShadow: 24,
      p: 4,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    {/* Customer Name - Center Aligned */}
    <Typography variant="h6" component="h2" mb={2} align="center">
      {customerName}
    </Typography>

    {/* Expected Amount - Left Aligned with Final Invoice Amount Right Aligned */}
    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
      <Typography variant="body1" component="span" sx={{ fontWeight: 'bold' }}>
        Invoice Amount:
      </Typography>
      <input
        type="text"
        value={formatNumberWithCommas(parseFloat((finalInvoiceAmount).toFixed(2)))}

        // value={finalInvoiceAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        placeholder="Invoice Amount"
        readOnly
        className="h-8 bg-gray-200 border border-gray-300 text-gray-900 text-center rounded-md focus:ring-primary-600 focus:border-primary-600 block p-1"
        style={{ width: '50%' }}
      />
    </Box>

    {/* Payment Type Selection - Label and Radio Buttons */}
    <Typography variant="div" component="b" mt={2} align="left" sx={{ width: '100%' }}>
      Amount Paid
    </Typography>
    <RadioGroup
      row
      value={paymentType}
      onChange={handlePaymentTypeChange}
      aria-label="payment-type"
      name="payment-type"
      sx={{ width: '100%', justifyContent: 'space-between', mt: 1 }}
    >
      <FormControlLabel value="fullPaid" control={<Radio />} label="Full Pay" />
      <FormControlLabel value="partialPaid" control={<Radio />} label="Partial Pay" />
    </RadioGroup>

    {/* Enter Amount - Label and Input Box */}
    {paymentType === "partialPaid" && (
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" mt={0}>
        <Typography variant="body1" component="span" sx={{ fontWeight: 'bold', color: '#000000' }}>
          Enter Amount:
        </Typography>
        <input
          type="text"
          // onInput={formatInput()}
          onBlur={handleBlur}
onFocus={handleFocus}
          value={tempamount}
          placeholder="Enter Amount"
          onChange={(e) => handleAmountChange(e)}
          className="h-8 bg-gray-10 border border-gray-300 text-gray-900 text-center rounded-md focus:ring-primary-600 focus:border-primary-600 block p-1"
          style={{ width: '50%' }}
        />
        {error && (
          <CustomTooltip1
          content={`Enter less than ${formatNumberWithCommas(parseFloat(finalInvoiceAmount.toFixed(2)))}`}

            // content={`Enter less than ${finalInvoiceAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
            show={`Enter less than ${formatNumberWithCommas(parseFloat(finalInvoiceAmount.toFixed(2)))}`}
            marginLeft={163}
          />
        )}
      </Box>
    )}

    {/* Remaining Amount Display */}
    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" mt={1}>
      {amount && (
        <>
          <Typography variant="body1" component="span" sx={{ fontWeight: 'bold', color: '#000000' }}>
            Due Amount:
          </Typography>
          <input
            type="text"
            value={remainingAmount >= 0 ? formatNumberWithCommas(parseFloat(remainingAmount.toFixed(2))) : "0"}

            // value={remainingAmount >= 0 ? remainingAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0"}
            placeholder="Due Amount"
            readOnly
            className="h-8 bg-gray-200 border border-gray-300 text-gray-900 text-center rounded-md focus:ring-primary-600 focus:border-primary-600 block p-1"
            style={{ width: '50%' }}
          />
        </>
      )}
    </Box>

    {/* Comment Section - Only when Partial Pay is selected */}
    {paymentType === "partialPaid" && (
      <Box width="100%" mt={2}>
        <Typography variant="body1" component="span" sx={{ fontWeight: 'bold', color: '#000000' }}>
          Comments:
        </Typography>
        {/* <TextField
          multiline
          rows={4}
          value={comment}
          placeholder="Add comments"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(e) => setComment(e.target.value)}
        /> */}
           <textarea
            id="comments"
            rows="2"
            name="comments"
            value={comment}
            maxLength={100}
            onChange={(e) => setComment(e.target.value)}
            className="block mt-2 mb-2 p-2.5 w-full text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 "
            placeholder="Write your Comments here..."
          ></textarea>
      </Box>
    )}

    {/* Submit and Cancel Buttons - Center Aligned */}
    <Box mt={1} display="flex" justifyContent="center" width="100%">
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={
          !paymentType || (error && paymentType === "partialPaid") || (paymentType === 'partialPaid' && !amount)
        }
        sx={{ marginRight: 2 }}
      >
        {isClicked ?  <div className="flex items-center">
             Submitting...
             <div className="spinner border-t-2 border-b-2 border-white rounded-full w-4 h-4 animate-spin"></div>

          </div> : 'Submit'}
      </Button>
      <Button variant="outlined" color="error" onClick={handleClose}>
        Cancel
      </Button>
    </Box>
  </Box>
</Modal>








  );
};

export default InvoicePayModal;
