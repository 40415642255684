import React, { useEffect, useState , useRef } from "react";
import Revenue from "./Revenue";
import ScrollToTop from "./ScrollTop";
import { createColumnHelper ,
          useReactTable, 
          flexRender,
          getCoreRowModel,
          getFilteredRowModel,
          getPaginationRowModel,
          getSortedRowModel, } from "@tanstack/react-table";
import { useSortBy } from "react-table";
import TableMenu from "./TableMenu";
import axios from "axios";
import dayjs from 'dayjs';
import { FaEye, FaTrashAlt, FaDownload , FaEdit} from 'react-icons/fa';

import { Navigate, json, useNavigate } from "react-router-dom";
import TableInvo from "./TableInvo";
import { Tooltip } from "react-tippy";
import DatePicker2 from "./DatePicker2";
import DatePicker1 from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker, Space } from "antd";

import IndeterminateCheckbox from "./IndeterminateCheckbox";
import { FiDownload } from "react-icons/fi";
import { AiFillMessage } from "react-icons/ai";
import { AiFillMail } from "react-icons/ai";

import { logDOM } from "@testing-library/react";
import InvoiceViewModal from "./InvoiceViewModal";
import config  from "../../config/config"
import { useDispatch , useSelector } from "react-redux";
import { addInvoice  , addDraftInvoice , deleteAllInvoices } from "../../store/invoiceSlice";
import PopUpModal from "./InvoiceDeleteModal";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { FaArrowDownLong , FaArrowUpLong} from "react-icons/fa6";
import { MdFilterList } from "react-icons/md";
import Badge from '@mui/material/Badge';
import PopUpModalForSendInvoice from "./invoiceSendModel";
import ScrollingMessage from "./ScrollingMessage";
import { showToastForUpdateInvoice } from "../toasts/toastForInvoice";
import ToastNotificationBox from "./ToastBox/ToastNotificationBox";
import { addToast } from '../pages/ToastBox/ToastBoxMessageSlice';
import InvoicePayModal from "./InvoicePayModal";
import DraftsIcon from '@mui/icons-material/Drafts';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SendIcon from '@mui/icons-material/Send';
import WarningIcon from '@mui/icons-material/Warning';
import MoneyIcon from '@mui/icons-material/Money';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DescriptionIcon from '@mui/icons-material/Description';  // For document symbol
import TextField from '@mui/material/TextField'; // Make sure this import is at the top

import { colors } from "@mui/material";
function Pricing() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filtering , setFiltering] = useState("")
  const [isfilterOpen, setIsfilterOpen] = useState(false);

  const [rightcardata , setrightcardata] = useState([])
    const [isModalOpencard, setIsModalOpencard] = useState(false);
      
      const [isCostToolTip, setIsCostToolTip] = useState(false);
//   const cardsData =
// sellerNames.length > 0 ?
// sellerNames.map((sellerName, index) => ({
//   id: sellerName.brand_id,
//   brandName: sellerName.seller_name, // Assuming brand_name is a property
//   logo: sellerName.brand_logo,
//   gallons: sellerName.total_gallons, // Adjust gallons data as needed
//   borderColor: colors[index % colors.length],
//   total_amount:sellerName.total_amount
// }))
// :[];
const cardsData = [
  {
    id: 1,
    brandName: 'Sunoco',
    logo: 'sunoco.png', // Assuming 'sunoco.png' is a string representing the path to the logo
    gallons: 1524, // Adjust gallons data as needed
    borderColor: 'green', // 'green' should be a string
    total_amount: 5478,
  },
  {
    id: 2, // Changed the ID to 2 to avoid duplicate IDs
    brandName: 'Shell',
    logo: 'shell.png', // Assuming 'shell.png' is another logo path
    gallons: 1400, // Adjust gallons data as needed
    borderColor: 'blue', // Changed the borderColor to differentiate
    total_amount: 4800,
  },
];


  const columnHelper = createColumnHelper();
  const [productOptions, setProductOptions] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openDeleteModalId, setOpenDeleteModalId] = useState(null);
  const [openSendInvoiceModalId, setOpenSendInvoiceModalId] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [clicked, setClicked] = useState(null);
  const [enterinvoiceinputstyles, setenterinputinvoicestyles] = useState('');
  
  const [invoiceTypeStyles, setinvoicetypestyles] = useState('');
  const [customerstyles, setcustomerStyles] = useState('');


  const isEditActive = selectedRows.length === 1;
  const isDeleteActive = selectedRows.length > 0;
  const [invoiceData, setInvoiceData] = useState([]);
  const [openModalId, setOpenModalId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [originalItemData, setOriginalItemData] = useState([]);
  const [businessType , setBusinessType] = useState()

  const [rowSelection, setRowSelection] = React.useState({});
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [sorting , setSorting] = React.useState([])
  const [columnFilters, setColumnFilters] = useState("")
  
  const [selectedCustomer , setSelectedCustomer] = useState("")
  const [filteredStatus, setFilteredStatus] = useState("all");
  const [selectedDateFrom, setSelectedDateFrom] = useState("");
  const [selectedDateTo, setSelectedDateTo] = useState("");
  const [selectedInvoiceNumber , setSelectedInvoiceNumber] = useState("");
 
  const user_id = localStorage.getItem("user_id")
  const userRole = localStorage.getItem("user_role")
  const business_id = localStorage.getItem("business_id")
  const managerId = localStorage.getItem("user_id")
  const [triggerFetch, setTriggerFetch] = useState(false);
  // const [customerFiltering , setCustomerFiltering] = useState("")
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [status , setStatus] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customerEmail , setCustomerEmail] = useState('')
  const [payModalInvoice, setpayModalInvoice] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false);

  // Function to handle scroll event
  const handleScroll = () => {
    if (window.scrollY > 220) {
      setIsScrolled(true);  // Add background when scrolling
    } else {
      setIsScrolled(false); // Remove background when at the top
    }
  };

  const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
  };
  // Adding the scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(()=> {
    setClicked('draft')
  }, [])
  const invoicesAll = useSelector(state => state.invoice?.allInvoices[0]);
  // const fetchStatus = useSelector(state => state.invoice?.status);
  console.log(invoicesAll, 'fetchststus')

  const [tableData, setTableData] = useState([]);
  // console.log('tabledata', tableData);
  useEffect(() => {
    if (invoicesAll) {
      const formattedData = invoicesAll.map(invoice => ({
        ...invoice,
        invoice_number: (invoice.invoice_number),
        final_invoice_amount: Number(invoice.final_invoice_amount),
        final_total_amount : Number(invoice.final_total_amount)
      }));
      setTableData(formattedData);
    }
  }, [invoicesAll]);
  // const scrollingMessages = useSelector((state) => state.scrollingMessage?.message[0]);
  // console.log("invoicess all" , invoicesAll);
  // console.log("message from store " , scrollingMessages);
  
  const [value, setValue] = React.useState('draft');
  const handleChange = (newValue) => {
    // if(newValue === "overdue") return;
    setClicked(newValue)
    setValue(newValue);
    setFilteredStatus(newValue)
  };

  const handleFilterClick = () => {
    setIsFilterVisible(!isFilterVisible);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const businessId = localStorage.getItem("business_id")
  const datePickerFromRef = useRef(null);
  const datePickerToRef = useRef(null);

  const handleIconClick = (ref) => {
    if (ref.current) {
      ref.current.setFocus();
    }
  };

  const handleDateChangeFrom = (from) => {
    console.log(from , 'from date.....')
    if (!from || !dayjs(from).isValid()) {
      // If the date is invalid or null, clear the state
      setSelectedDateFrom(null);
      return;
    }
    setSelectedDateFrom(from);
  };

  const handleDateChangeTo = (to) => {
    if (!to || !dayjs(to).isValid()) {
      // If the date is invalid or null, clear the state
      setSelectedDateTo(null);
      return;
    }
    setSelectedDateTo(to);
  };

  const handleDeleteInvoices = async () => {
    if (selectedRows.length > 0) {

    } 
  };
  const handlePayModalInvoice = ()=>{

    setpayModalInvoice(true)
  }

  const handleDeleteModalInvoice = (invoiceId) => {
    // alert(selectedRows.invoice_number)
    if (selectedRows) {  
      // console.log("sel" , selectedRows);
      setOpenDeleteModalId(table.getSelectedRowModel().flatRows[0]?.original.invoice_number);
    }
  };

  useEffect(() => {
    async function fetchCustomerOptions() {
      try {
        const response = await axios.get(
          `${baseURL}/${btoa("customer/view")}/${btoa(businessId)}`
        );
        setProductOptions(response.data.customers_data);
      } catch (error) {
        console.error("Error fetching customer options:", error);
      }
    }
    fetchCustomerOptions();
  }, []);

  useEffect(() => {
    axios
      .get(`${baseURL}/${btoa("business/profile")}/${btoa(business_id)}/${btoa(user_id)}/${btoa(userRole)}`)
      .then((response) => {
        // console.log("response businesstype" , response);
        const Type = response.data.business_data[0].business_type
        setBusinessType(Type)
      })
      .catch((error) => {
        console.error("Error fetching business data:", error);
       
      });
  },[] );

  // Fetching Data from APi into tables
  const handleViewInvoice = () => {
    // alert(businessType)
    if(businessType === "1"){
      navigate(`/invoice/invoiceforms`);
    }else if(businessType === "2"){
      navigate("/invoiceformtwo")
    }else{
      return
    }
  }

  const handleEditInvoice =()=>{
    const invoiceNumbers =  table.getSelectedRowModel().flatRows[0]?.original.invoice_number;
    if(businessType === "1"){
      navigate(`/viewinvoiceform/${invoiceNumbers}`);
    }else if(businessType === "2"){
      navigate(`/viewinvoiceformtwo/${invoiceNumbers}`)
    }else{
      return;
    }
  }

  const handleViewAllInvoice = async()=>{
    const fetchData = async () => {
      try {
        const apiUrl = `${baseURL}/${btoa("invoice/viewinvoicefilter")}/${btoa(business_id)}/${btoa("all")}/${btoa("all")}/${btoa(null)}/${btoa(null)}`;
        const response = await axios.get(apiUrl);
        setInvoiceData(response.data.invoice_data);
        dispatch(deleteAllInvoices())
        dispatch(addInvoice(response.data.invoice_data))
        // console.log("Filtered invoices:", response);
      } catch (error) {
        console.error("Error fetching filtered invoices:", error);
      }
    };
    fetchData();
  }
 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `${baseURL}/${btoa("invoice/viewinvoicefilter")}`;
        const response = await axios.post(apiUrl, {
          customerId: "all",
          status:'draft',
          reportType:null,
          monthName:null,
          quarterName:null,
          year:null,
          businessId:localStorage.getItem('business_id'),
          managerId:localStorage.getItem('user_id'),
          fromMdy:null,
          toMdy:null,
          userRole:userRole
        });
        console.log(response, 'response ...................')
        setInvoiceData(response.data.invoice_data);
        dispatch(deleteAllInvoices());
        dispatch(addInvoice(response.data.invoice_data));
        // dispatch(fetchStatus(response.data));

        setStatus(response.data);
        // console.log("status data" , response.data);
        setFilteredStatus("")
        setValue("draft")
        // console.log("Draft invoices:", response);
      } catch (error) {
        console.error("Error fetching draft invoices:", error);
      }
    };
    fetchData();
  }, [triggerFetch]);

  function formatDatefordatepicker(date) {
    // const day = date.getDate();
    // const month = date.getMonth() + 1; // Months are zero-based
    // const year = date.getFullYear().toString(); // Extract last two digits of year
    // return `${month}-${day}-${year}`;
    const year = date.$y;
    const month = String(date.$M+ 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.$D).padStart(2, '0');
    return `${month}-${day}-${year}`;
  }

useEffect(() => {

  if (initialRender.current) {
    initialRender.current = false;
    return; 
  }
  window.scrollTo(0, 0);
  const fetchData = async () => {
    try {
      const customerParam = selectedCustomer === "" ? "all" : selectedCustomer;
      const statusParam = filteredStatus === "" ? "all" : filteredStatus;
      const fromDateParam = selectedDateFrom === "" ? null : selectedDateFrom; 
      const toDateParam = selectedDateTo === "" ? null : selectedDateTo;
      const apiUrl = `${baseURL}/${btoa("invoice/viewinvoicefilter")}`;
      const response = await axios.post(apiUrl, {
        customerId: customerParam,
        status:statusParam,
        reportType:null,
        monthName:null,
        quarterName:null,
        year:null,
        businessId:localStorage.getItem('business_id'),
        managerId:localStorage.getItem('user_id'),
        // fromMdy:fromDateParam,
        // toMdy:toDateParam, 
        fromMdy:fromDateParam === null ? '' : formatDatefordatepicker(fromDateParam) ,
        toMdy:toDateParam === null ? '': formatDatefordatepicker(toDateParam),
        userRole:userRole
      });
      setInvoiceData(response.data.invoice_data);
      dispatch(deleteAllInvoices())
      dispatch(addInvoice(response.data.invoice_data))
      // dispatch(fetchStatus(response.data))

      setStatus(response.data)
      setrightcardata({brandGallonsTotal:0,
        brandAmountsTotal:0
      })

      // console.log("Filtered invoices:", response);
    } catch (error) {
      console.error("Error fetching filtered invoices:", error);
    }
  };

  fetchData();
}, [business_id, selectedCustomer, filteredStatus, selectedDateFrom, selectedDateTo, selectedInvoiceNumber]);

// Define a ref to track the initial render
  const initialRender = useRef(true);
  let debounceTimer;

  useEffect(() => {
    // Define a debounced version of fetchData
    const debouncedFetchData = () => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        fetchDataByInvoice(selectedInvoiceNumber);
      }, 300); 
    };

    // Check if selectedInvoiceNumber is truthy before executing the debounced fetch
    if (selectedInvoiceNumber) {
      debouncedFetchData();
    }
    
    // Clean up function to clear the timeout on unmount or when selectedInvoiceNumber changes
    return () => clearTimeout(debounceTimer);
  }, [selectedInvoiceNumber]);

  const fetchDataByInvoice = async (selectedInvoiceNumber) => {
    const invoiceNumbers = table.getSelectedRowModel().flatRows[0]?.original.invoice_number;
    try {
      const apiUrl = `${baseURL}${btoa("invoice/fetchinvoicebyinvooiocenumber")}/${btoa(invoiceNumbers)}/${btoa(businessId)}`;
      const response = await axios.get(apiUrl);
      // console.log("invoice on invoice number: " , response);
      setCustomerEmail(response.data.customerData[0].contact_email)
      // setInvoiceData(response.data.customerData);
      // dispatch(deleteAllInvoices())
      // dispatch(addInvoice(response.data.customerData))
    } catch (error) {
      console.error("Error fetching invoice by invoice number", error);
      if (error.response.data === "") {
          setInvoiceData([])
      }
    }
  };

  // useEffect(()=>{
  //     if(selectedRows)
  //   fetchDataByInvoice()
  // },[])
      const [isSubtotalExpanded, setSubtotalExpanded] = useState(false);
          const [isTaxesExpanded, setTaxesExpanded] = useState(false);
          
      
          const toggleSubtotal = (e) => {
            e.preventDefault();
            setSubtotalExpanded(!isSubtotalExpanded); // Toggle Subtotal
            setTaxesExpanded(false); // Ensure Taxes is closed
          };
          
          const toggleTaxes = (e) => {
            e.preventDefault();
            setTaxesExpanded(!isTaxesExpanded); // Toggle Taxes
            setSubtotalExpanded(false); // Ensure Subtotal is closed
          };
  function formatDate(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based
    const year = date.getFullYear().toString(); // Extract last two digits of year
    return `${month}-${day}-${year}`;
  }
  
  // useEffect(()=> {
  // const fetchData = async()=>{
  //   try {
  //     const response  = await axios.get(`${baseURL}${btoa("scrollingmsg/fetchscrollmsgforbusinesses")}/${btoa(business_id)}`)
  //     setScrollingMessage(response.data.scrollmsg_data[0].message)
  //     console.log("scrolling message" , response);
  //   } catch (error) {
  //     console.log("error fetching scrolling messages" , error);
  //   }   
  // }
  // fetchData()
  // },[])

  // useEffect(() => {
  //   fetchData();
  // }, [filteredStatus, searchQuery]);

  

  const handleApproveInvoice = async () => {
    const invoiceNumbers = table.getSelectedRowModel().flatRows[0]?.original.invoice_number;
    const businessName = table.getSelectedRowModel().flatRows[0]?.original.business_name;
    // console.log(invoiceNumbers)
    try {
      const response = await axios.get(`${baseURL}${btoa("invoice/approveinvoice")}/${btoa(invoiceNumbers)}/${btoa(managerId)}/${btoa(businessId)}/${btoa(userRole)}`);
      if(response.data) 
        // showToastForUpdateInvoice(`${businessName} Approved successfully` , "success")
      dispatch(addToast({ type: 'approve', message: `${businessName} Approved successfully`  }));

      // console.log("approved status :", response.data);
      table.resetRowSelection();
      setTriggerFetch(!triggerFetch); // Toggle the triggerFetch state
    } catch (error) {
      console.log("error details ", error);
    }
  }

  const handleDownloadInvoice = async () => {
    const invoiceNumbers = table.getSelectedRowModel().flatRows[0]?.original.invoice_number;
      // console.log("selected rrr" , selectedRows);
    try {
      const response = await axios.get(`${baseURL}/${btoa("invoice/downloadinvoice")}/${btoa(invoiceNumbers)}/${btoa(business_id)}`, {
        responseType: 'blob',
      });
      const filename = `${table.getSelectedRowModel().flatRows[0]?.original.business_name}_${table.getSelectedRowModel().flatRows[0]?.original.invoice_number}_${formatDate(new Date(table.getSelectedRowModel().flatRows[0]?.original.added_on))}.pdf`;
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename; // Set the filename here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      table.resetRowSelection();
      window.URL.revokeObjectURL(url);

     
      // console.log("Invoice downloaded successfully.");
    } catch (error) {
      console.log("Error downloading invoice:", error);
    }
  };
  

  // const handleStatusChange = (selectedStatus) => { 
  //   if (selectedStatus === "all") {
  //     // alert("all");
  //     setFilteredStatus(selectedStatus);
  //   } else {
  //     // alert(selectedStatus);
  //     setFilteredStatus(selectedStatus);
  //     // setSelectedRows([]);
     
  //   }
  // };


  const capitalizeFirstLetterWordWords = (str) => {
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const handleSortingChange = (columnId) => {
    setSorting((old) => {
      if (old.length && old[0].id === columnId) {
        // If it is, toggle the sorting direction
        return [{
          id: columnId,
          desc: !old[0].desc,
        }];
      }
      
      // If the columnId is not in the sorting state, set it as descending by default
      return [{ id: columnId, desc: true }];
    });
  };
  const [expandedRow, setExpandedRow] = useState(null);

  const toggleRow = (id) => {
    setExpandedRow((prevRow) => (prevRow === id ? null : id));
  };
  
  const columns = [

    columnHelper.accessor("invoice_number", {
      header: " #",
      enableColumnFilter: true,
      enableSorting: true,
      cell:(props)=> (
        
        <div className="text-center">{(props.row.original.invoice_number)}</div>
      )
    }),
    columnHelper.accessor("invoice_status", {
      header: "Product name",
      cell: (props) => (
        <div className="flex justify-center">
        <div
            className={`text-black text-[14px] py-[5px] px-[12px] w-32 rounded-sm font-semibold ${
              filteredStatus === "overDue"
                ? "bg-red-200 text-red-600" // Red background and red text for "Past Due"
                : props.row.original.invoice_status === "1"
                ? "bg-gray-200"
                : props.row.original.invoice_status === "2"
                ? "bg-blue-200 text-blue-600"
                : props.row.original.invoice_status === "3"
                ? "bg-green-200 text-green-600"
                : props.row.original.invoice_status === "4"
                ? "bg-purple-200 text-purple-600"
                : props.row.original.invoice_status === "5"
                ? "bg-red-200 text-red-600"
                : props.row.original.invoice_status === "6"
                ? "bg-purple-200 text-purple-600"
                : props.row.original.invoice_status === "7"
                ? "bg-green-200 text-green-600"
                 : props.row.original.invoice_status === "8"
                ? "bg-red-200 text-red-600"
                : ""
            }`}
          >
            {filteredStatus === "overDue"
              ? "PastDue"
              : props.row.original.invoice_status === "1"
              ? "Draft"
              : props.row.original.invoice_status === "2"
              ? "Approved"
              : props.row.original.invoice_status === "3"
              ? "Sent"
              : props.row.original.invoice_status === "5"
              ? "Overdue"
              : props.row.original.invoice_status === "4"
              ? "Partial paid"
              : props.row.original.invoice_status === "7"
              ? "Full paid" 
              : props.row.original.invoice_status === "8"
              ? "PastDue"
              : ""}
          </div>
        </div>
      ),
      enableColumnFilter: false, // Disable filter for status column
      enableSorting: false, // Disable sorting for status column
    }),
    columnHelper.accessor("business_name", {
      header: <div className="text-left w-30">Invoice Date</div>,
      cell: (props) => (
        <div className="text-center w-30">{capitalizeFirstLetterWordWords(props.row.original.business_name)}</div>
      ),
      enableColumnFilter: false,
      enableSorting: true,
    }),
    columnHelper.accessor("added_on", {
      header: " invoice #",
      cell: (props) => {
        // const date = new Date(props.row.original.added_on);
        // const options = { month: 'short', day: '2-digit', year: 'numeric' };
        // const formattedDate = `${date.toLocaleDateString("en-US", { month: 'short' })}-${date.toLocaleDateString("en-US", { day: '2-digit' })}-${date.toLocaleDateString("en-US", { year: 'numeric' })}`;
        // return (
        //   <div>{formattedDate}</div>
        // );
        const indianDate = props.row.original.added_on;
        const [day, month, year] = indianDate.split('/');      
        const date = new Date(`${year}-${month}-${day}`);      
    const formattedDate = `${date.toLocaleDateString("en-US", { month: 'short' })}-${date.toLocaleDateString("en-US", { day: '2-digit' })}-${date.toLocaleDateString("en-US", { year: 'numeric' })}`;

  return (
          <div>{formattedDate}</div>
        );

      },
      enableColumnFilter: false,
      enableSorting: true,
    }),
    columnHelper.accessor("due_date", {
      header: " fuel brand",
      cell: (props) => {
        // const date = new Date(props.row.original.added_on);
        // const options = { month: 'short', day: '2-digit', year: 'numeric' };
        // const formattedDate = `${date.toLocaleDateString("en-US", { month: 'short' })}-${date.toLocaleDateString("en-US", { day: '2-digit' })}-${date.toLocaleDateString("en-US", { year: 'numeric' })}`;
        // return (
        //   <div>{formattedDate}</div>
        // );
        const indianDate = props.row.original.due_date;
        const [day, month, year] = indianDate.split('/');      
        const date = new Date(`${year}-${month}-${day}`);      
    const formattedDate = `${date.toLocaleDateString("en-US", { month: 'short' })}-${date.toLocaleDateString("en-US", { day: '2-digit' })}-${date.toLocaleDateString("en-US", { year: 'numeric' })}`;

  return (
          <div>{formattedDate}</div>
        );

      },
      enableColumnFilter: false,
      enableSorting: true,
    }),
    columnHelper.accessor("id", {
      header: <div className="text-left w-30">invoice price</div>,
      cell: (props) => (
        <div className="text-center w-30">{capitalizeFirstLetterWordWords(props.row.original.business_name)}</div>
      ),
      enableColumnFilter: false,
      enableSorting: true,
    }),
    columnHelper.accessor("id", {
      header: <div className="text-left w-30">jabber price</div>,
      cell: (props) => (
        <div className="text-center w-30">{capitalizeFirstLetterWordWords(props.row.original.business_name)}</div>
      ),
      enableColumnFilter: false,
      enableSorting: true,
    }),
    columnHelper.accessor("id", {
      header: <div className="text-left w-30">Our price</div>,
      cell: (props) => (
        <div className="text-center w-30">{capitalizeFirstLetterWordWords(props.row.original.business_name)}</div>
      ),
      enableColumnFilter: false,
      enableSorting: true,
    }),
    columnHelper.accessor("id", {
      header: <div className="text-left w-30">Action</div>,
      cell: (props) => (
        <div className="text-center w-30">{capitalizeFirstLetterWordWords(props.row.original.business_name)}</div>
      ),
      enableColumnFilter: false,
      enableSorting: true,
    }),
  ];


  const table = useReactTable({
    data: tableData || [],
    columns: columns,
    state: {
      rowSelection: rowSelection,
      globalFilter: filtering,
      sorting: sorting,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: setColumnFilters,
    enableRowSelection: true,
    enableColumnFilter: true,
  });

  // useEffect(()=>{
  //   const fetchDataByInvoiceForCustomerEmail = async () => {
  //     const invoiceNumbers = table.getSelectedRowModel().flatRows[0]?.original.invoice_number;
  //     try {
  //       const apiUrl = `${baseURL}${btoa("invoice/fetchinvoicebyinvooiocenumber")}/${btoa(invoiceNumbers)}/${btoa(businessId)}`;
  //       const response = await axios.get(apiUrl);
  //       // console.log("invoice for customer email: " , response);
  //       setCustomerEmail(response.data.customerData[0].contact_email)
  //     } catch (error) {
  //       console.error("Error fetching invoice for email", error);
  //       if (error.response.data === "") {
  //           setInvoiceData([])
  //       }
  //     }
  //   };
  //   fetchDataByInvoiceForCustomerEmail()
  // },[table.getSelectedRowModel()])

  useEffect(() => {
  const selected = table.getSelectedRowModel().flatRows.map(row => row.original);
  // if(selected){
  //   fetchDataByInvoice()
  // }
  // console.log("selected rows", selected);
  setSelectedRows(selected);
}, [rowSelection]);

// Effect to reset row selection if necessary
useEffect(() => {
  if (filteredStatus) {
    // console.log("Filtered status changed", filteredStatus);
    table.resetRowSelection();
  }
}, [filteredStatus]);

// Reset row selection function
const resetRowSelection = () => {
  table.resetRowSelection();
};
   const [activeItem, setActiveItem] = useState("View");
  
        const handleMenuClick = (item) => {
          setActiveItem(item);
        };


  return (
    <div className="flex justify-center items-center flex-col w-full pb-5 ">

      <ScrollingMessage/>

      <div className="w-full px-8 mt-5">
        {/* <h1 className="font-bold text-xl"  style={{ 
    maxWidth: '1200px', 
    boxSizing: 'border-box', 
    marginLeft: '8px', 
    fontFamily: 'Poppins, sans-serif', // Use Poppins font
    color: '#444444' // Lighter black color
  }}>Invoice Dashboard</h1> */}

        <div>
        <div className="flex mb-6 justify-center p-4 rounded-md font-bold text-md items-center w-full mx-auto py-2 bg-white mt-3 ">
         {/* View */}
         <div
           className={`flex items-center cursor-pointer px-4 py-1 ${
             activeItem === "View" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
           } border-b border-gray-300  `}
           onClick={() => handleMenuClick("View")}
         >
           {/* <FaEye className="mr-2" /> */}
           <span>Pricing</span>
         </div>
   
       </div>
        <div className="flex justify-start space-x-4 p-1 mb-2 mt-2">
  {/* Card 1 */}
  <div
className={`flex flex-col items-center justify-between w-1/6 bg-white rounded-lg p-4 relative hover:scale-105 hover:border-l-[6px] hover:border-gray-500 transition-all duration-300 ${clicked === 'draft' ? 'border-l-[6px] border-gray-500' : ''}`}
style={{
      boxShadow: '0 0 20px 0 rgba(0, 0, 0,.2)',
      height: '90px', // Adjust as per your desired height
    }}
    onClick={() =>{ setFilteredStatus('draft'); setClicked('draft')}}
  >
    <div className="flex justify-between w-full">
      <span className="text-sm text-gray-500 font-bold"> Sales price</span>
    </div>
    <div>
      <h2
        className="absolute bottom-2 left-4  text-2xl font-bold text-grey-500 mt-2"
      
      >
        {status.total_draftInvoices}
      </h2>
    </div>
    <div className="absolute bottom-0 right-0 w-[100px] h-[55px] bg-gradient-to-tl from-[#ffffff] to-transparent rounded-tl-full flex items-center justify-center">
      <DescriptionIcon sx={{ fontSize: 30 }} className="text-grey-500 mt-2" />
    </div>
  </div>

  {/* Card 2 */}
  <div
    className={`flex flex-col items-center justify-between w-1/6 bg-white rounded-lg p-4 relative hover:scale-105 hover:border-l-[6px] hover:border-blue-500 transition-all duration-300 ${clicked === 'approved' ? 'border-l-[6px] border-blue-500' : ''}`}
    style={{
      boxShadow: '0 0 20px 0 rgba(0, 0, 0,.2)',
      height: '90px',
    }}
    onClick={() => {setFilteredStatus('approved'); setClicked('approved')}}
  >
    <div className="flex justify-between w-full">
      {/* <span className="text-sm text-blue-500 font-bold">APPROVED</span> */}
      <span className="text-sm text-blue-500 font-bold">Other Price</span>

    </div>
    <div>
      <h2
        className="absolute bottom-2 left-4  text-2xl font-bold text-blue-500 mt-2"
      >
        {status.total_approvedInvoices}
      </h2>
    </div>
    <div className="absolute bottom-0 right-0 w-[100px] h-[50px] bg-gradient-to-tl from-[#ffffff] to-transparent rounded-tl-full flex items-center justify-center">
      <CheckCircleIcon sx={{ color: 'blue', fontSize: 30 }} className="mt-2" />
      
    </div>
  </div>

  {/* Card 3 */}


  
  { userRole === '2' &&   
   <div className='flex flex-row'>
   <div className="w-1/2">
   <h3 className="text-lg font-semibold text-[#25caed] mb-4  underline absolute top-[125px] right-[5%]  ">
    2025 JAN Pricing Summary

      {/* {status.monthName && status.year ? `${status.year} ${status.monthName ? status.monthName.substring(0, 3).toUpperCase() : ''} Documents Summary` : ''} */}
    </h3>
   </div>
 <div className="lg:w-[18%] rounded-md p-4 mt-[20px]  ml-auto absolute top-[145px] right-[50px] bg-gray-100 border border-gray-300">
   {/* Subtotal Section */}
   <div className="flex justify-between items-center mb-2">
     <div className="flex items-center gap-2">
       <button onClick={toggleSubtotal} className="text-blue-500">
         {isSubtotalExpanded ? '▼' :  <span className="inline-block text-xl leading-none">►</span>}
       </button>
  
       <span className="text-gray-800 font-medium">Total Price:</span>
     </div>
     <span className="text-gray-800 font-medium">
     0{/* {formatNumberWithCommas( Number(status.total_draftInvoices+ status.total_approvedInvoices + status.total_sentInvoices) || 0 )}  */}
        </span>
   </div>
 
   {isSubtotalExpanded && (
     <>
       <div className="ml-6 mb-4">
           <div className="flex justify-between" >
             <span className="capitalize">other :</span>
             <span className="font-normal">
             {/* {formatNumberWithCommas(status.total_draftInvoices)} */}0
             </span>
           </div>
         
           <div className="flex justify-between font-normal pt-2">
     <span className="">Users Count :</span>
     <span className="border-t border-black  text-right">
     0{/* {formatNumberWithCommas( Number(status.total_draftInvoices+ status.total_approvedInvoices + status.total_sentInvoices) || 0 )}  */}
 
     </span>
   </div>
       </div>
 
     </>
   )}
 
   {/* Taxes Section */}
   <div className=" flex justify-between items-center">
     <div className="flex items-center gap-2">
       <button onClick={toggleTaxes} className="text-blue-500">
         {isTaxesExpanded ? '▼' :     <span className="inline-block text-xl leading-none">►</span>
         }
       </button>
       <span className="text-gray-800 font-medium"> Others:</span>
     </div>
     <span className="text-gray-800 font-medium">
     {/* ${(
   (status?.invoice_sum_data?.[0]?.total_final_invoice_amount ? Number(status?.invoice_sum_data[0]?.total_final_invoice_amount) : 0) +
   (status?.invoice_sum_data?.[1]?.total_final_invoice_amount ? Number(status?.invoice_sum_data[1]?.total_final_invoice_amount) : 0) +
   (status?.invoice_sum_data?.[2]?.total_final_invoice_amount ? Number(status?.invoice_sum_data[2]?.total_final_invoice_amount) : 0)
 ).toFixed(2) || 0} */}
 0
 
     </span>
   </div>
 
   {isTaxesExpanded && (
     <div className="ml-6 mt-2">
         <div className="flex justify-between">
           <span className="capitalize">Others:</span>
           <span className="font-normal">
           {/* $ {Number(status.invoice_sum_data[0].total_final_invoice_amount).toLocaleString(undefined, {
                   minimumFractionDigits: 2,
                   maximumFractionDigits: 2,
                 })} */}0
           </span>
         </div>
         <div className="flex justify-between">
           <span className="capitalize">Others:</span>
           <span className="font-normal">
           {/* $ {Number(status.invoice_sum_data[1].total_final_invoice_amount).toLocaleString(undefined, {
                   minimumFractionDigits: 2,
                   maximumFractionDigits: 2,
                 })} */}0
           </span>
         </div>
         <div className="flex justify-between">
           <span className="capitalize">Others:</span>
           <span className="font-normal">
           {/* $ {Number(status.invoice_sum_data[2].total_final_invoice_amount).toLocaleString(undefined, {
                   minimumFractionDigits: 2,
                   maximumFractionDigits: 2,
                 })} */}0
           </span>
         </div>
       <li className="flex justify-between font-normal pt-2">
         <span>Others:</span>
         <span className="border-t border-black  text-right">
 0{/* ${(
   (status?.invoice_sum_data?.[0]?.total_final_invoice_amount ? Number(status?.invoice_sum_data[0]?.total_final_invoice_amount) : 0) +
   (status?.invoice_sum_data?.[1]?.total_final_invoice_amount ? Number(status?.invoice_sum_data[1]?.total_final_invoice_amount) : 0) +
   (status?.invoice_sum_data?.[2]?.total_final_invoice_amount ? Number(status?.invoice_sum_data[2]?.total_final_invoice_amount) : 0)
 ).toFixed(2) || 0} */}
 
         </span>
       </li>
     </div>
   )}
 </div>
 </div>
 }
</div>
{/* 
<div className="flex justify-end items-end mt-[60px]">
          <button
            className="flex items-end justify-end font-bold mr-3 w-55 border-transparent border-2 text-white bg-[#25caed] hover:scale-105 transition-transform duration-300 ease-in-out focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-base px-4 py-1.5 focus:outline-none"
            type="button"
            onClick={handleViewInvoice}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="currentColor"
              className="w-5 h-5 mr-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v12m6-6H6"
              />
            </svg>
            New Invoice
          </button>
          </div> */}
        </div>
      </div>

     
    
      {/* {isFilterVisible && ( */}
    <div className="relative flex justify-between gap-2 items-center w-full px-10 mt-60 h-[40px]">
      {/* Fuel Brand Dropdown */}
      <div className="flex-grow" style={{ width: "250px" }}>
        {isfilterOpen && (
          <select
            id="customerId"
            className="border border-gray-400 text-sm font-medium rounded-lg block w-full h-[42px] px-2"
            // onChange={(e) => {
            //   handleChangeFuelBrands(e);
            //   setSelectedText(e.target.value !== "");
            // }}
          >
            <option value="">--Select Customer--</option>
            <option value="all">All Customer</option>
            {/* {sellerNames.map((seller) => (
              <option key={seller.id} value={seller.id}>
                {seller.seller_name}
              </option>
            ))} */}
          </select>
        )}
      </div>
    
      {/* Date Picker */}
      {isfilterOpen && (
        <Space direction="vertical" style={{ height: "100%" }}>
          <DatePicker
            label="date"
            picker="month"
            format="MMMM-YYYY"
            views={["year", "month"]}
            // value={formattedDatefrom}
            // onChange={handleDateChangeFrom}
            placeholder="Date"
            renderInput={(params) => (
              <TextField
                {...params}
                // value={formattedDatefrom ? formattedDatefrom.format("MMMM-YYYY") : ""}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: "32px",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "1rem",
                    // backgroundColor: formattedDatefrom ? "#d7f3f8" : "transparent",
                    // border: `1px solid ${formattedDatefrom ? "#25caed" : "#ccc"}`,
                    borderRadius: "6px",
                    "&:hover": {
                      // borderColor: formattedDatefrom ? "#25caed" : "#aaa",
                    },
                    "&.Mui-focused": {
                      borderColor: "blue",
                      boxShadow: "0 0 8px rgba(0, 0, 255, 0.5)",
                    },
                  },
                }}
              />
            )}
          />
        </Space>
      )}
    
      {/* Invoice Search */}
      <div className="flex-grow" style={{ width: "250px" }}>
        {isfilterOpen && (
          <select
            id="customerId"
            className="border border-gray-400 text-sm font-medium rounded-lg block w-full h-[42px] px-2"
            // onChange={(e) => {
            //   handleChangeFuelBrands(e);
            //   setSelectedText(e.target.value !== "");
            // }}
          >
            <option value="">--Select Fuel Price--</option>
            <option value="all">Old Price</option>
            <option value="all">New Price</option>

            {/* {sellerNames.map((seller) => (
              <option key={seller.id} value={seller.id}>
                {seller.seller_name}
              </option>
            ))} */}
          </select>
        )}
      </div>
    
      {/* Filters Button */}
      <div
        className="flex items-center justify-center bg-white p-2 rounded-lg shadow-lg cursor-pointer"
        onClick={() => setIsfilterOpen(!isfilterOpen)}
        style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)" }}
      >
        <MdFilterList className="w-5 h-[16px]  mr-2" />
        {!isfilterOpen && <span className="text-sm">Filters</span>}
      </div>
    
      {/* Upload PO Button */}
      <div className="flex justify-end">
        <button
          className="text-white bg-orange-600 flex items-center font-bold w-55 h-[40px] hover:scale-105 transition-transform duration-300 ease-in-out focus:ring-4 focus:ring-primary-300 rounded-lg text-base px-4"
          type="button"
          onClick={() => navigate('/NewPrice')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={3}
            stroke="currentColor"
            className="w-5 h-5 mr-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6v12m6-6H6"
            />
          </svg>
         New Price
        </button>
      </div>
    </div>
        {/* )} */}
        
        
           <div className={`flex justify-end items-end w-full pl-9 pr-9 ml-6 ${isFilterVisible ? 'mt-[0px]' : ''}`}>
           {/* <div >
            <Box sx={{ width: '100%' }}>
              <Tabs
                value={filteredStatus}
                // onChange={handleChange}
                indicatorColor="primary" // Default color for indicators
                sx={{
                  borderBottom: 3,
                  borderColor: 'divider', // Default border color
                  '& .MuiTabs-indicator': {
                    backgroundColor: indicatorColor(filteredStatus),
                  },
                }}
              >
                <Tab
                  value="draft"
                  onClick={()=> setFilteredStatus("draft")}
                  style={{
                    color: filteredStatus === 'draft' ? 'gray' : 'gray', // Gray color if filteredStatus is 'draft'
                    borderBottom: filteredStatus === 'draft' ? '1px solid gray' : 'none', // Gray border bottom if filteredStatus is 'draft'
                  }}
                  label={
                    <Badge
                      badgeContent={status.total_draftInvoices}
                      color=""
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: filteredStatus === 'draft' ? 'gray' : 'gray', // Gray or green background based on filteredStatus
                          color: 'white',
                          top: '-3px',
                          right: '-10px',
                          fontSize: '0.75rem',
                          height: '18px',
                          minWidth: '18px',
                          padding: '0 6px',
                          borderRadius: '50%'
                        }
                      }}
                    >
                      Draft
                    </Badge>
                  }
                />
                <Tab
                  value="approved"
                  onClick={()=> setFilteredStatus("approved")}
                  sx={{ width: "122px" }}
                  style={{
                    color: filteredStatus === 'approved' ? 'blue' : 'gray', // Blue color if filteredStatus is 'approved'
                    borderBottom: filteredStatus === 'approved' ? '1px solid blue' : 'none', // Blue border bottom if filteredStatus is 'approved'
                  }}
                  label={
                    <Badge
                      badgeContent={status.total_approvedInvoices}
                      color="primary"
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: filteredStatus === 'approved' ? 'blue' : 'gray',
                          color: 'white',
                          top: '-3px',
                          right: '-10px',
                          fontSize: '0.75rem',
                          height: '18px',
                          minWidth: '18px',
                          padding: '0 6px',
                          borderRadius: '50%'
                        }
                      }}
                    >
                      Approved
                    </Badge>
                  }
                />
                <Tab
                  value="sent"
                  onClick={()=> setFilteredStatus("sent")}
                  style={{
                    color: filteredStatus === 'sent' ? 'green' : 'gray', // Green color if filteredStatus is 'sent'
                    borderBottom: filteredStatus === 'sent' ? '1px solid green' : 'none', // Green border bottom if filteredStatus is 'sent'
                  }}
                  label={
                    <Badge
                      badgeContent={status.total_sentInvoices}
                      color="primary"
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: filteredStatus === 'sent' ? 'green' : 'gray',
                          color: 'white',
                          top: '-3px',
                          right: '-10px',
                          fontSize: '0.75rem',
                          height: '18px',
                          minWidth: '18px',
                          padding: '0 6px',
                          borderRadius: '50%'
                        }
                      }}
                    >
                      Sent
                    </Badge>
                  }
                />
                   <Tab
                  value="overDue"
                  onClick={()=> setFilteredStatus("overDue")}
                  sx={{ width: "110px" }}
                  style={{
                    color: filteredStatus === 'overDue' ? 'red' : 'gray', // Red color if filteredStatus is 'overdue'
                    borderBottom: filteredStatus === 'overDue' ? '1px solid red' : 'none', // Red border bottom if filteredStatus is 'overdue'
                  }}
                  label={
                    <Badge
                      badgeContent={status.total_overdueinvoices} // Default to "0" if undefined
                      color="primary"
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: filteredStatus === 'overDue' ? 'red' : 'gray',
                          color: 'white',
                          top: '-3px',
                          right: '-10px',
                          fontSize: '0.75rem',
                          height: '18px',
                          minWidth: '18px',
                          padding: '0 6px',
                          borderRadius: '50%'
                        }
                      }}
                    >
                      Past Due
                    </Badge>
                  }
                />
                <Tab
                  value="partialpaid"
                  onClick={()=> setFilteredStatus("partialpaid")}
                  sx={{ width: "126px" }}
                  style={{
                    color: filteredStatus === 'partialpaid' ? 'orange' : 'gray', // Orange color if filteredStatus is 'partialpaid'
                    borderBottom: filteredStatus === 'partialpaid' ? '1px solid orange' : 'none', // Orange border bottom if filteredStatus is 'partialpaid'
                  }}
                  label={
                    <Badge
                      badgeContent={status.total_partialpaidInvoices}
                      color="primary"
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: filteredStatus === 'partialpaid' ? 'orange' : 'gray',
                          color: 'white',
                          top: '-3px',
                          right: '-10px',
                          fontSize: '0.75rem',
                          height: '18px',
                          minWidth: '18px',
                          padding: '0 6px',
                          borderRadius: '50%'
                        }
                      }}
                    >
                      Partial Pay
                    </Badge>
                  }
                />
                <Tab
                  value="fullpaid"
                  onClick={()=> setFilteredStatus("fullpaid")}
                  sx={{ width: "110px" }}
                  style={{
                    color: filteredStatus === 'fullpaid' ? 'green' : 'gray', // Green color if filteredStatus is 'fullpaid'
                    borderBottom: filteredStatus === 'fullpaid' ? '1px solid green' : 'none', // Green border bottom if filteredStatus is 'fullpaid'
                  }}
                  label={
                    <Badge
                      badgeContent={status.total_fullpaidInvoices}
                      color="primary"
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: filteredStatus === 'fullpaid' ? 'green' : 'gray',
                          color: 'white',
                          top: '-3px',
                          right: '-10px',
                          fontSize: '0.75rem',
                          height: '18px',
                          minWidth: '18px',
                          padding: '0 6px',
                          borderRadius: '50%'
                        }
                      }}
                    >
                      Full Paid
                    </Badge>
                  }
                />
             
              </Tabs>
            </Box>
          </div> */}
          

                  {/* <div className="flex items-center gap-3 mr-6 " style={{borderBottom: '3px solid #DCDCDC'}}>  */}
    
<div
  className={`flex items-end gap-3 mr-6 transition-all ease-in-out `}
  style={{
    // borderBottom: '3px solid #DCDCDC',

  }}
>
                          {/* <Tooltip title="Filter" position="top" trigger="mouseenter">
                                          <button
                                              className="items-end mt-[-5px] text-sm font-medium text-center focus:outline-none text-gray-500 hover:text-blue-800 rounded-lg"
                                              type="button"
                                              onClick={handleFilterClick} 
                                          >
                                              <div className="rounded-full p-2">
                                                  <MdFilterList  size={24}     
 />
                                              </div>
                                          </button>
                                          
                              </Tooltip>                  */}
                            
                  </div>

            </div>

            {/* <hr className="border border-gray-300 w-[93%] mb-1 mt-1.5 mb-2"></hr> */}
        
            {/* <hr className=" border border-gray-300 w-[93%]"/> */}
            <div  style={{
                                

                                position:(isScrolled &&  selectedRows.length === 1) ? 'fixed': 'fixed',
                                left:(isScrolled &&  selectedRows.length === 1) ? '8px':'8px',
                                bottom: '3%',
                                width:'500px'
                            
                              }}>
            <ToastNotificationBox/>
            </div>
                        {/* <ToastNotificationBox/> */}


          <div className=" w-[99%] rounded-xl pl-8 pr-8 bg-white ">                                        {/*Table starts from here */}
            <div className="w-full mt-4">
             <table className="w-full table-auto bg-transparent rounded-xl mr-5 shadow-lg">
              <thead className="bg-gray-500/20 text-gray-500">
              {/* <thead className="bg-green-100 text-green-600 overflow-hidden rounded-lg"> */}

                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className="text-center text-xs">
                    {headerGroup.headers.map((header, index) => {
                      const isFirstHeader = index === 0;
                      const isLastHeader = index === headerGroup.headers.length - 1;
                      const headerClasses = [
                        'p-3 font-bold uppercase',
                        isFirstHeader ? 'rounded-l-xl' : '',
                        isLastHeader ? 'rounded-r-xl' : '',
                      ];

                      const isSorted = header.column.getIsSorted();
                      const showSortingIcons = header.column.columnDef.enableSorting !== false;
                      const ArrowUpIcon = (
                        <FaArrowUpLong
                          className={`${isSorted === 'asc' ? 'text-black' : 'text-gray-400'}`}
                          size={12}
                        />
                      );
                      const ArrowDownIcon = (
                        <FaArrowDownLong
                          className={`${isSorted === 'desc' ? 'text-black' : 'text-gray-400'}`}
                          size={12}
                        />
                      );

                      return (
                        <th
                          key={header.id}
                          className={headerClasses.join(' ')}
                          colSpan={header.colSpan}
                          onClick={() => showSortingIcons && handleSortingChange(header.column.id)}
                        >
                          <div className="flex items-center justify-center">
                            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                            {showSortingIcons && (
                              <div className="flex items-center ml-2">
                                {ArrowDownIcon}
                                {ArrowUpIcon}
                              </div>
                            )}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
  {table.getRowModel().rows.map((row) => (
    <React.Fragment key={row.id}>
      {/* Main Row */}
      <tr
        className="cursor-pointer text-center hover:bg-gray-500/10"
        onClick={() => toggleRow(row.id)}
      >
        {row.getVisibleCells().map((cell) => (
          <td
            key={cell.id}
            className="p-3 font-semibold text-[#081159] text-[14px] text-center"
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        ))}
      </tr>

      {/* Expanded Row */}
      {expandedRow === row.id && (
        <tr>
          <td colSpan={row.getVisibleCells().length}>
            <div
              className="flex flex-col items-center gap-3 mb-0"
              style={{
                position: 'fixed', // Changed from fixed to relative for better alignment within the table
                right: '10px',
                bottom: '8%',
              }}
            >
              {row?.original && (
              <>
              {/* SMS Button */}
              <Tooltip title="Send SMS" position="top" trigger="mouseenter">
                <button
                  className="items-center text-sm font-medium text-center focus:outline-none border rounded-full"
                  type="button"
                  aria-label="Send SMS"
                  style={{
                    color: "#25caed", // Icon color
                    borderColor: "#25caed", // Border color
                    backgroundColor: "#d5f8fc", // Lighter background
                  }}
                >
                  <div className="rounded-full p-3">
                    <AiFillMessage size={20} />
                  </div>
                </button>
              </Tooltip>
            
              {/* Email Button */}
              <Tooltip title="Send Email" position="top" trigger="mouseenter">
                <button
                  className="items-center text-sm font-medium text-center focus:outline-none border rounded-full"
                  type="button"
                  aria-label="Send Email"
                  style={{
                    color: "#800080", // Icon color
                    borderColor: "#800080", // Border color
                    backgroundColor: "#f2e6f9", // Lighter background
                  }}
                >
                  <div className="rounded-full p-3">
                    <AiFillMail size={20} />
                  </div>
                </button>
              </Tooltip>
            </>
            
              )}
            </div>
          </td>
        </tr>
      )}
    </React.Fragment>
  ))}
</tbody>

             </table>

           
              
              <InvoiceViewModal isOpen={isModalOpen} closeModal={closeModal} invoiceNumber={table.getSelectedRowModel().flatRows[0]?.original.invoice_number} />
              
              {
              !invoiceData || !invoiceData.length === 0 &&  (
                <div className="w-full h-[200px] flex items-center justify-center ">
                  There is not to show here
                </div>
              )}
                </div>
          </div>
     

        {openDeleteModalId && (
        <PopUpModal
          onClose={() => setOpenDeleteModalId(null)}
          invoiceNumber={openDeleteModalId}
          invoiceDelete={handleDeleteInvoices}
          invoiceName={table.getSelectedRowModel().flatRows[0]?.original.business_name}
          resetRowSelection={table.resetRowSelection}
          setTriggerFetch={setTriggerFetch}
          triggerFetch={triggerFetch}
        />
      )}

      {openSendInvoiceModalId && (
        <PopUpModalForSendInvoice
          onClose={() => setOpenSendInvoiceModalId(null)}
          invoiceNumber={openSendInvoiceModalId}
          invoiceName={table.getSelectedRowModel().flatRows[0]?.original.business_name}
          resetRowSelection={resetRowSelection}
          customerEmail={customerEmail}
          finalInvoiceAmount={table.getSelectedRowModel().flatRows[0]?.original.final_invoice_amount}
          dueDate={table.getSelectedRowModel().flatRows[0]?.original.due_date}
          setTriggerFetch={setTriggerFetch}
          triggerFetch={triggerFetch}
        />
      )}

{
  payModalInvoice && (
    <InvoicePayModal open= {payModalInvoice}
    handleClose= {() => setpayModalInvoice(false)}
    customerName={table.getSelectedRowModel().flatRows[0]?.original.business_name}
    invoiceNumber={table.getSelectedRowModel().flatRows[0]?.original.invoice_number}
    finalInvoiceAmount={(table.getSelectedRowModel().flatRows[0]?.original.final_invoice_amount)}
    setTriggerFetch={setTriggerFetch}
    triggerFetch={triggerFetch}
    resetRowSelection={table.resetRowSelection}

     
      />
  )
}

      <ScrollToTop />
    </div>
  );
}

export default Pricing;
