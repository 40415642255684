
import React, { useEffect } from "react";
import { useState } from "react";
import { FaEnvelope, FaLock, FaSignInAlt } from "react-icons/fa";
// import img1 from "../../assets/images/Login.jpg"
// import img2 from "../../assets/images/snapedit_1701426729284.png";
import { IoPerson } from "react-icons/io5";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup"
import Header from "../Headerside";
import { RiLoginCircleFill } from "react-icons/ri";
import {  FaEye, FaEyeSlash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from "../../App";
import { MdBusinessCenter } from "react-icons/md";
import { MdAttachEmail } from "react-icons/md";  

import CloseIcon from '@mui/icons-material/Close';
import { FaArrowRightLong } from "react-icons/fa6";
// import { FaXmark } from 'react-icons/fa6'
import config  from "../../config/config"
import PersonIcon from "@mui/icons-material/Person";
import InvoiceFileLogo from "../../assets/images/InvoiceFileLogo.png";
import { addToast } from "./ToastBox/ToastBoxMessageSlice";
import { useDispatch } from "react-redux";

const UserLoginPage = ({ onLogin , handleSignUpClick  ,handleShowOTP , openForgotPassword}) => {
  const dispatch = useDispatch()
  
  const [email, setEmail] = useState("");
  const [invalidemailError, setinvalidEmailError] = useState(false)
  const [password, setPassword] = useState("");
  const [EmailinputStyles, setEmailInputStyles] = useState(
    'pl-10 w-full px-3 py-2  rounded-lg focus:ring-blue-500 focus:border-blue-500 pr-10'
  );
  const [inputStyles, setInputStyles] = useState(
    'pl-10 w-full px-3 py-2  rounded-lg focus:ring-blue-500 focus:border-blue-500 pr-10'
  );
  const [domaininputStyles, setDomainInputStyles] = useState(
    'pl-10 w-full px-3 py-2  rounded-lg focus:ring-blue-500 focus:border-blue-500 mb-4'
  );

  const [emailError, setEmailError] = useState("");
  const [domainError, setDomainError] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  
  const [formData, setFormData] = useState({
    email: "",
  });
  const [user , setUser] = useState({contact_name : "" , contact_email : ""})
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;

  const [passwordVisible, setPasswordVisible] = useState(false);


  const [showSignUp , setShowSignUp] = useState(false)

  const handleLocalSignUpClick =()=>{
    setShowSignUp(!showSignUp)
  }

  const handleSignOutModel=()=>{
    setIsModalOpen(!isModalOpen)
    window.location.reload();
}
const [realEmail, setRealEmail] = useState("");
const formik = useFormik({
  initialValues: {
    email: "",
  },
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .test({
        name: 'custom-email-validation',
        test: (value) => {
          return /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/.test(value);
        },
        message: 'Invalid email address',
      }),
  }),
  initialTouched: { 
    email: true,
  },

  onSubmit: (values) => {
    console.log("Submitting real email:", realEmail);

  },
});

const handleInputChange = (e) => {
  const { name, value } = e.target;
  const trimmedValue = value.trim();
  setRealEmail(trimmedValue);
  formik.setFieldValue(name, trimmedValue);
  console.log("Trimmed real email:", trimmedValue);
  // if (validateEmail(value.trim())) {
  //   setIsEmailCompleted(true);
  // } else {
  //   setIsEmailCompleted(false);
  // }
};
const [isEmailCompleted, setIsEmailCompleted] = useState(false);

const handleBlur = (e) => {
  if (e.target.value.trim() !== "" && validateEmail(e.target.value)) {
    setIsEmailCompleted(true);
  } else {
    setIsEmailCompleted(false);
  }
  setIsFocused(false)
  const { name } = e.target;
  const formattedValue = formatEmail(realEmail);
  formik.setFieldValue(name, formattedValue);
  console.log("EormattedValue email:", formattedValue);
};
const handleFocus = () => {
  setIsFocused(true); // Mark as focused
};
const formatEmail = (email) => {
  if (!email) { return ''; }
  if (!email.includes("@")) return email;
  const [localPart, domain] = email.split('@');
    const maskedLocalPart = localPart.slice(0, 2) + "*****";
    const domainTLD = domain.slice(domain.lastIndexOf('.'));
    return `${maskedLocalPart}${domainTLD}`;
};

const handleLoginAsOwner = async() => {
  try {
    const response = await axios.post(`${baseURL}/${btoa("login/loginasou")}` , {

      loginAs : 2,
      // email: formik.values.email
      email: realEmail
      // userotp1: otpValues[0] || "",
      // userotp2: otpValues[1] || "",
      // userotp3: otpValues[2] || "",
      // userotp4: otpValues[3] || "",
      // userotp5: otpValues[4] || "",
      // userotp6: otpValues[5] || "",
    });

    console.log('OTP Response foe owner', response);

    setUser({contact_name : response.data.loggedin[0].contact_name , 
            contact_email : response.data.loggedin[0].contact_email})
    
            localStorage.setItem("userName" , response.data.loggedin[0].contact_name)
            localStorage.setItem("userEmail" , response.data.loggedin[0].contact_email)


    window.location.reload()
    
    localStorage.setItem('user_role' , response.data.loggedin[0].user_role);
    localStorage.setItem('id' , response.data.loggedin[0].id);
    localStorage.setItem('status' , 'true');
    localStorage.setItem("token" , response.data.token)
    localStorage.setItem("userName" , response.data.loggedin[0].contact_name)
    localStorage.setItem("userEmail" , response.data.loggedin[0].contact_email)

    if(response.data.loggedin[0].user_role === '1'){
      localStorage.setItem("user_id", response.data.loggedin[0].id)
    }
    else if(response.data.loggedin[0].user_role === "2"){
      localStorage.setItem("business_id", response.data.loggedin[0].business_id)
        localStorage.setItem("user_id", response.data.loggedin[0].owner_id)
    }else if(response.data.loggedin[0].user_role === "3"){
      localStorage.setItem("business_id", response.data.loggedin[0].business_id)
        localStorage.setItem("user_id", response.data.loggedin[0].manager_id)
    }
    // setIsModalOpen(false); 
  }
   catch (error) {
    console.log("error posting details", error);
  }
};


const handleLoginAsManager = async() => {
  try {
    const response = await axios.post(`${baseURL}/${btoa("login/loginasou")}`, {

      loginAs : 3,
      // email: formik.values.email
      email: realEmail,
      // userotp1: otpValues[0] || "",
      // userotp2: otpValues[1] || "",
      // userotp3: otpValues[2] || "",
      // userotp4: otpValues[3] || "",
      // userotp5: otpValues[4] || "",
      // userotp6: otpValues[5] || "",
    });

    console.log('OTP Response', response);

    setUser({contact_name : response.data.loggedin[0].contact_name , 
            contact_email : response.data.loggedin[0].contact_email})
    
            localStorage.setItem("userName" , response.data.loggedin[0].contact_name)
            localStorage.setItem("userEmail" , response.data.loggedin[0].contact_email)


    window.location.reload()
    
    localStorage.setItem('user_role' , response.data.loggedin[0].user_role);
    localStorage.setItem('id' , response.data.loggedin[0].id);
    localStorage.setItem('status' , 'true');
    localStorage.setItem("token" , response.data.token)
    localStorage.setItem("userName" , response.data.loggedin[0].contact_name)
    localStorage.setItem("userEmail" , response.data.loggedin[0].contact_email)

    if(response.data.loggedin[0].user_role === '1'){
      localStorage.setItem("user_id", response.data.loggedin[0].id)
    }
    else if(response.data.loggedin[0].user_role === "2"){
      localStorage.setItem("business_id", response.data.loggedin[0].business_id)
        localStorage.setItem("user_id", response.data.loggedin[0].owner_id)
    }else if(response.data.loggedin[0].user_role === "3"){
      localStorage.setItem("business_id", response.data.loggedin[0].business_id)
        localStorage.setItem("user_id", response.data.loggedin[0].manager_id)
    }
    // setIsModalOpen(false); 
  }
   catch (error) {
    console.log("error posting details", error);
  }
};


 


  const CustomTooltip = ({ content, styles }) => {
    return (
      <div
        style={{
          zIndex:1000,
          display: content ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: `1px solid ${styles.borderColor || "red"}`,
          marginTop: "-10px",
          marginLeft: "175px",
          marginRight: "auto",
          ...styles,
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "-7px",
            left: "50%",
            marginLeft: "-10px",
            width: "0",
            height: "0",
            borderLeft: "7px solid transparent",
            borderRight: "7px solid transparent",
            borderTop: `7px solid ${styles.arrowStyle?.borderTopColor || "red"}`,
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };
  
  


  let navigate = useNavigate();

  const handleForgotPassword = () => {
    openForgotPassword()
  };

 

  const handleLoginClick = async (e) => {
    e.preventDefault()

    // if (!validateEmail(formik.values.email)) {
    if (!validateEmail(realEmail)) {
      setEmailError("Email / Password is invalid ");
      return;
    }
    if(password === ""){
      setEmailError('Please enter password')
    }
    if(formik.values.domainName === ""){
      setDomainError('Please enter domainName')
    }
    // setEmailError("");
    console.log("email:", realEmail, "password:", password , "domainName :" , formik.values.domainName);

    // onLogin( formik.values.email, password);

    try{
      if(realEmail && password && formik.values.domainName){

      const response = await axios.post(`${baseURL}${btoa("login/managerlogin")}` , {
        submit : "true" , email: realEmail, password : password ,domainName : formik.values.domainName
        
      })
        if(response.data.login_status === true && response.data.userStatus === 4){
          setIsModalOpen(!isModalOpen)
        }
      console.log("login response :" , response);
     
      localStorage.setItem("userStatus" , response.data.userStatus)
      localStorage.setItem("userOtp" , response.data.otp)
      const userData = response.data 
      
      // console.log("userData" ,userData);
      // console.log("status" ,userData.login_status);
      // console.log("token" ,userData.token);
  
      if (response.data.otpSentStatus === true &&  response.data.oldIpAddress === false) {
        // alert("true")
        handleShowOTP(true);
        // onLogin(formik.values.email, password);
        onLogin(realEmail, password);
        // toast.success('Login successful!', {
        //   position: 'top-right',
        //   autoClose: 3000, // 3 seconds
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
        // <Header contact_email={userData.contact_email} contact_name={userData.contact_name}  />
        
      } 
      if(response.data.oldIpAddress === true && response.data.selectUserRole === "twoOrThree"){
        setIsModalOpen(!isModalOpen)
        return
      }
      if(response.data.oldIpAddress === true && response.data.userStatus === 4){
        setIsModalOpen(!isModalOpen)
        return 
      }

      const token = userData.token
      // localStorage.setItem("token", token)
      axios.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`

      if(response.data.oldIpAddress === true && response.data.userStatus !== 4){
         setUser({contact_name : response.data.loggedin[0].contact_name , 
          contact_email : response.data.loggedin[0].contact_email})
            window.location.reload()
            localStorage.setItem('user_role' , response.data.loggedin[0].user_role);
            localStorage.setItem('id' , response.data.loggedin[0].id);
            localStorage.setItem('status' , 'true');
            localStorage.setItem("token" , response.data.token)
            localStorage.setItem("userName" , response.data.loggedin[0].contact_name)
            localStorage.setItem("userEmail" , response.data.loggedin[0].contact_email)

            if(response.data.loggedin[0].user_role === '1'){
              localStorage.setItem("user_id", response.data.loggedin[0].id)
            }
            else if(response.data.loggedin[0].user_role === "2"){
              localStorage.setItem("business_id", response.data.loggedin[0].business_id)
                localStorage.setItem("user_id", response.data.loggedin[0].owner_id)
            }else if(response.data.loggedin[0].user_role === "3"){
              localStorage.setItem("business_id", response.data.loggedin[0].business_id)
                localStorage.setItem("user_id", response.data.loggedin[0].manager_id)
            }
      }
       
    }
      navigate("/")
    }
    catch(error){
      console.log("error posting details" , error);
      if (error.response.data.emailPassStatus === "Email or password is not correct") {

        setEmailError("Email or password is not correct")
         
      } 
      else if (error.response.data.domainStatus === "Domain name does not exist") {
        setEmailError("Invalid Domain Name ")

      } 
      else if(error.response.data.domainStatus === "Domain name does not exist" && error.response.data.login_status === false){
        // toast.error("Invalid Domain Name" , {autoClose : 2000})
      dispatch(addToast({ type: 'error', message: `Invalid Domain Name"` }));
        
        setEmailError("")
      }
      // if (error.response.data.login_status === false && error.response.data.domainStatus === "Domain name does not exist") {
      //   setEmailError("Email / Password is invalid ")
      //   toast.error("Invalid Domain Name" , {autoClose : 2000})
      //   return
      // } 
    }
  };

  const validateEmail =(email)=>{
    const emailRegex = /^.+@.+\..+$/ 
    return emailRegex.test(email)
  }

  const showTooltip = () => {

    const isInvalidEmail = !validateEmail(realEmail);
    return (
      formik.touched.email &&
      realEmail.length > 0 &&
      isInvalidEmail && {
        borderColor: "red",
        arrowStyle: {
          borderTopColor: "red",
        },
      }
    );
  };

  const handleInputChangeDomain = (e) => {
    const { name, value } = e.target;
    formik.handleChange(e);
    formik.setFieldValue(name, value.trim());
    setFormData((prevData) => ({
      ...prevData,
      [name]: value.trim(), 
    }));
    // console.log("Email value:", value.trim());
  };
  
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   const formattedValue = formatEmail(value.trim()); // Format the email
  //   formik.setFieldValue(name, value.trim());
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value.trim(),
  //   }));
  //   // Optionally, if you want to keep the formatted value displayed
  //   formik.setFieldValue('email', formattedValue);
  // };
  



  //   const formatEmail = (email) => {
  //   if (!email) { return ''; }
  //   const [localPart, domain] = email.split('@');
  //   const maskedLocalPart = localPart.slice(0, 2) + "*****";
  //   const domainTLD = domain.slice(domain.lastIndexOf('.'));
  //   return `${maskedLocalPart}${domainTLD}`;
  // };
  // const formatEmail = (email) => {
  //   const [localPart, domain] = email.split("@");
  //   if (!localPart || !domain) return email; // In case of invalid input
  //   if (localPart.length > 2) {
  //     const maskedPart = localPart.slice(0, 2) + "*****";
  //     return `${maskedPart}@${domain}`;
  //   }
  //   return email; // Return as is if local part is too short
  // };
  

  return (
    <>
     {!isModalOpen &&
    <div className="flex h-screen w-screen">
    {/* Left Section (3/4 width) */}
    <div className="w-3/5 bg-gradient-to-br from-[#235DB6] to-[#0044AB] flex flex-col justify-center items-center ">
      <div className="text-center text-white max-w-[500px] px-8">
      <h1 className="text-5xl font-bold m-auto ml-[50px]">
        <img
           style={{ width: '300px',
            height: '80px'}}
            src={ InvoiceFileLogo}
            alt="InvoiceFile"
            className={`${ "w-max"}`}
          />

        </h1>
        <p className="text-xl font-normal mb-14 text-white-300">Smart Invoice Processing</p>
        <h2 className="text-[28px] font-bold mb-4">
          Create Professional Invoices to Send to Your Customers
        </h2>
        <p className="text-base leading-relaxed ">
          Streamline your billing process with 
        </p>
        <p className="text-md leading-relaxed ">
        professional, easy-to-use
        templates.
        </p>
      </div>
    </div>

    {/* Right Section (1/4 width) */}
    <div className="w-2/5 flex justify-center items-center">
      <div className="w-full max-w-sm px-8 py-12">
        <div className="text-center mb-8">
          <div className="w-24 h-24 bg-gray-200 rounded-full m-auto flex items-center justify-center">
      <PersonIcon style={{ fontSize: 50, color: "gray" }} />

          </div>
          <h2 className="text-2xl text-gray-600 font-bold mt-2">User Login</h2>
        </div>

        <form className="space-y-3"  onSubmit={formik.handleSubmit}>
        {invalidemailError && showTooltip() && (
                  <CustomTooltip content="Invalid email address" styles={showTooltip()} />
                )}
          <div className="relative">
            <label htmlFor="email" className=" text-sm font-medium text-gray-700">
              Email
            </label>
            <div className="relative">

            <span className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <MdAttachEmail
                      className="text-gray-500 absolute top-1/2 transform -translate-y-1/2 left-3"
                      style={{ fontSize: "18px" }}
                    />
    </span>
            <input
              type="text"
              name="email"
              id="email"
              autoComplete="off"
              value={formik.values.email}
              onChange={(e) => {
                setEmailError('')
                handleInputChange(e);
                formik.setFieldValue('email', e.target.value.trim());
              }}
              // onBlur={handleBlur}
              onBlur={(e) => {
                handleBlur(e)
                setinvalidEmailError(true)
                if (validateEmail(realEmail)) {
                
                  setEmailInputStyles(
                    'pl-10 w-full px-3 py-2 border-2 rounded-lg bg-green-100 text-green-600 border-green-600 pr-10 '
                  );
                } else {
                
                  setEmailInputStyles(
                    'pl-10 w-full px-3 py-2 rounded-lg focus:ring-blue-500 focus:border-blue-500 pr-10'
                  );
                }
              }}
              
              onFocus={() =>{
                setinvalidEmailError(false)
                setEmailInputStyles(
                  'pl-10 w-full px-3 py-2 border rounded-lg focus:ring-blue-500 focus:border-blue-500 pr-10'
                )
              }
              }
              placeholder="Enter your email address"
              // onFocus={handleFocus}
              className={`${EmailinputStyles} pl-10 w-full px-3 py-2 border rounded-lg focus:ring-blue-500 focus:border-blue-500  ${
                showTooltip() ? "border-red-500" : ""
              } `}
            />
                        </div>

          </div>
          <div className="relative">
            <div  className="flex justify-between items-baseline">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password
           
            </label>
        
            </div>
            <div className="relative">

<span className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
<FaLock
                      className="text-gray-500 absolute top-1/2 transform -translate-y-1/2 left-3"
                    />
</span>
            {/* <input
              type={passwordVisible ? 'text' : 'password'}
              name="password"
              id="password"
              value={password}
              onChange={(e) => {
                // Ensure only numeric values are entered
              
                // const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
                // Ensure only six digits are considered
                const truncatedValue = sanitizedValue.slice(0, 6);
                setPassword(truncatedValue);
              }}
              required
              placeholder="Enter your password"
              className="pl-10 w-full px-3 py-2 border rounded-lg focus:ring-blue-500 focus:border-blue-500 pr-10"
            /> */}
                <input
              type={passwordVisible ? 'text' : 'password'}
              name="password"
      id="password"
      value={password}
      onChange={(e) => {
        setEmailError('')

        const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
        const truncatedValue = sanitizedValue.slice(0, 6);
        setPassword(truncatedValue);

      }}
      onBlur={() => {
        if (password === "") {
        
          setInputStyles(
            'pl-10 w-full px-3 py-2 rounded-lg focus:ring-blue-500 focus:border-blue-500 pr-10'
          );
        } else {
          setInputStyles(
            'pl-10 w-full px-3 py-2 border-2 rounded-lg bg-green-100 text-green-600 border-green-600 pr-10 '
          );
        }
      }}
      
      onFocus={() =>
        setInputStyles(
          'pl-10 w-full px-3 py-2 border rounded-lg focus:ring-blue-500 focus:border-blue-500 pr-10'
        )
      }
      required
      placeholder="Enter your password"
      className={inputStyles}
    />
            </div>
            {/* <div className="text-right mt-1" onClick={handleForgotPassword}>
              <a href="#" className="text-blue-500 text-sm hover:underline">
                Forgot Password?
              </a>
            </div> */}
                 {password && password.length > 0 &&(
                            <div
                              className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer text-gray-500 pt-[30px]"
                              onClick={() => setPasswordVisible(!passwordVisible)}
                            >
                              {passwordVisible ? <FaEye /> : <FaEyeSlash /> }
                            </div>
                          )}
          </div>

          <div  className="relative">
          <div  className="flex justify-between items-baseline">

            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Domain Name
            </label>
            </div>
       
            <div className="relative">

<span className="absolute inset-y-0 left-0 my-auto pl-3 flex items-center pointer-events-none">
<MdBusinessCenter
                      className="text-gray-500 absolute top-[20px] transform -translate-y-1/2 left-3"
                      style={{ fontSize: "20px" }}
                    />
</span>
            {/* <input
              type="text"
              id="domainName"
              name="domainName"
              value={formik.values.domainName}
              onChange={(e) => {
                handleInputChangeDomain(e);
                formik.setFieldValue('domainName', e.target.value);
              }}
              onBlur={formik.handleBlur}
              required
              placeholder="Enter your domain name"
              className="pl-10 w-full px-3 py-2 border rounded-lg focus:ring-blue-500 focus:border-blue-500 mb-4"
            /> */}
       <input
        type="text"
        id="domainName"
        name="domainName"
        autoComplete="off"
        value={formik.values.domainName}
        onChange={(e) => {
          setEmailError('')

          handleInputChangeDomain(e);
          formik.setFieldValue('domainName', e.target.value);
        }}
        onBlur={(e) => {
          formik.handleBlur(e);
          // Apply green styles only if input is not empty
          if (formik.values.domainName) {
         
            setDomainInputStyles(
              'pl-10 w-full px-3 py-2 border-2 rounded-lg bg-green-100 text-green-600 border-green-600 pr-10 mb-4'
            );
          } 
        }}
        onFocus={() =>
          setDomainInputStyles(
            'pl-10 w-full px-3 py-2 border rounded-lg focus:ring-blue-500 focus:border-blue-500 mb-4'
          )
        }
        required
        placeholder="Enter your domain name"
        className={domaininputStyles}
      />
            </div>
          </div>
          {domainError && <div style={{color:'red'}}>{domainError}</div>}
          <button
  type="submit"
  className={`transition transform active:scale-95 w-full text-white font-bold py-2 rounded-lg mt-8 ${
    realEmail && password && formik.values.domainName && !showTooltip()
      ? 'bg-gradient-to-br from-[#235DB6] to-[#0044AB] cursor-pointer'
      : 'bg-[#93c5fd] cursor-not-allowed'
  }`}
  onClick={handleLoginClick}
  disabled={
    !(realEmail && password && formik.values.domainName && !showTooltip())
  }
>
  LOGIN
</button>

          {/* <button
            type="submit"
            className="w-full bg-blue-600 text-white font-bold py-2 rounded-lg hover:bg-blue-700 transition"
            onClick={()=>handleSignUpClick()}
            >
            SIGN UP
          </button> */}
          <div className="flex justify-between">
          <div className="text-left " onClick={handleSignUpClick}>
              <a href="#" className="text-blue-500 text-sm hover:underline hover:bg-green-100 p-2 hover:text-green-600 ">
              Sign Up
              </a>
            </div>
            <div className="text-right " onClick={handleForgotPassword}>
              <a href="#" className="text-blue-500 text-sm hover:underline hover:bg-orange-100 p-2 hover:text-orange-600">
                Forgot Password?
              </a>
            </div>
            </div>
     
        </form>

        {emailError && <div style={{color:"red"}} className="flex justify-center mt-8">{emailError}</div>}


        <div className="mt-[110px] text-center ">
          <div className="flex items-center text-gray-600 text-sm mb-1">
      <div className="flex-grow border-t border-gray-300"></div>
      <span className="px-2 font-bold">Follow Us</span>
      <div className="flex-grow border-t border-gray-300"></div>
    </div>
          
    <div className="flex justify-center space-x-1">
  <a
    href="#"
    className="text-gray-600 hover:bg-gray-200 w-10 h-10 flex items-center justify-center rounded-full"
  >
    <i className="fab fa-facebook-f text-md"></i>
  </a>
  <a
    href="#"
    className="text-gray-600 hover:bg-gray-200 w-10 h-10 flex items-center justify-center rounded-full"
  >
    <i className="fab fa-instagram text-md"></i>
  </a>
  <a
    href="#"
    className="text-gray-600 hover:bg-gray-200 w-10 h-10 flex items-center justify-center rounded-full"
  >
    <i className="fab fa-twitter text-md"></i>
  </a>
  <a
    href="#"
    className="text-gray-600 hover:bg-gray-200 w-10 h-10 flex items-center justify-center rounded-full"
  >
    <i className="fab fa-linkedin-in text-md"></i>
  </a>
</div>

        </div>

      </div>
    </div>
  </div>
  }
                                                                     {isModalOpen && (
  <div className="flex h-screen w-screen">
  {/* Left Section (3/4 width) */}
  <div className="w-3/5 bg-gradient-to-br from-[#235DB6] to-[#0044AB] flex flex-col justify-center items-center">
    <div className="text-center text-white max-w-[500px] px-8">
    <h1 className="text-5xl font-bold m-auto ml-[50px]">
        <img
           style={{ width: '300px',
            height: '80px'}}
            src={ InvoiceFileLogo}
            alt="InvoiceFile"
            className={`${ "w-max"}`}
          />

        </h1>
      <p className="text-xl font-normal mb-14 text-white-300">Smart Invoice Processing</p>
      <h2 className="text-[28px] font-bold mb-4">
        Create Professional Invoices to Send to Your Customers
      </h2>
      <p className="text-base leading-relaxed ">
          Streamline your billing process with 
        </p>
        <p className="text-md leading-relaxed ">
        professional, easy-to-use
        templates.
        </p>
    </div>
  </div>

  {/* Right Section (1/4 width) */}
  <div className="w-2/5 flex justify-center items-center">
    <div className="w-full max-w-sm px-8 py-12">
      <div className="text-center mb-8">
        <div className="w-24 h-24 bg-gray-200 rounded-full m-auto flex items-center justify-center">
    <PersonIcon style={{ fontSize: 50, color: "gray" }} />

        </div>
        <h2 className="text-2xl text-gray-600 font-bold mt-4">Select User Role </h2>
      </div>

    {/* userole selction */}
      {/* Owner Role */}
      <div className="transition transform active:scale-95 cursor-pointer flex items-center w-30 p-1 mb-4 border-2 border-orange-300 rounded-md bg-orange-50"  onClick={handleLoginAsOwner}>
        <div className="w-12 h-12 bg-orange-100 rounded-full flex items-center justify-center">
          <PersonIcon style={{ fontSize: 30, color: "#F6AD55" }} />
        </div>
        <div className="ml-4">
          <h2 className="font-bold text-orange-600 text-md">OWNER</h2>
          <p className="text-sm text-gray-600">Full Access to All Functions</p>
        </div>
      </div>

      {/* User Role */}
      <div className="transition transform active:scale-95 cursor-pointer  flex items-center w-30 p-1 border-2 border-green-300 rounded-md bg-green-50"
         onClick={handleLoginAsManager}
      >
        <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center">
          <PersonIcon style={{ fontSize: 30, color: "#48BB78" }} />
        </div>
        <div className="ml-4">
          <h2 className="font-bold text-green-600 text-md">USER</h2>
          <p className="text-sm text-gray-600">Uploading and Invoice Creation</p>
        </div>
      </div>

    {/* userrole ends here */}
 

    <div className="mt-[240px] text-center ">
          <div className="flex items-center text-gray-600 text-sm mb-3">
      <div className="flex-grow border-t border-gray-300"></div>
      <span className="px-2 font-bold">Follow Us</span>
      <div className="flex-grow border-t border-gray-300"></div>
    </div>
          
    <div className="flex justify-center space-x-1">
  <a
    href="#"
    className="text-gray-600 hover:bg-gray-200 w-10 h-10 flex items-center justify-center rounded-full"
  >
    <i className="fab fa-facebook-f  text-md"></i>
  </a>
  <a
    href="#"
    className="text-gray-600 hover:bg-gray-200 w-10 h-10 flex items-center justify-center rounded-full"
  >
    <i className="fab fa-instagram  text-md"></i>
  </a>
  <a
    href="#"
    className="text-gray-600 hover:bg-gray-200 w-10 h-10 flex items-center justify-center rounded-full"
  >
    <i className="fab fa-twitter  text-md"></i>
  </a>
  <a
    href="#"
    className="text-gray-600 hover:bg-gray-200 w-10 h-10 flex items-center justify-center rounded-full"
  >
    <i className="fab fa-linkedin-in  text-md"></i>
  </a>
</div>
        </div>

    </div>
  </div>
</div>
  )}
  </>

  );
};

export default UserLoginPage;



